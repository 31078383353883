import Drawer from "@/components/drawer/Drawer";
import ViewJSON from "@/components/ViewJSON";
import { formatCurrency } from "@/utils/helper";
import { api } from "@/utils/trpc";
import formatDistance from "date-fns/formatDistance";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GocardlessBank } from "./Banks";

type Props = {
  account: GocardlessBank["accounts"][number];
};

const AccountRow: FC<Props> = ({ account }) => {
  const nav = useNavigate();
  const { id } = useParams<{ id?: string }>();

  const { data } = api.account.coverage.useQuery(account.id);
  return (
    <>
      <tr
        onClick={() => nav(`/account/${account.id}`)}
        key={account.id}
        className="bg-neutral cursor-pointer"
      >
        <td>
          <p>{account.ownerName || "N/A"}</p>
          <p className="text-xs text-secondary">
            Synced{" "}
            {formatDistance(account.updatedAt, new Date(), { addSuffix: true })}
          </p>
        </td>
        <td className="">
          {!!data?.total && (
            <div className="flex justify-center text-secondary text-xs items-center gap-3">
              <div className="grid justify-start">
                <span>Missing Categories: {data.categoryPercentage} %</span>
                <span>Missing Merchant: {data.merchantPercentage} %</span>
              </div>
              <div className="flex !w-fit items-center gap-1">
                Total
                <span className="font-bold">{data.total}</span> transactions
              </div>
            </div>
          )}
        </td>

        <td className="text-right">
          <h2 className="text-lg font-semibold">
            {formatCurrency(account.availableBalance, account.currency)}
          </h2>
          <p className="text-xs">{account.IBAN || account.BBAN}</p>
        </td>
      </tr>
      <Drawer
        isOpen={id === account.id}
        onClose={() => nav("/accounts/connected")}
        title="Account Details"
      >
        <div>
          <ViewJSON json={account.rawJSON} />
        </div>
      </Drawer>
    </>
  );
};

export default AccountRow;
