import { PageTitle } from "@/ui/PageTitle";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div className="">
      <PageTitle title="Page not found" />
      <Link className="link link-primary" to={"/"}>
        Take me home
      </Link>
    </div>
  );
}

export default NotFound;
