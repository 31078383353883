import LoadingSpin from "@/ui/LoadingSpin";
import SelectCategories, { Categories } from "@/ui/SelectCategories";
import { api } from "@/utils/trpc";
import { FC } from "react";
import { FaWandMagicSparkles } from "react-icons/fa6";
import { IoMdCheckmarkCircleOutline, IoMdRemoveCircle } from "react-icons/io";
import useTransactions from "../hooks/useTransactions";
import { TransactionHistory } from "../Transactions";

type Props = {
  data: TransactionHistory;
  readonly?: boolean;
};

const ManageCategories: FC<Props> = ({
  data: { category, subCategory, categorySuggestion, id },
  readonly,
}) => {
  const update = useTransactions();
  const add = api.transaction.category.add.useMutation();

  const setCategories = async (
    categories: Categories = {},
    suggestionAccepted?: boolean
  ) => {
    if (readonly) return;
    let { category, subCategory } = categories;

    if (suggestionAccepted && categorySuggestion) {
      category = categorySuggestion.category;
      subCategory = categorySuggestion.subCategory;
    }

    const res = await add.mutateAsync({
      categoryId: category ? category.id : null,
      subCategoryId: subCategory ? subCategory.id : null,
      suggestionAccepted,
      transactionId: id,
    });

    console.log(res);

    update(id, res);
  };

  if (categorySuggestion && categorySuggestion.accepted === null) {
    const { category, subCategory } = categorySuggestion;
    return (
      <div className="w-fit">
        <p className="label label-text-alt text-accent justify-start gap-2">
          <FaWandMagicSparkles />
          Review AI suggestion
        </p>
        <div className="btn w-full">
          <LoadingSpin loading={add.isLoading} />

          <div className="grid justify-items-center">
            <div className="flex scale-90 items-center gap-2">
              <span
                className="btn scale-75 btn-circle btn-xs "
                style={{ background: category.color }}
              />

              {category.name}
            </div>
            {subCategory && (
              <div className="flex scale-75 items-center gap-2">
                <span
                  className="btn btn-circle scale-75 btn-xs"
                  style={{ background: subCategory.color }}
                />

                {subCategory.name}
              </div>
            )}
          </div>
          {!readonly && (
            <div className="flex items-center join">
              <button
                className="btn btn-success join-item btn-xs tooltip tooltip-left tooltip-success"
                onClick={() => setCategories(categorySuggestion, true)}
                data-tip="Approve"
              >
                <IoMdCheckmarkCircleOutline />
              </button>
              <button
                className="btn join-item btn-error btn-outline btn-xs tooltip tooltip-right tooltip-error"
                data-tip="Reject"
                onClick={() => setCategories(undefined, false)}
              >
                <IoMdRemoveCircle />
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }

  if (readonly) {
    return (
      <div className="grid w-fit">
        {category ? (
          <div className="flex scale-90 items-center gap-2">
            <span
              className="btn scale-75 btn-circle btn-xs "
              style={{ background: category.color }}
            />

            {category.name}
          </div>
        ) : (
          <p className="text-xs mx-14">N/A</p>
        )}
        {subCategory && (
          <div className="flex scale-75 items-center gap-2">
            <span
              className="btn btn-circle scale-75 btn-xs"
              style={{ background: subCategory.color }}
            />

            {subCategory.name}
          </div>
        )}
      </div>
    );
  }
  return (
    <SelectCategories
      {...{
        categories: { category, subCategory },
        setCategories,
        isLoading: add.isLoading,
      }}
    />
  );
};

export default ManageCategories;
