import { useOrganization } from "@/context/OrganizationContext";
import { api } from "@/utils/trpc";
import { Vendor } from "../vendors/Vendors";

const useVendors = () => {
  const utils = api.useUtils();
  const { organizationId } = useOrganization<true>();

  const update = (data: Vendor, _delete = false) => {
    utils.organizations.vendor.list.setData(organizationId, (p = []) => {
      const res = p.filter((v) => v.id !== data.id);
      if (!_delete) res.unshift(data);
      return res;
    });
  };

  return update;
};

export default useVendors;
