import { useOrganization } from "@/context/OrganizationContext";
import { api, RouterOutputs } from "@/utils/trpc";
import MerchantRow from "./MerchantRow";

type Props = {};

export type Merchant =
  RouterOutputs["organizations"]["merchant"]["list"][number];

const Merchants = (props: Props) => {
  const { organizationId } = useOrganization<true>();

  const { data = [] } =
    api.organizations.merchant.list.useQuery(organizationId);
  return (
    <div>
      <table className="table bg-base-200">
        <thead>
          <tr>
            <th> Name</th>
            <th>Created At</th>
            <th>Vendor</th>
            <th> Code</th>
          </tr>
        </thead>
        <tbody>
          {data.map((merchant) => (
            <MerchantRow merchant={merchant} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Merchants;
