import { FC } from "react";
import { BsFillFileEarmarkCheckFill } from "react-icons/bs";
import { MdDeleteOutline } from "react-icons/md";
import DropZoneButton from "./DropZone/DropZoneButton";

type Props = {
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  files: File[];
};

const FileInput: FC<Props> = ({ setFiles, files }) => {
  return (
    <div className="">
      <ul className="grid lg:w-96 w-42">
        {files.map((f, i) => (
          <li
            key={f.name + i}
            className="flex mb-2 items-center bg-base-200 p-2 rounded-md gap-5"
          >
            <div className="flex-1 flex items-center gap-2">
              <BsFillFileEarmarkCheckFill className="text-secondary" />
              <span className="label label-text-alt">{f.name}</span>
            </div>
            <button
              onClick={() => setFiles((f) => f.filter((_, _i) => _i !== i))}
              className="btn btn-error btn-sm btn-outline border-none"
            >
              <MdDeleteOutline />
            </button>
          </li>
        ))}
      </ul>

      <DropZoneButton
        files={files}
        setFiles={(f) => setFiles((p) => [...p, ...f])}
      >
        <button className="btn btn-secondary">Choose files</button>
      </DropZoneButton>
    </div>
  );
};

export default FileInput;
