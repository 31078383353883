import FormInput from "@/components/FormInput";
import { useOrganization } from "@/context/OrganizationContext";
import { useUser } from "@/context/UserContext";
import LoadingSpin from "@/ui/LoadingSpin";
import { formatLabel, wait } from "@/utils/helper";
import { api } from "@/utils/trpc";
import useForm from "@/utils/useForm";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import { z } from "zod";

type Props = {};

const Validate = z.object({
  amount: z.number().min(1),
  IBAN: z.string().min(1),
  name: z.string().min(1),
  reference: z.string().optional(),
});

type Validate = z.infer<typeof Validate>;

const Transfer = (props: Props) => {
  const { inputs, handleChange, setValue, errors, setErrors } = useForm<
    Partial<Validate>
  >({});
  const pay = api.swan.transfers.initiate.useMutation();
  const [params] = useSearchParams();

  const {
    user: { swanUser },
  } = useUser<true>();
  const {
    organization: { swanAccount },
  } = useOrganization<true>();

  const handlePay = async () => {
    if (!swanUser || !swanAccount)
      return toast.error("Pro account/user not found");
    const valid = Validate.safeParse(inputs);

    if (!valid.success) return setErrors(valid.error.formErrors.fieldErrors);

    const res = await pay.mutateAsync({
      ...valid.data,
      accountId: swanAccount.id,
      consentRedirectUrl: window.location.href,
      swanUserId: swanUser.id,
      payees: [valid.data],
    });

    if (!res) return toast.error("Failed to initiate payment");

    if (res.__typename !== "InitiateCreditTransfersSuccessPayload") {
      return toast.error(
        `${formatLabel(res.__typename)}: ${(res as any).message}`
      );
    }

    if (res.payment.statusInfo.__typename !== "PaymentConsentPending") {
      return toast.error(
        `${formatLabel(res.payment.statusInfo.__typename)}: ${
          (res as any).payment.statusInfo.message || ""
        }`
      );
    }

    await toast.promise(wait(1000), {
      loading: "Authorizing your request ...",
      error: "Failed to redirect to OAuth",
      success: "Redirecting to OAuth to get your consent ...",
    });
    await wait(500);

    window.open(res.payment.statusInfo.consent.consentUrl, "_blank");
  };
  return (
    <div className="w-full grid content-start">
      <div className="grid w-96 ">
        <FormInput
          isError={errors.name}
          title="Beneficiary Name"
          value={inputs.name}
          onChange={handleChange("name")}
        />
        <FormInput
          isError={errors.IBAN}
          title="Beneficiary IBAN"
          value={inputs.IBAN}
          onChange={handleChange("IBAN")}
        />
        <FormInput
          isError={errors.amount}
          title="Amount"
          value={inputs.amount}
          onChange={({ target }) => setValue("amount", +target.value)}
        />
        <FormInput
          title="Reference"
          value={inputs.reference}
          onChange={handleChange("reference")}
        />
        <button
          onClick={handlePay}
          className="btn btn-primary btn-sm w-96 mt-6"
        >
          <LoadingSpin loading={pay.isLoading} />
          Pay
        </button>
      </div>
    </div>
  );
};

export default Transfer;
