import { format } from "date-fns/esm";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import UpdateVendor from "./UpdateVendor";
import { Vendor } from "./Vendors";

type Props = {
  vendor: Vendor;
};

const VendorRow: FC<Props> = ({ vendor }) => {
  const nav = useNavigate();
  return (
    <tr
      className="border-white/10 cursor-pointer"
      onClick={() => nav(`/partners/vendors/${vendor.id}`)}
    >
      <td>{vendor.name}</td>
      <td>{vendor.email}</td>
      <td>{format(new Date(vendor.createdAt), "MMM dd, yyyy")}</td>
      <UpdateVendor vendor={vendor} />
    </tr>
  );
};

export default VendorRow;
