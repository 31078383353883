import { useOrganization } from "@/context/OrganizationContext";
import { useUser } from "@/context/UserContext";
import { api } from "@/utils/trpc";
import { FC, useMemo, useState } from "react";
import { RiMenu4Fill } from "react-icons/ri";

import { GoOrganization } from "react-icons/go";
import { Link, useLocation } from "react-router-dom";
import ImageFromPath from "../../ui/ImageFromPath";
import OrganizationsList from "../../ui/OrganizationsList";
import { NavigationControl } from "./NavigationControl";

const defaultValue = {
  transactionsWithoutProof: { id: 0 },
  unApprovedBills: [],
  unApprovedExpenses: [],
  unApprovedInvoices: [],
  unCategorizedTransactions: { id: 0 },
} as const;

const control = new NavigationControl();

const DesktopNavBar: FC = () => {
  const { pathname } = useLocation();
  const { organization, organizationId = "" } = useOrganization();
  const { userId = "" } = useUser();
  const { data: tasks = defaultValue } = api.utils.tasks.useQuery({
    organizationId,
    userId,
  });

  const totalTasks = useMemo(() => {
    return (
      tasks.unApprovedBills.length +
      tasks.unApprovedExpenses.length +
      tasks.unApprovedInvoices.length
    );
  }, [tasks]);

  const links = useMemo(() => {
    if (!organization) return [];
    return control.validNavigationLinks(organization.billingPlan);
  }, [organizationId]);

  const [open, setOpen] = useState(true);

  return (
    <div
      className={`bg-base-200/90 hidden overflow-auto h-screen relative md:block border-r border-gray-400/50 duration-300 transition-all ${
        !open ? "w-[3.5rem]" : "w-fit"
      }`}
    >
      <button
        className="btn btn-xs mx-1 my-6 btn-ghost"
        onClick={() => setOpen((p) => !p)}
      >
        <RiMenu4Fill size={30} />
      </button>
      <div className="px-0.5 ">
        {open ? (
          <div className="w-56 z-20">
            <OrganizationsList />
          </div>
        ) : (
          <div
            className="tooltip  tooltip-right"
            data-tip={organization?.name}
            onClick={() => setOpen(true)}
          >
            <div className="btn-neutral p-0 h-10 w-10 btn ml-1">
              {organization?.logo ? (
                <ImageFromPath
                  key={organization.id}
                  path={organization.logo}
                  className="rounded-full w-8"
                />
              ) : (
                <GoOrganization size={30} />
              )}
            </div>
          </div>
        )}
        <div className="divider px-2 h-0"></div>
      </div>
      <ul className="menu">
        {links.map(({ Icon, name, path, end }, i) => {
          return (
            <>
              <li className="clicky mb-0.5">
                <Link
                  to={path}
                  key={i}
                  className={`px-2.5 tooltip tooltip-right z-10 ${
                    path === pathname ? "active" : ""
                  } ${open ? "w-full" : "w-fit"}`}
                  data-tip={open ? null : name}
                >
                  <div className="flex gap-4  items-center">
                    <Icon size={22} />
                    {open && <p className="w-max capitalize">{name}</p>}

                    {path === "/tasks" && totalTasks > 0 && (
                      <span
                        className={`badge badge-md badge-warning ${
                          open ? "relative" : "absolute -right-6 -top-4"
                        } `}
                      >
                        {totalTasks}
                      </span>
                    )}
                  </div>
                </Link>
              </li>
              {end && <div className="divider h-1"></div>}
            </>
          );
        })}
      </ul>
    </div>
  );
};
export default DesktopNavBar;
