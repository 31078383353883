import { useUser } from "@/context/UserContext";
import { AiOutlineLogout } from "react-icons/ai";

type Props = {};

const PendingApproval = (props: Props) => {
  const { logout } = useUser();
  return (
    <div className="grid justify-center items-center h-screen w-screen">
      <div className="h-full gap-5 w-full grid place-items-center content-center">
        <h1 className="logo">Dynt</h1>
        <p>Hold on! Our team will duly contact you and verify your account</p>
        <div>
          <span className="loading loading-bars loading-lg"></span>
        </div>

        <button
          onClick={logout}
          className="bg-red-500 mt-16 flex justify-center gap-2 items-center text-white px-8 py-2 text-base rounded-md"
        >
          <AiOutlineLogout size="24" />
          Logout
        </button>
      </div>
    </div>
  );
};

export default PendingApproval;
