import Dropdown from "@/components/Dropdown";
import { useOrganization } from "@/context/OrganizationContext";
import LoadingSpin from "@/ui/LoadingSpin";
import { api, RouterOutputs } from "@/utils/trpc";
import { FC, useMemo, useState } from "react";
import { HiChevronRight, HiPlus } from "react-icons/hi";
import UpdateReview from "./UpdateReview";

type Props = {
  expenseId: string;
  reviews: RouterOutputs["expenses"]["get"]["reviews"];
  team_member_id: string;
};

const colors = {
  pending: "btn-warning",
  approved: "btn-success",
  rejected: "btn-error",
  not_needed: "",
};

const ManageReviewer: FC<Props> = ({ reviews, expenseId, team_member_id }) => {
  const { organization } = useOrganization<true>();

  const { data: members = [], isLoading } =
    api.organizations.team_member.list.useQuery(organization.id);
  const utils = api.useUtils();

  const assignToExpense = api.expenses.reviewer.manage.useMutation();

  const [selected, setSelected] = useState<string[]>(
    reviews.map((r) => r.reviewerId)
  );
  const [changed, setChanged] = useState(false);

  const handleAssign = async () => {
    if (expenseId) {
      const res = await assignToExpense.mutateAsync({
        expenseId,
        reviewers: selected,
      });

      utils.expenses.get.setData(expenseId, (p) => {
        if (!p) return p;
        return { ...p, reviews: res };
      });
    }
    setChanged(false);
  };

  const loading = assignToExpense.isLoading;

  const selectSet = new Set(selected);

  const membersMap = useMemo(
    () => Object.fromEntries(members.map((m) => [m.id, m])),
    [members]
  );

  return (
    <div>
      <div className="flex w-full justify-between items-center">
        <div className="label">Reviewers</div>
        <Dropdown
          hidden={organization.role !== "admin"}
          title={loading ? <LoadingSpin loading /> : <HiPlus />}
          dropdownClass="w-60 justify-end flex dropdown-bottom"
          hideArrow
          buttonClass="btn-neutral btn-circle !w-8 btn-sm"
          content={members.map((s) => {
            if (team_member_id === s.id) return null;
            return (
              <button
                className={`justify-between hover:bg-neutral capitalize ${
                  selectSet.has(s.id) ? "bg-neutral" : "bg-base-300"
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  if (selectSet.has(s.id)) {
                    setSelected(selected.filter((id) => id !== s.id));
                  } else setSelected([...selected, s.id]);
                  setChanged(true);
                }}
                onTouchEnd={(e) => {
                  e.stopPropagation();
                  if (selectSet.has(s.id)) {
                    setSelected(selected.filter((id) => id !== s.id));
                  } else setSelected([...selected, s.id]);
                  setChanged(true);
                }}
              >
                {s.user.name}
                <HiChevronRight />
              </button>
            );
          })}
          footer={
            changed && (
              <div className="w-full sticky bottom-0">
                <button
                  onClick={handleAssign}
                  onTouchEnd={handleAssign}
                  className="btn btn-primary sticky bottom-0 w-48 btn-sm"
                >
                  Save
                </button>
              </div>
            )
          }
        />
      </div>
      <div className="grid items-center gap-1">
        {reviews.map(({ id, reviewerId, approval }) => {
          if (!membersMap[reviewerId]) return null;

          const _me = reviewerId === organization.team_member_id;
          const canChange = _me && approval === "pending";

          if (canChange) {
            return <UpdateReview expenseId={expenseId} reviewId={id} />;
          }
          return (
            <div className="btn justify-between" key={id}>
              <div className="">{membersMap[reviewerId].user.name}</div>
              <span
                className={`btn capitalize btn-xs w-fit ${colors[approval]}`}
              >
                {approval}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ManageReviewer;
