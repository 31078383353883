import FormInput from "@/components/FormInput";
import Select from "@/components/Select";
import Switch from "@/components/Switch";
import { useOrganization } from "@/context/OrganizationContext";
import { SwanLimitPeriod } from "@/types/validation";
import LoadingSpin from "@/ui/LoadingSpin";
import { formatLabel, wait } from "@/utils/helper";
import { api, RouterOutputs } from "@/utils/trpc";
import useForm from "@/utils/useForm";
import { FC } from "react";
import toast from "react-hot-toast";
import { FaRegEye } from "react-icons/fa";

type Props = {
  card: RouterOutputs["swan"]["card"]["list"][number];
};

const UpdateCard: FC<Props> = ({ card }) => {
  const { inputs, setErrors, errors, handleChange, setValue } = useForm(card);
  const { organization } = useOrganization();

  const edit = api.swan.card.edit.useMutation();
  const viewDetails = api.swan.card.viewDetails.useMutation();

  const handleUpdate = async () => {
    if (!organization?.swanAccountMembership?.userId) {
      return toast.error("Swan account membership user info not found");
    }

    const { name, membershipId: _, limitAmount, ...rest } = inputs;
    if (!name) {
      return setErrors({ name: "Name is required" });
    }

    const res = await edit.mutateAsync({
      ...rest,
      name,
      swanUserId: organization.swanAccountMembership.userId,
      consentRedirectUrl: `${window.location.origin}/pro-account/cards`,
      cardId: card.id,
      limitAmount: +limitAmount,
    });

    if (res.__typename !== "UpdateCardSuccessPayload") {
      return toast.error(
        `${formatLabel(res.__typename)}: ${(res as any).message || ""}`
      );
    }

    await toast.promise(wait(1000), {
      loading: "Authorizing your request ...",
      error: "Failed to redirect to OAuth",
      success: "Redirecting to OAuth to get your consent ...",
    });
    await wait(500);

    window.open(res.consent.consentUrl);
  };

  const handleViewDetails = async () => {
    if (!organization?.swanAccountMembership?.userId) {
      return toast.error("Swan account membership user info not found");
    }
    const res = await viewDetails.mutateAsync({
      swanUserId: organization.swanAccountMembership.userId,
      cardId: card.id,
      consentRedirectUrl: `${window.location.origin}/pro-account/cards`,
    });

    if (res.__typename !== "ViewCardNumbersSuccessPayload") {
      return toast.error(
        `${formatLabel(res.__typename)}: ${(res as any).message || ""}`
      );
    }

    await toast.promise(wait(1000), {
      loading: "Authorizing your request ...",
      error: "Failed to redirect to OAuth",
      success: "Redirecting to OAuth to get your consent ...",
    });
    await wait(500);

    window.open(res.consent.consentUrl);
  };
  return (
    <div className="grid gap-6">
      <button
        onClick={handleViewDetails}
        className="btn btn-outline mx-auto btn-secondary btn-sm text-sm"
      >
        <LoadingSpin loading={viewDetails.isLoading} />
        <FaRegEye />
        Reveal card numbers
      </button>
      <FormInput
        placeholder="Card name"
        isError={errors.name}
        value={inputs.name}
        onChange={handleChange("name")}
        title="Name"
      />

      <div className="grid gap-2">
        <div className="divider divider-start">Permissions</div>

        <Switch
          value={inputs.withdrawal}
          onChange={(e) => setValue("withdrawal", e)}
          text="Withdrawal"
          className="!w-full justify-between"
        />

        <Switch
          value={inputs.international}
          onChange={(e) => setValue("international", e)}
          text="International"
          className="!w-full justify-between"
        />

        <Switch
          value={inputs.nonMainCurrencyTransactions}
          onChange={(e) => setValue("nonMainCurrencyTransactions", e)}
          text="Non Main Currency Transactions"
          className="!w-full justify-between"
        />

        <Switch
          value={inputs.eCommerce}
          onChange={(e) => setValue("eCommerce", e)}
          text="eCommerce"
          className="!w-full justify-between"
        />

        <div>
          <div className="divider divider-start j">Spending Limit</div>

          <div className="flex items-center join">
            <p className="btn border border-white/10 btn-neutral btn-sm join-item">
              EUR
            </p>
            <input
              type="text"
              value={+inputs.limitAmount}
              className="input input-sm join-item input-bordered w-44"
              placeholder="Amount"
              onChange={handleChange("limitAmount")}
              data-number
            />
            <Select
              options={SwanLimitPeriod.options.map((value) => ({
                value,
                label: value.replace("Always", "Total"),
              }))}
              onChange={(e) => setValue("limitPeriod", e)}
              className="!w-28"
              buttonClass="btn-sm join-item"
              value={inputs.limitPeriod}
              placeholder="Period"
            />
          </div>
        </div>
      </div>

      <button onClick={handleUpdate} className="btn btn-sm btn-primary">
        <LoadingSpin loading={edit.isLoading} />
        Save
      </button>
    </div>
  );
};

export default UpdateCard;
