import DisplayName from "@/components/DisplayName";
import InViewTrigger from "@/components/InViewTrigger";
import MultiSelect from "@/components/MultiSelect";
import Switch from "@/components/Switch";
import NotFound from "@/components/utils/notfound";
import { useOrganization } from "@/context/OrganizationContext";
import { months } from "@/lib";
import { TransactionFiltersType } from "@/types/validation";
import Tooltip from "@/ui/Tooltip";
import Rows from "@/ui/skeletons/Rows";
import { encodeObjectToBase64, formatCurrency, wait } from "@/utils/helper";
import { api } from "@/utils/trpc";
import endOfYear from "date-fns/endOfYear";
import startOfYear from "date-fns/startOfYear";
import { FC, useEffect, useMemo, useState } from "react";
import {
  BsArrowDownLeft,
  BsArrowUpRight,
  BsSortDown,
  BsSortUp,
} from "react-icons/bs";
import { useNavigate } from "react-router-dom";

type Props = {
  year: number;
};

const SortIcon = {
  asc: <BsSortUp size={16} />,
  desc: <BsSortDown size={16} />,
} as const;

const MerchantReports: FC<Props> = ({ year }) => {
  const { organizationId } = useOrganization<true>();

  const [currentPage, setCurrentPage] = useState(1);
  const [type, setType] = useState<"debit" | "credit">("credit");
  const [mode, setMode] = useState<"total" | "count">("total");
  const [sorting, setSorting] = useState<"asc" | "desc">("desc");
  const nav = useNavigate();

  const period = useMemo(() => {
    const startDate = startOfYear(new Date().setFullYear(year));
    const endDate = endOfYear(new Date().setFullYear(year));

    return { endDate, startDate };
  }, [year]);

  const { data, isLoading } = api.reports.merchantReports.useQuery({
    ...period,
    organizationId,
  });

  const list = useMemo(() => {
    if (!data) return [];
    return data.reports;
  }, [data]);

  const [selectedMerchants, setSelectedMerchants] = useState([...list]);

  useEffect(() => {
    const sorted = list.sort((a, b) => {
      const aTotal = Object.values(a.history || {}).reduce(
        (acc, curr) => acc + curr[type][mode],
        0
      );
      const bTotal = Object.values(b.history || {}).reduce(
        (acc, curr) => acc + curr[type][mode],
        0
      );

      if (aTotal === 0) return 1;
      if (bTotal === 0) return -1;

      if (sorting === "asc") {
        return aTotal - bTotal;
      } else {
        return bTotal - aTotal;
      }
    });

    setSelectedMerchants([...sorted]);
  }, [sorting, type, list, mode]);

  if (isLoading) return <Rows count={10} />;
  if (!data) return <NotFound title="Data" />;

  const {
    currency,
    total,
    totalCreditWithoutMerchant,
    totalDebitWithoutMerchant,
    withMerchant,
  } = data;

  const percentage = (withMerchant / total) * 100;

  const hasMore = selectedMerchants.length > currentPage * 10;

  return (
    <div className="flex flex-col gap-6">
      <div className="sm:flex-row flex-col flex items-center gap-4">
        <MultiSelect
          className="flex-1 z-50 w-full"
          placeholder="Filter by Merchants"
          searchable
          values={
            selectedMerchants.length === list.length
              ? []
              : selectedMerchants.map((m) => m.id)
          }
          onChange={(e) => {
            if (!e.length) return setSelectedMerchants(list);
            setSelectedMerchants(
              list.filter((m) => e.some((_e) => _e === m.id))
            );
          }}
          options={list.map((m) => ({ label: m.name, value: m.id }))}
        />
        <div className="flex gap-4">
          <button
            className={`btn btn-sm ${
              type === "credit" ? "" : "btn-outline"
            } btn-success `}
            onClick={() => setType("credit")}
          >
            <BsArrowDownLeft />
            Revenue
          </button>

          <button
            onClick={() => setType("debit")}
            className={`btn btn-sm ${
              type === "debit" ? "" : "btn-outline"
            } btn-error `}
          >
            <BsArrowUpRight />
            Spending
          </button>
        </div>
      </div>

      <Switch
        onChange={(e) => setMode(e ? "count" : "total")}
        value={mode === "count"}
        text="Show count"
      />

      <table className="table">
        <thead className="border bg-neutral border-neutral text-neutral-content">
          <tr>
            <th className="flex items-center gap-4">
              Merchant{" "}
              <Tooltip
                className="z-20 tooltip-right"
                text={[
                  percentage < 100
                    ? `${percentage.toFixed(
                        1
                      )}% of transactions have merchants. Please add merchants to the remaining ${
                        total - withMerchant
                      } ( debit: ${formatCurrency(
                        totalDebitWithoutMerchant,
                        currency
                      )}, credit: ${formatCurrency(
                        totalCreditWithoutMerchant,
                        currency
                      )} ) transactions for a precise report.`
                    : total
                      ? "100% of transactions have merchants. Your report is precise."
                      : "No transaction found",
                  "Transactions categorized as Internal won't be displayed in this report",
                ]}
              />
            </th>
            {months.map((m) => (
              <th className="text-center" key={m.name}>
                {m.name}
              </th>
            ))}
            <th
              onClick={() => setSorting((p) => (p === "asc" ? "desc" : "asc"))}
              className="text-center cursor-pointer flex w-max justify-center items-center gap-2 min-w-full"
            >
              Total {SortIcon[sorting]}
            </th>
          </tr>
        </thead>
        <tbody>
          {selectedMerchants.slice(0, currentPage * 10).map((m, i) => {
            const total = Object.values(m.history || {}).reduce(
              (acc, curr) => acc + curr[type][mode],
              0
            );
            if (total === 0) return null;

            return (
              <tr
                key={m.id}
                onClick={() =>
                  nav(
                    `/transactions?filters=${encodeObjectToBase64<TransactionFiltersType>(
                      { merchantsId: [m.id] }
                    )}&period=${encodeObjectToBase64(period)}`
                  )
                }
                className={`border-x bg-base-100 cursor-pointer  border-base-300 ${type === "credit" ? "text-success" : "text-error"}`}
              >
                <td>
                  <DisplayName
                    className="text-xs text-base-content"
                    text={m.name}
                  />
                </td>

                {months.map((mo) => {
                  const value = m.history?.[mo.name]?.[type][mode] || 0;
                  return (
                    <td
                      key={mo.name + m.id}
                      className={"text-center text-xs border-l border-base-300"}
                    >
                      {mode === "count"
                        ? value
                        : formatCurrency(value, currency, true)}
                    </td>
                  );
                })}
                <td className="text-center text-xs border-l border-base-300">
                  {mode === "count" ? total : formatCurrency(total, currency)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {hasMore && (
        <InViewTrigger
          onInView={async () => {
            await wait(300);
            setCurrentPage((p) => ++p);
          }}
        ></InViewTrigger>
      )}
    </div>
  );
};

export default MerchantReports;
