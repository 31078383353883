import Select from "@/components/Select";
import { FC } from "react";

export const team_member_roles = ["admin", "employee", "viewer"] as const;
export type TeamMemberRole = (typeof team_member_roles)[number];

type Props = {
  role?: TeamMemberRole;
  setRole: (role: TeamMemberRole) => void;
  isError?: any;
};

const SelectRole: FC<Props> = ({ isError, role, setRole }) => {
  return (
    <div>
      <p className="label">Role</p>
      <Select
        title="Role"
        isError={isError}
        value={role}
        onChange={setRole}
        options={team_member_roles.map((role) => ({ value: role }))}
      />
    </div>
  );
};

export default SelectRole;
