import Modal from "@/ui/Modal";
import { FC, ReactNode, useState } from "react";
import { TbUpload } from "react-icons/tb";
import FileDropZone from "./FileDropZone";

type Props = {
  files: File[];
  setFiles: (files: File[]) => void;
  children?: ReactNode;
  accept?: string;
  multiple?: boolean;
};

const DropZoneButton: FC<Props> = ({
  files,
  setFiles,
  multiple,
  children = (
    <button className="btn btn-primary">
      <TbUpload />
    </button>
  ),
  accept,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div onClick={() => setIsOpen(true)}>{children}</div>
      <Modal isOpen={isOpen} onClose={setIsOpen}>
        <FileDropZone
          {...{
            files,
            setFiles: (files) => {
              setFiles(files);
              setIsOpen(false);
            },
            accept,
            multiple,
          }}
        />
      </Modal>
    </>
  );
};

export default DropZoneButton;
