import Tabs from "@/components/Tabs";
import { PageTitle } from "@/ui/PageTitle";
import { ReactNode } from "react";
import { useParams } from "react-router-dom";
import Customers from "./customers/Customers";
import Merchants from "./merchant/Merchants";
import Vendors from "./vendors/Vendors";

const tabs = ["customers", "vendors", "merchants"] as const;

type Tab = (typeof tabs)[number];

const Partners = () => {
  const { tab = "customers" } = useParams<{ tab: Tab }>();

  const content: Record<Tab, ReactNode> = {
    customers: <Customers />,
    vendors: <Vendors />,
    merchants: <Merchants />,
  };

  return (
    <div className="grid gap-6">
      <PageTitle title="Partners" />

      <Tabs
        tab={tab}
        className="w-fit"
        tabs={tabs}
        parentRoute="/partners"
        paths={["/customers", "/vendors", "/merchants"]}
      />

      {content[tab]}
    </div>
  );
};

export default Partners;
