import { copyToClipboard } from "@/utils/helper";
import { FC, ReactNode, useState } from "react";
import { LuCheckCircle } from "react-icons/lu";
import { MdContentCopy } from "react-icons/md";

type Props = {
  hideIcon?: boolean;
  label: string;
  value?: string | null;
  text?: ReactNode;
  className?: string;
};

const CopyToClipboard: FC<Props> = (props) => {
  const [copied, setCopied] = useState(false);
  const { label, text, value, className = "", hideIcon } = props;

  if (!value) return null;

  return (
    <button
      className={`btn group ${className}`}
      onClick={(e) => {
        e.stopPropagation();
        copyToClipboard(value, label);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      }}
    >
      {text || value}
      {!hideIcon && (
        <label className="swap group-hover:opacity-100 opacity-0 swap-rotate">
          <input type="checkbox" checked={copied} />

          <MdContentCopy className="swap-off current" />
          <LuCheckCircle className="swap-on text-success" />
        </label>
      )}
    </button>
  );
};

export default CopyToClipboard;
