import { useOrganization } from "@/context/OrganizationContext";
import ReportsTable from "@/ui/skeletons/ReportsTable";
import { formatCurrency } from "@/utils/helper";
import { api } from "@/utils/trpc";
import startOfMonth from "date-fns/startOfMonth";
import { FC, useEffect, useMemo, useState } from "react";

type Props = {
  year: number;
  month: number;
};

const PayrollSpending: FC<Props> = ({ year, month }) => {
  const { organizationId = "" } = useOrganization();

  const date = useMemo(() => {
    return startOfMonth(new Date(year, month));
  }, [year, month]);

  const { data, isLoading } = api.reports.payrollSpending.useQuery({
    organizationId,
    date,
  });

  const [showAll, setShowAll] = useState({
    departments: false,
    roles: false,
  });

  useEffect(() => {
    if (!data) return;

    const _showAll = { departments: false, roles: false };

    if (data.topDepartments.length <= 5) _showAll.departments = true;
    if (data.topRoles.length <= 5) _showAll.roles = true;

    setShowAll(_showAll);
  }, [data]);

  if (isLoading)
    return (
      <div className="flex xl:flex-row flex-col  gap-6">
        <ReportsTable />
        <ReportsTable />
      </div>
    );

  if (!data) return null;

  const roles = showAll.roles ? data.topRoles : data.topRoles.slice(0, 5);

  const departments = showAll.departments
    ? data.topDepartments
    : data.topDepartments.slice(0, 5);

  const otherRolesTotal = data.topRoles.slice(5).reduce((acc, curr) => {
    return acc + curr.total;
  }, 0);

  const otherDepartmentsTotal = data.topDepartments
    .slice(5)
    .reduce((acc, curr) => {
      return acc + curr.total;
    }, 0);

  return (
    <div className="grid gap-6">
      <div className="flex xl:flex-row flex-col justify-between gap-6">
        <div className="bg-base-100/80 max-h-96 overflow-auto h-fit p-4 flex-1 rounded-lg border border-neutral">
          <p className="label">Top Departments</p>
          <table className="table text-xs text-base-content/70">
            <tbody>
              {departments.map((c) => (
                <tr className="border-neutral borde" key={c.id}>
                  <td>{c.name}</td>
                  <td>{formatCurrency(c.total, data.currency)}</td>
                </tr>
              ))}

              {!showAll.departments && (
                <tr>
                  <td>Other categories</td>
                  <td>
                    {formatCurrency(otherDepartmentsTotal, data.currency)}
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {!showAll.departments && (
            <button
              className="btn btn-link w-full "
              onClick={() => setShowAll((p) => ({ ...p, departments: true }))}
            >
              Show all
            </button>
          )}
        </div>
        <div className="bg-base-100/80 max-h-screen overflow-auto h-fit flex-1 p-4 rounded-lg border border-neutral">
          <p className="label">Top Roles</p>
          <table className="table text-xs text-base-content/70">
            <tbody>
              {roles.map((v) => (
                <tr className="border-neutral" key={v.id}>
                  <td>{v.name}</td>
                  <td>{formatCurrency(v.total, data.currency)}</td>
                </tr>
              ))}
              {!showAll.roles && (
                <tr>
                  <td>Other Merchants</td>
                  <td>{formatCurrency(otherRolesTotal, data.currency)}</td>
                </tr>
              )}
            </tbody>
          </table>
          {!showAll.roles && (
            <button
              className="btn btn-link w-full "
              onClick={() => setShowAll((p) => ({ ...p, roles: true }))}
            >
              Show all
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PayrollSpending;
