import DisplayName from "@/components/DisplayName";
import Drawer from "@/components/drawer/Drawer";
import AssignCategories from "@/ui/AssignCategories";
import Checkbox from "@/ui/Checkbox";
import { formatCurrency, formatId, formatLabel } from "@/utils/helper";
import { RouterOutputs } from "@/utils/trpc";
import format from "date-fns/format";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { useNavigate } from "react-router-dom";
import AccountingStatus from "./ManageAccountingStatus";

type Props = {
  list: RouterOutputs["invoices"]["invoices"];
  isLoading: boolean;
  setSelection: Dispatch<SetStateAction<Record<string, any>>>;
  selection: Record<string, any>;
  totalSelected: number;
  showAccountingOverview?: boolean;
  params: any;
};

const InvoiceListing: FC<Props> = ({
  list,
  isLoading,
  selection,
  setSelection,
  totalSelected,
  showAccountingOverview,
  params,
}) => {
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);

  const toggleDrawer = () => {
    setSelectedItemId(null);
  };

  const nav = useNavigate();

  const view = (id: string) => () => nav(`/invoice/${id}`);

  return (
    <tbody>
      {list.map((i, index, { length }) => (
        <tr
          key={i.id}
          style={{ zIndex: length - index }}
          className="hover cursor-pointer "
        >
          <th>
            <Checkbox
              checked={!!selection[i.id]}
              onChange={() => {
                setSelection((p) => {
                  let prev = { ...p };

                  if (totalSelected > list.length) {
                    prev = list.reduce(
                      (acc, curr) => ({ ...acc, [curr.id]: curr }),
                      {}
                    );
                  }

                  if (prev[i.id]) {
                    const { [i.id]: _, ...rest } = prev;
                    return rest;
                  } else return { ...prev, [i.id]: i };
                });
              }}
            />
          </th>

          <td onClick={view(i.id)}>
            <p className="flex md:flex-row flex-col  w-full text-center pr-2 gap-1">
              {format(new Date(i.dueDate), "dd MMM")},
              <span className=" text-neutral-500 text-sm  ">
                {format(new Date(i.dueDate), "yyyy")}
              </span>
            </p>
          </td>

          <td onClick={view(i.id)} className="flex gap-6">
            <div className="flex-1 w-max">
              <DisplayName text={i.customer.name} className="" />
            </div>
          </td>
          <td>
            <div className="btn btn-xs capitalize rounded-sm btn-neutral">
              {i.status}
            </div>
          </td>
          {showAccountingOverview && (
            <>
              <td>
                <AccountingStatus
                  {...{
                    invoiceId: i.id,
                    status: i.accountingStatus,
                    params,
                  }}
                />
              </td>
              <td>{i.financialAccount?.name || "N/A"}</td>
            </>
          )}
          <td onClick={view(i.id)}>
            <div className="w-full text-start pr-2">
              <p className="text-start">
                {formatCurrency(i.total, i.currency)}
              </p>

              <p className="text-xs text-neutral-500">
                {formatCurrency(i.totalVat, i.currency)}/
                {formatCurrency(i.totalAmount, i.currency)}
              </p>
            </div>
          </td>
          <th>
            <p className="text-xs">{formatId(i.invoice_number, "INV")}</p>
          </th>
          <td onClick={view(i.id)}>
            <p className="flex md:flex-row text-right flex-col w-full justify-center pr-2 gap-1">
              {format(new Date(i.createdAt), "dd MMM")},
              <span className=" text-neutral-500 text-sm  ">
                {format(new Date(i.createdAt), "yyyy")}
              </span>
            </p>
          </td>

          {/* <th className="">
            <button
              className="btn btn-ghost btn-xs"
              onClick={() => setSelectedItemId(i.id)}
            >
              <RiMoreFill size="22" />
            </button>
          </th> */}
          <Drawer
            isOpen={selectedItemId == i.id}
            onClose={toggleDrawer}
            title={"Invoice Details"}
            content={
              <div className="flex flex-col w-full gap-3 items-center justify-end">
                <div className="w-full">
                  <AssignCategories
                    {...{
                      assignedCategories: {
                        category: i.category,
                        subCategory: i.subCategory,
                      },
                      invoiceId: i.id,
                      isList: false,
                    }}
                  />
                </div>
                <div className="flex flex-row gap-5 mt-3 items-center w-full">
                  <div className="w-full">
                    <span className="text-gray-500   text-sm">Status</span>
                    <div
                      className={`w-full px-4 py-2 rounded-md text-sm text-center mt-1  text-gray-200     capitalize  `}
                    >
                      {i.status || "Draft"}
                    </div>
                  </div>
                  <div className="w-full">
                    <span className="text-gray-500   text-sm ">
                      Approval status
                    </span>
                    <div
                      className={`w-full px-4 py-2 rounded-md text-sm mt-1 text-gray-200 text-center    capitalize  `}
                    >
                      {formatLabel(i.approvalStatus)}
                    </div>
                  </div>
                </div>
              </div>
            }
          />
        </tr>
      ))}
    </tbody>
  );
};

export default InvoiceListing;
