import { formatCurrency } from "@/utils/helper";
import { RouterOutputs } from "@/utils/trpc";
import { AreaChart } from "@tremor/react";
import { FC, useMemo } from "react";
import NotFound from "../utils/notfound";
import Spinner from "../utils/spinner";
import { months } from "@/lib";

type Props = {
  data?: RouterOutputs["reports"]["barChart"]["balances"];
  currency: string;
  loading: boolean;
  year: number;
};

const Balances: FC<Props> = ({ data, currency, loading, year }) => {
  if (loading) return <Spinner />;
  if (!data) return <NotFound title="Data" />;

  const balancesByMonth = useMemo(() => {
    const monthlyBalances = new Array(12).fill(0);
    data.forEach(([date, balance]) => {
      const month = new Date(date).getMonth();
      monthlyBalances[month] = balance;
    });
    return monthlyBalances;
  }, [data]);

  return (
    <div className="">
      <span className="badge badge-primary">Balance History</span>
      <AreaChart
        data={months.map((m, index) => ({
          balance: balancesByMonth[index],
          month: m.name,
        }))}
        index="month"
        categories={["balance"]}
        valueFormatter={(v) => formatCurrency(v, currency)}
        colors={["indigo"]}
      />
    </div>
  );
};

export default Balances;
