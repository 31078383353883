import PhoneInput from "@/components/PhoneInput";
import Select from "@/components/Select";
import Switch from "@/components/Switch";
import { Organization } from "@/context/OrganizationContext";
import { countries } from "@/lib/countries";
import { mccList } from "@/lib/mccList";
import { UpdateOrganization } from "@/types/validation";
import LoadingSpin from "@/ui/LoadingSpin";
import { formatLabel } from "@/utils/helper";
import { api } from "@/utils/trpc";
import useForm from "@/utils/useForm";
import { FC, useEffect } from "react";
import { toast } from "react-hot-toast";
import { BiCheck } from "react-icons/bi";
import { useUser } from "../../context/UserContext";

const employeeCounts = [
  "1-10",
  "11-50",
  "51-100",
  "101-500",
  "501-1000",
  "1001-5000",
  "5001+",
];

type BusinessType = UpdateOrganization["type"];

const types: BusinessType[] = [
  "company",
  "government_entity",
  "individual",
  "non_profit",
];

const fields: {
  name: keyof UpdateOrganization;
  placeholder: string;
  number?: boolean;
  numberString?: boolean | "phone" | "alphanumeric";
  title: string;
}[] = [
  {
    name: "name",
    placeholder: "Enter business name",
    title: "Business Name",
  },
  {
    name: "business_tax_number",
    placeholder: "Enter business tax number",
    numberString: "alphanumeric",
    title: "Business Tax Number",
  },
  {
    name: "business_number",
    placeholder: "Enter business number",
    numberString: "alphanumeric",
    title: "Business Number",
  },
  {
    name: "address",
    placeholder: "Enter Company address",
    title: "Address",
  },
  {
    name: "email",
    placeholder: "Enter business email",
    title: "Email address",
  },

  {
    name: "city",
    placeholder: "Enter city",
    title: "City",
  },
  {
    name: "state",
    placeholder: "Enter state",
    title: "State",
  },

  {
    name: "postCode",
    placeholder: "Enter postal code",
    numberString: true,
    title: "Postal Code",
  },
  {
    name: "website",
    placeholder: "Enter website",
    title: "Website",
  },
  {
    name: "policyURL",
    placeholder: "Enter privacy policy URL",
    title: "Privacy Policy URL",
  },
];
type Props = {
  organization: Organization;
};

const OrganizationDetails: FC<Props> = ({ organization }) => {
  const { handleChange, inputs, setInputs, errors, setErrors, setValue } =
    useForm(organization);

  const { userId } = useUser<true>();

  useEffect(() => {
    setInputs(organization);
  }, [organization]);

  const uitls = api.useUtils();

  const create = api.organizations.create.useMutation();
  const update = api.organizations.update.useMutation();

  const handleUpdate = async () => {
    const validate = UpdateOrganization.safeParse(inputs);
    console.log(validate);
    if (!validate.success) {
      return setErrors(validate.error.formErrors.fieldErrors);
    }

    const res = await update.mutateAsync({
      ...validate.data,
      id: organization.id,
    });

    uitls.organizations.myOrganizations.setData(userId, (p = []) =>
      p.map((o) => (o.id === res.id ? { ...o, ...res } : o))
    );

    toast.success("Organization saved successfully");
  };

  return (
    <div className="w-full grid gap-6">
      <div className="card bg-base-200 w-full">
        <div className="card-body ">
          <p className="card-title">Basic Information</p>

          <div className="grid 2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-2 grid-cols-1  gap-x-4">
            {fields
              .slice(0, 6)
              .map(({ name, placeholder, title, number, numberString }) => (
                <div key={name}>
                  <label className="label">
                    <span className="label-text">{title}</span>
                  </label>
                  <input
                    type="text"
                    placeholder={placeholder}
                    className={`input w-full shadow-sm  ${
                      errors[name] ? "input-error" : "input-bordered"
                    }`}
                    value={inputs[name] || ""}
                    onChange={handleChange(name)}
                    data-number={number}
                    data-number-string={numberString}
                  />
                  <p className="err">{errors[name]}</p>
                </div>
              ))}

            <PhoneInput
              onChange={(e) => setValue("phone", e)}
              value={inputs.phone}
              isError={errors.phone}
            />

            <div className=" z-20">
              <label className="label">
                <span className="label-text">Business Type</span>
              </label>

              <Select
                {...{
                  onChange: (e) => setValue("type", e as BusinessType),
                  options: types.map((c) => ({
                    label: formatLabel(c),
                    value: c,
                  })),
                  value: inputs.type,
                  isError: !!errors.type,
                  placeholder: "Select Business Type",
                }}
              />
            </div>
            <div className="md:col-span-2">
              <label className="label">
                <span className="label-text">MCC</span>
              </label>

              <Select
                {...{
                  onChange: (e) => setValue("mcc", e),
                  options: mccList.map((c) => ({
                    label: `${c.category} (${c.code})`,
                    value: c.code,
                  })),
                  value: inputs.mcc,
                  isError: !!errors.mcc,
                }}
                placeholder="Select MCC category"
                searchable
              />
            </div>
            {/* <div className="rounded-full w-40">
                <ImageFromPath
                  {...{ path: inputs.logo }}
                  className="w-full overflow-hidden aspect-square object-cover rounded-full "
                />

                <button className="btn mx-auto btn-neutral w-full text-xs mt-4 ">
                  <LoadingSpin loading={upload.isLoading} />
                  {!upload.isLoading && (
                    <input
                      type="file"
                      className="absolute w-full h-full opacity-0 brd top-0 left-0"
                      accept="image/*"
                      onChange={handleUpload}
                    />
                  )}
                  Logo
                </button>
              </div> */}
          </div>
        </div>
      </div>
      <div className="card bg-base-200 w-full">
        <div className="card-body ">
          <p className="card-title">Additional Information</p>
          <div className="grid  2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-2 grid-cols-1  gap-x-4">
            {fields
              .slice(6)
              .map(({ name, placeholder, title, number, numberString }) => (
                <div key={name}>
                  <label className="label">
                    <span className="label-text">{title}</span>
                  </label>
                  <input
                    type="text"
                    placeholder={placeholder}
                    className={`input w-full shadow-sm  ${
                      errors[name] ? "input-error" : "input-bordered"
                    }`}
                    value={inputs[name]?.toString() || ""}
                    onChange={handleChange(name)}
                    data-number={number}
                    data-number-string={numberString}
                  />
                  <p className="err">{errors[name]}</p>
                </div>
              ))}

            <div className="">
              <label className="label label-text">Employee Count</label>

              <Select
                {...{
                  onChange: (e) => setValue("employeeCount", e),
                  options: employeeCounts.map((c) => ({
                    label: c,
                    value: c,
                  })),
                  value: inputs.employeeCount,
                  isError: !!errors.employeeCount,
                  placeholder: "Select Employee Count",
                }}
              />
            </div>
            <div className="">
              <label className="label label-text">Default Currency</label>

              <Select
                {...{
                  onChange: (e) => setValue("defaultCurrency", e),
                  options: ["EUR", "GBP"].map((c) => ({
                    label: c,
                    value: c,
                  })),
                  value: inputs.defaultCurrency,
                  isError: !!errors.defaultCurrency,
                  placeholder: "Select Default Currency",
                }}
              />
            </div>

            <div>
              <label className="label label-text">Country</label>

              <Select
                {...{
                  onChange: (e) => setValue("countryId", +e),
                  options: countries.map((c) => ({
                    label: c.name,
                    value: c.id.toString(),
                  })),
                  value: inputs.countryId?.toString(),
                  isError: !!errors.countryId,
                  placeholder: "Select Country",
                  searchable: true,
                }}
              />
            </div>

            <div>
              <p className="label label-text">
                Require approval to transfer payment
              </p>
              <Switch
                value={inputs.requiredApprovalToTransferPayment}
                onChange={(e) =>
                  setValue("requiredApprovalToTransferPayment", e)
                }
              />
            </div>
          </div>
        </div>
      </div>
      <button
        onClick={handleUpdate}
        className="btn btn-primary flex items-center w-fit mt-6 ml-auto btn-sm"
      >
        <BiCheck />
        Save
        <LoadingSpin loading={update.isLoading} />
      </button>
    </div>
  );
};

export default OrganizationDetails;
