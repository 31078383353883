import { useOrganization } from "@/context/OrganizationContext";
import LoadingSpin from "@/ui/LoadingSpin";
import SelectCategories, {
  Categories,
  CategoryData,
} from "@/ui/SelectCategories";
import { api } from "@/utils/trpc";
import { FC, useState } from "react";
import { FaWandMagicSparkles } from "react-icons/fa6";
import { IoMdCheckmarkCircleOutline, IoMdRemoveCircle } from "react-icons/io";

type Props = {
  category?: CategoryData | null;
  subCategory?: CategoryData | null;
  suggestedCategory?: CategoryData | null;
  suggestedSubCategory?: CategoryData | null;
  suggestionAccepted?: boolean | null;

  transactionId: string;

  cardId?: string;
};

const ManageCategories: FC<Props> = ({
  category,
  subCategory,
  suggestedCategory,
  transactionId,
  suggestionAccepted,
  suggestedSubCategory,
  cardId,
}) => {
  const [categories, setCategories] = useState<Categories>({
    category,
    subCategory,
  });

  const { organizationId } = useOrganization<true>();

  const add = api.swan.transfers.category.add.useMutation();
  const utils = api.useUtils();

  const handleSave = async (suggestionAccepted?: boolean) => {
    let { category, subCategory } = categories;

    if (suggestionAccepted) {
      category = suggestedCategory;
      subCategory = suggestedSubCategory;
    }

    const categoryInfo = await add.mutateAsync({
      categoryId: category ? category.id : null,
      subCategoryId: subCategory ? subCategory.id : null,
      suggestionAccepted,
      transactionId,
    });

    organizationId &&
      utils.swan.transfers.list.setInfiniteData({ organizationId }, (p) => {
        if (!p) return p;
        return {
          ...p,
          pages: p.pages.map((page) => ({
            ...page,
            data: page.data.map((t) =>
              t.id === transactionId ? { ...t, categoryInfo } : t
            ),
          })),
        };
      });

    cardId &&
      utils.swan.card.transactions.setData(cardId, (p = []) => {
        return p.map((t) =>
          t.id === transactionId ? { ...t, categoryInfo } : t
        );
      });

    setCategories(categoryInfo);
  };

  if (suggestedCategory && suggestionAccepted === null) {
    return (
      <div>
        <p className="label text-accent justify-start gap-2">
          <FaWandMagicSparkles />
          Suggested by AI
        </p>
        <div className="btn w-full justify-between">
          <div className="">
            <LoadingSpin loading={add.isLoading} />

            <div className="grid justify-items-center">
              <div className="flex scale-90 items-center gap-2">
                <span
                  className="btn scale-75 btn-circle btn-xs "
                  style={{ background: suggestedCategory.color }}
                />

                {suggestedCategory.name}
              </div>
              {suggestedSubCategory && (
                <div className="flex scale-75 items-center gap-2">
                  <span
                    className="btn btn-circle scale-75 btn-xs"
                    style={{ background: suggestedSubCategory.color }}
                  />

                  {suggestedSubCategory.name}
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center join">
            <button
              className="btn btn-success join-item btn-xs tooltip tooltip-left tooltip-success"
              onClick={() => handleSave(true)}
              data-tip="Approve"
            >
              <IoMdCheckmarkCircleOutline />
            </button>
            <button
              className="btn join-item btn-error btn-outline btn-xs tooltip tooltip-right tooltip-error"
              data-tip="Reject"
              onClick={() => handleSave(false)}
            >
              <IoMdRemoveCircle />
            </button>
          </div>
        </div>
      </div>
    );
  }
  return (
    <SelectCategories
      {...{
        categories,
        setCategories,
        handleSave: () => handleSave(false),
        categoryList: [],
        isLoading: add.isLoading,
      }}
    />
  );
};

export default ManageCategories;
