import { FC } from "react";
import { PiInfoBold } from "react-icons/pi";

type Props = {
  text: string[];
  className?: string;
};

const Tooltip: FC<Props> = ({ text, className = "" }) => {
  return (
    <div
      className={`tooltip cursor-pointer w-fit  before:whitespace-pre-wrap before:[--tw-content:'line1_\a_line2'] ${className}`}
      data-tip={text.join("\n")}
    >
      <PiInfoBold size={26} className="text-info" />
    </div>
  );
};

export default Tooltip;
