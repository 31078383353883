import { FC, ReactNode, useEffect, useMemo, useState } from "react";

import { json2csv } from "json-2-csv";
import { Link, useLocation } from "react-router-dom";
import Bills from "../pages/bills/Bills";
import Invoices from "../pages/invoices/Invoices";

import { DatePeriod } from "@/types/validation";
import SelectDate from "@/ui/SelectDate";
import { decodeBase64ToObject, saveFile } from "@/utils/helper";
import endOfDay from "date-fns/endOfDay";
import endOfYear from "date-fns/endOfYear";
import format from "date-fns/format";
import startOfDay from "date-fns/startOfDay";
import startOfYear from "date-fns/startOfYear";
import { HiDownload, HiPlus } from "react-icons/hi";
import { MdFilterListAlt } from "react-icons/md";
import OrgExpenses from "../pages/expense/OrgExpenses";

const tabs = ["bills", "invoices", "expenses"] as const;
type Tab = (typeof tabs)[number];

const routes: Record<Tab, string> = {
  bills: "/bills/create",
  invoices: "/invoices/create",
  expenses: "/expenses/create",
};

export type DatePeriod = {
  startDate: Date;
  endDate: Date;
};

type Props = { tab: Tab };

const PaymentsWrapper: FC<Props> = ({ tab }) => {
  const location = useLocation();

  const [selection, setSelection] = useState<Record<string, any>>({});
  const [showFilters, setShowFilters] = useState(false);

  const [period, setPeriod] = useState<DatePeriod | null>({
    startDate: startOfYear(new Date()),
    endDate: endOfYear(new Date()),
  });

  const props = {
    setShowFilters,
    showFilters,
    selection,
    setSelection,
    period,
  };

  const content: Record<Tab, ReactNode> = {
    invoices: <Invoices {...props} />,
    bills: <Bills {...props} />,
    expenses: <OrgExpenses {...props} />,
  };

  useEffect(() => {
    setSelection([]);
    setShowFilters(false);
  }, [tab]);

  const selectionArray = useMemo(() => Object.values(selection), [selection]);

  useEffect(() => {
    const q = new URLSearchParams(location.search);
    const _period = q.get("period");

    if (_period) {
      const { startDate, endDate } = decodeBase64ToObject<DatePeriod>(_period);
      const validate = DatePeriod.safeParse({
        startDate: new Date(startDate),
        endDate: new Date(endDate),
      });

      if (!validate.success) return;
      if (period) setPeriod(validate.data);
    }
  }, [location.search]);

  const handleDownload = async () => {
    if (!selectionArray.length) return;

    const csv = json2csv(selectionArray);
    const blob = new Blob([csv], { type: "text/csv" });

    saveFile(blob, `Dynt-${tab}-${format(new Date(), "dd MMM yyyy")}.csv`);
  };

  return (
    <div className="h-full flex-1 overflow-auto flex flex-col">
      <div className="flex gap-2 justify-between items-center">
        <SelectDate
          value={period}
          showFutureDates
          className="w-fit  z-[60] md:block hidden my-4"
          popoverDirection="down"
          onChange={(v) => {
            if (!v?.endDate || !v.startDate) return setPeriod(null);

            setPeriod({
              endDate: endOfDay(new Date(v.endDate)),
              startDate: startOfDay(new Date(v.startDate)),
            });
          }}
        />

        <div className="flex items-center gap-2">
          <Link
            to={"?filters"}
            onClick={() => setShowFilters(true)}
            className="btn btn-primary h-12 btn-sm md:btn-md"
          >
            <MdFilterListAlt />
          </Link>
          <div
            className="tooltip tooltip-top before:left-1/3 before:text-xs before:w-40"
            data-tip={
              selectionArray.length
                ? `Download ${selectionArray.length} ${tab}`
                : "Please select items to download"
            }
          >
            <button
              className={`btn btn-sm h-12 md:btn-md ${
                selectionArray.length ? " btn-primary" : "pointer-events-none"
              } `}
              onClick={handleDownload}
            >
              <HiDownload />
            </button>
          </div>

          <Link
            to={routes[tab]}
            className="btn btn-primary btn-sm md:btn-md h-12"
          >
            <HiPlus />
          </Link>
        </div>
      </div>
      <SelectDate
        value={period}
        className="w-full z-[60] md:hidden my-4"
        popoverDirection="down"
        showFutureDates
        onChange={(v) => {
          if (!v?.endDate || !v.startDate) return setPeriod(null);

          setPeriod({
            endDate: new Date(v.endDate),
            startDate: new Date(v.startDate),
          });
        }}
      />
      {content[tab]}
    </div>
  );
};

export default PaymentsWrapper;
