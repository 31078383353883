import LoadingSpin from "@/ui/LoadingSpin";
import { RouterOutputs, api } from "@/utils/trpc";
import { FC, useState } from "react";
import { HiPlus } from "react-icons/hi";
import AttachBankTransaction from "./AttachBankTransaction";
import AttachDocuments from "./AttachDocuments";
import AttachSwanTransaction from "./AttachSwanTransaction";

type Props = {
  proofs: RouterOutputs["invoices"]["invoiceById"]["proofs"];
} & (
  | { invoiceId: string; billId?: never; expenseId?: never }
  | { billId: string; invoiceId?: never; expenseId?: never }
  | { expenseId: string; billId?: never; invoiceId?: never }
);

const AttachProof: FC<Props> = ({ proofs, billId, expenseId, invoiceId }) => {
  const [type, setType] = useState<
    "document" | "bankTransaction" | "swanTransaction"
  >("document");
  const [adding, setAdding] = useState(false);
  const [documents, setDocuments] = useState<Record<string, boolean>>({});
  const [bankTransaction, setBankTransaction] = useState<
    Record<string, boolean>
  >({});
  const [swanTransactions, setSwanTransactions] = useState<
    Record<string, boolean>
  >({});

  const utils = api.useUtils();

  const attachToInvoice = api.invoices.attachProof.useMutation();
  const attachToBill = api.bills.attachProof.useMutation();
  const attachToExpense = api.expenses.attachProof.useMutation();

  const deleteProof = api.utils.deleteProof.useMutation();

  const handleAttach = async () => {
    setDocuments({});
    setBankTransaction({});
    setSwanTransactions({});
    setAdding(false);
    const files = Object.keys(documents).map((fileId) => ({
      fileId,
    }));

    const transactions = Object.keys(bankTransaction).map((id) => ({
      transactionHistoryId: id,
    }));

    const _swanTransactions = Object.keys(swanTransactions).map(
      (swanTransactionId) => ({ swanTransactionId })
    );

    const proofs = [...files, ...transactions, ..._swanTransactions];

    if (invoiceId) {
      const res = await attachToInvoice.mutateAsync({ proofs, invoiceId });

      utils.invoices.invoiceById.setData(invoiceId, (p) => {
        if (!p) return p;
        return { ...p, proofs: [...res, ...p.proofs] };
      });
    }

    if (billId) {
      const res = await attachToBill.mutateAsync({ proofs, billId });

      utils.bills.billById.setData(billId, (p) => {
        if (!p) return p;
        return { ...p, proofs: [...res, ...p.proofs] };
      });
    }

    if (expenseId) {
      const res = await attachToExpense.mutateAsync({ proofs, expenseId });

      utils.expenses.expenseById.setData(expenseId, (p) => {
        if (!p) return p;
        return { ...p, proofs: [...res, ...p.proofs] };
      });
    }
  };

  const handleDelete = async (id: string) => {
    await deleteProof.mutateAsync(id);

    if (invoiceId) {
      utils.invoices.invoiceById.setData(invoiceId, (p) => {
        if (!p) return p;
        return { ...p, proofs: p.proofs.filter((p) => p.id !== id) };
      });
    }

    if (billId) {
      utils.bills.billById.setData(billId, (p) => {
        if (!p) return p;
        return { ...p, proofs: p.proofs.filter((p) => p.id !== id) };
      });
    }

    if (expenseId) {
      utils.expenses.expenseById.setData(expenseId, (p) => {
        if (!p) return p;
        return { ...p, proofs: p.proofs.filter((p) => p.id !== id) };
      });
    }
  };

  const content = {
    document: (
      <AttachDocuments
        {...{
          adding,
          setAdding,
          selected: documents,
          setSelected: setDocuments,
          proofs,
          handleAttach,
          handleDelete,
          isDeleting: deleteProof.isLoading,
        }}
      />
    ),
    bankTransaction: (
      <AttachBankTransaction
        {...{
          adding,
          setAdding,
          selected: bankTransaction,
          setSelected: setBankTransaction,
          proofs,
          handleAttach,
          handleDelete,
          isDeleting: deleteProof.isLoading,
        }}
      />
    ),
    swanTransaction: (
      <AttachSwanTransaction
        {...{
          adding,
          setAdding,
          selected: swanTransactions,
          setSelected: setSwanTransactions,
          proofs,
          handleAttach,
          handleDelete,
          isDeleting: deleteProof.isLoading,
        }}
      />
    ),
  };

  const loading =
    attachToInvoice.isLoading ||
    attachToBill.isLoading ||
    attachToExpense.isLoading;
  return (
    <div className="flex-1  bg-base-100 border border-base-300  p-4 rounded-lg">
      <p className="label">Proofs</p>
      <div className="flex mb-6 items-center justify-between">
        <div className="tabs tabs-boxed">
          <button
            className={`tab  ${type === "document" ? "tab-active" : ""}`}
            onClick={() => setType("document")}
          >
            Documents
          </button>
          <button
            className={`tab min-w-max ${type === "bankTransaction" ? "tab-active" : ""}`}
            onClick={() => setType("bankTransaction")}
          >
            Bank Transactions
          </button>
          <button
            className={`tab min-w-max ${type === "swanTransaction" ? "tab-active" : ""}`}
            onClick={() => setType("swanTransaction")}
          >
            Dynt Transactions
          </button>
        </div>
        <button
          className="btn btn-primary btn-sm"
          onClick={() => setAdding(true)}
        >
          <LoadingSpin loading={loading} />
          <HiPlus />
        </button>
      </div>

      {content[type]}
    </div>
  );
};

export default AttachProof;
