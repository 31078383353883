import SelectFiles from "@/components/SelectFiles";
import { useOrganization } from "@/context/OrganizationContext";
import Collapsible from "@/ui/Collapsible";
import LoadingSpin from "@/ui/LoadingSpin";
import { api } from "@/utils/trpc";
import { FC, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { FaDeleteLeft } from "react-icons/fa6";
import { GoSkipFill } from "react-icons/go";
import { HiPlus } from "react-icons/hi";
import { MdClose } from "react-icons/md";
import { TransactionHistory } from "../Transactions";
import useTransactions from "../hooks/useTransactions";

type Props = {
  transaction: TransactionHistory;
};

const ManageProofs: FC<Props> = ({
  transaction: { id: transactionId, proofStatus },
}) => {
  const [files, setFiles] = useState<Record<string, string>>({});
  const { organizationId } = useOrganization<true>();

  const { data = [] } = api.transaction.proofs.list.useQuery(transactionId);

  const update = useTransactions();
  const utils = api.useUtils();

  const add = api.transaction.proofs.add.useMutation();
  const remove = api.transaction.proofs.remove.useMutation();
  const notNeeded = api.transaction.proofs.setNotNeeded.useMutation();

  const proofs = useMemo(
    () => data.map((p) => ({ ...p.file!, proofId: p.id })),
    [data]
  );

  const handleAdd = (toggle: Function) => async () => {
    const res = await add.mutateAsync({
      files: Object.keys(files),
      transactionId,
    });

    utils.transaction.proofs.list.setData(transactionId, (p = []) => {
      return [...res, ...p];
    });

    update(transactionId, { proofStatus: "complete" });

    toggle();
  };

  const handleRemove = (proofId: string) => async () => {
    if (data.length === 1) return toast.error("At least one proof is required");
    await remove.mutateAsync(proofId);

    utils.transaction.proofs.list.setData(transactionId, (p = []) => {
      return p.filter((p) => p.id !== proofId);
    });
  };

  const handleNotNeeded = async () => {
    await notNeeded.mutateAsync(transactionId);

    update(transactionId, { proofStatus: "not_needed" });
  };
  return (
    <div className="overflow-hidden max-h-96">
      <Collapsible
        className="!bg-base-100"
        hideArrow
        label={(_, show) => (
          <div className="flex items-center gap-2 justify-between">
            <div className="flex items-center gap-2">
              <p className="divider divider-start text-sm flex-1">Proofs</p>
              {proofStatus === "missing" && (
                <button
                  onClick={handleNotNeeded}
                  className="btn z-40 tooltip tooltip-secondary btn-secondary btn-outline btn-sm"
                  data-tip="Mark as not needed"
                >
                  <GoSkipFill />
                </button>
              )}
            </div>
            <label className="btn btn-primary btn-sm swap swap-rotate ">
              <input type="checkbox" checked={show} />
              <HiPlus className="swap-off fill-current" />
              <MdClose className="swap-on fill-current" />
            </label>
          </div>
        )}
        render={(toggle) => (
          <div className="max-h-72 pt-4 overflow-auto">
            <SelectFiles className="-6" {...{ files, setFiles }} />
            <button
              onClick={handleAdd(toggle)}
              className="btn btn-xs w-full sticky bottom-0 btn-primary"
            >
              <LoadingSpin loading={add.isLoading} />
              Add
            </button>
          </div>
        )}
      />

      <div className="grid overflow-auto max-h-72 gap-2">
        {proofs.map((p) => (
          <div
            className="btn justify-between text-start btn-sm transform-none"
            key={p.proofId}
          >
            <p>{p.name}</p>
            <button
              onClick={handleRemove(p.proofId)}
              className="btn btn-sm border-none btn-outline btn-error"
            >
              <FaDeleteLeft />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ManageProofs;
