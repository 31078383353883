import { FC } from "react";

type Props = {
  size?: number;
};
const Spinner: FC<Props> = ({ size = 30 }) => {
  return (
    <div className="w-full h-full grid place-content-center">
      <span className="loading loading-md loading-spinner text-primary"></span>
    </div>
  );
};

export default Spinner;
