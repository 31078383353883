import Drawer from "@/components/drawer/Drawer";
import Switch from "@/components/Switch";
import LoadingSpin from "@/ui/LoadingSpin";
import { api, RouterOutputs } from "@/utils/trpc";
import useForm from "@/utils/useForm";
import format from "date-fns/format";
import formatDistance from "date-fns/formatDistance";
import { FC } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import SelectRole from "./SelectRole";

type Props = {
  member: RouterOutputs["organizations"]["team_member"]["list"][number];
};

const UpdateTeamMember: FC<Props> = ({ member }) => {
  const { id } = useParams<{ id?: string }>();
  const { inputs, setValue } = useForm(member);

  const update = api.organizations.team_member.update.useMutation();
  const nav = useNavigate();
  const utils = api.useUtils();
  const handleUpdate = async () => {
    const { role, id, isActive } = inputs;
    const res = await update.mutateAsync({
      isActive,
      role,
      team_member_Id: id,
    });

    utils.organizations.team_member.list.setData(member.organizationId, (p) => {
      if (!p) return p;
      return p.map((m) => (m.id === id ? { ...m, ...res } : m));
    });
    toast.success("Team member updated successfully");
    nav("/organization/team");
  };
  return (
    <Drawer
      isOpen={id === member.id}
      onClose={() => nav("/organization/team")}
      title="Team Member"
      className="grid gap-6"
    >
      <div>
        <span className="divider divider-start">Member</span>
        <p className="input w-full badge badge-neutral justify-start">
          {member.user.name!}
        </p>
      </div>
      <div>
        <span className="divider divider-start">Actions</span>
        <SelectRole
          role={inputs.role}
          setRole={(role) => setValue("role", role)}
        />

        <div className="flex mt-4 justify-between items-center">
          <p className="label">Status</p>
          <Switch
            value={inputs.isActive}
            text="active"
            className="w-ful"
            onChange={(active) => setValue("isActive", active)}
          />
        </div>
      </div>
      <div className="">
        <span className="divider divider-start">History</span>
        <table className="table">
          <thead>
            <tr>
              <th>Joined</th>
              <th>Left</th>
              <th>Duration</th>
            </tr>
          </thead>
          <tbody>
            {member.lastJoined && (
              <tr>
                <td>{format(member.lastJoined, "dd/MM/yyy")}</td>
                <td>-</td>
                <td>{formatDistance(member.lastJoined, new Date())}</td>
              </tr>
            )}

            {member.history.map((h) => (
              <tr key={h.id}>
                <td>{format(h.startDate, "dd/MM/yyy")}</td>
                <td>{h.endDate ? format(h.endDate, "dd/MM/yyy") : "-"}</td>
                <td>{formatDistance(h.startDate, h.endDate || new Date())}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button className="btn btn-primary btn-sm mt-6" onClick={handleUpdate}>
        <LoadingSpin loading={update.isLoading} />
        Save
      </button>
    </Drawer>
  );
};

export default UpdateTeamMember;
