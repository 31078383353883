import { FC, ReactNode, useState } from "react";
import Modal from "./Modal";

type Props = {
  render: (toggle: (a?: boolean) => void, show: boolean) => ReactNode;
  message: string;
  buttons: {
    label: string;
    onClick: () => void;
    type: "primary" | "secondary" | "danger";
  }[];
  hidden?: boolean;
};

const PromptModal: FC<Props> = ({
  buttons,
  message,
  render,
  hidden = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  if (hidden) return null;

  return (
    <>
      {render((a) => setIsOpen((p) => a || !p), isOpen)}
      <Modal
        className="aspect-[16/9] flex flex-col w-96"
        isOpen={isOpen}
        onClose={setIsOpen}
      >
        <p className="flex-1 font-medium">{message}</p>

        <div className="flex flex-1  gap-2 items-center justify-between">
          {buttons.map(({ label, onClick, type }) => (
            <button
              onClick={() => {
                onClick();
                setIsOpen(false);
              }}
              className={`btn flex-1  ${
                type === "primary"
                  ? "btn-primary"
                  : type === "secondary"
                  ? "btn-secondary"
                  : "btn-error"
              }`}
            >
              {label}
            </button>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default PromptModal;
