import FileDropZone from "@/components/DropZone/FileDropZone";
import Select from "@/components/Select";
import Drawer from "@/components/drawer/Drawer";
import { useOrganization } from "@/context/OrganizationContext";
import { documentTypes } from "@/lib";
import { CreateDocumentType, DocumentTypesType } from "@/types/validation";
import LoadingSpin from "@/ui/LoadingSpin";
import { formatFileSize } from "@/utils/helper";
import { uploadFile } from "@/utils/supabase";
import { api } from "@/utils/trpc";
import useForm from "@/utils/useForm";
import { useMutation } from "@tanstack/react-query";
import { FC, useEffect, useMemo, useState } from "react";
import { MdClose } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";

type Props = {
  folderId?: string | null;
};

const CreateFiles: FC<Props> = ({ folderId }) => {
  const location = useLocation();
  const [creating, setCreating] = useState(false);
  const nav = useNavigate();
  const { organizationId = "" } = useOrganization();
  const utils = api.useUtils();

  const { inputs, setValue, handleChange, errors, setErrors, setInputs } =
    useForm<Record<string, CreateDocumentType>>({});
  const upload = useMutation(uploadFile);
  const create = api.organizations.createFiles.useMutation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const _tab = queryParams.get("modal");

    if (_tab == "createDocument") setCreating(true);
  }, [location.search]);

  const handleCreate = async () => {
    const files = Object.values(inputs);

    const res = await create.mutateAsync(
      files.map((f) => ({ ...f, organizationId, folderId }))
    );

    utils.organizations.files.setInfiniteData(
      { organizationId, folderId },
      (p) => {
        if (!p) return p;
        return {
          ...p,
          pages: p.pages.map((page) => ({
            ...page,
            list: [...res, ...page.list],
          })),
        };
      }
    );

    setCreating(false);
  };

  const handleUpload = async (files: File[]) => {
    const _inputs = files
      .filter((f) => f.type)
      .map(async (f) => {
        const link = await upload.mutateAsync(f);
        return {
          link,
          name: f.name,
          size: formatFileSize(f.size),
          mimeType: f.type,
        };
      });

    const _files = await Promise.all(_inputs);

    setInputs(Object.fromEntries(_files.map((i, index) => [Math.random(), i])));
  };

  const files = useMemo(() => Object.entries(inputs), [inputs]);

  return (
    <div>
      <Drawer
        title="Add File"
        isOpen={creating}
        onClose={(c) => {
          setCreating(c);
          nav("/documents");
        }}
      >
        <div className="grid gap-4">
          <FileDropZone files={[]} setFiles={handleUpload} />
          {upload.isLoading && <p className="label">uploading files ...</p>}
          <div className="grid gap-4">
            {files.map(([id, file], i, { length }) => (
              <div
                className="border border-info p-4 rounded-lg"
                style={{ zIndex: length - i }}
              >
                <button
                  className="btn btn-xs btn-circle btn-error absolute -top-2 z-10 -right-2"
                  onClick={() => {
                    setInputs((p) => {
                      const { [id]: _, ...rest } = p;
                      return rest;
                    });
                  }}
                >
                  <MdClose />
                </button>
                <p className="label bg-neutral rounded-lg">{file.name}</p>
                <div>
                  <p className="label capitalize label-text-alt">Description</p>
                  <input
                    type="text"
                    value={file.description!}
                    className="input input-sm input-bordered w-full"
                    placeholder="File description"
                    onChange={(e) =>
                      setValue(id, (p) => ({
                        ...p,
                        description: e.target.value,
                      }))
                    }
                  />
                </div>
                <div>
                  <p className="label label-text-alt">File Type</p>
                  <Select
                    value={file.type!}
                    options={documentTypes.map((d) => ({
                      value: d,
                      label: d,
                    }))}
                    onChange={(t) =>
                      setValue(id, (p) => ({
                        ...p,
                        type: t as DocumentTypesType,
                      }))
                    }
                  />
                </div>
              </div>
            ))}
          </div>

          {!!files.length && (
            <button className="btn self-end btn-primary" onClick={handleCreate}>
              <LoadingSpin loading={create.isLoading} />
              Save
            </button>
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default CreateFiles;
