import { endOfMonth, endOfWeek } from "date-fns";
import endOfDecade from "date-fns/endOfDecade";
import endOfYear from "date-fns/endOfYear";
import startOfMonth from "date-fns/startOfMonth";
import startOfYear from "date-fns/startOfYear";
import { FC } from "react";
import Datepicker from "react-tailwindcss-datepicker";

export type DatePeriod = {
  startDate: Date;
  endDate: Date;
};

type Props = {
  value: DatePeriod;
  onChange: (
    value: DatePeriod,
    e?: HTMLInputElement | null | undefined
  ) => void;
  asSingle?: boolean;
  showShortcuts?: boolean;
  popoverDirection?: "up" | "down";
  className?: string;
  showFutureDates?: boolean;
};

const SelectDate: FC<Props> = ({
  onChange,
  value,
  asSingle,
  popoverDirection,
  showShortcuts = true,
  className = "",
  showFutureDates,
}) => {
  const shortCuts = {
    single: {
      today: "Today",
      endOfMonth: {
        text: "End of this month",
        period: {
          start: endOfMonth(new Date()),
          end: endOfMonth(new Date()),
        },
      },
      endOfThisWeek: {
        text: "End of this Week",
        period: {
          start: endOfWeek(new Date()),
          end: endOfWeek(new Date()),
        },
      },
    },
    range: {
      today: "Today",
      yesterday: "Yesterday",
      past: (period: number) => `Past ${period} days`,
      thisMonth: {
        text: "This month",
        period: {
          start: startOfMonth(new Date()),
          end: new Date(),
        },
      },
      pastMonth: "Last month",
      currentYear: {
        text: "This year",
        period: {
          start: startOfYear(new Date()),
          end: new Date(),
        },
      },
      lastYear: {
        text: "Last year",
        period: {
          start: startOfYear(new Date(new Date().getFullYear() - 1, 0, 1)),
          end: endOfYear(new Date(new Date().getFullYear() - 1, 11, 31)),
        },
      },
    },
  };

  if (showFutureDates) {
    shortCuts.range.thisMonth.period.end = endOfMonth(new Date());
    shortCuts.range.currentYear.period.end = endOfYear(new Date());
  }
  return (
    <div className={className}>
      <Datepicker
        value={value}
        onChange={(e) => {
          if (!e?.endDate || !e.startDate) return;
          onChange({
            startDate: new Date(e.startDate),
            endDate: new Date(e.endDate),
          });
        }}
        asSingle={asSingle}
        showShortcuts={showShortcuts}
        disabledDates={
          showFutureDates
            ? []
            : [{ startDate: new Date(), endDate: endOfDecade(new Date()) }]
        }
        showFooter
        primaryColor="indigo"
        separator="-"
        useRange={false}
        readOnly
        displayFormat="DD/MM/YYYY"
        popoverDirection={popoverDirection}
        configs={{
          shortcuts: asSingle ? shortCuts.single : shortCuts.range,
          footer: {
            cancel: "Cancel",
            apply: "Apply",
          },
        }}
      />
    </div>
  );
};

export default SelectDate;
