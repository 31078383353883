import { useOrganization } from "@/context/OrganizationContext";
import { months } from "@/lib";
import { formatCurrency } from "@/utils/helper";
import { RouterOutputs } from "@/utils/trpc";
import { AreaChart } from "@tremor/react";
import { FC } from "react";
import NotFound from "../utils/notfound";
import Spinner from "../utils/spinner";

type Props = {
  data?: RouterOutputs["reports"]["barChart"]["cashFlow"];
  loading: boolean;
};

const Spending: FC<Props> = ({ data: data, loading }) => {
  const { organization } = useOrganization<true>();
  if (loading) return <Spinner />;
  if (!data) return <NotFound title="Data" />;

  return (
    <div className="">
      <span className="badge badge-primary">Spending</span>
      <AreaChart
        data={months.map((m) => ({
          value: data.debits[m.value],
          name: m.name,
        }))}
        index="name"
        categories={["value"]}
        colors={["blue"]}
        valueFormatter={(v) => formatCurrency(v, organization.defaultCurrency)}
      />
    </div>
  );
};

export default Spending;
