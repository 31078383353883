import { useOrganization } from "@/context/OrganizationContext";
import { api } from "@/utils/trpc";
import { Customer } from "../customers/Customers";

const useCustomer = () => {
  const utils = api.useUtils();
  const { organizationId } = useOrganization<true>();

  const update = (data: Customer, _delete = false) => {
    utils.organizations.customer.list.setData(organizationId, (p = []) => {
      const res = p.filter((v) => v.id !== data.id);
      if (!_delete) res.unshift(data);
      return res;
    });
  };

  return update;
};

export default useCustomer;
