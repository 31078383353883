import FormInput from "@/components/FormInput";
import Tabs from "@/components/Tabs";
import Collapsible from "@/ui/Collapsible";
import LoadingSpin from "@/ui/LoadingSpin";
import { api, RouterOutputs } from "@/utils/trpc";
import useForm from "@/utils/useForm";
import { FC } from "react";
import toast from "react-hot-toast";
import { HiPlus } from "react-icons/hi";
import { MdClose } from "react-icons/md";
import { ExternalAccount as Validation } from "../validation";
import AccountRow from "./AccountRow";

type Props = {
  vendorId?: string;
  customerId?: string;
};

export const currencies = ["USD", "EUR", "GBP"];

export const fields: {
  name: keyof Validation;
  title: string;
  placeholder: string;
  currencies: string[];
}[] = [
  {
    name: "name",
    title: "Account Name",
    placeholder: "Enter Account name",
    currencies: ["USD", "EUR", "GBP"],
  },

  {
    name: "IBAN",
    title: "IBAN",
    placeholder: "Enter IBAN",
    currencies: ["EUR", "GBP"],
  },
  {
    name: "SWIFT",
    title: "SWIFT",
    placeholder: "Enter SWIFT",
    currencies: ["EUR"],
  },
  {
    name: "ACCOUNT_NUMBER",
    title: "Account Number",
    placeholder: "Enter Account Number",
    currencies: ["USD", "GBP"],
  },
  {
    name: "ROUTING_NUMBER",
    title: "Routing Number",
    placeholder: "Enter Routing Number",
    currencies: ["USD"],
  },
  {
    name: "SORT_CODE",
    title: "Sort Code",
    placeholder: "Enter Sort Code",
    currencies: ["GBP"],
  },
];

export type ExternalAccount =
  RouterOutputs["organizations"]["externalAccount"]["list"][number];

const Accounts: FC<Props> = ({ vendorId, customerId }) => {
  const { data = [] } = api.organizations.externalAccount.list.useQuery({
    vendorId,
    customerId,
  });

  const { handleChange, inputs, setValue, errors, setErrors, setInputs } =
    useForm<Partial<Validation>>({ currency: "EUR" });

  const create = api.organizations.externalAccount.create.useMutation();
  const utils = api.useUtils();

  const handleAdd = (toggle: Function) => async () => {
    const valid = Validation.safeParse(inputs);

    if (!valid.success) {
      setErrors(valid.error.formErrors.fieldErrors);

      return toast.error("Please check the details and try again");
    }

    const res = await create.mutateAsync({
      ...valid.data,
      vendorId,
      customerId,
    });

    utils.organizations.externalAccount.list.setData(
      { vendorId, customerId },
      (prev = []) => [...prev, res]
    );

    setInputs({ currency: "EUR" });
    toggle();
  };
  return (
    <div className="h-full">
      <Collapsible
        label={(_, show) => (
          <div className="flex items-center gap-2 justify-between">
            <div className="flex items-center gap-2">
              <p className="divider divider-start text-sm flex-1">Accounts</p>
            </div>
            <label className="btn btn-primary btn-sm swap swap-rotate ">
              <input type="checkbox" checked={show} />
              <HiPlus className="swap-off fill-current" />
              <MdClose className="swap-on fill-current" />
            </label>
          </div>
        )}
        className="!bg-base-100"
        hideArrow
        render={(toggle) => (
          <>
            <Tabs
              tab={inputs.currency!}
              setTab={(c) => setValue("currency", c)}
              tabs={currencies}
              labels={currencies}
            />
            <div>
              {fields.map((f, i) => {
                if (!f.currencies.includes(inputs.currency!)) return null;
                return (
                  <FormInput
                    title={f.title}
                    placeholder={f.placeholder}
                    key={f.name}
                    className="last:col-span-2"
                    onChange={handleChange(f.name)}
                    isError={errors[f.name]}
                    value={inputs[f.name]}
                  />
                );
              })}
            </div>

            <button
              className="btn mt-4 btn-primary btn-xs w-full"
              onClick={handleAdd(toggle)}
            >
              <LoadingSpin loading={create.isLoading} />
              Save
            </button>
          </>
        )}
      />

      <div className="grid gap-1 my-4">
        {data.map((b) => (
          <AccountRow
            params={{ vendorId, customerId }}
            key={b.id}
            account={b}
          />
        ))}
      </div>
    </div>
  );
};

export default Accounts;
