type Props = {};

const ReportsTable = (props: Props) => {
  return (
    <div className="w-full p-4 rounded-lg flex flex-col gap-4 bg-base-100/40">
      <span className="skeleton rounded-lg h-5 w-1/3 min-w-[3rem]" />
      <span className="skeleton rounded-lg h-6 w-full" />
      <span className="skeleton rounded-lg h-6 w-full" />
      <span className="skeleton rounded-lg h-6 w-full" />
      <span className="skeleton rounded-lg h-6 w-full" />
      <span className="skeleton rounded-lg h-6 w-full" />
      <span className="skeleton rounded-lg h-2 w-full" />
    </div>
  );
};

export default ReportsTable;
