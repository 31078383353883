import { PageTitle } from "@/ui/PageTitle";

type Props = {};

const EmployeeDashboard = (props: Props) => {
  return (
    <>
      <PageTitle title="Home" />
      <div className="w-full h-full grid place-content-center font-semibold text-xl">
        Soon we will be able to do a bit of a log of recent activity
      </div>
    </>
  );
};

export default EmployeeDashboard;
