import { FC, useEffect, useState } from "react";

import Spinner from "@/components/utils/spinner";
import { useOrganization } from "@/context/OrganizationContext";
import { useUser } from "@/context/UserContext";
import { InviteTeamMember } from "@/types/validation";
import LoadingSpin from "@/ui/LoadingSpin";
import { setTitle } from "@/utils/helper";
import { inviteUser } from "@/utils/supabase";
import { api } from "@/utils/trpc";
import useForm from "@/utils/useForm";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { ImBlocked } from "react-icons/im";
import { Link, useNavigate } from "react-router-dom";
import CommonLayout from "./CommonLayout";

const roles = ["viewer", "employee", "admin"] as const;

const InviteTeam: FC = () => {
  useEffect(() => setTitle("Invite Team"), []);
  const { organizationId } = useOrganization();
  const { user } = useUser();
  const { inputs, setValue, handleChange, errors, setErrors } = useForm<
    Partial<InviteTeamMember>
  >({});

  const invite = useMutation(inviteUser);
  const usersByEmail = api.users.userByEmail.useMutation();
  const create = api.organizations.createTeamMember.useMutation();
  const nav = useNavigate();

  const utils = api.useUtils();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!user) return;

    (async () => {
      setLoading(true);
      await utils.organizations.myOrganizations.refetch(user.id, {
        type: "all",
      });
      setLoading(false);
    })();
  }, [user]);

  const isLoading =
    invite.isLoading || usersByEmail.isLoading || create.isLoading;

  if (loading) {
    return (
      <CommonLayout step={4}>
        <Spinner />
      </CommonLayout>
    );
  }

  if (!organizationId) {
    return (
      <CommonLayout step={4}>
        <p>
          You must{" "}
          <Link
            to={"/register/company-details"}
            className="text-primary font-bold border-b pb-0.5 border-primary"
          >
            create an organization
          </Link>{" "}
          to continue
        </p>
        <ImBlocked color="red" size={40} />
      </CommonLayout>
    );
  }
  const handleSubmit = async () => {
    const valid = InviteTeamMember.safeParse(inputs);
    if (!valid.success) {
      setErrors(valid.error.formErrors.fieldErrors);
      return toast.error("Please fill all the fields");
    }

    const { email, role } = valid.data;

    let userId = await usersByEmail.mutateAsync(email).then((u) => u?.id);

    if (!userId) {
      userId = (await invite.mutateAsync(email).then((u) => u.id)) as string;
    }

    await create.mutateAsync({
      userId,
      organizationId,
      role,
    });

    nav("/");
  };

  return (
    <CommonLayout step={4}>
      <div className="">
        <h3 className="sm:text-2xl text-2xl text-center font-bold ">
          Invite Members
        </h3>
        <p className="sm:text-xs text-sm text-center">Expand Your Team</p>
      </div>

      <div className="w-full">
        <label className="label">
          <span className="label-text">Email</span>
        </label>
        <div className="flex gap-1 items-center">
          <div>
            <input
              type="text"
              placeholder="Enter email"
              className={`input  shadow-sm input-bordered flex-1`}
              value={inputs.email}
              onChange={handleChange("email")}
            />
            <p className="err h-4">{errors.email}</p>
          </div>

          <div>
            <div className="dropdown ">
              <label tabIndex={0} className="btn w-28 capitalize">
                {inputs.role || "Select role"}
              </label>
              <ul
                tabIndex={0}
                className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-28"
              >
                {roles.map((r) => (
                  <li key={r} onClick={() => setValue("role", r)}>
                    <a className="capitalize">{r}</a>
                  </li>
                ))}
              </ul>
            </div>
            <p className="err h-4">{errors.role}</p>
          </div>
        </div>
      </div>
      <button className="btn w-full btn-primary" onClick={handleSubmit}>
        <LoadingSpin loading={isLoading} />
        Submit
      </button>
      <button className="text-primary btn text-sm" onClick={() => nav("/")}>
        Skip
      </button>
    </CommonLayout>
  );
};

export default InviteTeam;
