import Rows from "@/ui/skeletons/Rows";
import { api } from "@/utils/trpc";
import { format } from "date-fns/esm";
import { FC } from "react";
import { IoWarningOutline } from "react-icons/io5";

type Props = {
  membershipId: string;
};

const BindingErrorInfo: FC<Props> = ({ membershipId }) => {
  const { data, isLoading } = api.swan.membership.get.useQuery(membershipId);

  if (isLoading) return <Rows count={1} />;
  if (!data?.user) return;
  const {
    user: swan,
    team_member: { user: dynt },
  } = data;

  return (
    <div>
      <div className="alert alert-error mb-4">
        <IoWarningOutline size={30} />
        Binding User Error. The following data is mismatching. Please ask the
        user to update their profile. If everything looks okay, please click
        "save".
      </div>

      <table className="table">
        <thead className="text-base-content">
          <tr>
            <th></th>
            <th>Expected</th>
            <th>Provided</th>
          </tr>
        </thead>
        <tbody className="text-error-content">
          <tr className={!swan.idVerified ? "bg-error" : "bg-success"}>
            <th className="bg-base-100 text-base-content text-xs w-24">
              Verification Status
            </th>
            <td>Verified</td>
            <td>{swan.idVerified ? "Verified" : "Not verified"}</td>
          </tr>
          <tr
            className={
              swan.firstName !== dynt.firstName ? "bg-error" : "bg-success"
            }
          >
            <th className="bg-base-100 text-base-content text-xs w-24">
              First Name
            </th>
            <td>{swan.firstName}</td>
            <td>{dynt.firstName}</td>
          </tr>
          <tr
            className={
              swan.lastName !== dynt.lastName ? "bg-error" : "bg-success"
            }
          >
            <th className="bg-base-100 text-base-content text-xs w-24">
              Last Name
            </th>
            <td>{swan.lastName}</td>
            <td>{dynt.lastName}</td>
          </tr>

          <tr
            className={
              swan.birthDate && dynt.dateOfBirth
                ? format(swan.birthDate, "dd/MM/yyy") !==
                  format(dynt.dateOfBirth, "dd/MM/yyy")
                  ? "bg-error"
                  : "bg-success"
                : "bg-error"
            }
          >
            <th className="bg-base-100 text-base-content text-xs w-24">
              Birth Date
            </th>
            <td>
              {swan.birthDate ? format(swan.birthDate, "dd/MM/yyy") : "Missing"}
            </td>
            <td>
              {dynt.dateOfBirth
                ? format(dynt.dateOfBirth, "dd/MM/yyy")
                : "Missing"}
            </td>
          </tr>
          <tr className={swan.phone !== dynt.phone ? "bg-error" : "bg-success"}>
            <th className="bg-base-100 text-base-content text-xs w-24">
              Phone
            </th>
            <td>{swan.phone}</td>
            <td>{dynt.phone}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default BindingErrorInfo;
