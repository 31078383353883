import FormInput from "@/components/FormInput";
import Drawer from "@/components/drawer/Drawer";
import { useOrganization } from "@/context/OrganizationContext";
import { availableIntegrations } from "@/lib";
import { CreateIntegration, CreateIntegrationType } from "@/types/validation";
import LoadingSpin from "@/ui/LoadingSpin";
import { PageTitle } from "@/ui/PageTitle";
import { RouterOutputs, api } from "@/utils/trpc";
import useForm from "@/utils/useForm";
import { useMemo, useState } from "react";
import SingleIntegration from "./SingleIntegration";

type Props = {};

export type Integration = RouterOutputs["integrations"]["list"][number];

const Integration = (props: Props) => {
  const { organizationId = "" } = useOrganization();
  const { data = [], isLoading } =
    api.integrations.list.useQuery(organizationId);
  const [creating, setCreating] = useState(false);
  const create = api.integrations.create.useMutation();
  const sync = api.integrations.sync.useMutation();

  const utils = api.useUtils();

  const { inputs, setValue, handleChange, setErrors, errors, setInputs } =
    useForm<Partial<CreateIntegrationType>>({});

  const integrationMap = useMemo(
    () => Object.fromEntries(data.map((i) => [i.appName, i])),
    [data]
  );

  const handleCreate = async () => {
    const valid = CreateIntegration.safeParse(inputs);

    if (!valid.success) {
      setErrors(valid.error.formErrors.fieldErrors);
      return;
    }

    const res = await create.mutateAsync({
      ...valid.data,
      organizationId,
    });

    utils.integrations.list.setData(organizationId, (p) => {
      if (!p) return [res];
      return [res, ...p];
    });

    setInputs({});
    setCreating(false);
  };

  const handleSync = async (id: string) => {
    const res = await sync.mutateAsync(id);

    utils.integrations.list.setData(organizationId, (p) => {
      if (!p) return [res];
      return p.map((i) => (i.id === id ? res : i));
    });
  };
  return (
    <div>
      <PageTitle title="Integrations" />
      <div className="grid gap-12 my-12">
        {availableIntegrations.map(({ category, items }) => (
          <div className="" key={category}>
            <h3 className="label text-xl underline mb-3">{category}</h3>

            <div className="flex flex-wrap gap-6 justify-">
              {items.map((i) => (
                <SingleIntegration
                  key={i.name}
                  item={i}
                  isLoading={isLoading}
                  setConnect={(appName) => {
                    setInputs({ appName });
                    setCreating(true);
                  }}
                  integration={integrationMap[i.name]}
                />
              ))}
            </div>
          </div>
        ))}
      </div>

      <Drawer
        isOpen={creating}
        onClose={() => setCreating(false)}
        title={`Add ${inputs.appName || ""} Integration`}
      >
        <FormInput
          onChange={handleChange("apiKey")}
          value={inputs.apiKey}
          placeholder="Enter API Key"
          title={`${inputs.appName} API Key`}
          isError={errors.apiKey}
        />

        <FormInput
          onChange={handleChange("subdomain")}
          value={inputs.subdomain}
          placeholder="Enter Subdomain"
          title={`${inputs.appName} Subdomain`}
          isError={errors.subdomain}
        />
        <p className="label text-gray-400 label-text-alt justify-start">
          Example: https:// <kbd className="kbd">dynt-ai</kbd> .kiwihr.com
        </p>

        <button onClick={handleCreate} className="btn mt-6 w-full btn-primary">
          <LoadingSpin loading={create.isLoading} />
          Add
        </button>
      </Drawer>
    </div>
  );
};

export default Integration;
