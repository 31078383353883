import { PageTitle } from "@/ui/PageTitle";
import OrganizationDetails from "./OrganizationDetails";

const CreateOrg = () => {
  return (
    <div>
      <PageTitle title="Create Organization" />
      <OrganizationDetails />
    </div>
  );
};

export default CreateOrg;
