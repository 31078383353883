import { FC, useEffect, useMemo, useState } from "react";
import { MdClose } from "react-icons/md";
import Dropdown, { DropdownProps } from "./Dropdown";

export type MultiSelectOption = { label?: string | null; value: string };

type Props = {
  onChange: (value: string[]) => void;
  placeholder?: string;
  options: MultiSelectOption[];
  values?: string[];
  searchable?: boolean;
  clearable?: boolean;
  className?: string;
  disabled?: boolean;
  error?: boolean;
  viewMode?: "item" | "count";
  title?: string;
} & Partial<DropdownProps>;

const MultiSelect: FC<Props> = ({
  placeholder = "Select an option",
  options,
  values = [],
  clearable = true,
  onChange,
  searchable,
  disabled,
  className = "",
  error,
  viewMode = "item",
  title,
  buttonClass,
  dropdownClass,
  footer,
}) => {
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState<MultiSelectOption[]>([]);

  useEffect(() => {
    if (!values) return;
    const valueSet = new Set(values);
    const selectedOptions = options.filter((o) => valueSet.has(o.value));
    setSelected(selectedOptions);
  }, [values.join(",")]);

  const searchedOptions = useMemo(() => {
    if (!search) return options;

    return options.filter((option) =>
      (option.label || option.value)
        .toLowerCase()
        .includes(search.toLowerCase())
    );
  }, [search, options]);

  const selectedSet = useMemo(
    () => new Set(selected.map((s) => s.value)),
    [selected]
  );

  return (
    <div className={className}>
      <Dropdown
        hideArrow={!!selected.length && viewMode === "item"}
        dropdownClass="w-full"
        buttonClass={`overflow-auto h-fit justify-between max-h-24 flex-1  ${
          error ? "border border-error" : ""
        } ${buttonClass} `}
        footer={footer}
        title={
          selected.length ? (
            viewMode === "count" ? (
              <p>
                {selected.length} {title} selected
              </p>
            ) : (
              <div className="flex flex-wrap overflow-hidden w-full gap-2">
                {selected.map((s) => (
                  <button
                    key={s.value}
                    onClick={() =>
                      onChange(values.filter((v) => v !== s.value))
                    }
                    onTouchEnd={() =>
                      onChange(values.filter((v) => v !== s.value))
                    }
                    className="btn btn-sm btn-neutral"
                  >
                    <p>{s.label || s.value}</p>

                    <MdClose />
                  </button>
                ))}
              </div>
            )
          ) : (
            <p className="flex-1 text-left">{placeholder}</p>
          )
        }
        header={
          searchable && (
            <input
              type="text"
              className="input input-sm input-bordered"
              placeholder={placeholder}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          )
        }
        content={searchedOptions.map((o) => (
          <button
            className={`z-10 w-full text-left ${
              selectedSet.has(o.value) ? "" : ""
            }`}
            onClick={(e) => {
              e.stopPropagation();
              if (selectedSet.has(o.value)) {
                onChange(values.filter((s) => s !== o.value));
              } else onChange([...values, o.value]);
            }}
            onTouchEnd={(e) => {
              e.stopPropagation();
              if (selectedSet.has(o.value)) {
                onChange(values.filter((s) => s !== o.value));
              } else onChange([...values, o.value]);
            }}
            key={o.value}
          >
            {o.label || o.value}
          </button>
        ))}
      />
    </div>
  );
};

export default MultiSelect;
