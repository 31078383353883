import Drawer from "@/components/drawer/Drawer";
import { useOrganization } from "@/context/OrganizationContext";
import LoadingSpin from "@/ui/LoadingSpin";
import { api } from "@/utils/trpc";
import formatDistance from "date-fns/formatDistance";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";

type Props = {};

const PendingMembers = (props: Props) => {
  const { organizationId = "" } = useOrganization();

  const { data = [] } =
    api.organizations.team_member.invite.list.useQuery(organizationId);
  const resend = api.organizations.team_member.invite.resend.useMutation();
  const cancel = api.organizations.team_member.invite.cancel.useMutation();
  const utils = api.useUtils();
  const [params] = useSearchParams();
  const isOpen = params.has("pending");

  const nav = useNavigate();

  const handleResend = (inviteId: string) => async () => {
    await resend.mutateAsync(inviteId);
    toast.success("Invite resent!");
  };

  const handleCanel = (inviteId: string) => async () => {
    await cancel.mutateAsync(inviteId);
    utils.organizations.team_member.invite.list.setData(
      organizationId,
      (p = []) => p.filter((i) => i.id !== inviteId)
    );
    toast.success("Invite cancelled!");
  };
  return (
    <>
      {data.length > 0 && (
        <button
          onClick={() => nav("/organization/team?pending")}
          className="link link-accent btn-primary"
        >
          {data.length} Invites are pending
        </button>
      )}

      <Drawer
        isOpen={isOpen}
        onClose={() => nav("/organization/team")}
        title="Invite Team Member"
        className="grid gap-4"
        drawerClass="lg:w-[80vw] xl:w-[60vw]"
      >
        <table className="table">
          <thead>
            <tr>
              <th>Email</th>
              <th>Invited</th>
              <th />
            </tr>
          </thead>

          <tbody>
            {data.map((invite) => (
              <tr key={invite.id}>
                <td className="break-all">{invite.email}</td>
                <td>
                  {formatDistance(invite.invitedAt, new Date(), {
                    addSuffix: true,
                  })}
                </td>
                <td className="grid gap-2 w-fit">
                  <button
                    onClick={handleResend(invite.id)}
                    className="btn btn-secondary btn-xs"
                  >
                    <LoadingSpin loading={resend.isLoading} />
                    Resend
                  </button>
                  <button
                    onClick={handleCanel(invite.id)}
                    className="btn btn-error btn-outline btn-xs"
                  >
                    <LoadingSpin loading={cancel.isLoading} />
                    Cancel
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Drawer>
    </>
  );
};

export default PendingMembers;
