import SelectFinancialAccount from "@/components/SelectFinancialAccount";
import { BaseType } from "@/types";
import { api } from "@/utils/trpc";
import { FC } from "react";

type Props = {
  expenseId: string;
  financialAccount: BaseType<{ code: string }> | null;
};

const ManageFinancialAccount: FC<Props> = ({ expenseId, financialAccount }) => {
  const add = api.expenses.financialAccount.add.useMutation();
  const remove = api.expenses.financialAccount.remove.useMutation();

  const utils = api.useUtils();

  const handleAdd = async (financialAccountId: string) => {
    const { financialAccount } = await add.mutateAsync({
      financialAccountId,
      expenseId,
    });

    utils.expenses.get.setData(expenseId, (p) => {
      if (!p) return p;
      return { ...p, financialAccount };
    });
  };

  const handleRemove = async () => {
    await remove.mutateAsync(expenseId);
    utils.expenses.get.setData(expenseId, (p) => {
      if (!p) return p;
      return { ...p, financialAccount: null };
    });
  };
  return (
    <SelectFinancialAccount
      {...{
        financialAccount,
        handleAdd,
        handleRemove,
        loading: add.isLoading || remove.isLoading,
      }}
    />
  );
};

export default ManageFinancialAccount;
