import { useOrganization } from "@/context/OrganizationContext";
import { useUser } from "@/context/UserContext";
import { BaseType } from "@/types";
import Checkbox from "@/ui/Checkbox";
import { PageTitle } from "@/ui/PageTitle";
import { api } from "@/utils/trpc";
import {
  MultiSelect,
  MultiSelectItem,
  SearchSelect,
  SearchSelectItem,
} from "@tremor/react";
import { useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import ExpenseDetails, { CreateExpenseProps } from "./ExpenseDetails";

const CreateExpense = () => {
  const { organizationId = "", organization } = useOrganization();
  const { userId = "" } = useUser();
  const create = api.expenses.createExpense.useMutation();
  const utils = api.useUtils();

  const { data: teamMembers = [] } = api.organizations.teamMembers.useQuery(
    organizationId,
    { enabled: !!organizationId }
  );

  const { data: staffs = [] } = api.organizations.staffs.useQuery(
    organizationId,
    { enabled: !!organizationId }
  );

  const { data: merchants = [], isLoading } = api.expenses.merchants.useQuery(
    organizationId,
    { enabled: !!organizationId }
  );

  const { data: events = [] } = api.expenses.events.useQuery(organizationId, {
    enabled: !!organizationId,
  });
  const nav = useNavigate();

  const [teamMemberId, setTeamMemberId] = useState<string | null>(null);
  const [guestStaff, setGuestStaff] = useState<string[]>([]);
  const [eventId, setEventId] = useState<string | null>(null);
  const [merchantName, setMerchantName] = useState("");
  const [merchant, setMerchant] = useState<Partial<BaseType> | null>(null);
  const [externalGuest, setExternalGuest] = useState(false);

  const handleCreate = async ({
    rows,
    customId,
    date,
    currency,
    files,
  }: CreateExpenseProps) => {
    if (!organizationId || !merchant || !userId || !teamMemberId) return;

    const { totalAmount, totalVat } = rows.reduce(
      (acc, { amount, vat, quantity }) => {
        acc.totalVat += vat * quantity;
        acc.totalAmount += amount * quantity;
        return acc;
      },
      { totalAmount: 0, totalVat: 0 }
    );

    const expense = await create.mutateAsync({
      rows,
      date,
      total: totalAmount + totalVat,
      totalAmount,
      totalVat,
      customId,
      currency,
      organizationId,
      guestStaff,
      eventId,
      merchant,
      userId,
      team_member_id: teamMemberId,
      externalGuest,
      files,
    });

    toast.success("Expense created successfully");
    await utils.expenses.expensesByOrgId.invalidate({ organizationId });
    nav(`/expense/${expense.id}`);
  };

  const teamMembersList = useMemo(() => {
    if (!organization?.role || !userId) return [];
    return teamMembers.filter(
      (s) => organization.role === "admin" || s.user.id === userId
    );
  }, [teamMembers.length, organization?.role, userId]);

  return (
    <div className="grid content-start gap-4">
      <PageTitle title="Create Expense" />
      <div className="">
        <p className="font-semibold mb-2">Choose a Merchant:</p>

        <Select
          options={[{ name: merchantName, id: undefined }, ...merchants]}
          onInputChange={(e) => setMerchantName(e)}
          getOptionLabel={(m) => m.name}
          onChange={(e) => setMerchant(e)}
          getOptionValue={(m) => m.id || m.name}
          formatOptionLabel={(m) => (
            <div
              className={`items-center justify-between w-full ${
                m.name ? "flex" : "hidden"
              }`}
            >
              <p
                title={m.name}
                className="flex-wrap text-xs overflow-hidden w-40 font-semibold"
              >
                {m.name}
              </p>

              <div
                className={`rounded-full px-3 py-0.5  text-xs w-fit text-white ${
                  !m.id ? "bg-primary" : "bg-secondary"
                } `}
              >
                {m.id ? "Add" : "Create new"}
              </div>
            </div>
          )}
        />
      </div>

      {!!merchant && (
        <div>
          <p className="font-semibold mb-2">Choose a team member:</p>
          <SearchSelect enableClear={false} onValueChange={setTeamMemberId}>
            {teamMembersList.map((s) => (
              <SearchSelectItem key={s.id} value={s.id}>
                {s.user.name}
              </SearchSelectItem>
            ))}
          </SearchSelect>
        </div>
      )}
      {!!teamMemberId && (
        <>
          <div>
            <div className="flex items-center gap-2 mb-2">
              <p
                className={`font-semibold ${
                  externalGuest ? "opacity-50 pointer-events-none" : ""
                }`}
              >
                Choose guest staffs:
              </p>
              <div className="flex items-center">
                <Checkbox
                  checked={externalGuest}
                  onChange={(c) => {
                    setExternalGuest(c);
                    setGuestStaff([]);
                  }}
                />
                <p className="text-sm">External guest</p>
              </div>
            </div>
            <MultiSelect
              className={externalGuest ? "opacity-50 pointer-events-none" : ""}
              onValueChange={setGuestStaff}
              value={guestStaff}
            >
              {staffs.map((s) => {
                if (s.id === teamMemberId) return null;
                return (
                  <MultiSelectItem key={s.id} value={s.id}>
                    {s.name}
                  </MultiSelectItem>
                );
              })}
            </MultiSelect>
          </div>
          <div>
            <p className="font-semibold mb-2">Select an event:</p>
            <SearchSelect onValueChange={setEventId}>
              {events.map((s) => (
                <SearchSelectItem key={s.id} value={s.id}>
                  {s.name}
                </SearchSelectItem>
              ))}
            </SearchSelect>
          </div>
          <ExpenseDetails {...{ handleCreate }} />
        </>
      )}
    </div>
  );
};

export default CreateExpense;
