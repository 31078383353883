import Drawer from "@/components/drawer/Drawer";
import FormInput from "@/components/FormInput";
import Select from "@/components/Select";
import Switch from "@/components/Switch";
import { useOrganization } from "@/context/OrganizationContext";
import { AddCard } from "@/types/validation";
import LoadingSpin from "@/ui/LoadingSpin";
import { formatLabel, wait } from "@/utils/helper";
import { api } from "@/utils/trpc";
import useForm from "@/utils/useForm";
import { FC, useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import SpendingLimitControl from "../components/SpendingLimitControl";

type Props = {
  accountId: string;
};

const defaultValue = {
  eCommerce: false,
  international: false,
  nonMainCurrencyTransactions: false,
  withdrawal: false,
};

const CreateCard: FC<Props> = ({ accountId }) => {
  const { inputs, setInputs, setErrors, errors, handleChange, setValue } =
    useForm<Partial<AddCard>>(defaultValue);
  const { organization } = useOrganization();

  const { data = [], isLoading } = api.swan.membership.list.useQuery(accountId);

  const add = api.swan.card.add.useMutation();
  const nav = useNavigate();

  const [params] = useSearchParams();
  const isOpen = params.has("new");

  useEffect(() => {
    setInputs(defaultValue);
  }, [isOpen]);

  const handleCreate = async () => {
    if (!organization?.swanAccountMembership?.userId) {
      return toast.error("Swan account membership user info not found");
    }

    const valid = await AddCard.safeParseAsync(inputs);

    if (!valid.success) {
      return setErrors(valid.error.formErrors.fieldErrors);
    }

    const res = await add.mutateAsync({
      ...valid.data,
      swanUserId: organization.swanAccountMembership.userId,
      consentRedirectUrl: `${window.location.origin}/pro-account/cards`,
    });

    if (res.__typename !== "AddCardSuccessPayload") {
      return toast.error(`${formatLabel(res.__typename)}: ${res.message}`);
    }

    if (res.card.statusInfo.__typename !== "CardConsentPendingStatusInfo") {
      return toast.error(
        `${formatLabel(res.card.statusInfo.__typename)}: ${
          res.card.statusInfo.status
        }`
      );
    }

    await toast.promise(wait(1000), {
      loading: "Authorizing your request ...",
      error: "Failed to redirect to OAuth",
      success: "Redirecting to OAuth to get your consent ...",
    });
    await wait(500);

    window.open(res.card.statusInfo.consent.consentUrl, "_blank");
  };

  return (
    <Drawer
      isOpen={isOpen}
      onClose={() => nav("/pro-account/cards")}
      title="Create Card"
      className="gap-4 grid"
    >
      <Select
        value={inputs.membershipId}
        placeholder="Select a Cardholder"
        options={data.map((member) => ({
          label: member.team_member.user.name,
          value: member.id,
        }))}
        isError={!!errors.membershipId}
        label="Cardholder"
        onChange={(e) => setValue("membershipId", e)}
      />

      <FormInput
        placeholder="Name"
        isError={errors.name}
        value={inputs.name}
        onChange={handleChange("name")}
        title="Card Name"
      />

      <div>
        <div className="divider divider-start">Permissions</div>

        <Switch
          value={inputs.withdrawal}
          onChange={(e) => setValue("withdrawal", e)}
          text="Withdrawal"
          className="!w-full justify-between"
        />

        <Switch
          value={inputs.international}
          onChange={(e) => setValue("international", e)}
          text="International"
          className="!w-full justify-between"
        />

        <Switch
          value={inputs.nonMainCurrencyTransactions}
          onChange={(e) => setValue("nonMainCurrencyTransactions", e)}
          text="Non Main Currency Transactions"
          className="!w-full justify-between"
        />

        <Switch
          value={inputs.eCommerce}
          onChange={(e) => setValue("eCommerce", e)}
          text="eCommerce"
          className="!w-full justify-between"
        />
      </div>
      <SpendingLimitControl
        {...{
          amount: inputs.limitAmount,
          period: inputs?.limitPeriod,
          onAmountChange: (value) => setValue("limitAmount", value as any),
          onPeriodChange: (value) => setValue("limitPeriod", value),
        }}
      />

      <button onClick={handleCreate} className="btn btn-sm btn-primary mt-6">
        <LoadingSpin loading={add.isLoading} />
        Save
      </button>
    </Drawer>
  );
};

export default CreateCard;
