import PhoneInput from "@/components/PhoneInput";
import { useUser } from "@/context/UserContext";
import { CreateProfile } from "@/types/validation";
import LoadingSpin from "@/ui/LoadingSpin";
import { setTitle } from "@/utils/helper";
import { api } from "@/utils/trpc";
import useForm from "@/utils/useForm";
import { useEffect } from "react";
import { GrFormNext } from "react-icons/gr";
import { ImBlocked } from "react-icons/im";
import "react-phone-input-2/lib/style.css";
import { Link, useNavigate } from "react-router-dom";
import CommonLayout from "./CommonLayout";

const fields: {
  name: keyof CreateProfile;
  placeholder: string;
  title: string;
}[] = [
  {
    name: "firstName",
    placeholder: "Enter your first name",
    title: "First Name",
  },
  {
    name: "lastName",
    placeholder: "Enter your last name",
    title: "Last Name",
  },
];

const PersonalDetails = () => {
  useEffect(() => setTitle("Personal Details"), []);
  const { handleChange, setValue, inputs, setInputs, errors, setErrors } =
    useForm<Partial<CreateProfile>>({});

  const { session, user, refetch } = useUser();
  const create = api.users.profile.create.useMutation();
  const nav = useNavigate();

  if (!session) {
    return (
      <CommonLayout step={2}>
        <p>
          You must confirm your{" "}
          <Link
            to={"/register/sign-up"}
            className="text-primary font-bold border-b pb-0.5 border-primary"
          >
            sign up
          </Link>{" "}
          to continue
        </p>
        <ImBlocked color="red" size={40} />
      </CommonLayout>
    );
  }

  const handleCreate = async () => {
    const valid = CreateProfile.safeParse({
      ...inputs,
      email: session.user.email,
      name: `${inputs.firstName} ${inputs.lastName}`,
    });

    if (!valid.success) {
      return setErrors(valid.error.formErrors.fieldErrors);
    }

    await create.mutateAsync({
      ...valid.data,
      id: session.user.id,
    });

    const inviteId = localStorage.getItem("@inviteId");

    if (inviteId) return window.location.replace(`/invitation/${inviteId}`);

    window.location.replace("/register/company-details");
  };
  return (
    <CommonLayout step={2}>
      <div className="">
        <h3 className="sm:text-2xl text-2xl text-center font-bold ">
          Your Profile
        </h3>
        <p className="sm:text-xs text-sm text-center">A bit about you</p>
      </div>
      <div className="w-full">
        {fields.map(({ name, placeholder, title }) => (
          <div key={name}>
            <label className="label">
              <span className="label-text">{title}</span>
            </label>
            <input
              placeholder={placeholder}
              className={`input w-full shadow-sm ${
                errors[name] ? "input-error" : "input-bordered"
              }`}
              value={inputs[name] || ""}
              onChange={handleChange(name)}
            />
            <p className="err">{errors[name]}</p>
          </div>
        ))}

        <PhoneInput
          onChange={(e) => setValue("phone", e)}
          value={inputs.phone}
          isError={errors.phone}
        />
      </div>

      <button
        disabled={create.isLoading}
        onClick={handleCreate}
        className="btn btn-primary w-full"
      >
        <LoadingSpin loading={create.isLoading} />
        Next
        <GrFormNext size={30} />
      </button>
    </CommonLayout>
  );
};

export default PersonalDetails;
