import NotFound from "@/components/utils/notfound";
import Spinner from "@/components/utils/spinner";
import LoadingSpin from "@/ui/LoadingSpin";
import { PageTitle } from "@/ui/PageTitle";
import Pagination from "@/ui/Pagination";
import { inviteUser, listAuthUsers, resetPassword } from "@/utils/supabase";
import { User } from "@supabase/supabase-js";
import { useMutation, useQuery } from "@tanstack/react-query";
import format from "date-fns/format";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { BsCheckLg, BsQuestionLg } from "react-icons/bs";
import { FaEnvelope } from "react-icons/fa";
import isEmail from "validator/es/lib/isEmail";

const Users: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [email, setEmail] = useState("");

  const { data, refetch, isLoading } = useQuery(
    ["auth-users", currentPage],
    () => listAuthUsers(currentPage)
  );

  const invite = useMutation(inviteUser);
  const reset = useMutation(resetPassword);

  const handleInvite = async () => {
    if (!isEmail(email)) return toast.error("Invalid email");

    await invite.mutateAsync(email);
    toast.error("User invited successfully");
    setEmail("");
    await refetch();
  };

  const handleReset = (email?: string) => async () => {
    if (!email || !isEmail(email)) return toast.error("Invalid email");

    await reset.mutateAsync(email);
    toast.success("Password reset email sent successfully");
  };

  if (isLoading) return <Spinner />;
  if (!data) return <NotFound title="Users" />;

  return (
    <div className="">
      <PageTitle title="Users" />
      <div className=" items-center flex">
        <label className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">
          Invite
        </label>
        <div className="flex flex-row gap-3">
          <input
            type="email"
            id="email"
            className=" w-full p-2 px-3 text-gray-900 border border-gray-300 text-base      rounded-md"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Invite user by email"
          />

          <button
            disabled={invite.isLoading}
            onClick={handleInvite}
            className="btn text-white bg-primary py-2 rounded-md  text-sm w-40   font-medium"
          >
            Invite
          </button>
        </div>
      </div>

      <div className="users-list border-t mt-5">
        {data.users.map((user: User) => {
          return (
            <div
              key={user.id}
              className=" w-full flex gap-3 items-center py-4 border-b border-gray-200"
            >
              <div className="  flex w-full flex-1 font-bold text-base-content text-base">
                <b className="  flex flex-col font-medium text-base-content text-base">
                  {user.email}
                  <span className="text-xs text-gray-500 flex-1  ">
                    {user.confirmed_at
                      ? format(new Date(user.confirmed_at), "dd/MM/yyyy")
                      : "Not confirmed yet"}
                  </span>
                </b>
              </div>
              <div className="w-fit  text-sm font-medium  ">
                {user.confirmed_at ? (
                  <button className="bg-base-300 rounded-md px-3 py-1.5 hover:bg-base-300">
                    <BsCheckLg className="text-green-400" size="22" />
                  </button>
                ) : (
                  <button className="bg-base-300 rounded-md px-3 py-1.5 hover:bg-base-300">
                    <BsQuestionLg
                      data-tooltip-target="tooltip-default"
                      className="text-red-400"
                      size="22"
                    />
                  </button>
                )}
              </div>

              <button
                className="bg-base-300 rounded-md  px-3 py-1.5   text-slate-400 relative hover:bg-base-300"
                onClick={handleReset(user.email)}
              >
                <LoadingSpin loading={reset.isLoading} />
                <FaEnvelope size="22" />
              </button>
            </div>
          );
        })}
      </div>

      <Pagination {...{ currentPage, setCurrentPage, total: data.total }} />
    </div>
  );
};

export default Users;
