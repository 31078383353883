import Drawer from "@/components/drawer/Drawer";
import FormInput from "@/components/FormInput";
import { useOrganization } from "@/context/OrganizationContext";
import { InviteTeamMember } from "@/types/validation";
import LoadingSpin from "@/ui/LoadingSpin";
import { api } from "@/utils/trpc";
import useForm from "@/utils/useForm";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import SelectRole from "./SelectRole";

type Props = {};

const InviteTeam = (props: Props) => {
  const { inputs, setValue, handleChange, setErrors, errors } = useForm<
    Partial<InviteTeamMember>
  >({});

  const invite = api.organizations.team_member.invite.send.useMutation();

  const [params] = useSearchParams();
  const isOpen = params.has("new");

  const nav = useNavigate();

  const { organizationId = "" } = useOrganization();

  const handleInvite = async () => {
    const valid = InviteTeamMember.safeParse(inputs);

    if (!valid.success) {
      return setErrors(valid.error.formErrors.fieldErrors);
    }

    await invite.mutateAsync({
      ...valid.data,
      organizationId,
    });

    toast.success("Invitation sent successfully");
  };
  return (
    <Drawer
      isOpen={isOpen}
      onClose={() => nav("/organization/team")}
      title="Invite Team Member"
      className="grid gap-4"
    >
      <FormInput
        title="Email"
        value={inputs.email}
        onChange={handleChange("email")}
        placeholder="Enter email"
        isError={errors.email}
      />

      <SelectRole
        role={inputs.role}
        setRole={(role) => setValue("role", role)}
        isError={errors.role}
      />
      <button
        onClick={handleInvite}
        className="btn btn-primary btn-sm w-full mt-6"
      >
        <LoadingSpin loading={invite.isLoading} />
        Invite
      </button>
    </Drawer>
  );
};

export default InviteTeam;
