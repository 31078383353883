import { useOrganization } from "@/context/OrganizationContext";
import LoadingSpin from "@/ui/LoadingSpin";
import { formatTime } from "@/utils/helper";
import { api, RouterOutputs } from "@/utils/trpc";
import formatDistance from "date-fns/formatDistance";
import { BiRun } from "react-icons/bi";
import { IoIosArrowRoundForward, IoIosTimer } from "react-icons/io";
import { PiScalesDuotone } from "react-icons/pi";
import { RiRobot2Line } from "react-icons/ri";
import { Link } from "react-router-dom";
import useAgents from "../hooks/useAgents";
import UpdateAgent from "./UpdateAgent";

type Props = {};

export type Agent = RouterOutputs["organizations"]["agent"]["list"][number];

const Agents = (props: Props) => {
  const { organizationId } = useOrganization<true>();
  const { data = [] } = api.organizations.agent.list.useQuery(organizationId);

  const run = api.organizations.agent.run.useMutation();
  const update = useAgents();

  const handleRun = async (id: string) => {
    const res = await run.mutateAsync(id);
    update(id, res);
  };

  return (
    <div className="flex gap-4 flex-wrap">
      {data.map((agent) => (
        <div className="card bg-base-100 w-96 shadow-xl">
          <div className="card-body gap-6">
            <h2 className="card-title text">
              <RiRobot2Line /> {agent.name}
            </h2>
            <p className="text-sm"> {agent.description}</p>
            <div className="flex items-center gap-4">
              <p className="flex items-center gap-2">
                <IoIosTimer />
                {formatTime(agent.timeSaved)}
              </p>
              <p className="flex items-center gap-2">
                <BiRun />
                {agent.totalRuns}
              </p>
              <p className="flex items-center gap-2">
                <PiScalesDuotone />
                {agent.accuracy * 100}%
              </p>
            </div>
            <div className="card-actions items-center justify-between">
              <button
                className="btn btn-sm btn-primary"
                onClick={() => handleRun(agent.id)}
              >
                Run
                <LoadingSpin
                  loading={run.isLoading && run.variables === agent.id}
                />
              </button>
              <Link
                to={`/organization/agents/${agent.id}`}
                className="btn btn-sm btn-ghost"
              >
                Details <IoIosArrowRoundForward />
              </Link>
            </div>
            <p className="text-xs">
              Last run {formatDistance(agent.lastRun, new Date())}
            </p>
          </div>
          <UpdateAgent agent={agent} />
        </div>
      ))}
    </div>
  );
};

export default Agents;
