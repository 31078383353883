import { FC } from "react";
import Select from "./Select";

type Props = {
  onChange: (id: string) => void;
  value?: string;
  className?: string;
  isError?: string | string[];
  code?: boolean;
  name?: boolean;
  symbol?: boolean;
  noLabel?: boolean;
};

const currencies = ["USD", "EUR", "GBP"];

const SelectCurrency: FC<Props> = ({
  onChange,
  value,
  className = "",
  isError,
}) => {
  return (
    <Select
      value={value}
      onChange={onChange}
      className={className}
      isError={isError}
      options={currencies.map((c) => ({ value: c }))}
    />
  );
};

export default SelectCurrency;
