import { useOrganization } from "@/context/OrganizationContext";
import { useUser } from "@/context/UserContext";
import LoadingSpin from "@/ui/LoadingSpin";
import { formatLabel, wait } from "@/utils/helper";
import { api } from "@/utils/trpc";
import { FC } from "react";
import toast from "react-hot-toast";

type Props = {
  bills?: string[];
  expenses?: string[];
  className?: string;
  text?: string;
};

const PaymentHandler: FC<Props> = ({ bills, expenses, className, text }) => {
  const payBills = api.bills.pay.useMutation();
  const payExpenses = api.expenses.pay.useMutation();
  const { user } = useUser<true>();
  const { organizationId } = useOrganization<true>();

  const handlePay = async () => {
    if (!user.swanUser) return toast.error("Pro account user not found");

    let res;

    if (bills) {
      res = await payBills.mutateAsync({
        redirectURL: window.location.href,
        bills,
        swanUserId: user.swanUser.id,
      });
    }

    if (expenses) {
      res = await payExpenses.mutateAsync({
        redirectURL: window.location.href,
        expensesId: expenses,
        swanUserId: user.swanUser.id,
        organizationId,
      });
    }

    if (!res) return toast.error("Failed to initiate payment");

    if (res.__typename !== "InitiateCreditTransfersSuccessPayload") {
      return toast.error(
        `${formatLabel(res.__typename)}: ${(res as any).message}`
      );
    }

    if (res.payment.statusInfo.__typename !== "PaymentConsentPending") {
      return toast.error(
        `${formatLabel(res.payment.statusInfo.__typename)}: ${
          (res as any).payment.statusInfo.message || ""
        }`
      );
    }

    await toast.promise(wait(1000), {
      loading: "Authorizing your request ...",
      error: "Failed to redirect to OAuth",
      success: "Redirecting to OAuth to get your consent ...",
    });
    await wait(500);

    window.open(res.payment.statusInfo.consent.consentUrl, "_blank");
  };

  const loading = payBills.isLoading || payExpenses.isLoading;
  return (
    <button onClick={handlePay} className={`btn ${className} `}>
      <LoadingSpin loading={loading} />
      Pay {text}
    </button>
  );
};

export default PaymentHandler;
