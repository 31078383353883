import { useOrganization } from "@/context/OrganizationContext";
import { Vendor } from "@/types";
import Collapsible from "@/ui/Collapsible";
import { PageTitle } from "@/ui/PageTitle";
import { api } from "@/utils/trpc";
import { useState } from "react";

import { DataRowType } from "@/components/DataRow";
import NotFound from "@/components/utils/notfound";
import Spinner from "@/components/utils/spinner";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { AccountInfo } from "../invoices/SelectOrganizationAccount";
import BillDetails, { CreateBillProps } from "./BillDetails";
import SelectVendor from "./SelectVendor";
import SelectVendorAccount from "./SelectVendorAccount";

const UpdateBill = () => {
  const { organizationId } = useOrganization();
  const { billId = "" } = useParams();
  const nav = useNavigate();

  const update = api.bills.updateBill.useMutation();

  const { data: bill, isLoading } = api.bills.billToUpdate.useQuery(billId, {
    enabled: !!billId,
  });

  const [selectedVendor, setSelectedVendor] = useState<Vendor | null>(null);

  const [accountInfo, setAccountInfo] = useState<AccountInfo>();

  if (isLoading) return <Spinner />;

  const canEdit =
    bill &&
    ["draft", "scheduled"].includes(bill.status) &&
    bill.organizationId === organizationId;

  if (!canEdit) return <NotFound title="bill" />;

  const handleCreate = async ({
    rows,
    customId,
    dueDate,
    currency,
    terms,
    files,
  }: CreateBillProps) => {
    if (!organizationId) return;

    const { totalAmount, totalVat } = rows.reduce(
      (acc, { amount, vat, quantity }) => {
        acc.totalVat += vat * quantity;
        acc.totalAmount += amount * quantity;
        return acc;
      },
      { totalAmount: 0, totalVat: 0 }
    );

    await update.mutateAsync({
      vendorId: selectedVendor?.id,
      rows,
      dueDate,
      terms,
      total: totalAmount + totalVat,
      totalAmount,
      files,
      totalVat,
      customId,
      currency,
      id: bill.id,
      accountInfo,
      organizationId,
    });

    toast.success("Bill created successfully");

    nav(`/bill/${bill.id}`);
  };

  return (
    <div className="grid content-start gap-4">
      <PageTitle title="Create Bill" />
      <Collapsible
        defaultOpen
        header={
          <div>
            {!selectedVendor ? (
              <p className="font-semibold ">Choose a Vendor:</p>
            ) : (
              <p className="text-sm flex items-center gap-2">
                Selected Vendor:
                <span className="font-bold text-base">
                  {selectedVendor.name}
                </span>
              </p>
            )}
          </div>
        }
        render={(toggle) => (
          <SelectVendor
            {...{
              setSelectedVendor: (a) => {
                setSelectedVendor(a);
                toggle(false);
              },
              prevSelection: bill.vendorId,
            }}
          />
        )}
      />

      {selectedVendor && (
        <Collapsible
          defaultOpen
          header={
            <div>
              {!accountInfo ? (
                <p className="font-semibold ">Choose a bank account:</p>
              ) : (
                <p className="text-sm flex items-center gap-2">
                  Selected account:
                  <span className="font-bold text-base">
                    {accountInfo.name} ({accountInfo.currency})
                  </span>
                </p>
              )}
            </div>
          }
          render={(toggle) => (
            <SelectVendorAccount
              {...{
                setAccountInfo: (a) => {
                  setAccountInfo(a);
                  toggle(false);
                },
                vendorId: selectedVendor.id,
              }}
            />
          )}
        />
      )}
      <BillDetails
        {...{
          handleCreate,
          data: bill,
          prevRows: bill.rows.reduce(
            (acc, el) => ({
              ...acc,
              [el.id]: {
                ...el,
                vat: (el.vat / el.amount) * 100,
              },
            }),
            {} as Record<string, DataRowType>
          ),
        }}
      />
    </div>
  );
};

export default UpdateBill;
