import Drawer from "@/components/drawer/Drawer";
import { useOrganization } from "@/context/OrganizationContext";
import { countries } from "@/lib/countriesWithBanks";
import { UpsertManualAccount } from "@/types/validation";
import LoadingSpin from "@/ui/LoadingSpin";
import { TOAST } from "@/utils/helper";
import { RouterOutputs, api } from "@/utils/trpc";
import useForm from "@/utils/useForm";
import { FC, useEffect } from "react";
import { AiTwotoneBank } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
import { useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";

const currencies = ["USD", "EUR", "GBP"] as const;

const fields: {
  name: keyof Validation;
  title: string;
  placeholder: string;
  currencies: string[];
}[] = [
  {
    name: "name",
    title: "Account Name",
    placeholder: "Enter Account name",
    currencies: ["USD", "EUR", "GBP"],
  },
  {
    name: "type",
    title: "Account Type",
    placeholder: "Enter Account Type",
    currencies: ["USD"],
  },
  {
    name: "bankAddress",
    title: "Bank Address",
    placeholder: "Enter Bank Address",
    currencies: ["USD", "EUR", "GBP"],
  },
  {
    name: "IBAN",
    title: "IBAN",
    placeholder: "Enter IBAN",
    currencies: ["EUR", "GBP"],
  },
  {
    name: "SWIFT",
    title: "SWIFT",
    placeholder: "Enter SWIFT",
    currencies: ["EUR"],
  },
  {
    name: "ACCOUNT_NUMBER",
    title: "Account Number",
    placeholder: "Enter Account Number",
    currencies: ["USD", "GBP"],
  },
  {
    name: "ROUTING_NUMBER",
    title: "Routing Number",
    placeholder: "Enter Routing Number",
    currencies: ["USD"],
  },
  {
    name: "SORT_CODE",
    title: "Sort Code",
    placeholder: "Enter Sort Code",
    currencies: ["GBP"],
  },
];

type Props = {
  accounts: RouterOutputs["manualAccounts"]["organizationAccounts"];
  isLoading: boolean;
};

const ManualAccounts: FC<Props> = ({ accounts, isLoading }) => {
  const { organizationId } = useOrganization<true>();
  const upsert = api.manualAccounts.upsertOrganizationAccount.useMutation();
  const deleteAccount = api.manualAccounts.delete.useMutation();
  const utils = api.useUtils();
  const nav = useNavigate();

  const { handleChange, inputs, setValue, errors, setErrors, setInputs } =
    useForm<Partial<Validation>>({ currency: "EUR" });

  const [params] = useSearchParams();
  const isOpen = params.has("new");

  const handleUpsert = async () => {
    const valid = UpsertManualAccount.safeParse(inputs);

    if (!valid.success) return setErrors(valid.error.formErrors.fieldErrors);

    const res = (await upsert.mutateAsync({
      ...valid.data,
      organizationId,
    })) as (typeof accounts)[number];

    utils.manualAccounts.organizationAccounts.setData(organizationId, (p) => {
      if (!p) return [res];
      return [res, ...p.filter((c) => c.id !== res.id)];
    });
    nav("/accounts/manual");
  };

  // useEffect(() => {
  //   if (editingAccount) return;

  //   setInputs({ currency: "EUR" });
  // }, [editingAccount]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const _tab = queryParams.get("drawer");

    if (_tab === "createManualAccount") nav("/accounts/manual");
  }, [location.search]);

  const handleDelete = async () => {
    if (!inputs.id) return;

    const _confirm = confirm(
      `Are you sure you want to delete the account: ${
        inputs.name || ""
      }? This action cannot be undone.`
    );

    if (!_confirm) return;

    await deleteAccount.mutateAsync(inputs.id);

    utils.manualAccounts.organizationAccounts.setData(organizationId, (p) => {
      if (!p) return [];
      return p.filter((c) => c.id !== inputs.id);
    });

    nav("/accounts/manual");

    TOAST("Account deleted successfully", "success");

    await utils.manualAccounts.organizationAccounts.refetch(organizationId);
  };

  return (
    <div>
      <div className="grid gap-1 my-4">
        {accounts.map((b) => (
          <button
            className="btn h-fit  w-full justify-between"
            key={b.id}
            onClick={() => {
              setInputs(b);
            }}
          >
            <AiTwotoneBank />
            <div className="flex-1 w-1/3 break-words text-left">
              <p className="lg:text-sm text-xs font-semibold">{b.name}</p>
              <p className="text-xs">
                {b.IBAN || b.ACCOUNT_NUMBER || b.ROUTING_NUMBER}
              </p>
            </div>

            <FaEdit />
          </button>
        ))}
      </div>

      <Drawer isOpen={isOpen} onClose={() => {}} title="Manual Account">
        <div className="grid gap-6">
          <div>
            <p className="label">Country</p>
            <select
              value={inputs.countryId || ""}
              className={`select select-bordered w-full ${
                errors.countryId ? "select-error" : ""
              } `}
              onChange={(e) => setValue("countryId", +e.target.value)}
            >
              <option disabled value={""}>
                Select country
              </option>
              {countries.map((c) => (
                <option value={c.id}>{c.name}</option>
              ))}
            </select>
          </div>
          <div>
            <p className="label">Currency</p>
            <div className="flex gap-2 items-center justify-between">
              {currencies.map((c) => (
                <button
                  onClick={() => {
                    setInputs({ currency: c });
                    setErrors({});
                  }}
                  className={`btn flex-1 btn-sm ${
                    inputs.currency === c ? "btn-primary" : ""
                  } `}
                >
                  {c}
                </button>
              ))}
            </div>
          </div>
          <div>
            {fields.map((f, i) => {
              if (!f.currencies.includes(inputs.currency!)) return null;
              return (
                <div key={f.name}>
                  <label className="label">
                    <span className="label-text">{f.title}</span>
                  </label>
                  <input
                    type="text"
                    placeholder={f.placeholder}
                    className={`input  w-full shadow-sm ${
                      errors[f.name] ? "input-error" : "input-bordered"
                    }`}
                    value={inputs[f.name] || ""}
                    onChange={handleChange(f.name)}
                  />
                </div>
              );
            })}
          </div>
          <div className="grid gap-3">
            <button onClick={handleUpsert} className="btn btn-sm btn-primary">
              <LoadingSpin loading={upsert.isLoading} />
              Save Account
            </button>
            {!!inputs.id && (
              <button
                onClick={handleDelete}
                className="btn btn-sm btn-error btn-outline"
              >
                <LoadingSpin loading={deleteAccount.isLoading} />
                Delete Account
              </button>
            )}
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default ManualAccounts;

const validation = z.object({
  id: z.string().optional(),

  name: z.string(),
  bankAddress: z.string().min(1),
  currency: z.string().length(3),
  countryId: z.number().min(1),

  type: z
    .string()
    .optional()
    .nullable()
    .transform((a) => a || null),
  IBAN: z
    .string()
    .optional()
    .nullable()
    .transform((a) => a || null),
  SWIFT: z
    .string()
    .optional()
    .nullable()
    .transform((a) => a || null),
  ACCOUNT_NUMBER: z
    .string()
    .optional()
    .nullable()
    .transform((a) => a || null),
  SORT_CODE: z
    .string()
    .optional()
    .nullable()
    .transform((a) => a || null),
  ROUTING_NUMBER: z
    .string()

    .optional()
    .nullable()
    .transform((a) => a || null),
});

type Validation = z.infer<typeof validation>;
