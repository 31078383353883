import Drawer from "@/components/drawer/Drawer";
import ViewJSON from "@/components/ViewJSON";
import { useOrganization } from "@/context/OrganizationContext";
import { api } from "@/utils/trpc";
import useForm from "@/utils/useForm";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ValidateFinancialAccount } from "./CreateFinancialAccount";
import { FinancialAccount } from "./FinancialAccountList";

type Props = {
  account: FinancialAccount;
};

const UpdateFinancialAccounts: FC<Props> = ({ account }) => {
  const { inputs, handleChange, errors, setErrors } = useForm(account);

  const { organizationId } = useOrganization<true>();

  const update = api.organizations.financialAccount.update.useMutation();
  const utils = api.useUtils();

  const nav = useNavigate();
  const { id } = useParams<{ id?: string }>();

  const handleCreate = async () => {
    const valid = ValidateFinancialAccount.safeParse(inputs);

    if (!valid.success) {
      return setErrors(valid.error.formErrors.fieldErrors);
    }

    const res = await update.mutateAsync({
      ...valid.data,
      id: account.id,
    });

    utils.organizations.financialAccount.list.setData(
      organizationId,
      (p = []) => p.map((t) => (t.id === res.id ? res : t))
    );
    nav("/accounting/financial-accounts");
  };

  return (
    <Drawer
      isOpen={id === account.id}
      onClose={() => nav("/organization/g-l-accounts")}
      title="Update GL Account"
      className="grid gap-4"
    >
      <ViewJSON json={account.json} defaultOpen />
      {/* <div className="grid grid-cols-2 gap-2">
        {fields.map((field) => (
          <FormInput
            key={field}
            placeholder={`Account ${field}`}
            title={field}
            value={inputs[field]}
            onChange={handleChange(field)}
            isError={errors[field]}
            className="last:col-span-2 capitalize"
          />
        ))}
      </div>
      <button onClick={handleCreate} className="btn btn-primary btn-sm mt-4">
        <LoadingSpin loading={update.isLoading} />
        Update
      </button> */}
    </Drawer>
  );
};

export default UpdateFinancialAccounts;
