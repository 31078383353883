import Dropdown from "@/components/Dropdown";
import { useUser } from "@/context/UserContext";
import { ExpenseStatusType, expenseStatuses } from "@/lib";
import { Enums } from "@/types";
import LoadingSpin from "@/ui/LoadingSpin";
import { api } from "@/utils/trpc";
import { FC, useEffect, useState } from "react";
import { HiChevronRight } from "react-icons/hi";

type Props = {
  expenseId: string;
  approvalStatus: Enums["ApprovalStatus"];
  status: ExpenseStatusType;
  expenseUserId: string;
};

const ExpenseStatus: FC<Props> = ({ expenseId, status: s }) => {
  const [status, setStatus] = useState(s);
  const [changed, setChanged] = useState(false);
  const { userId } = useUser();

  const updateStatus = api.expenses.updateExpenseStatus.useMutation();

  useEffect(() => setChanged(status !== s), [status]);

  const utils = api.useUtils();

  const handleStatus = async () => {
    const res = await updateStatus.mutateAsync({
      expenseId,
      status,
      userId,
    });

    utils.expenses.get.setData(expenseId, (p) => {
      if (!p) return p;
      return { ...p, ...res };
    });

    setChanged(false);
  };

  return (
    <div className="flex z-40 justify-between items-center">
      <p className="label label-text hidden sm:flex">Status:</p>
      <Dropdown
        title={status}
        dropdownClass="w-60"
        buttonClass="capitalize"
        content={expenseStatuses.map((s) => (
          <button
            className="justify-between capitalize"
            onClick={(e) => {
              e.stopPropagation();
              setStatus(s);
            }}
            onTouchEnd={(e) => {
              e.stopPropagation();
              setStatus(s);
            }}
          >
            {s}
            <HiChevronRight />
          </button>
        ))}
        footer={
          changed && (
            <div className="w-full sticky bottom-0">
              <button
                onClick={handleStatus}
                className="btn btn-primary sticky bottom-0 w-48 btn-sm"
              >
                Save
                <LoadingSpin loading={updateStatus.isLoading} />
              </button>
            </div>
          )
        }
      />
    </div>
  );
};

export default ExpenseStatus;
