import { RouterOutputs } from "@/utils/trpc";
import format from "date-fns/format";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import UpdateTeamMember from "./UpdateTeamMember";

type Props = {
  member: RouterOutputs["organizations"]["team_member"]["list"][number];
};

const TeamMember: FC<Props> = ({ member }) => {
  const nav = useNavigate();
  return (
    <>
      <tr
        key={member.id}
        onClick={() => nav(`/organization/team/${member.id}`)}
        className={`cursor-pointer border-white/5 ${
          member.isActive ? "" : "opacity-50"
        } `}
      >
        <td className="text-left grid">
          <span className="font-semibold text-sm">{member.user.name}</span>
          <span className="  text-gray-500 text-sm">{member.user.email}</span>
        </td>
        <td>{member.user.phone}</td>
        <td>{member.role}</td>

        <td>{format(member.createdAt, "MMM dd, yyyy")}</td>
        <td className="text-center">
          <div
            className={`btn btn-xs w-16 btn-outline ${
              member.isActive ? "btn-success" : "btn-warning"
            } `}
          >
            {member.isActive ? "Active" : "Inactive"}
          </div>
        </td>
      </tr>

      <UpdateTeamMember member={member} />
    </>
  );
};

export default TeamMember;
