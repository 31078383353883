import { formatCurrency } from "@/utils/helper";
import { RouterOutputs } from "@/utils/trpc";
import { FC } from "react";
import { BsThreeDots } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import CardDetails from "./CardDetails";

type Props = {
  card: RouterOutputs["swan"]["card"]["list"][number];
};

const getGradient = (spending: number, limit: number) => {
  const percentage = (spending / limit) * 100;
  if (percentage < 25) return "progress-success";
  if (percentage < 50) return "progress-info";
  if (percentage < 75) return "progress-warning";
  return "progress-error";
};

const Card: FC<Props> = ({ card }) => {
  const nav = useNavigate();

  return (
    <tr
      key={card.id}
      className="cursor-pointer border-white/5"
      onClick={() => nav(`/pro-account/cards/${card.id}`)}
    >
      <td>{card.membership.team_member.user.name}</td>
      <td>
        <p className="max-w-32 break-before-all">{card.name}</p>
      </td>
      <td>{card.cardMaskedNumber}</td>
      <td>
        <div className="min-w-full w-max  text-right text-xs">
          {formatCurrency(+card.spending, card.currency)} /{" "}
          {formatCurrency(+card.limitAmount, card.currency)}
        </div>
        <progress
          className={`progress ${getGradient(
            +card.spending,
            +card.limitAmount
          )} w-full h-1`}
          value={(+card.spending / +card.limitAmount) * 100}
          max={100}
        />
      </td>
      <td>
        <p className="btn btn-xs btn-outline btn-info">{card.type}</p>
      </td>
      <td>
        <span className="btn btn-success btn-outline btn-xs text-xs">
          {card.status}
        </span>
      </td>
      <th>
        <BsThreeDots />
      </th>
      <div onClick={(e) => e.stopPropagation()}>
        <CardDetails card={card} />
      </div>
    </tr>
  );
};

export default Card;
