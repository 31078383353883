import { FC } from "react";

type Props = {
  maxLength?: number;
  text: string | null | undefined;
  className?: string;
};

const DisplayName: FC<Props> = ({ maxLength = 22, text, className }) => {
  return (
    <p className={className}>
      {text && text?.length > maxLength
        ? text.slice(0, maxLength) + "..."
        : text}
    </p>
  );
};

export default DisplayName;
