import DisplayName from "@/components/DisplayName";
import Drawer from "@/components/drawer/Drawer";
import Spinner from "@/components/utils/spinner";
import { useOrganization } from "@/context/OrganizationContext";
import { encodeObjectToBase64 } from "@/utils/helper";
import { RouterOutputs, api } from "@/utils/trpc";
import format from "date-fns/format";
import { useEffect, useMemo, useState } from "react";
import { HiChevronRight } from "react-icons/hi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import EventDetails from "./EventDetails";

export type Event = RouterOutputs["expenses"]["events"][number];

const routes: Record<string, string> = {
  admin: "/expenses?filters=",
  employee: "/employee/expenses?filters=",
};

const Events = () => {
  const { organizationId = "", organization } = useOrganization();

  const [event, setEvent] = useState<Event | null>(null);
  const { data = [], isLoading } = api.expenses.events.useQuery(
    organizationId,
    { enabled: !!organizationId }
  );
  const nav = useNavigate();
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const _modal = queryParams.get("modal");

    if (_modal == "createEvent") setIsOpen(true);
  }, [location.search]);

  const parentRoute = useMemo(() => {
    const queryParams = new URLSearchParams(location.search);

    queryParams.delete("modal");

    return location.pathname + "?" + queryParams.toString();
  }, [location.search, location.pathname]);

  useEffect(() => {
    if (isOpen) return;
    setEvent(null);
    nav(parentRoute);
  }, [isOpen]);

  return (
    <div className="overflow-auto flex-1 h-full flex flex-col">
      <Drawer
        isOpen={isOpen}
        onClose={(e) => {
          setIsOpen(e);
          nav(parentRoute);
        }}
        title={event?.name || "New Customer"}
        key={event?.id.toString()}
        unMountOnClose
        content={
          <EventDetails
            {...{
              setIsOpen,
              event,
            }}
          />
        }
      />

      <div className="btn  text-xs md:text-base btn-neutral mt-4 mb-3">
        <p className="flex-1 text-left">Name</p>
        <p className="flex-1 hidden md:block text-center">Start date</p>
        <p className="flex-1 text-center hidden md:block">End date</p>
        <p className="flex-1">Num of expenses</p>
        <p className="w-5"></p>
      </div>
      {isLoading && <Spinner />}
      <div className="grid gap-2">
        {data.map((c) => (
          <button
            key={c.id}
            onClick={() => {
              setEvent(c);
              setIsOpen(true);
            }}
            className="btn  text-xs md:text-sm w-full"
          >
            <DisplayName text={c.name} className="flex-1 text-left" />
            <div className="flex-1 hidden md:block w-full text-center">
              {format(c.startDate, "MMM dd, yyyy")}
            </div>
            <div className="flex-1 hidden md:block">
              {format(c.endDate, "MMM dd, yyyy")}
            </div>
            <div onClick={(e) => e.stopPropagation()} className="flex-1 ">
              <Link
                to={`${routes[organization?.role || ""]}${encodeObjectToBase64({
                  eventId: c.id,
                })}`}
              >
                View ({c._count.expenses})
              </Link>
            </div>
            <HiChevronRight size={22} className=" " />
          </button>
        ))}
      </div>
    </div>
  );
};

export default Events;
