import Drawer from "@/components/drawer/Drawer";
import Tabs from "@/components/Tabs";
import { RouterOutputs } from "@/utils/trpc";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CardTransactions from "./CardTransactions";
import UpdateCard from "./UpdateCard";

type Props = {
  card: RouterOutputs["swan"]["card"]["list"][number];
};

const tabs = ["details", "transactions"] as const;
type Tab = (typeof tabs)[number];

const CardDetails: FC<Props> = ({ card }) => {
  const { id, subTab = "details" } = useParams<{ subTab?: Tab; id?: string }>();

  const nav = useNavigate();

  const content: Record<Tab, JSX.Element> = {
    details: <UpdateCard card={card} />,
    transactions: <CardTransactions cardId={card.id} />,
  };

  return (
    <Drawer
      isOpen={id === card.id}
      onClose={() => nav("/pro-account/cards")}
      title="Card Details"
      className="grid"
      drawerClass="lg:w-[80vw] xl:w-[60vw]"
    >
      <Tabs
        className="w-full mb-6 z-20"
        tabs={tabs}
        paths={[`/${card.id}`, `/${card.id}/transactions`]}
        parentRoute="/pro-account/cards"
        tab={subTab}
        type="bordered"
      />

      {content[subTab]}
    </Drawer>
  );
};

export default CardDetails;
