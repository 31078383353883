import SelectAccountingStatus from "@/components/SelectAccountingStatus";
import { Enums } from "@/types";
import { api } from "@/utils/trpc";
import { FC } from "react";
import useTransactions from "../hooks/useTransactions";
import { TransactionHistory } from "../Transactions";

type Props = {
  transaction: TransactionHistory;
};

const ManageAccountingStatus: FC<Props> = ({
  transaction: { accountingStatus: status, id },
}) => {
  const mutate = api.transaction.accountingStatus.update.useMutation();
  const update = useTransactions();

  const handleUpdate = async (status: Enums["AccountingStatus"]) => {
    const res = await mutate.mutateAsync({
      transactionId: id,
      accountingStatus: status,
    });
    update(id, res);
  };

  return (
    <SelectAccountingStatus
      {...{ status, setStatus: handleUpdate, isLoading: mutate.isLoading }}
    />
  );
};

export default ManageAccountingStatus;
