import DisplayName from "@/components/DisplayName";
import Dropdown from "@/components/Dropdown";
import { useOrganization } from "@/context/OrganizationContext";
import LoadingSpin from "@/ui/LoadingSpin";
import { api } from "@/utils/trpc";
import { FC, useMemo, useState } from "react";
import { FaWandMagicSparkles } from "react-icons/fa6";
import { IoMdCheckmarkCircleOutline, IoMdRemoveCircle } from "react-icons/io";
import { MdClose } from "react-icons/md";
import { TransactionHistory } from "../Transactions";
import useTransactions from "../hooks/useTransactions";

type Props = {
  transaction: TransactionHistory;
};

const ManageMerchant: FC<Props> = ({
  transaction: { merchant, id, merchantSuggestion, merchantId, amount },
}) => {
  const { organizationId } = useOrganization<true>();
  const { data = [] } =
    api.organizations.merchant.list.useQuery(organizationId);
  const { data: history = [] } =
    api.organizations.merchant.categorizationHistory.useQuery(
      { merchantId: merchantId!, isCredit: amount > 0 }!,
      { enabled: !!merchantId }
    );
  const [input, setInput] = useState("");

  const add = api.transaction.merchant.add.useMutation();
  const create = api.transaction.merchant.create.useMutation();
  const remove = api.transaction.merchant.remove.useMutation();

  const update = useTransactions();

  const loading = add.isLoading || create.isLoading || remove.isLoading;

  const merchantsList = useMemo(() => {
    if (!input) return data;

    return data
      .filter((m) => m.name.toLowerCase().includes(input.trim().toLowerCase()))
      .sort((a) =>
        a.name.toLowerCase().startsWith(input.toLowerCase()) ? -1 : 1
      );
  }, [input, data]);

  const handleAccept = async (merchantId: string | null) => {
    const res = await add.mutateAsync({
      merchantId,
      suggestionAccepted: !!merchantId,
      transactionId: id,
    });

    update(id, res);
  };

  if (merchantSuggestion && merchantSuggestion.accepted === null) {
    const { merchant } = merchantSuggestion;
    return (
      <div className="w-fit">
        <p className="label text-accent justify-start gap-2">
          <FaWandMagicSparkles />
          Suggested by AI
        </p>
        <div className="btn w-full">
          <LoadingSpin loading={add.isLoading} />

          <div>{merchant.name}</div>
          <div className="flex items-center join">
            <button
              className="btn btn-success join-item btn-xs tooltip tooltip-left tooltip-success"
              onClick={() => handleAccept(merchant.id)}
              data-tip="Approve"
            >
              <IoMdCheckmarkCircleOutline />
            </button>
            <button
              className="btn join-item btn-error btn-outline btn-xs tooltip tooltip-right tooltip-error"
              data-tip="Reject"
              onClick={() => handleAccept(null)}
            >
              <IoMdRemoveCircle />
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="grid gap-4">
      <Dropdown
        dropdownClass="w-full z-50 min-w-max"
        buttonClass="btn-neutral w-full min-w-max justify-between"
        title={
          loading ? (
            <LoadingSpin loading />
          ) : merchant ? (
            <DisplayName
              className="text-sm"
              text={merchant.name}
              maxLength={22}
            />
          ) : (
            <>+ Add merchant</>
          )
        }
        header={
          <div className="items-end bg-base-300 pb-3 w-full flex flex-1 justify-between border-b border-neutral rounded-b-none">
            <label htmlFor="" className="label-text flex-1 grid">
              Create new
              <input
                type="text"
                className="input flex-1 input-bordered 2xl:input-sm input-xs w-full placeholder:text-neutral-600"
                placeholder="Merchant name"
                value={input}
                onChange={(e) => setInput(e.target.value)}
              />
            </label>

            <button
              onClick={async () => {
                if (!input) return;
                const res = await create.mutateAsync({
                  name: input,
                  organizationId,
                  transactionId: id,
                });
                update(id, res);
              }}
              disabled={!input}
              className="btn 2xl:btn-sm btn-xs btn-primary"
            >
              Create
            </button>
          </div>
        }
        content={merchantsList.map((m) => {
          if (m.id === merchant?.id) return null;
          return (
            <button
              onClick={async () => {
                const res = await add.mutateAsync({
                  transactionId: id,
                  merchantId: m.id,
                });
                update(id, res);
              }}
              className="items-center justify-between w-full  flex"
            >
              <p className="flex-wrap 2xl:text-sm text-xs overflow-hidden  font-semibold">
                {m.name}
              </p>

              <div className="btn btn-xs btn-secondary">Add</div>
            </button>
          );
        })}
        footer={
          !!merchant && (
            <button
              onClick={async () => {
                const res = await remove.mutateAsync(id);
                update(id, res);
              }}
              className="btn btn-error w-full btn-xs"
            >
              <MdClose />
              Remove
            </button>
          )
        }
      />

      {!!history.length && (
        <div className="grid gap-2">
          <p className="divider divider-start">Categorization History</p>
          {history.map((c) => (
            <div className="flex flex-col items-start w-full" key={c.id}>
              <button className="text-left w-full flex items-center gap-2">
                <span
                  style={{ backgroundColor: c.color }}
                  className="btn btn-circle btn-xs"
                ></span>
                <span>
                  <p className="text-sm font-semibold">{c.name}</p>
                  <p className="text-xs italic font-normal text-left">
                    {c.group}
                  </p>
                </span>
                <span className="badge badge-neutral">{c.count}</span>
              </button>

              {!!c.subCategories.length && (
                <div className="grid mt-3">
                  <span className="px-3 text-xs">Subcategories:</span>
                  {c.subCategories.map((s) => {
                    return (
                      <button
                        className="flex hover:bg-base-100/50 rounded-md px-3 py-2 items-center gap-2"
                        key={s.id}
                      >
                        <span
                          style={{ backgroundColor: s.color }}
                          className="btn btn-circle btn-xs"
                        ></span>
                        <p className="text-xs text-left">{s.name}</p>
                        <span className="badge badge-neutral">{s.count}</span>
                      </button>
                    );
                  })}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ManageMerchant;
