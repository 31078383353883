import Dropdown from "@/components/Dropdown";
import { useOrganization } from "@/context/OrganizationContext";
import LoadingSpin from "@/ui/LoadingSpin";
import { api } from "@/utils/trpc";
import { FC } from "react";
import { TransactionHistory } from "../Transactions";
import useTransactions from "../hooks/useTransactions";

type Props = {
  transaction: TransactionHistory;
  showLabel?: boolean;
  className?: string;
};

const AssignFinancialAccount: FC<Props> = ({
  transaction: { financialAccount, id: transactionId },
  showLabel,
  className,
}) => {
  const { organizationId } = useOrganization<true>();
  const { data = [], isLoading } =
    api.organizations.financialAccount.list.useQuery(organizationId);

  const update = useTransactions();

  const add = api.transaction.financialAccount.add.useMutation();
  const remove = api.transaction.financialAccount.remove.useMutation();

  const handleAdd = (financialAccountId: string) => async () => {
    const { financialAccount } = await add.mutateAsync({
      financialAccountId,
      transactionId,
    });

    update(transactionId, { financialAccount });
  };

  const handleRemove = async () => {
    await remove.mutateAsync(transactionId);

    update(transactionId, { financialAccount: null });
  };

  const loading = add.isLoading || remove.isLoading;

  return (
    <div className={className}>
      {showLabel && (
        <label className="text-sm divider divider-start">GL Code</label>
      )}
      <Dropdown
        dropdownClass="w-full min-w-max z-10 "
        buttonClass="bg-base-300"
        title={
          loading ? (
            <LoadingSpin loading />
          ) : financialAccount ? (
            <span>
              {financialAccount.name} ({financialAccount.code})
            </span>
          ) : (
            "N/A"
          )
        }
        footer={
          financialAccount && (
            <button
              onClick={handleRemove}
              className="btn btn-error btn-sm w-full text-xs"
            >
              Remove ({financialAccount.name})
            </button>
          )
        }
        content={
          isLoading
            ? ["Loading..."]
            : data.map((t) => (
                <button
                  onClick={handleAdd(t.id)}
                  onTouchEnd={handleAdd(t.id)}
                  key={t.id}
                >
                  {t.name} ({t.code})
                </button>
              ))
        }
      />
    </div>
  );
};

export default AssignFinancialAccount;
