import CopyToClipboard from "@/components/CopyToClipboard";
import Drawer from "@/components/drawer/Drawer";
import ViewJSON from "@/components/ViewJSON";
import Checkbox from "@/ui/Checkbox";
import LoadingSpin from "@/ui/LoadingSpin";
import { calculateTax, formatCurrency } from "@/utils/helper";
import { api } from "@/utils/trpc";
import format from "date-fns/format";
import { useAtom } from "jotai";
import { FC } from "react";
import { CiWarning } from "react-icons/ci";
import { MdClose, MdDelete } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import ManageAccountingStatus from "./components/ManageAccountingStatus";
import ManageCategories from "./components/ManageCategories";
import AssignFinancialAccount from "./components/ManageFinancialAccount";
import ManageMerchant from "./components/ManageMerchant";
import ManageProofs from "./components/ManageProofs";
import AssignTaxCode from "./components/ManageTaxCode";
import MangeStatuses from "./components/MangeStatuses";
import useTransactions from "./hooks/useTransactions";
import { selectionAtom } from "./store/Selection";
import { TransactionHistory } from "./Transactions";

type Props = {
  transaction: TransactionHistory;
  showAccountingOverview?: boolean;
  index: number;
};

const TransactionRow: FC<Props> = ({
  transaction: t,
  showAccountingOverview,
  index,
}) => {
  const [selection, setSelection] = useAtom(selectionAtom);

  const { id } = useParams<{ id?: string }>();
  const nav = useNavigate();
  const update = useTransactions();

  const notDuplicate = api.transaction.notDuplicate.useMutation();
  const _delete = api.transaction.delete.useMutation();

  const handleNotDuplicate = async (id: string) => {
    await notDuplicate.mutateAsync(id);
    update(t.id, { duplicates: t.duplicates.filter((d) => d.id !== id) });
  };

  const handleDelete = async () => {
    await _delete.mutateAsync(t.id);
    update(t.id, {}, true);
  };

  return (
    <tr
      className="border-white/10 cursor-pointer"
      onClick={() => nav(`/transactions/${t.id}`)}
      key={t.id}
    >
      <td>
        <Checkbox
          checked={!!selection[t.id]}
          onChange={() => {
            if (selection[t.id]) {
              const { [t.id]: _, ...rest } = selection;
              setSelection(rest);
            } else setSelection({ ...selection, [t.id]: t });
          }}
        />
      </td>
      <td>
        <div className="flex items-center gap-2 ">
          {format(t.dateTime, "dd MMM yyyy")}
          {t.duplicates.length > 0 && (
            <CiWarning className="text-warning text-base" />
          )}
        </div>
        <span
          className={`badge badge-sm text-xs ${t.source === "Manual" ? "badge-warning" : "badge-neutral"} `}
        >
          {t.source}
        </span>
      </td>
      <td>{t.merchant?.name || "N/A"}</td>
      <td>
        <div className="flex gap-3 items-center">
          <img src={t.account?.bankLogo!} className="w-7 h-7" alt="" />
          <div>
            <p>{t.account?.name}</p>
            <CopyToClipboard
              className="btn-xs !p-0 text-accent"
              label="IBAN"
              value={t.account?.IBAN || t.account?.BBAN}
            />
          </div>
        </div>
      </td>
      <td>{t.status}</td>
      <td>{formatCurrency(t.amount, t.currency)}</td>

      {showAccountingOverview ? (
        <td className="text-center" style={{ zIndex: 1000 - index }}>
          <ManageAccountingStatus transaction={t} />
        </td>
      ) : (
        <td className=" text-center ">
          <ManageCategories data={t} readonly />
        </td>
      )}

      <Drawer
        title="Transfers"
        className="grid gap-4"
        unMountOnClose
        isOpen={id === t.id}
        onClose={() => nav("/transactions")}
      >
        <div className="text-4xl">
          <p className="px-2">{formatCurrency(t.amount as any, t.currency)}</p>
          {t.taxCode && (
            <p className="text-xs badge badge-neutral">
              tax{" "}
              {formatCurrency(
                calculateTax(t.amount as any, t.taxCode.rate),
                t.currency
              )}{" "}
            </p>
          )}
        </div>
        <ManageMerchant transaction={t} />
        <MangeStatuses transaction={t} />

        <ManageProofs transaction={t} />

        <div className="z-30">
          <div className="divider divider-start">Categories</div>
          <ManageCategories data={t} />
        </div>
        <AssignFinancialAccount
          {...{
            transaction: t,
            showLabel: true,
            className: "z-20",
          }}
        />

        <AssignTaxCode
          {...{
            transactionId: t.id,
            taxCode: t.taxCode,
            amount: t.amount as any,
            currency: t.currency,
            showLabel: true,
          }}
        />

        {t.duplicates.length > 0 && (
          <div className="grid gap-2">
            <p className="title">Duplicates</p>
            {t.duplicates.map((d) => (
              <div
                key={d.id}
                className="btn justify-start gap-4 w-fit btn-neutral btn-sm"
              >
                <p>{format(d.dateTime, "dd MMM yyyy")}</p>
                <span className="badge badge-warning badge-xs">{d.source}</span>
                <p>{d.merchant?.name || "N/A"} </p>
                <p>{formatCurrency(d.amount, d.currency)}</p>
                <button
                  onClick={() => handleNotDuplicate(d.id)}
                  className="btn btn-xs transform-none  btn-warning tooltip tooltip-warning"
                  data-tip="Not duplicate"
                >
                  <MdClose />{" "}
                  <LoadingSpin
                    loading={
                      notDuplicate.variables === d.id && notDuplicate.isLoading
                    }
                  />
                </button>
                {
                  <button
                    onClick={handleDelete}
                    className="btn btn-xs btn-error tooltip tooltip-error"
                    data-tip="Delete duplicate"
                  >
                    <MdDelete />{" "}
                    <LoadingSpin
                      loading={_delete.variables === t.id && _delete.isLoading}
                    />
                  </button>
                }
              </div>
            ))}
          </div>
        )}

        <ViewJSON json={t.json} />
      </Drawer>
    </tr>
  );
};

export default TransactionRow;
