import DisplayName from "@/components/DisplayName";
import NotFound from "@/components/utils/notfound";
import Spinner from "@/components/utils/spinner";
import { useOrganization } from "@/context/OrganizationContext";
import { useUser } from "@/context/UserContext";
import LoadingSpin from "@/ui/LoadingSpin";
import { PageTitle } from "@/ui/PageTitle";
import { copyToClipboard, formatCurrency, formatLabel } from "@/utils/helper";
import { api } from "@/utils/trpc";
import format from "date-fns/format";
import formatDistance from "date-fns/formatDistance";
import { useMemo, useState } from "react";
import { FaSimCard } from "react-icons/fa";
import { ImConnection } from "react-icons/im";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { MdOutlineContentCopy } from "react-icons/md";
import { RiMoneyDollarCircleFill, RiVisaLine } from "react-icons/ri";
import { useParams } from "react-router-dom";

const tabs = ["transactions", "authorizations"] as const;
type Tab = (typeof tabs)[number];

const authStatusStyles: Record<string, string> = {
  closed: "bg-blue-100 text-blue-500",
  pending: "bg-yellow-100 text-yellow-500",
  reversed: "bg-orange-100 text-orange-500",
};

const cardStatusStyles = {
  active: "btn-success",
  inactive: "btn-warn",
  canceled: "btn-error",
} as const;

const CardPage = () => {
  const { id = "" } = useParams();

  const [tab, setTab] = useState<Tab>("transactions");
  const { organizationId = "" } = useOrganization();
  const { data: account = { id: "" } } =
    api.stripe.accountByOrgId.useQuery(organizationId);
  const { userId = "" } = useUser();

  const { data: card, isLoading } = api.stripe.cardById.useQuery(
    { accountId: account.id, id, userId },
    { enabled: !!id && !!account.id && !!userId }
  );

  const updateStatus = api.stripe.updateCardStatus.useMutation();
  const utils = api.useUtils();

  const [showDetails, setShowDetails] = useState(false);

  const { authorizations, transactions } = useMemo(() => {
    if (!card) return { transactions: 0, authorizations: 0 };

    const transactions = card.transactions.reduce((acc, el) => {
      if (el.type === "capture") acc += Math.abs(el.amount);
      return acc;
    }, 0);

    const authorizations = card.authorizations.reduce((acc, el) => {
      if (el.approved) acc += el.amount;
      return acc;
    }, 0);

    return { transactions, authorizations };
  }, [card]);

  if (isLoading) return <Spinner />;

  if (!card) return <NotFound title="Card" />;

  const handleStatus = (input: "active" | "inactive") => async () => {
    const { status } = await updateStatus.mutateAsync({
      id,
      accountId: account.id,
      status: input,
    });

    utils.stripe.cardById.setData(
      { accountId: account.id, id, userId },
      (card) => card && { ...card, status }
    );
  };
  return (
    <>
      <PageTitle title="Card" />
      <div className="flex flex-col gap-8">
        <div className="flex xl:flex-row gap-8 flex-col w-full justify-between">
          <div className="alert aspect-[16/9]  flex card-ui flex-col justify-center  ">
            <div className="flex justify-between items-center">
              <ImConnection size={35} className="rotate-90" />
              <RiVisaLine size={80} />
            </div>
            <div className="flex items-center ">
              <div className="text-xs flex-1 grid gap-5">
                <div className=" flex items-center gap-3 justify-center">
                  {!!card.number && (
                    <button
                      className="grid opacity-0 card-show_details justify-center"
                      onClick={() => setShowDetails((p) => !p)}
                    >
                      {showDetails ? (
                        <IoMdEye size={24} />
                      ) : (
                        <IoMdEyeOff size={24} />
                      )}
                      <p className="text-[11px] text-slate-400 text-center">
                        {!showDetails ? "show" : "hide"}
                      </p>
                    </button>
                  )}
                  <p className="font-bold flex-1 text-center lg:text-xl text-base">
                    {showDetails ? card.number : card.last4.padStart(16, "*")}
                  </p>
                  {showDetails && (
                    <button
                      onClick={() =>
                        copyToClipboard(card.number, "Card number")
                      }
                    >
                      <MdOutlineContentCopy size={20} />
                    </button>
                  )}
                </div>

                <div className="flex items-center lg:gap-5 gap-2">
                  <div className="grid grid-rows-2 gap-1">
                    <p className="text-gray-400">Cardholder name</p>
                    <DisplayName text={card.cardholder.name} />
                  </div>
                  <div className="grid grid-rows-2 gap-1">
                    <p className="text-gray-400">Expiry Date</p>
                    <p>
                      {card.exp_month}/{card.exp_year.toString().slice(2)}
                    </p>
                  </div>
                  <div className="grid grid-rows-2 gap-1">
                    <p className="text-gray-400 mr-2">CVC</p>
                    <div className="flex items-center gap-2">
                      <p>{showDetails ? card.cvc : "***"}</p>
                      {showDetails && (
                        <button
                          onClick={() => copyToClipboard(card.cvc, "Card CVC")}
                        >
                          <MdOutlineContentCopy size={20} />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <FaSimCard size={32} className="rotate-90 mt-8" />
            </div>
          </div>
          <div className="alert !flex w-full">
            <div className="grid content-around flex-1  w-full gap-6">
              <div className="flex justify-between w-full">
                <div className="grid">
                  <p className="text-xs text-gray-500 ">CURRENT SPEND</p>
                  <p className="font-semibold text-xl">
                    {formatCurrency(transactions, "EUR")}
                  </p>
                </div>
                <div className="btn btn-circle btn-error">
                  <RiMoneyDollarCircleFill className="text-white" size={30} />
                </div>
              </div>
              <progress
                className="progress progress-primary"
                value={(transactions / authorizations) * 100}
                max={100}
              />
              <div className="">
                <p className="text-xs">SPENDING LIMIT:</p>
                <div className="grid gap-1">
                  {card.spending_controls.spending_limits?.map(
                    ({ amount, categories: c, interval }, i) => (
                      <div
                        key={i}
                        className="flex items-center gap-3 capitalize text-base"
                      >
                        <p className="font-semibold">
                          {formatCurrency(amount / 100, "EUR")}
                        </p>
                        <p className="font-semibold">{interval}</p>
                        <div className="font-semibold">
                          {!c?.length
                            ? "All categories"
                            : c?.slice(0, 1).map((e) => formatLabel(e) + ", ")}
                          {c && c?.length > 1 && (
                            <span className="text-primary">
                              {" "}
                              +{c.length - 3} more
                            </span>
                          )}
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className=" alert text-xs w-full !flex">
            <div className="grid content-around gap-6 h-full w-full flex-1">
              <div className="flex items-center justify-between w-full">
                <div className="flex items-center gap-2">
                  <p className="font-semibold">Type:</p>
                  <p className="capitalize">{card.type}</p>
                </div>
                <div className="flex items-center gap-2">
                  <p className="font-semibold">Created</p>
                  <p className="capitalize">
                    {format(card.created * 1000, "MMM dd, YYY")}
                  </p>
                </div>
              </div>
              <div className="flex items-center justify-between w-full">
                <div className="flex items-center gap-2">
                  <p className="font-semibold">Currency</p>
                  <p className="uppercase">{card.currency}</p>
                </div>
                <div className="flex items-center gap-2">
                  <p>Status:</p>
                  <p
                    className={`btn btn-sm capitalize ${
                      cardStatusStyles[card.status]
                    }`}
                  >
                    {card.status}
                  </p>
                </div>
              </div>

              {card.shipping?.status && (
                <div className="w-full">
                  <p className="font-semibold">Shipping</p>
                  <p className="font-semibold capitalize">
                    Carrier: {formatLabel(card.shipping.carrier || "N/A")}
                  </p>
                  <p className="font-semibold capitalize">
                    Status: {card.shipping.status}
                  </p>
                  <p className="font-semibold capitalize">
                    ETA:{" "}
                    {formatDistance(
                      new Date((card.shipping.eta || 0) * 1000),
                      new Date(),
                      { addSuffix: true }
                    )}
                  </p>
                </div>
              )}

              {card.status !== "canceled" && (
                <button
                  className="btn btn-neutral"
                  onClick={handleStatus(
                    card.status === "active" ? "inactive" : "active"
                  )}
                >
                  <LoadingSpin loading={updateStatus.isLoading} />
                  {card.status === "active" ? "Deactivate" : "Activate"}
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="flex gap-2 lg:gap-3 mb-3 items-center">
          {tabs.map((t) => (
            <button
              onClick={() => setTab(t)}
              key={t}
              className={`btn capitalize ${t == tab ? "btn-primary" : ""}`}
            >
              {t}
            </button>
          ))}
        </div>
        {tab === "transactions" && (
          <div className="grid gap-2">
            <div className="btn btn-neutral">
              <p className="flex-1 text-left">DATE</p>
              <p className="flex-1">AMOUNT</p>
              <p className="flex-1">MERCHANT</p>
              <p className="flex-1">MERCHANT CATEGORY</p>
            </div>

            <div className="grid gap-1">
              {card.transactions.map((t) => (
                <div key={t.id} className="btn">
                  <p className="flex-1 text-left">
                    {format(t.createdAt, "MMM dd, YYY")}
                  </p>
                  <p className="flex-1 ">
                    {formatCurrency(t.amount, t.currency)}
                  </p>
                  <p className="flex-1 ">{t.merchant?.name}</p>
                  <p className="flex-1 ">
                    {formatLabel(t.merchant?.category || "N/A")}
                  </p>
                </div>
              ))}
            </div>
          </div>
        )}
        {tab === "authorizations" && (
          <div className="grid gap-2">
            <div className="btn btn-neutral">
              <p className="flex-1 text-left">DATE</p>
              <p className="flex-1">AMOUNT</p>
              <p className="flex-1">STATUS</p>
              <p className="flex-1">MERCHANT</p>
              <p className="hidden md:block">MERCHANT CATEGORY</p>
            </div>

            <div className="grid gap-1">
              {card.authorizations.map((t) => (
                <div key={t.id} className="btn">
                  <p className="flex-1 text-left">
                    {format(t.createdAt, "MMM dd, YYY")}
                  </p>
                  <p className="md:flex grid flex-1  items-center gap-3">
                    {formatCurrency(t.amount, t.currency)}
                    <span
                      className={`btn transform-none btn-xs text-xs  ${
                        t.approved ? "btn-success" : "btn-error"
                      }`}
                    >
                      {t.approved ? "APPROVED" : "DECLINED"}
                    </span>
                  </p>
                  <p className="btn btn-neutral btn-info btn-xs text-xs">
                    {t.status}
                  </p>
                  <p className="flex-1">{t.merchant?.name}</p>
                  <p className="hidden md:block">
                    {formatLabel(t.merchant?.category || "N/A")}
                  </p>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CardPage;
