import Rows from "@/ui/skeletons/Rows";
import { copyToClipboard, formatCurrency } from "@/utils/helper";
import { api } from "@/utils/trpc";
import { format } from "date-fns/esm";
import { FC } from "react";
import toast from "react-hot-toast";
import { RiRefreshLine } from "react-icons/ri";
import AssignFinancialAccount from "../components/AssignFinancialAccount";
import AssignTaxCode from "../components/AssignTaxCode";
import ManageCategories from "../components/ManageCategories";

const statusColor = {
  Booked: "btn-success",
  Rejected: "btn-error",
  Pending: "btn-warning",
  Canceled: "btn-error",
  Upcoming: "btn-info",
  Released: "btn-neutral",
};

type Props = {
  cardId: string;
};

const calculateTax = (amount: number, rate: number) => {
  return amount - amount / (1 + rate / 100);
};

const CardTransactions: FC<Props> = ({ cardId }) => {
  const {
    data = [],
    isLoading,
    refetch,
    isRefetching,
  } = api.swan.card.transactions.useQuery(cardId);

  const handleRefetch = async () => {
    await refetch();
    toast.success("Refetch successful!");
  };

  if (isLoading) return <Rows count={3} />;
  return (
    <div className="grid gap-6">
      <button
        className="btn btn-secondary justify-self-end btn-outline btn-sm"
        onClick={handleRefetch}
      >
        <RiRefreshLine
          size={20}
          className={isRefetching ? "animate-spin" : ""}
        />
      </button>
      <div className="w-full overflow-x-auto">
        <table className="table bg-base-300">
          <thead>
            <tr>
              <th>Transaction</th>
              <th>Amount</th>
              <th className="text-center">Tax Code</th>
              <th className="text-center">Category</th>
              <th className="text-center">GL Account</th>
              <th className="text-center">Date</th>
              <th className="text-center">Status</th>
            </tr>
          </thead>

          <tbody>
            {data.map((t, i, { length }) => (
              <tr
                style={{ zIndex: length - i }}
                key={t.id}
                onClick={() => copyToClipboard(t.id, "Transaction ID")}
              >
                <td>{t.counterParty}</td>
                <td className="text-right">
                  <p className="px-2">
                    {formatCurrency(t.amount as any, t.currency)}
                  </p>
                  {t.taxCode && (
                    <p className="text-xs min-w-max badge badge-neutral">
                      tax{" "}
                      {formatCurrency(
                        calculateTax(t.amount as any, t.taxCode.rate),
                        t.currency
                      )}{" "}
                    </p>
                  )}
                </td>
                <td className="min-w-full w-max">
                  <AssignTaxCode
                    {...{
                      cardId,
                      transactionId: t.id,
                      taxCode: t.taxCode,
                      amount: t.amount as any,
                      currency: t.currency,
                    }}
                  />
                </td>
                <td className="text-center">
                  <td className="text-center">
                    <ManageCategories
                      {...{
                        ...t.categoryInfo,
                        cardId,
                        transactionId: t.id,
                      }}
                    />
                  </td>
                </td>
                <td>
                  <AssignFinancialAccount
                    {...{
                      cardId,
                      transactionId: t.id,
                      financialAccount: t.financialAccount,
                    }}
                  />
                </td>
                <td>{format(t.executionDate, "dd/MM/yyyy H:mm:ss")}</td>
                <td className="text-center">
                  <span
                    className={`btn btn-xs btn-outline ${
                      statusColor[t.status]
                    }`}
                  >
                    {t.status}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CardTransactions;
