import NotFound from "@/components/utils/notfound";
import { useOrganization } from "@/context/OrganizationContext";
import { months } from "@/lib";
import { Enums } from "@/types";
import Checkbox from "@/ui/Checkbox";
import Tooltip from "@/ui/Tooltip";
import Rows from "@/ui/skeletons/Rows";
import { formatCurrency } from "@/utils/helper";
import { api } from "@/utils/trpc";
import endOfYear from "date-fns/endOfYear";
import startOfYear from "date-fns/startOfYear";
import { FC, useMemo, useState } from "react";
import { FaCaretDown } from "react-icons/fa";

type Props = {
  year: number;
};

const groups: Enums["CategoryGroup"][] = ["revenue", "cost", "internal"];

const ProfitNLossReport: FC<Props> = ({ year }) => {
  const { organization, organizationId = "" } = useOrganization();

  const [collapse, setCollapse] = useState<Record<string, boolean>>({
    revenue: true,
    cost: true,
    internal: true,
  });

  const period = useMemo(() => {
    const startDate = startOfYear(new Date().setFullYear(year));
    const endDate = endOfYear(new Date().setFullYear(year));

    return { endDate, startDate };
  }, [year]);

  const { data, isLoading } = api.reports.profitAndLoss.useQuery({
    organizationId,
    ...period,
  });

  const [showInternal, setShowInternal] = useState(false);
  if (isLoading) return <Rows count={10} />;
  if (!data || !organization) return <NotFound title="Data" />;

  const {
    reports,
    currency,
    totalTransactions,
    totalUnCategorized,
    openingBalances,
  } = data;

  const revenue = reports.revenue?.total || 0;
  const cost = reports.cost?.total || 0;

  const percentage = (totalUnCategorized / totalTransactions) * 100;

  return (
    <div>
      <div className="flex mb-4 text-sm items-center gap-2">
        <Checkbox checked={showInternal} onChange={setShowInternal} />
        <p className="text-primary">Show internal</p>
      </div>
      <table className="table text-xs">
        <thead className="bg-neutral border border-neutral text-neutral-content">
          <tr>
            <th className="flex items-center gap-4">
              Name
              <Tooltip
                className="z-20 tooltip-right"
                text={[
                  percentage <= 100
                    ? `${percentage.toFixed(
                        1
                      )}% of transactions are categorized. Please label the remaining ${totalUnCategorized} transactions for accurate reporting.`
                    : totalTransactions
                      ? "100% of transactions are categorized. Your reports are now accurate."
                      : "No transaction found",
                ]}
              />
            </th>
            {months.map((m) => (
              <th className="text-center" key={m.name}>
                {m.name}
              </th>
            ))}
            <th className="text-center">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr className="border cursor-pointer  border-base-300 bg-base-100">
            <th className="text-">Opening Balance</th>
            {openingBalances.map((o, i) => (
              <td key={i} className="text-center">
                {formatCurrency(o || 0, currency, true)}
              </td>
            ))}
            <th />
          </tr>
          {groups.map((g) => {
            if (g === "internal" && !showInternal) return null;
            return (
              <>
                <tr
                  key={g}
                  className="border cursor-pointer  border-base-300 bg-base-100"
                >
                  <th className="min-w-full w-max">
                    <button
                      className="btn btn-ghost w-max text-info font-semibold text-xs capitalize"
                      onClick={() =>
                        setCollapse((p) => ({
                          ...p,
                          [g]: !p[g],
                        }))
                      }
                    >
                      Total {g}
                      <FaCaretDown
                        className={`transition-all duration-300 ${
                          collapse[g] ? "rotate-180 " : ""
                        }`}
                      />
                    </button>
                  </th>
                  {months.map((m) => (
                    <th className="text-center" key={m.name}>
                      {formatCurrency(
                        reports[g].history[m.name] || 0,
                        currency
                      )}
                    </th>
                  ))}
                  <th className="text-center">
                    {formatCurrency(reports[g].total || 0, currency)}
                  </th>
                </tr>
                {collapse[g] &&
                  reports[g].categories.map((cat) => (
                    <>
                      <tr
                        key={cat.id}
                        className="border cursor-pointer  border-base-300 bg-base-100"
                      >
                        <th className="w-max">
                          <button
                            className="btn text-xs btn-ghost w-max"
                            onClick={() =>
                              setCollapse((p) => ({
                                ...p,
                                [cat.id]: !p[cat.id],
                              }))
                            }
                          >
                            <span
                              className="circle h-4 w-4 rounded-full mr-2"
                              style={{
                                backgroundColor: cat.color,
                              }}
                            />
                            <p>{cat.name}</p>

                            <FaCaretDown
                              className={`transition-all duration-300 ${
                                collapse[cat.id] ? "rotate-180 " : ""
                              }`}
                            />
                          </button>
                        </th>
                        {months.map((m) => (
                          <td
                            key={m.name}
                            className="text-center text-xs border-l border-base-300"
                          >
                            {formatCurrency(cat.history[m.name] || 0, currency)}
                          </td>
                        ))}
                        <td className="text-center text-xs border-l border-base-300">
                          {formatCurrency(Math.abs(cat.total), currency)}
                        </td>
                      </tr>

                      {collapse[cat.id] && (
                        <>
                          <tr
                            key={cat.id + "sub"}
                            className="border cursor-pointer  border-base-100 bg-base-300"
                          >
                            <td className="border-x cursor-pointer border-base-100">
                              Sub categories
                            </td>
                            {Array.from({ length: 13 }).map((_, i) => (
                              <td />
                            ))}
                          </tr>
                          {cat.subCategories.map((s) => (
                            <tr
                              key={s.id}
                              className="border bg-base-300 cursor-pointer  border-base-100"
                            >
                              <td className="flex items-center ml-6">
                                <span
                                  className="circle h-4 w-4 rounded-full mr-2"
                                  style={{
                                    backgroundColor: s.color,
                                  }}
                                />
                                <p className="flex-1">{s.name}</p>
                              </td>
                              {months.map((m) => (
                                <td
                                  key={m.name}
                                  className="text-center text-xs border-l border-base-100"
                                >
                                  {formatCurrency(
                                    s.history[m.name] || 0,
                                    currency
                                  )}
                                </td>
                              ))}
                              <td className="text-center text-xs border-l border-base-100">
                                {formatCurrency(Math.abs(s.total), currency)}
                              </td>
                            </tr>
                          ))}
                        </>
                      )}
                    </>
                  ))}
              </>
            );
          })}
          <tr className="border cursor-pointer  border-base-300 bg-base-100">
            <th>
              <div className="text- text-info btn btn-ghost text-xs">
                Net profit
              </div>
            </th>
            {months.map((m) => (
              <td key={m.name} className="text-center w-max ">
                {formatCurrency(
                  (reports.revenue.history[m.name] || 0) -
                    (reports.cost.history[m.name] || 0),
                  currency
                )}
              </td>
            ))}
            <th className="text-center">
              {formatCurrency(revenue - cost, currency)}
            </th>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ProfitNLossReport;
