import { accountingStatuses, AccountingStatusType } from "@/lib";
import LoadingSpin from "@/ui/LoadingSpin";
import { formatLabel } from "@/utils/helper";
import { FC } from "react";
import Dropdown from "./Dropdown";

type Props = {
  status: AccountingStatusType;
  setStatus: (status: AccountingStatusType) => void;
  isLoading?: boolean;
};

const SelectAccountingStatus: FC<Props> = ({
  setStatus,
  status,
  isLoading,
}) => {
  return (
    <Dropdown
      dropdownClass="w-40"
      buttonClass="capitalize"
      title={isLoading ? <LoadingSpin loading /> : formatLabel(status)}
      content={accountingStatuses.map((s) => (
        <button onClick={() => setStatus(s)} className="capitalize">
          {formatLabel(s)}
        </button>
      ))}
    />
  );
};

export default SelectAccountingStatus;
