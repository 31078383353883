import Drawer from "@/components/drawer/Drawer";
import Tabs from "@/components/Tabs";
import { useOrganization } from "@/context/OrganizationContext";
import LoadingSpin from "@/ui/LoadingSpin";
import Rows from "@/ui/skeletons/Rows";
import { api } from "@/utils/trpc";
import { useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

type Props = {};

const ConnectGocardless = (props: Props) => {
  const [country, setCountry] = useState("NL");
  const { data = [], isLoading } =
    api.gocardless.institution.list.useQuery(country);
  const authorize = api.gocardless.authorize.useMutation();
  const { organizationId } = useOrganization<true>();
  const [search, setSearch] = useState("");

  const nav = useNavigate();

  const [params] = useSearchParams();
  const isOpen = params.has("new");

  const institutions = useMemo(() => {
    const _search = search.trim().toLowerCase();
    return data
      .filter((i) => i.name.toLowerCase().includes(_search))
      .sort((a, b) => (b.name.toLowerCase().startsWith(_search) ? 1 : -1));
  }, [data, search]);

  const handleAuthorize = (institution: (typeof data)[number]) => async () => {
    const res = await authorize.mutateAsync({
      institution,
      organizationId,
      country,
      redirectUrl: `${window.location.origin}/accounts/connected`,
    });

    window.location.href = res.link;
  };

  return (
    <Drawer
      title="Connect new account"
      className="grid gap-8"
      isOpen={isOpen}
      onClose={() => nav("/accounts/connected")}
    >
      <Tabs
        tab={country}
        tabs={["NL", "PT"]}
        setTab={setCountry}
        labels={["Netherlands", "Portugal"]}
        type="boxed"
      />
      <input
        type="text"
        placeholder="Search for your bank"
        className="input input-bordered input-primary"
        onChange={(e) => setSearch(e.target.value)}
      />
      <Rows isLoading={isLoading} />
      <div className="grid gap-2">
        {institutions.map((i) => (
          <button
            onClick={handleAuthorize(i)}
            key={i.id}
            className="btn justify-start"
          >
            <img src={i.logo} className="w-6" alt="" />
            {i.name}
            <div className="ml-auto text-primary">
              <LoadingSpin
                loading={
                  authorize.isLoading &&
                  authorize.variables?.institution.id === i.id
                }
              />
            </div>
          </button>
        ))}
      </div>
    </Drawer>
  );
};

export default ConnectGocardless;
