import Dropdown from "@/components/Dropdown";
import { useOrganization } from "@/context/OrganizationContext";
import { Category, Subcategory } from "@/types";
import { RouterOutputs, api } from "@/utils/trpc";
import { FC, useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { BsArrowDownLeft, BsArrowUpRight } from "react-icons/bs";
import { FiMoreVertical } from "react-icons/fi";
import { MdBlock, MdClose, MdOutlineKeyboardArrowRight } from "react-icons/md";
import LoadingSpin from "./LoadingSpin";

type S = Category;
type C = Subcategory;

type Props = {
  invoiceId?: string;
  transactionId?: string;
  billId?: string;
  expenseId?: string;
  assignedCategories: {
    category?: C | null;
    subCategory?: S | null;
  };
  cachedCategories?: RouterOutputs["utils"]["categories"];
  params?: any;
};

const AssignCategories: FC<Props> = ({
  assignedCategories,
  invoiceId,
  transactionId,
  billId,
  expenseId,
  cachedCategories,
  params,
}) => {
  const { organizationId: orgId = "" } = useOrganization();
  const utils = api.useUtils();

  const { data = [] } = api.utils.categories.useQuery(orgId, {
    enabled: !cachedCategories,
  });

  const categories = useMemo(() => {
    return cachedCategories || data;
  }, [data, cachedCategories]);

  const [changed, setChanged] = useState(false);
  const [{ category, subCategory }, setSelectedCategory] =
    useState(assignedCategories);

  const selectedCategory = { category, subCategory };

  const [showMore, setShowMore] = useState(false);
  const [autoCategories, setAutoCategories] = useState({
    debit: false,
    credit: false,
  });

  const addToInvoice = api.invoices.updateInvoiceCategory.useMutation();
  const addToBill = api.bills.updateBillCategory.useMutation();
  const addToTransaction =
    api.transactions.updateTransactionCategory.useMutation();
  const addToExpense = api.expenses.updateExpenseCategory.useMutation();

  useEffect(() => {
    setSelectedCategory(assignedCategories);
  }, [assignedCategories]);

  const handleCategories = (cat: C, subCat?: S) => (e: any) => {
    e.stopPropagation();
    setChanged(true);
    setSelectedCategory((p) => ({
      category: cat?.id === p.category?.id && !subCat ? null : cat,
      subCategory: subCat?.id === p.subCategory?.id ? null : subCat,
    }));
  };

  const handleAdd = async () => {
    if (!orgId) return toast.error("No organization selected");

    const { category, subCategory } = selectedCategory;

    const data = {
      categoryId: category?.id || null,
      subCategoryId: subCategory?.id || null,
    };
    if (invoiceId) {
      await addToInvoice.mutateAsync({ invoiceId, ...data });
      utils.invoices.invoices
        .invalidate({ organizationId: orgId })
        .then(() => null);
    }
    if (billId) {
      await addToBill.mutateAsync({ billId, ...data });
      utils.bills.bills.invalidate({ organizationId: orgId }).then(() => null);
    }
    if (transactionId) {
      const res = await addToTransaction.mutateAsync({
        transactionId,
        debitCategoryId: autoCategories.debit ? data.categoryId : null,
        creditCategoryId: autoCategories.credit ? data.categoryId : null,
        creditSubCategoryId: autoCategories.credit ? data.subCategoryId : null,
        debitSubCategoryId: autoCategories.debit ? data.subCategoryId : null,
        ...data,
      });

      utils.transactions.transactions.setInfiniteData(params, (p) => {
        if (!p) return p;
        return {
          ...p,
          pages: p.pages.map((page) => ({
            ...page,
            list: page.list.map((t) => {
              if (t.id === transactionId) return { ...t, ...res };
              return t;
            }),
          })),
        };
      });

      if (autoCategories.debit || autoCategories.credit) {
        utils.transactions.transactions
          .refetch()
          .then(() => toast.success("Auto categorization enabled"));
      }
    }

    if (expenseId) {
      await addToExpense.mutateAsync({ expenseId, ...data });
      utils.expenses.expensesByOrgId
        .invalidate({ organizationId: orgId })
        .then(() => null);
    }

    setChanged(false);
    setShowMore(false);
    setAutoCategories({ debit: false, credit: false });
    toast.success("Categories saved");
  };

  const loading =
    addToInvoice.isLoading ||
    addToTransaction.isLoading ||
    addToBill.isLoading ||
    addToExpense.isLoading;

  return (
    <Dropdown
      dropdownClass="w-full z-10"
      buttonClass="btn-neutral justify-between "
      title={
        <div className="flex items-center justify-between flex-1">
          <div className="grid justify-items-center">
            <div className="flex scale-90 items-center gap-2">
              <span
                className="btn scale-75 btn-circle btn-xs "
                style={{ background: category?.color }}
              />

              {category?.name || <MdBlock />}
            </div>
            {subCategory && (
              <div className="flex scale-75 items-center gap-2">
                <span
                  className="btn btn-circle scale-75 btn-xs"
                  style={{ background: subCategory?.color }}
                />

                {subCategory?.name || <MdBlock />}
              </div>
            )}
          </div>
          <LoadingSpin loading={loading} />
        </div>
      }
      content={categories.map((c) => (
        <button
          key={c.id}
          onClick={(e) => e.stopPropagation()}
          onTouchEnd={(e) => e.stopPropagation()}
        >
          <button className="grid">
            <button
              className="text-left flex items-center gap-2"
              onClick={handleCategories(c)}
              onTouchEnd={handleCategories(c)}
            >
              <span
                style={{ backgroundColor: c.color }}
                className="btn btn-circle btn-xs"
              ></span>
              <span>
                <p className="text-sm font-semibold">{c.name}</p>
                <p className="text-xs italic font-normal text-left">
                  {c.group}
                </p>
              </span>
            </button>

            {!!c.subCategories.length && (
              <div className="grid mt-3">
                <span className="label px-3 label-text-alt">
                  Subcategories:
                </span>
                {c.subCategories.map((s) => {
                  return (
                    <button
                      className="flex hover:bg-base-100/50 rounded-md px-3 py-2 items-center gap-2"
                      key={s.id}
                      onClick={handleCategories(c, s)}
                      onTouchEnd={handleCategories(c, s)}
                    >
                      <span
                        style={{ backgroundColor: s.color }}
                        className="btn btn-circle btn-xs"
                      ></span>
                      <p className="text-xs text-left">{s.name}</p>
                      <MdOutlineKeyboardArrowRight />
                    </button>
                  );
                })}
              </div>
            )}
          </button>
        </button>
      ))}
      footer={
        changed && (
          <div className="w-full flex p-0">
            {showMore && !!category && (
              <button
                className="bg-base-200 border border-accent rounded-xl  absolute bottom-full mb-0.5 z-10 p-4 w-56"
                onClick={(e) => e.stopPropagation()}
                onTouchEnd={(e) => e.stopPropagation()}
              >
                <button
                  className="btn-xs btn-outline absolute z-10 top-2 right-2 btn-error btn-circle border grid place-items-center"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowMore(false);
                    setAutoCategories({ debit: false, credit: false });
                  }}
                  onTouchEnd={(e) => {
                    e.stopPropagation();
                    setShowMore(false);
                    setAutoCategories({ debit: false, credit: false });
                  }}
                >
                  <MdClose />
                </button>
                <p className="text-xs text-left mb-3">
                  Enable auto categorization for{" "}
                  <span className="font-bold text-accent">
                    {" "}
                    {category.name}{" "}
                  </span>
                  from this merchant
                </p>

                <div className="flex text-xs gap-4 items-center">
                  <button
                    className={`btn btn-xs text-xs btn-error ${
                      autoCategories.debit ? "" : "btn-outline"
                    }`}
                    onClick={() =>
                      setAutoCategories((p) => ({ ...p, debit: !p.debit }))
                    }
                    onTouchEnd={() =>
                      setAutoCategories((p) => ({ ...p, debit: !p.debit }))
                    }
                  >
                    <BsArrowUpRight /> Debit
                  </button>
                  <button
                    className={`btn btn-success btn-xs text-xs ${
                      autoCategories.credit ? "" : "btn-outline"
                    }`}
                    onClick={() =>
                      setAutoCategories((p) => ({
                        ...p,
                        credit: !p.credit,
                      }))
                    }
                    onTouchEnd={() =>
                      setAutoCategories((p) => ({
                        ...p,
                        credit: !p.credit,
                      }))
                    }
                  >
                    <BsArrowDownLeft /> Credit
                  </button>
                </div>
              </button>
            )}
            <div className="gap-1 flex flex-1 items-center w-48">
              <button
                onClick={handleAdd}
                onTouchEnd={handleAdd}
                className="btn flex-1 btn-sm btn-primary"
              >
                <LoadingSpin loading={loading} />
                Save
              </button>
              {!!transactionId && category && (
                <button
                  className="py-2 btn px-1 h-8 rounded-md btn-xs bg-accent text-accent-content"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowMore((p) => !p);
                  }}
                  onTouchEnd={(e) => {
                    e.stopPropagation();
                    setShowMore((p) => !p);
                  }}
                >
                  <FiMoreVertical />
                </button>
              )}
            </div>
          </div>
        )
      }
    />
  );
};

export default AssignCategories;
