import Switch from "@/components/Switch";
import { useOrganization } from "@/context/OrganizationContext";
import LoadingSpin from "@/ui/LoadingSpin";
import { formatLabel, wait } from "@/utils/helper";
import { api, RouterOutputs } from "@/utils/trpc";
import useForm from "@/utils/useForm";
import { FC } from "react";
import toast from "react-hot-toast";
import SpendingLimitControl from "../components/SpendingLimitControl";
import BindingErrorInfo from "./BindingErrorInfo";

type Props = {
  member: RouterOutputs["swan"]["membership"]["list"][number];
};

const UpdateMembership: FC<Props> = ({ member }) => {
  const { inputs, setValue, handleChange } = useForm(member);
  const { organization } = useOrganization();
  const edit = api.swan.membership.edit.useMutation();
  const send = api.emails.sendSwanMembershipInvite.useMutation();

  const handleAdd = async () => {
    if (!organization?.swanAccountMembership?.userId) {
      return toast.error("Swan account membership user info not found");
    }

    const { team_member_id, accountId, limitAmount, ...rest } = inputs;

    const res = await edit.mutateAsync({
      ...rest,
      swanUserId: organization.swanAccountMembership.userId,
      consentRedirectUrl: `${window.location.origin}/pro-account/members`,
      membershipId: member.id,
      limitAmount: +limitAmount,
    });

    if (res.__typename !== "UpdateAccountMembershipSuccessPayload") {
      return toast.error(
        `${formatLabel(res.__typename)}: ${(res as any).message || ""}`
      );
    }

    await toast.promise(wait(1000), {
      loading: "Authorizing your request ...",
      error: "Failed to redirect to OAuth",
      success: "Redirecting to OAuth to get your consent ...",
    });
    await wait(500);

    window.open(res.consent.consentUrl);
  };

  const handleSend = async () => {
    await send.mutateAsync(member.id);
    toast.success("Invitation sent");
  };

  return (
    <div className="gap-6 grid">
      {member.status === "BindingUserError" && (
        <BindingErrorInfo membershipId={member.id} />
      )}
      <div>
        <span className="divider divider-start">Member</span>
        <div className="flex items-center gap-2">
          <p className="input w-full badge badge-neutral justify-start">
            {member.team_member.user.name!}
          </p>

          {member.status !== "Enabled" && (
            <button onClick={handleSend} className="btn btn-neutral">
              <LoadingSpin loading={send.isLoading} />
              Resend Invite
            </button>
          )}
        </div>
      </div>

      <div>
        <span className="divider divider-start">Permissions</span>
        <Switch
          value={inputs.canViewAccount}
          onChange={(e) => setValue("canViewAccount", e)}
          text="Can View Account"
          className="!w-full justify-between"
        />

        <Switch
          value={inputs.canManageBeneficiaries}
          onChange={(e) => setValue("canManageBeneficiaries", e)}
          text="Can Manage Beneficiaries"
          className="!w-full justify-between"
        />

        <Switch
          value={inputs.canInitiatePayments}
          onChange={(e) => setValue("canInitiatePayments", e)}
          text="Can Initiate Payments"
          className="!w-full justify-between"
        />

        <Switch
          value={inputs.canManageAccountMembership}
          onChange={(e) => setValue("canManageAccountMembership", e)}
          text="Can Manage Account Membership"
          className="!w-full justify-between"
        />

        <Switch
          value={inputs.canManageCards}
          onChange={(e) => setValue("canManageCards", e)}
          text="Can Manage Cards"
          className="!w-full justify-between"
        />
      </div>
      <SpendingLimitControl
        {...{
          amount: +inputs.limitAmount,
          period: inputs.limitPeriod,
          onAmountChange: (value) => setValue("limitAmount", value as any),
          onPeriodChange: (value) => setValue("limitPeriod", value),
        }}
      />

      <button className="btn btn-sm btn-primary" onClick={handleAdd}>
        <LoadingSpin loading={edit.isLoading} />
        Save
      </button>
    </div>
  );
};

export default UpdateMembership;
