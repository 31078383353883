import Rows from "@/ui/skeletons/Rows";
import { api } from "@/utils/trpc";
import { FC } from "react";
import { CiLocationArrow1 } from "react-icons/ci";
import { RiMastercardFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

type Props = {
  membershipId: string;
};

const MembershipCards: FC<Props> = ({ membershipId }) => {
  const { data = [], isLoading } =
    api.swan.membership.cards.useQuery(membershipId);
  const nav = useNavigate();

  if (isLoading) return <Rows count={3} />;

  return (
    <div className="overflow-x-auto" onClick={(e) => e.stopPropagation()}>
      <table className="table bg-base-200 ">
        <thead>
          <tr>
            <th></th>
            <th>Card name</th>
            <th>Card number</th>
            <th>Expiry</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.map((card) => (
            <tr
              key={card.id}
              className="cursor-pointer border-white/10"
              onClick={() => nav(`/pro-account/cards/${card.id}`)}
            >
              <th className="flex items-center justify-between gap-2">
                <RiMastercardFill size={30} />
                <p className="btn btn-xs btn-outline btn-info">{card.type}</p>
              </th>
              <td>{card.name}</td>
              <td>{card.cardMaskedNumber}</td>
              <td>{card.expiryDate}</td>
              <td>
                <span className="btn btn-success btn-outline btn-sm text-xs">
                  {card.status}
                </span>
              </td>
              <th>
                <CiLocationArrow1 />
              </th>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MembershipCards;
