import Spinner from "@/components/utils/spinner";
import { useOrganization } from "@/context/OrganizationContext";
import { useUser } from "@/context/UserContext";
import { api } from "@/utils/trpc";
import { useEffect, useRef } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";

type Props = {};

const Invitation = (props: Props) => {
  const { inviteId } = useParams();
  const { user } = useUser();
  const { refetch } = useOrganization();
  const nav = useNavigate();

  const mounted = useRef(false);

  const accept = api.organizations.team_member.invite.accept.useMutation();

  useEffect(() => {
    if (!inviteId) return;

    if (mounted.current) return;
    mounted.current = true;

    localStorage.setItem("@inviteId", inviteId);

    if (!user) {
      toast.success("Please sign up to accept the invitation");
      return nav("/register/sign-up");
    }

    accept
      .mutateAsync({ inviteId, userId: user.id, email: user.email })
      .then(() => {
        toast.success("Invitation accepted");
        window.location.replace("/");
        localStorage.removeItem("@inviteId");
      });
  }, [inviteId]);

  return (
    <div className="m-10 w-full h-full">
      <div className="alert">
        <Spinner />
        <p>Accepting Invitation</p>
      </div>
    </div>
  );
};

export default Invitation;
