import Switch from "@/components/Switch";
import { useOrganization } from "@/context/OrganizationContext";
import ReportsTable from "@/ui/skeletons/ReportsTable";
import { formatCurrency } from "@/utils/helper";
import { api } from "@/utils/trpc";
import endOfMonth from "date-fns/endOfMonth";
import format from "date-fns/format";
import startOfMonth from "date-fns/startOfMonth";
import { FC, useEffect, useMemo, useState } from "react";

type Props = {
  year: number;
  month: number;
};

const NonPayrollSpending: FC<Props> = ({ month, year }) => {
  const { organizationId = "" } = useOrganization();

  const period = useMemo(() => {
    const startDate = startOfMonth(new Date(year, month));
    const endDate = endOfMonth(new Date(year, month));

    return { endDate, startDate };
  }, [year, month]);

  console.log(period);

  const { data, isLoading } = api.reports.nonPayrollSpending.useQuery({
    organizationId,
    ...period,
  });

  const [purchaseType, setPurchaseType] = useState<"recurring" | "regular">(
    "regular"
  );

  const [showAll, setShowAll] = useState({
    merchants: false,
    categories: false,
  });

  useEffect(() => {
    if (!data) return;

    const _showAll = { merchants: false, categories: false };

    if (data.topMerchants.length <= 5) _showAll.merchants = true;
    if (data.topCategories.length <= 5) _showAll.categories = true;
    setShowAll(_showAll);
  }, [data]);

  if (isLoading)
    return (
      <div className="flex flex-col gap-6">
        <div className="flex xl:flex-row flex-col  gap-6">
          <ReportsTable />
          <ReportsTable />
        </div>
        <ReportsTable />
      </div>
    );

  if (!data) return null;

  const categories = showAll.categories
    ? data.topCategories
    : data.topCategories.slice(0, 5);

  const merchants = showAll.merchants
    ? data.topMerchants
    : data.topMerchants.slice(0, 5);

  const otherMerchantsTotal = data.topMerchants.slice(5).reduce((acc, curr) => {
    return acc + curr.total;
  }, 0);

  const otherCategoriesTotal = data.topCategories
    .slice(5)
    .reduce((acc, curr) => {
      return acc + curr.total;
    }, 0);

  return (
    <div className="grid gap-6">
      <div className="flex xl:flex-row flex-col justify-between gap-6">
        <div className="bg-base-100/80 max-h-96 overflow-auto h-fit p-4 flex-1 rounded-lg border border-neutral">
          <p className="label">Top Categories</p>
          <table className="table text-xs text-base-content/70">
            <tbody>
              {categories.map((c) => (
                <tr className="border-neutral borde" key={c.id}>
                  <td>{c.name}</td>
                  <td>{formatCurrency(c.total, data.currency)}</td>
                </tr>
              ))}

              {!showAll.categories && (
                <tr>
                  <td>Other categories</td>
                  <td>{formatCurrency(otherCategoriesTotal, data.currency)}</td>
                </tr>
              )}
            </tbody>
          </table>

          {!showAll.categories && (
            <button
              className="btn btn-link w-full "
              onClick={() => setShowAll((p) => ({ ...p, categories: true }))}
            >
              Show all
            </button>
          )}
        </div>
        <div className="bg-base-100/80 max-h-screen overflow-auto h-fit flex-1 p-4 rounded-lg border border-neutral">
          <p className="label">Top Merchants</p>
          <table className="table text-xs text-base-content/70">
            <tbody>
              {merchants.map((v) => (
                <tr className="border-neutral" key={v.id}>
                  <td>{v.name}</td>
                  <td>{formatCurrency(v.total, data.currency)}</td>
                </tr>
              ))}
              {!showAll.merchants && (
                <tr>
                  <td>Other Merchants</td>
                  <td>{formatCurrency(otherMerchantsTotal, data.currency)}</td>
                </tr>
              )}
            </tbody>
          </table>
          {!showAll.merchants && (
            <button
              className="btn btn-link w-full "
              onClick={() => setShowAll((p) => ({ ...p, merchants: true }))}
            >
              Show all
            </button>
          )}
        </div>
      </div>

      <div className="overflow-x-auto">
        <div className="bg-base-100/80 p-4 flex-1 rounded-lg border border-neutral">
          <p className="label">Top purchases</p>
          <Switch
            text="Recurring only"
            onChange={(e) => setPurchaseType(e ? "recurring" : "regular")}
            value={purchaseType === "recurring"}
          />
          <table className="table text-xs text-base-content/70">
            <thead>
              <tr>
                <th>Date</th>
                <th>Merchant</th>
                <th>Description</th>
                <th>Category</th>
                {purchaseType === "recurring" && <th>Recurring</th>}
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {data.topPurchases[purchaseType].map((c) => (
                <tr className="border-neutral" key={c.id}>
                  <td>{format(c.date, "MMM dd, yyyy")}</td>
                  <td>{c.merchant?.name || "N/A"}</td>
                  <td>
                    <div data-tip={c.description} className="tooltip">
                      <p className="max-h-10 max-w-xs text-left tooltip overflow-auto">
                        {c.description}
                      </p>
                    </div>
                  </td>
                  <td>{c.category?.name || "N/A"}</td>
                  {purchaseType === "recurring" && <td>{c.recurringType}</td>}
                  <td>{formatCurrency(c.amount, data.currency)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default NonPayrollSpending;
