import Dropdown from "@/components/Dropdown";
import useCategories from "@/hooks/useCategories";
import { FC } from "react";
import { MdBlock, MdOutlineKeyboardArrowRight } from "react-icons/md";
import LoadingSpin from "./LoadingSpin";

export type CategoryData = { id: string; name: string; color: string };

export type Categories = {
  category?: CategoryData | null;
  subCategory?: CategoryData | null;
};

type Props = {
  setCategories: (categories: Categories) => void;
  categories: Categories;
  isLoading: boolean;
};

const SelectCategories: FC<Props> = ({
  setCategories,
  categories,
  isLoading,
}) => {
  const { category, subCategory } = categories;

  const [list] = useCategories();

  const handleCategories =
    (category: CategoryData | null, subCategory?: CategoryData | null) =>
    (e: any) => {
      setCategories({
        category:
          category?.id === categories.category?.id && !subCategory
            ? null
            : category,
        subCategory:
          subCategory?.id === categories.subCategory?.id ? null : subCategory,
      });
    };

  return (
    <Dropdown
      dropdownClass="w-full min-w-[300px] z-10"
      title={
        <div className="flex items-center justify-between flex-1">
          <LoadingSpin loading={isLoading} />

          <div className="grid justify-items-center">
            <div className="flex scale-90 items-center gap-2">
              <span
                className="btn scale-75 btn-circle btn-xs "
                style={{ background: category?.color }}
              />

              {category?.name || <MdBlock />}
            </div>
            {subCategory && (
              <div className="flex scale-75 items-center gap-2">
                <span
                  className="btn btn-circle scale-75 btn-xs"
                  style={{ background: subCategory?.color }}
                />

                {subCategory?.name || <MdBlock />}
              </div>
            )}
          </div>
        </div>
      }
      content={list.map((c) => (
        <div className="flex flex-col items-start w-full" key={c.id}>
          <button
            className="text-left w-full flex items-center gap-2"
            onClick={handleCategories(c)}
            onTouchEnd={handleCategories(c)}
          >
            <span
              style={{ backgroundColor: c.color }}
              className="btn btn-circle btn-xs"
            ></span>
            <span>
              <p className="text-sm font-semibold">{c.name}</p>
              <p className="text-xs italic font-normal text-left">{c.group}</p>
            </span>
          </button>

          {!!c.subCategories.length && (
            <div className="grid mt-3">
              <span className="px-3 text-xs">Subcategories:</span>
              {c.subCategories.map((s) => {
                return (
                  <button
                    className="flex hover:bg-base-100/50 rounded-md px-3 py-2 items-center gap-2"
                    key={s.id}
                    onClick={handleCategories(c, s)}
                    onTouchEnd={handleCategories(c, s)}
                  >
                    <span
                      style={{ backgroundColor: s.color }}
                      className="btn btn-circle btn-xs"
                    ></span>
                    <p className="text-xs text-left">{s.name}</p>
                    <MdOutlineKeyboardArrowRight />
                  </button>
                );
              })}
            </div>
          )}
        </div>
      ))}
    />
  );
};

export default SelectCategories;
