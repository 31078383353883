import SelectAccountingStatus from "@/components/SelectAccountingStatus";
import { AccountingStatusType } from "@/lib";
import { RouterInputs, api } from "@/utils/trpc";
import { FC } from "react";

type Props = {
  status: AccountingStatusType;
  billId: string;
  params: RouterInputs["bills"]["bills"];
};

const AccountingStatus: FC<Props> = ({ status, billId, params }) => {
  const update = api.bills.updateAccountingStatus.useMutation();
  const utils = api.useUtils();

  const handleUpdate = (status: AccountingStatusType) => async () => {
    const res = await update.mutateAsync({ billId, status });

    utils.bills.bills.setData(params, (p) => {
      if (!p) return p;
      return p.map((b) => {
        if (b.id === billId) return { ...b, ...res };
        return b;
      });
    });
  };

  return (
    <SelectAccountingStatus
      {...{ status, setStatus: handleUpdate, isLoading: update.isLoading }}
    />
  );
};

export default AccountingStatus;
