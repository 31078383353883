import { useOrganization } from "@/context/OrganizationContext";
import { PageTitle } from "@/ui/PageTitle";
import Rows from "@/ui/skeletons/Rows";
import { RouterOutputs, api } from "@/utils/trpc";
import format from "date-fns/format";
import { useMemo, useState } from "react";
import { FaFolder } from "react-icons/fa";
import { HiPlus } from "react-icons/hi";
import { Link } from "react-router-dom";
import CreateFiles from "./CreateFiles";
import SingleFile from "./SingleFile";

export type Folder = RouterOutputs["organizations"]["folders"][number];
export type File = RouterOutputs["organizations"]["files"]["list"][number];

const Files = () => {
  const { organizationId = "" } = useOrganization();

  const [_folders, setFolders] = useState<Folder[]>([]);

  const folderId = useMemo(() => {
    const current = _folders.at(-1);

    return current ? current.id : null;
  }, [_folders]);

  const { data = { pages: [] }, isLoading: fiLoading } =
    api.organizations.files.useInfiniteQuery({
      organizationId,
      folderId,
    });

  const { data: folders = [], isLoading: foLoading } =
    api.organizations.folders.useQuery({
      organizationId,
      parentId: folderId,
    });

  const files = useMemo(() => data.pages.flatMap((p) => p.list), [data]);

  return (
    <div>
      <PageTitle title="My Documents" />

      <div className="breadcrumbs text-sm flex justify-between">
        <ul>
          <li onClick={() => setFolders([])}>
            <a>All</a>
          </li>
          {_folders.map((f, i) => (
            <li
              key={f.id}
              onClick={() => {
                setFolders((p) => p.slice(0, i + 1));
              }}
            >
              <a>{f.name}</a>
            </li>
          ))}
        </ul>

        <Link
          to="?modal=createDocument"
          className="btn my-4 ml-auto btn-sm btn-primary"
        >
          <HiPlus />
        </Link>
      </div>

      <table className="table">
        <thead>
          <tr className="text-base-content bg-neutral">
            <th> Name</th>
            <th> Type</th>
            <th> Date</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {foLoading && (
            <tr className="border-none">
              <td colSpan={3} className="w-full !p-0">
                <Rows count={3} />
              </td>
            </tr>
          )}
          {folders.map((f) => (
            <tr
              key={f.id}
              className="bg-base-100 link link-hover "
              onClick={() => setFolders((p) => [...p, f])}
            >
              <td>
                <div className=" flex gap-2 items-center">
                  <FaFolder />
                  {f.name}
                </div>
              </td>
              <td className="capitalize">folder</td>
              <td>{format(f.createdAt, "dd MMM yyy")}</td>
            </tr>
          ))}
          {fiLoading && (
            <tr>
              <td colSpan={3} className="w-full !p-0">
                <Rows count={5} />
              </td>
            </tr>
          )}
          {files.map((file) => (
            <SingleFile {...{ file, folderId }} />
          ))}
        </tbody>
      </table>
      <CreateFiles {...{ folderId }} />
    </div>
  );
};

export default Files;
