import formatDistance from "date-fns/formatDistance";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { TaxCodeType } from "./TaxCodeList";
import UpdateTaxCode from "./UpdateTaxCode";

type Props = {
  taxCode: TaxCodeType;
};

const TaxCode: FC<Props> = ({ taxCode }) => {
  const nav = useNavigate();
  return (
    <tr
      key={taxCode.id}
      className="border-white/5 cursor-pointer"
      onClick={() => nav(`/organization/tax-code/${taxCode.id}`)}
    >
      <td>{taxCode.name}</td>
      <td>{taxCode.rate}</td>
      <td>{taxCode.code}</td>
      <td>
        {formatDistance(taxCode.updatedAt, new Date(), {
          addSuffix: true,
        })}
      </td>
      <UpdateTaxCode taxCode={taxCode} />
    </tr>
  );
};

export default TaxCode;
