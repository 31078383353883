import PhoneInput from "@/components/PhoneInput";
import Spinner from "@/components/utils/spinner";
import { countries } from "@/lib/countries";
import { CreateOrganization } from "@/types/validation";
import LoadingSpin from "@/ui/LoadingSpin";
import { setTitle } from "@/utils/helper";
import { api } from "@/utils/trpc";
import useForm from "@/utils/useForm";
import { SearchSelect, SearchSelectItem } from "@tremor/react";
import { FC, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { GrFormNext } from "react-icons/gr";
import { ImBlocked } from "react-icons/im";
import "react-phone-input-2/lib/style.css";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "../../context/UserContext";
import CommonLayout from "./CommonLayout";

const fields: {
  name: keyof CreateOrganization;
  placeholder: string;
  number?: boolean;
  numberString?: boolean | "phone" | "alphanumeric";
  title: string;
}[] = [
  {
    name: "name",
    placeholder: "Enter business name",
    title: "Business Name",
  },
  {
    name: "business_tax_number",
    placeholder: "Enter business tax number",
    numberString: "alphanumeric",
    title: "Business Tax Number",
  },
  {
    name: "email",
    placeholder: "Enter business email",
    title: "Email address",
  },
];

const CompanyDetails: FC = () => {
  useEffect(() => setTitle("Company Details"), []);
  const { handleChange, inputs, errors, setErrors, setValue } = useForm<
    Partial<CreateOrganization>
  >({});

  const { user, session } = useUser();
  const nav = useNavigate();
  const utils = api.useUtils();
  const [loading, setLoading] = useState(false);

  const create = api.organizations.create.useMutation();

  useEffect(() => {
    if (!session) return;
    (async () => {
      setLoading(true);
      await utils.users.userById.refetch(session.user.id, { type: "all" });
      setLoading(false);
    })();
  }, [session]);

  if (loading) {
    return (
      <CommonLayout step={3}>
        <Spinner />
      </CommonLayout>
    );
  }

  if (!user) {
    return (
      <CommonLayout step={3}>
        <p>
          You must{" "}
          <Link
            to={"/register/personal-details"}
            className="text-primary font-bold border-b pb-0.5 border-primary"
          >
            create a profile
          </Link>{" "}
          to continue
        </p>
        <ImBlocked color="red" size={40} />
      </CommonLayout>
    );
  }

  const handleUpdate = async () => {
    const validate = CreateOrganization.safeParse(inputs);

    if (!validate.success) {
      setErrors(validate.error.formErrors.fieldErrors);

      return toast.error("Please fill all required fields");
    }

    await create.mutateAsync({ ...validate.data, userId: user.id });

    nav("/register/invite");
  };

  return (
    <CommonLayout step={3}>
      <div className="">
        <h3 className="sm:text-2xl text-2xl text-center font-bold ">
          Your Organization
        </h3>
        <p className="sm:text-xs text-sm text-center">About your company</p>
      </div>
      <div className="w-full">
        {fields.map(({ name, placeholder, title, number, numberString }) => (
          <div key={name}>
            <label className="label">
              <span className="label-text">{title}</span>
            </label>
            <input
              type="text"
              placeholder={placeholder}
              className={`input w-full shadow-sm  ${
                errors[name] ? "input-error" : "input-bordered"
              }`}
              value={inputs[name]?.toString() || ""}
              onChange={handleChange(name)}
              data-number={number}
              data-number-string={numberString}
            />
            <p className="err">{errors[name]}</p>
          </div>
        ))}
        <div className="w-full">
          <label className="label">
            <span className="label-text">Phone</span>
          </label>
          <PhoneInput
            onChange={(e) => setValue("phone", e)}
            value={inputs.phone}
            isError={errors.phone}
          />
        </div>
        <div className="">
          <label className="label">
            <span className="label-text">Default Currency</span>
          </label>
          <SearchSelect
            enableClear={false}
            onValueChange={(e) => setValue("defaultCurrency", e)}
            value={inputs.defaultCurrency}
            className={`${
              errors.defaultCurrency ? "border-danger rounded-lg border" : ``
            }`}
          >
            {["EUR", "GBP"].map((c) => (
              <SearchSelectItem key={c} value={c}>
                {c}
              </SearchSelectItem>
            ))}
          </SearchSelect>
          <p className="err">{errors.defaultCurrency}</p>
        </div>
        <div>
          <label className="label">
            <span className="label-text">Country</span>
          </label>
          <SearchSelect
            enableClear={false}
            onValueChange={(e) => setValue("countryId", +e)}
            value={inputs.countryId?.toString()}
            className={`${
              errors.countryId ? "border-danger rounded-lg border" : ``
            }`}
          >
            {countries.map((c) => (
              <SearchSelectItem key={c.id} value={c.id.toString()}>
                {c.name}
              </SearchSelectItem>
            ))}
          </SearchSelect>
          <p className="err">{errors.countryId}</p>
        </div>
      </div>
      <button
        onClick={handleUpdate}
        disabled={create.isLoading}
        className="btn  btn-primary w-full"
      >
        <LoadingSpin loading={create.isLoading} />
        Next
        <GrFormNext className="text-white" color="white" size={30} />
      </button>
    </CommonLayout>
  );
};

export default CompanyDetails;
