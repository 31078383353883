import { FC } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const pages = [-2, -1, 0, 1, 2];

type Props = {
  setCurrentPage: (page: number) => void;
  currentPage: number;
  total: number;
};

const Pagination: FC<Props> = ({ setCurrentPage, total, currentPage }) => {
  const totalPages = Math.ceil(total / 10);
  if (totalPages === 1 || !total) return null;
  return (
    <div className="flex justify-center gap-2 mt-5">
      {currentPage > 1 && (
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          className="btn btn-ghost"
        >
          <IoIosArrowBack />
        </button>
      )}
      {pages.map((p) => {
        const page = p + currentPage;
        if (page < 1 || page > totalPages) return null;
        return (
          <button
            onClick={() => setCurrentPage(page)}
            className={`btn ${
              currentPage === page ? "btn-primary" : "btn-ghost"
            } `}
          >
            {page}
          </button>
        );
      })}
      {currentPage < totalPages && (
        <button
          onClick={() => setCurrentPage(currentPage + 1)}
          className="btn btn-ghost"
        >
          <IoIosArrowForward />
        </button>
      )}
    </div>
  );
};

export default Pagination;
