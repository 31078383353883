import { FC } from "react";
import { TbAlertTriangleFilled } from "react-icons/tb";

interface NotFoundProps {
  title: string;
}

const NotFound: FC<NotFoundProps> = ({ title }) => {
  return (
    <span className=" w-full h-full gap-2 grid place-items-center place-content-center">
      <TbAlertTriangleFilled size={45} className="text-warning" />
      <p className="text-neutral-content text-sm">No {title} found</p>
    </span>
  );
};

export default NotFound;
