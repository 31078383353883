import Drawer from "@/components/drawer/Drawer";
import Tabs from "@/components/Tabs";
import { RouterOutputs } from "@/utils/trpc";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MembershipCards from "./MembershipCards";
import UpdateMembership from "./UpdateMembership";

type Props = {
  membership: RouterOutputs["swan"]["membership"]["list"][number];
};

const tabs = ["details", "cards"] as const;
type Tab = (typeof tabs)[number];

const MembershipDetails: FC<Props> = ({ membership }) => {
  const { id, subTab = "details" } = useParams<{ subTab?: Tab; id?: string }>();

  const nav = useNavigate();

  const content: Record<Tab, JSX.Element> = {
    details: <UpdateMembership member={membership} />,
    cards: <MembershipCards membershipId={membership.id} />,
  };

  return (
    <Drawer
      isOpen={id === membership.id}
      onClose={() => nav("/pro-account/members")}
      title="Membership"
      drawerClass="lg:w-[80vw] xl:w-[60vw]"
    >
      <Tabs
        className="w-full mb-6 z-20"
        tabs={tabs}
        paths={[`/${membership.id}`, `/${membership.id}/cards`]}
        parentRoute="/pro-account/members"
        tab={subTab}
        type="bordered"
      />

      {content[subTab]}
    </Drawer>
  );
};

export default MembershipDetails;
