import FormInput from "@/components/FormInput";
import Tabs from "@/components/Tabs";
import Collapsible from "@/ui/Collapsible";
import LoadingSpin from "@/ui/LoadingSpin";
import { api } from "@/utils/trpc";
import useForm from "@/utils/useForm";
import { FC } from "react";
import toast from "react-hot-toast";
import { AiTwotoneBank } from "react-icons/ai";
import { MdClose } from "react-icons/md";
import { ExternalAccount as Validation } from "../validation";
import { currencies, ExternalAccount, fields } from "./Accounts";

type Props = {
  account: ExternalAccount;
  params: { vendorId?: string; customerId?: string };
};

const AccountRow: FC<Props> = ({ account, params }) => {
  const { handleChange, inputs, setValue, errors, setErrors, setInputs } =
    useForm(account);

  const update = api.organizations.externalAccount.update.useMutation();
  const utils = api.useUtils();
  const handleAdd = (toggle: Function) => async () => {
    const valid = Validation.safeParse(inputs);

    if (!valid.success) {
      setErrors(valid.error.formErrors.fieldErrors);

      return toast.error("Please check the details and try again");
    }

    const res = await update.mutateAsync({
      ...valid.data,
      id: account.id,
    });

    utils.organizations.externalAccount.list.setData(params, (prev = []) => [
      res,
      ...prev.filter((p) => p.id !== account.id),
    ]);

    toggle();
  };

  return (
    <Collapsible
      label={(toggle, show) =>
        show ? (
          <div className="title">
            <p>Update</p>
            <button
              className="btn btn-xs btn-primary"
              onClick={() => toggle(false)}
            >
              <MdClose />
            </button>
          </div>
        ) : (
          <button className="btn h-fit  w-full justify-between">
            <AiTwotoneBank />
            <div className="flex-1 w-1/3 break-words text-left">
              <p className="lg:text-sm text-xs font-semibold">{account.name}</p>
              <p className="text-xs">
                {account.IBAN ||
                  account.ACCOUNT_NUMBER ||
                  account.ROUTING_NUMBER}
              </p>
            </div>
          </button>
        )
      }
      className="!bg-base-100 !p-0"
      hideArrow
      render={(toggle) => (
        <>
          <Tabs
            tab={inputs.currency!}
            setTab={(c) => setValue("currency", c)}
            tabs={currencies}
            labels={currencies}
          />
          <div>
            {fields.map((f, i) => {
              if (!f.currencies.includes(inputs.currency!)) return null;
              return (
                <FormInput
                  title={f.title}
                  placeholder={f.placeholder}
                  key={f.name}
                  className="last:col-span-2"
                  onChange={handleChange(f.name)}
                  isError={errors[f.name]}
                  value={inputs[f.name]}
                />
              );
            })}
          </div>

          <button
            className="btn mt-4 btn-primary btn-xs w-full"
            onClick={handleAdd(toggle)}
          >
            <LoadingSpin loading={update.isLoading} />
            Save
          </button>
        </>
      )}
    />
  );
};

export default AccountRow;
