import Drawer from "@/components/drawer/Drawer";
import { formatLabel } from "@/utils/helper";
import { RouterOutputs } from "@/utils/trpc";
import { format } from "date-fns/esm";
import { FC, useMemo, useState } from "react";
import { LuFileJson2 } from "react-icons/lu";
import { MdCircle } from "react-icons/md";
import { JsonView, allExpanded, defaultStyles } from "react-json-view-lite";

const tabs = ["payee", "payer"] as const;
type Props = {
  data: RouterOutputs["invoices"]["invoiceById"]["payments"][0];
};

type Status = {
  name: string;
  date: Date;
  id: string;
  rawJSON?: any;
};

const PaymentDetails: FC<Props> = ({ data }) => {
  const [party, setParty] = useState<"payee" | "payer">("payee");

  const [showRaw, setShowRaw] = useState<any>(false);

  const status = useMemo(() => {
    if (!data) return [];
    const values: Status[] = [];

    data.isoStatuses.forEach((status) => {
      values.push({
        name: formatLabel(status.name),
        date: status.statusUpdateDate,
        id: status.id,
        rawJSON: status.rawJSON,
      });
    });

    data.statuses.forEach((status) => {
      values.push({
        name: status.status.toLowerCase(),
        date: status.statusUpdateDate,
        id: status.id,
        rawJSON: status.rawJSON,
      });
    });

    return values.sort((a, b) => a.date.getTime() - b.date.getTime());
  }, [data]);

  if (!data) {
    return (
      <div className="shadow-sm flex-1 bg-base-100 p-4 rounded-md grid gap-6">
        <p className="font-bold text-lg">Payment Details:</p>
        <div className="alert">Payment details could not be found</div>
      </div>
    );
  }

  return (
    <div className="shadow-sm flex-1 bg-base-100 p-4 rounded-md grid gap-6">
      <Drawer
        content={
          <JsonView
            data={showRaw || {}}
            shouldExpandNode={allExpanded}
            style={{
              ...defaultStyles,
              container: "break-words rounded-xl text-xs w-full bg-white",
            }}
          />
        }
        onClose={setShowRaw}
        title="Raw JSON"
        isOpen={!!showRaw}
      />
      <div className="flex items-center justify-between">
        <p className="font-bold text-lg">Payment Details:</p>
        {!!data.rawJSON && (
          <button
            onClick={() => setShowRaw(data.rawJSON)}
            className="btn btn-neutral"
          >
            <LuFileJson2 />
          </button>
        )}
      </div>

      <div className="grid gap-2">
        {status.map((status) => (
          <div
            key={status.id}
            className="btn h-fit status-item overflow-hidden justify-between"
          >
            <div className="flex flex-1 items-center gap-2">
              <MdCircle className="text-primary" />
              <p className="capitalize text-left text-xs lg:text-sm font-medium">
                {status.name}
              </p>
            </div>
            <p className="lg:text-sm text-xs text-right">
              {format(status.date, "dd MMM yyyy ' at 'hh:mm aaa")}
            </p>
            {!!status.rawJSON && (
              <div className="max-w-0 overflow-hidden transition-all  status-item_json ">
                <button
                  onClick={() => setShowRaw(status.rawJSON)}
                  className="btn btn-neutral btn-sm"
                >
                  <LuFileJson2 />
                </button>
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="flex gap-2">
        {tabs.map((t) => (
          <button
            onClick={() => setParty(t)}
            key={t}
            className={`btn btn-sm capitalize flex-1 ${
              t === party ? "btn-primary" : ""
            }`}
          >
            {t}
          </button>
        ))}
      </div>

      <div className="grid gap-1">
        <div className="btn justify-between btn-sm">
          <p className="font-medium">Name:</p>
          <p>
            {data[party]?.name || (
              <span className="text-sm">not available</span>
            )}{" "}
          </p>
        </div>

        <div className="btn justify-between btn-sm">
          <p className="font-medium">IBAN:</p>
          <p>
            {data[party]?.IBAN || (
              <span className="text-sm">not available</span>
            )}{" "}
          </p>
        </div>
        <div className="btn justify-between btn-sm">
          <p className="font-medium">BIC/SWIFT code:</p>
          <p>
            {data[party]?.SWIFT || (
              <span className="text-sm">not available</span>
            )}{" "}
          </p>
        </div>
        <div className="btn justify-between btn-sm">
          <p className="font-medium">Account Number:</p>
          <p>
            {data[party]?.ACCOUNT_NUMBER || (
              <span className="text-sm">not available</span>
            )}{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PaymentDetails;
