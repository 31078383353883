import Drawer from "@/components/drawer/Drawer";
import ViewJSON from "@/components/ViewJSON";
import { useOrganization } from "@/context/OrganizationContext";
import Categorization from "@/pages/accounts/components/Categorization";
import LoadingSpin from "@/ui/LoadingSpin";
import { formatCurrency } from "@/utils/helper";
import { api } from "@/utils/trpc";
import csvToJson from "csvtojson";
import { format } from "date-fns/esm";
import { useState } from "react";
import toast from "react-hot-toast";
import { MdDelete } from "react-icons/md";
import { VscJson } from "react-icons/vsc";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import MerchantCoverage from "./components/MerchantCompletion";

type Props = {};

const AccountPage = (props: Props) => {
  const { id } = useParams<{ id: string }>();
  const { organizationId } = useOrganization<true>();

  const { data } = api.account.get.useQuery(id!);
  const nav = useNavigate();
  const mapFields = api.transaction.mapFields.useMutation();
  const createManual = api.account.manualEntry.create.useMutation();
  const deleteManual = api.account.manualEntry.delete.useMutation();
  const [mappings, setMappings] = useState<Record<string, string>>({});
  const [transactions, setTransactions] = useState<any[]>([]);
  const [params] = useSearchParams();
  const json = params.get("json");
  const [fileName, setFileName] = useState("");

  const utils = api.useUtils();
  const { data: manuals = [] } = api.account.manualEntry.list.useQuery(id!);

  if (!data) return;

  const handleMap = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const [file] = e.target.files;
    setFileName(file.name);

    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = async function () {
      const csv = reader.result as string;
      const json = await csvToJson().fromString(csv);
      const res = await mapFields.mutateAsync(json);
      setMappings(res);
      setTransactions(json);
    };
  };

  const handleUpload = async () => {
    const { failed, success, entry } = await createManual.mutateAsync({
      mappings,
      organizationId,
      accountId: data.id,
      transactions,
      fileName,
    });
    if (!failed) return toast.success("Transactions added successfully");
    if (!success) return toast.error("Failed to add transactions");
    toast(`${failed} transactions failed to add`);
    setTransactions([]);
    setMappings({});
    setFileName("");

    utils.account.manualEntry.list.setData(id!, (p = []) => [...p, entry]);
  };

  const handleDelete = async (entryId: string) => {
    await deleteManual.mutateAsync(entryId);

    utils.account.manualEntry.list.setData(id!, (p = []) =>
      p.filter((m) => m.id !== entryId)
    );
  };

  return (
    <div className="grid gap-10">
      <div className="card lg:card-side bg-base-100 shadow-xl">
        <div className="card-body gap-6">
          <div className=" flex gap-2 items-center">
            <h3>@{data.bankName}</h3>
            <figure className="w-10">
              <img src={data.bankLogo!} alt="country" />
            </figure>
          </div>
          <div className="flex items-center gap-2 w-fit">
            <p className="font-normal">Source</p>
            <p className="badge badge-neutral">{data.source}</p>
          </div>
          <div className="flex items-center gap-4">
            <button
              onClick={() => nav("?json=true")}
              className="btn w-fit btn-sm btn-primary"
            >
              <VscJson /> View JSON
            </button>
            <input
              type="file"
              className="file-input file-input-sm"
              onChange={handleMap}
              name=""
              id=""
            />
            <LoadingSpin loading={mapFields.isLoading} />
          </div>
          {!!transactions.length && (
            <>
              <div className="flex items-center gap-2 w-full ">
                <div className="title flex-1">Confirm Mapping</div>
                <p className="">Total {transactions.length} </p>
              </div>
              <div className="grid gap-2 w-96">
                {Object.entries(mappings).map(([key, value]) => {
                  if (key === "dateFormat") return null;
                  return (
                    <div
                      key={key}
                      className="flex justify-between gap-4 w-full items-center"
                    >
                      <div>{key}</div>
                      <div>
                        <select
                          className="select select-bordered select-primary select-sm"
                          value={value}
                          onChange={(e) =>
                            setMappings((prev) => ({
                              ...prev,
                              [key]: e.target.value,
                            }))
                          }
                        >
                          {Object.keys(transactions[0]).map((field) => (
                            <option key={field} value={field}>
                              {field}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  );
                })}
                <button
                  className="btn btn-primary w-fit ml-auto btn-sm"
                  onClick={handleUpload}
                >
                  <LoadingSpin loading={createManual.isLoading} />
                  Apply
                </button>
              </div>
            </>
          )}

          {manuals.length > 0 && (
            <div className="grid gap-2 w-fit">
              <p className="title">Manual Entries</p>
              {manuals.map((manual) => (
                <div
                  key={manual.id}
                  className="flex justify-between gap-4 w-full items-center"
                >
                  <p>{manual.fileName}</p>
                  <p className="text-xs">
                    ({manual._count.transactions}) transactions
                  </p>
                  <p className="text-xs">
                    {format(manual.dateTime, "dd-MMM-yyyy")}
                  </p>
                  <button
                    onClick={() => handleDelete(manual.id)}
                    className="btn btn-sm btn-error"
                  >
                    <MdDelete />
                    <LoadingSpin loading={deleteManual.isLoading} />
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="card-body">
          <div className="flex items-center gap-2 w-fit">
            <p className="font-normal">Total Balance:</p>
            <p className="badge badge-neutral">
              {formatCurrency(data.balance, data.currency)}
            </p>
          </div>
          {data.IBAN && (
            <div className="flex items-center gap-2 w-fit">
              <p className="font-normal">IBAN</p>
              <p className="badge badge-neutral">{data.IBAN}</p>
            </div>
          )}
          {data.BBAN && (
            <div className="flex items-center gap-2 w-fit">
              <p className="font-normal">BBAN:</p>
              <p className="badge badge-neutral">{data.BBAN}</p>
            </div>
          )}
          <div className="flex items-center gap-2 w-fit">
            <p className="font-normal">SWIFT:</p>
            <p className="badge badge-neutral">{data.SWIFT}</p>
          </div>
        </div>
      </div>

      <div className="flex gap-10 items-center">
        <Categorization data={data.metrics} />
        <MerchantCoverage data={data.metrics} />
      </div>

      <Drawer
        isOpen={!!json}
        onClose={() => nav(`/account/${data.id}`)}
        title="Account "
      >
        <ViewJSON json={data.json} defaultOpen />
      </Drawer>
    </div>
  );
};

export default AccountPage;
