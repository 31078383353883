import Dropdown from "@/components/Dropdown";
import { useOrganization } from "@/context/OrganizationContext";
import { BaseType } from "@/types";
import LoadingSpin from "@/ui/LoadingSpin";
import { api } from "@/utils/trpc";
import { FC, useState } from "react";

type Props = {
  className?: string;
  loading?: boolean;
  financialAccount?: BaseType<{ code: string }> | null;
  showLabel?: boolean;
  handleAdd: (financialAccountId: string) => Promise<void>;
  handleRemove: () => Promise<void>;
};

const SelectFinancialAccount: FC<Props> = ({
  className,
  financialAccount,
  loading,
  handleAdd,
  handleRemove,
  showLabel,
}) => {
  const [enabled, setEnabled] = useState(false);
  const { organizationId } = useOrganization<true>();
  const { data = [], isLoading } =
    api.organizations.financialAccount.list.useQuery(organizationId, {
      enabled,
    });

  return (
    <div className={className}>
      {showLabel && (
        <label className="text-sm divider divider-start">GL Code</label>
      )}
      <Dropdown
        onClick={(e) => {
          e.stopPropagation();
          setEnabled(true);
        }}
        dropdownClass="w-full min-w-max z-10 "
        buttonClass="bg-base-300"
        title={
          loading ? (
            <LoadingSpin loading />
          ) : financialAccount ? (
            <span>
              {financialAccount.name} ({financialAccount.code})
            </span>
          ) : (
            "N/A"
          )
        }
        footer={
          financialAccount && (
            <button
              onClick={handleRemove}
              className="btn btn-error btn-sm w-full text-xs"
            >
              Remove ({financialAccount.name})
            </button>
          )
        }
        content={
          isLoading
            ? ["Loading..."]
            : data.map((t) => (
                <button
                  onClick={() => handleAdd(t.id)}
                  onTouchEnd={() => handleAdd(t.id)}
                  key={t.id}
                >
                  {t.name} ({t.code})
                </button>
              ))
        }
      />
    </div>
  );
};

export default SelectFinancialAccount;
