import Drawer from "@/components/drawer/Drawer";
import { useOrganization } from "@/context/OrganizationContext";
import { colorPalette } from "@/lib";
import LoadingSpin from "@/ui/LoadingSpin";
import { api } from "@/utils/trpc";
import useForm from "@/utils/useForm";
import { FC, useEffect, useState } from "react";
import { HiPlusCircle } from "react-icons/hi";
import { MdDelete, MdEdit } from "react-icons/md";
import { Category } from "./Categories";

type Props = {
  category: Category;
};

const defaultValue = {
  name: "",
  description: "" as string | null,
  color: "red",
  id: null as string | null,
};

const SubCategories: FC<Props> = ({ category }) => {
  const [creating, setCreating] = useState(false);

  const utils = api.useUtils();
  const { organizationId = "", organization } = useOrganization();

  const { inputs, handleChange, setInputs, setValue } = useForm(defaultValue);

  const upsert = api.utils.upsertSubCategory.useMutation();

  const deleteSubcategory = api.utils.deleteSubCategory.useMutation();

  const handleUpsert = async () => {
    const res = await upsert.mutateAsync({
      ...inputs,
      categoryId: category.id,
    });

    const _data = { ...res, mappedTo: [] };

    const setData = (
      p?: (typeof category)[]
    ): (typeof category)[] | undefined => {
      if (!p) return p;
      return p.map((c) => {
        if (c.id !== category.id) return c;
        return {
          ...c,
          subCategories: [
            _data,
            ...c.subCategories.filter((s) => s.id !== _data.id),
          ],
        };
      });
    };

    utils.organizations.categories.setData(organizationId, setData);

    if (organization?.accountingApp) {
      utils.accounting.categories.setData(
        organization.accountingApp.id,
        setData
      );
    }

    setCreating(false);
  };

  useEffect(() => {
    if (!creating) setInputs(defaultValue);
  }, [creating]);

  const handleDelete = async (
    subCat: (typeof category.subCategories)[number]
  ) => {
    const _confirm = confirm(
      `Are you sure you want to delete the ${subCat.name} sub category?`
    );
    if (!_confirm) return;

    await deleteSubcategory.mutateAsync(subCat.id);

    const seMap = (
      p?: (typeof category)[]
    ): (typeof category)[] | undefined => {
      if (!p) return p;
      return p.map((c) => {
        if (c.id === category.id) {
          return {
            ...c,
            subCategories: c.subCategories.filter((s) => s.id !== subCat.id),
          };
        }
        return c;
      });
    };

    utils.organizations.category.list.setData(organizationId, seMap);
    utils.accounting.categories.setData(organizationId, seMap);
  };

  return (
    <div className="grid">
      <button
        onClick={() => setCreating(true)}
        className="btn ml-auto btn-secondary btn-xs"
      >
        <HiPlusCircle /> New
      </button>
      {!!category.subCategories.length && (
        <div className="grid mt-4 ml-4 gap-2 ">
          {category.subCategories.map((s) => (
            <div className="btn bg-base-300 justify-between" key={s.id}>
              <div className="flex items-center gap-2">
                <span
                  className="w-4 h-4 rounded-full aspect-square"
                  style={{
                    backgroundColor: s.color,
                  }}
                />
                <p>{s.name}</p>
              </div>
              <div className="flex items-center gap-2 z-10">
                <button
                  className="btn btn-xs btn-secondary"
                  onClick={() => {
                    setInputs(s);
                    setCreating(true);
                  }}
                >
                  <MdEdit />
                </button>
                <button
                  onClick={() => handleDelete(s)}
                  className="btn btn-error btn-xs "
                >
                  <LoadingSpin loading={deleteSubcategory.isLoading} />
                  <MdDelete />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      <Drawer isOpen={creating} onClose={setCreating} title="Subcategory">
        <div className="grid gap-4">
          <label htmlFor="name">
            <p className="label">Name</p>
            <input
              placeholder="eg. Food"
              type="text"
              className="input input-bordered w-full"
              onChange={handleChange("name")}
              value={inputs.name}
            />
          </label>
          <label htmlFor="name">
            <p className="label">Description</p>
            <textarea
              onChange={handleChange("description")}
              placeholder="eg. Food related expenses"
              name="description"
              value={inputs.description || ""}
              className="input input-bordered w-full"
            />
          </label>

          <div>
            <p className="label">Color</p>
            <div className="grid grid-cols-9 w-full gap-1">
              {colorPalette.map((h) => (
                <button
                  key={h}
                  className={`btn-square  btn-xs  ${
                    inputs.color === h ? "rounded-full" : "rounded-sm"
                  }`}
                  style={{ backgroundColor: h }}
                  onClick={() => setValue("color", h)}
                ></button>
              ))}
            </div>
          </div>
          <button className="btn btn-primary" onClick={handleUpsert}>
            <LoadingSpin loading={upsert.isLoading} />
            Save
          </button>
        </div>
      </Drawer>
    </div>
  );
};

export default SubCategories;
