import { useEffect, useMemo, useState } from "react";
import Dropdown, { DropdownProps } from "./Dropdown";

type Option<V extends string> = { label?: string | null; value: V };

type Props<V extends string> = {
  onChange: (value: V) => void;
  placeholder?: string;
  options: Option<V>[];
  value?: V | null;
  searchable?: boolean;
  clearable?: boolean;
  className?: string;
  disabled?: boolean;
  isError?: any;
  label?: string;
} & Partial<DropdownProps>;

const Select = <V extends string>({
  placeholder = "Select an option",
  options,
  value,
  onChange,
  searchable,
  isError: error,
  className = "w-full",
  buttonClass,
  dropdownClass,
  footer,
  listClass,
  label,
}: Props<V>) => {
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState<Option<V> | null>();

  useEffect(() => {
    if (!value) return;
    const selectedOption = options.find((o) => o.value === value);
    setSelected(selectedOption);
  }, [value]);

  const searchedOptions = useMemo(() => {
    if (!search) return options;
    return options.filter(
      (option) => option.label?.toLowerCase().includes(search.toLowerCase())
    );
  }, [search, options]);

  return (
    <div className={` ${className} `}>
      {label && <p className="label">{label}</p>}
      <Dropdown
        dropdownClass={`w-full   ${dropdownClass}`}
        buttonClass={`overflow-auto border border-base-content/20 justify-between h-fit max-h-24 flex-1  ${
          error ? "border border-error" : ""
        } ${buttonClass} `}
        title={selected?.label || selected?.value || placeholder}
        footer={footer}
        listClass={listClass}
        header={
          searchable && (
            <input
              type="text"
              className="input input-sm w-full input-bordered"
              placeholder={placeholder}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          )
        }
        content={searchedOptions.map((o) => (
          <button
            className={`z-10 w-full text-left ${
              selected?.value === o.value ? "" : ""
            }`}
            onClick={() => onChange(o.value)}
            onTouchEnd={() => onChange(o.value)}
            key={o.value}
          >
            {o.label || o.value}
          </button>
        ))}
      />
    </div>
  );
};

export default Select;
