import { FC, ReactNode } from "react";
import { PageTitle } from "./PageTitle";

type Props = {
  page: ReactNode;
  title?: string;
};

const PageWrapper: FC<Props> = ({ page, title }) => {
  return (
    <>
      {!!title && <PageTitle title={title} />}
      {page}
    </>
  );
};

export default PageWrapper;
