import Dropdown from "@/components/Dropdown";
import { approvalStatuses, invoiceStatuses } from "@/lib";
import { Enums } from "@/types";
import LoadingSpin from "@/ui/LoadingSpin";
import { TOAST } from "@/utils/helper";
import { api } from "@/utils/trpc";
import { FC, useEffect, useState } from "react";
import { HiChevronRight } from "react-icons/hi";

type Props = {
  invoiceId: string;
  approvalStatus: Enums["ApprovalStatus"];
  status: Enums["InvoiceStatus"];
};

const statusPriority = {
  draft: 1,
  scheduled: 2,
  sent: 3,
  overdue: 4,
  paid: 5,
  cancelled: 5,
};

const InvoiceStatus: FC<Props> = ({
  approvalStatus: a,
  invoiceId,
  status: oldStatus,
}) => {
  const [approvalStatus, setApprovalStatus] = useState(a);
  const [status, setStatus] = useState(oldStatus);
  const [changed, setChanged] = useState(false);

  const updateStatus = api.invoices.updateInvoiceStatus.useMutation();
  const updateApproval = api.invoices.updateInvoiceApprovalStatus.useMutation();

  useEffect(() => {
    const _changed = approvalStatus !== a || status !== oldStatus;

    setChanged(_changed);
  }, [approvalStatus, status]);

  const utils = api.useUtils();

  const handleStatus = async () => {
    const res = await updateStatus.mutateAsync({ invoiceId, status });

    utils.invoices.invoiceById.setData(invoiceId, (p) => {
      if (!p) return p;
      return { ...p, ...res };
    });

    TOAST("Invoice status updated successfully", "success");
  };

  const handleApproval = async () => {
    await updateApproval.mutateAsync({
      invoiceId,
      approvalStatus,
    });

    utils.invoices.invoiceById.setData(invoiceId, (p) => {
      if (!p) return p;
      return { ...p, approvalStatus };
    });

    TOAST("Invoice approval status updated successfully", "success");
  };

  return (
    <>
      <div className="flex z-40 justify-between items-center">
        <p className="label hidden sm:flex label-text">Status:</p>
        <Dropdown
          title={
            <div className="flex items-center gap-4">
              <LoadingSpin loading={updateStatus.isLoading} />
              {status}
            </div>
          }
          dropdownClass="w-60"
          buttonClass="capitalize"
          content={invoiceStatuses
            .filter(
              (newStatus) =>
                statusPriority[newStatus] > statusPriority[oldStatus] ||
                newStatus === oldStatus
            )
            .map((s) => {
              return (
                <button
                  className="justify-between capitalize"
                  onClick={(e) => {
                    e.stopPropagation();
                    setStatus(s);
                  }}
                  onTouchEnd={(e) => {
                    e.stopPropagation();
                    setStatus(s);
                  }}
                >
                  {s}
                  <HiChevronRight />
                </button>
              );
            })}
          footer={
            changed && (
              <div className="w-full sticky bottom-0">
                <button
                  onClick={handleStatus}
                  className="btn btn-primary sticky bottom-0 w-48 btn-sm"
                >
                  Save
                </button>
              </div>
            )
          }
        />
      </div>
      <div className="flex z-30 justify-between items-center">
        <p className="label hidden sm:flex label-text">Approval:</p>
        <Dropdown
          title={approvalStatus}
          dropdownClass="w-60"
          buttonClass="capitalize"
          content={approvalStatuses.map((s) => (
            <button
              className="justify-between capitalize"
              onClick={(e) => {
                e.stopPropagation();
                setApprovalStatus(s);
              }}
              onTouchEnd={(e) => {
                e.stopPropagation();
                setApprovalStatus(s);
              }}
            >
              {s}
              <HiChevronRight />
            </button>
          ))}
          footer={
            changed && (
              <div className="w-full sticky bottom-0">
                <button
                  onClick={handleApproval}
                  className="btn btn-primary sticky bottom-0 w-48 btn-sm"
                >
                  Save
                  <LoadingSpin loading={updateApproval.isLoading} />
                </button>
              </div>
            )
          }
        />
      </div>
    </>
  );
};

export default InvoiceStatus;
