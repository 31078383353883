import Select from "@/components/Select";
import Tabs from "@/components/Tabs";
import { months, pastThreeYears } from "@/lib";
import { PageTitle } from "@/ui/PageTitle";
import { ReactNode, useState } from "react";
import { useParams } from "react-router-dom";
import MerchantReports from "./MerchantReports";
import ProfitNLossReport from "./ProfitAndLossReport";
import Spending from "./spending/Spending";

const tabs = ["merchants", "profit-and-loss", "spending"] as const;

type Tab = (typeof tabs)[number];

const Reports = () => {
  const { tab = "merchants" } = useParams<{ tab: Tab }>();

  const [year, setYear] = useState(pastThreeYears[0]);
  const [month, setMonth] = useState(new Date().getMonth());

  const content: Record<Tab, ReactNode> = {
    merchants: <MerchantReports {...{ setYear, year }} />,
    "profit-and-loss": <ProfitNLossReport {...{ setYear, year }} />,
    spending: <Spending {...{ year, month }} />,
  };

  return (
    <div id="s-2">
      <PageTitle title="Reports" />
      <div className="flex gap-4 my-4 items-center justify-between">
        <Tabs
          type="boxed"
          parentRoute="/reports"
          tab={tab}
          paths={["/merchants", "/profit-and-loss", "/spending"]}
          tabs={tabs}
        />

        <div className="join ">
          <Select
            value={String(year)}
            options={pastThreeYears.map((y) => ({
              label: String(y),
              value: String(y),
            }))}
            onChange={(e) => setYear(+e)}
            className="z-20 w-fit"
            dropdownClass="join-item  dropdown-bottom"
            buttonClass="join-item"
          />
          {tab === "spending" && (
            <Select
              options={months.map((m) => ({
                value: m.value.toString(),
                label: m.fullName,
              }))}
              className="w-fit"
              buttonClass="join-item"
              dropdownClass="join-item  dropdown-bottom"
              value={month.toString()}
              onChange={(e) => setMonth(+e)}
            />
          )}
        </div>
      </div>

      <div className="my-6 mx-auto ">{content[tab]}</div>
    </div>
  );
};

export default Reports;
