import Drawer from "@/components/drawer/Drawer";
import { useOrganization } from "@/context/OrganizationContext";
import Rows from "@/ui/skeletons/Rows";
import { formatCurrency, formatLabel } from "@/utils/helper";
import { RouterOutputs, api } from "@/utils/trpc";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import StaffDetails from "./StaffDetails";

export type Staff = RouterOutputs["organizations"]["staffs"][number];

const Employees: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { organizationId: orgId = "" } = useOrganization();
  const nav = useNavigate();
  const [staff, setStaff] = useState<Staff | null>(null);
  const { data = [], isLoading } = api.organizations.staffs.useQuery(orgId, {
    enabled: !!orgId,
  });

  useEffect(() => {
    if (isOpen) return;
    setStaff(null);
  }, [isOpen]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const _tab = queryParams.get("modal");

    if (_tab == "createEmployee") setIsOpen(true);
  }, [location.search]);
  return (
    <div className="">
      <Drawer
        isOpen={isOpen}
        onClose={(e) => {
          setIsOpen(e);
          nav("/people");
        }}
        title={staff?.name || "New staff"}
        content={
          <StaffDetails
            {...{
              staff,
              setIsOpen,
            }}
          />
        }
      />

      <table className="table">
        <thead>
          <tr>
            <th>Staff</th>
            <th>Role</th>
            <th>Department</th>
            <th>Contract Type</th>
            <th>Salary</th>
          </tr>
        </thead>

        <tbody>
          {isLoading && (
            <tr>
              <td colSpan={5}>
                <Rows />
              </td>
            </tr>
          )}
          {data.map((staff) => (
            <tr
              key={staff.id}
              onClick={() => {
                setStaff(staff);
                setIsOpen(true);
              }}
              className="cursor-pointer"
            >
              <td>
                <p>{staff.name}</p>
                <p className="text-xs text-neutral-400">{staff.email}</p>
              </td>
              <td>{staff.role?.name || "N/A"}</td>
              <td>{staff.department?.name || "N/A"}</td>
              <td className="capitalize">
                {staff.contractType ? formatLabel(staff.contractType) : "N/A"}
              </td>
              <td>
                {staff.gross_monthly_salary
                  ? formatCurrency(staff.gross_monthly_salary, staff.currency!)
                  : "N/A"}
              </td>
            </tr>
          ))}

          {!data.length && !isLoading && (
            <tr>
              <td colSpan={5} className="text-center">
                No staff found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Employees;
