export const mccList = [
  { code: "7623", category: "ac refrigeration repair" },
  { code: "8931", category: "accounting bookkeeping services" },
  { code: "7311", category: "advertising services" },
  { code: "0763", category: "agricultural cooperative" },
  { code: "4511", category: "airlines air carriers" },
  { code: "4582", category: "airports flying fields" },
  { code: "4119", category: "ambulance services" },
  { code: "7996", category: "amusement parks carnivals" },
  { code: "5937", category: "antique reproductions" },
  { code: "5932", category: "antique shops" },
  { code: "7998", category: "aquariums" },
  { code: "8911", category: "architectural surveying services" },
  { code: "5971", category: "art dealers and galleries" },
  { code: "7531", category: "auto body repair shops" },
  { code: "7535", category: "auto paint shops" },
  { code: "7538", category: "auto service shops" },
  { code: "5531", category: "auto and home supply stores" },
  { code: "6011", category: "automated cash disburse" },
  { code: "5542", category: "automated fuel dispensers" },
  { code: "8675", category: "automobile associations" },
  { code: "5533", category: "automotive parts and accessories stores" },
  { code: "5532", category: "automotive tire stores" },
  { code: "9223", category: "bail and bond payments" },
  { code: "5462", category: "bakeries" },
  { code: "7929", category: "bands orchestras" },
  { code: "7995", category: "betting casino gambling" },
  { code: "7932", category: "billiard pool establishments" },
  { code: "5551", category: "boat dealers" },
  { code: "4457", category: "boat rentals and leases" },
  { code: "5942", category: "book stores" },
  { code: "5192", category: "books periodicals and newspapers" },
  { code: "7933", category: "bowling alleys" },
  { code: "4131", category: "bus lines" },
  { code: "8244", category: "business secretarial schools" },
  { code: "7278", category: "buying shopping services" },
  {
    code: "4899",
    category: "cable satellite and other pay television and radio",
  },
  { code: "5946", category: "camera and photographic supply stores" },
  { code: "5441", category: "candy nut and confectionery stores" },
  { code: "7512", category: "car rental agencies" },
  { code: "7542", category: "car washes" },
  { code: "5511", category: "car and truck dealers new used" },
  { code: "5521", category: "car and truck dealers used only" },
  { code: "7217", category: "carpet upholstery cleaning" },
  { code: "5811", category: "caterers" },
  {
    code: "8398",
    category: "charitable and social service organizations fundraising",
  },
  { code: "5169", category: "chemicals and allied products" },
  { code: "8351", category: "child care services" },
  { code: "5641", category: "childrens and infants wear stores" },
  { code: "8049", category: "chiropodists podiatrists" },
  { code: "8041", category: "chiropractors" },
  { code: "5993", category: "cigar stores and stands" },
  { code: "8641", category: "civic social fraternal associations" },
  { code: "7349", category: "cleaning and maintenance" },
  { code: "7296", category: "clothing rental" },
  { code: "5046", category: "commercial equipment" },
  { code: "5139", category: "commercial footwear" },
  { code: "7333", category: "commercial photography art and graphics" },
  { code: "4111", category: "commuter transport and ferries" },
  { code: "4816", category: "computer network services" },
  { code: "7372", category: "computer programming" },
  { code: "7379", category: "computer repair" },
  { code: "5734", category: "computer software stores" },
  { code: "5045", category: "computers peripherals and software" },
  { code: "1771", category: "concrete work services" },
  { code: "5039", category: "construction materials" },
  { code: "7392", category: "consulting public relations" },
  { code: "8241", category: "correspondence schools" },
  { code: "5977", category: "cosmetic stores" },
  { code: "7277", category: "counseling services" },
  { code: "7997", category: "country clubs" },
  { code: "4215", category: "courier services" },
  { code: "9211", category: "court costs" },
  { code: "7321", category: "credit reporting agencies" },
  { code: "4411", category: "cruise lines" },
  { code: "5451", category: "dairy products stores" },
  { code: "7911", category: "dance hall studios schools" },
  { code: "7273", category: "dating escort services" },
  { code: "8021", category: "dentists orthodontists" },
  { code: "5311", category: "department stores" },
  { code: "7393", category: "detective agencies" },
  { code: "5815", category: "digital goods media" },
  { code: "5817", category: "digital goods applications" },
  { code: "5816", category: "digital goods games" },
  { code: "5818", category: "digital goods large volume" },
  { code: "5964", category: "direct marketing catalog merchant" },
  {
    code: "5965",
    category: "direct marketing combination catalog and retail merchant",
  },
  { code: "5967", category: "direct marketing inbound telemarketing" },
  { code: "5969", category: "direct marketing other" },
  { code: "5966", category: "direct marketing outbound telemarketing" },
  { code: "5968", category: "direct marketing subscription" },
  { code: "5962", category: "direct marketing travel" },
  { code: "8011", category: "doctors" },
  { code: "5963", category: "door to door sales" },
  { code: "5714", category: "drapery window covering and upholstery stores" },
  { code: "5813", category: "drinking places" },
  { code: "5912", category: "drug stores and pharmacies" },
  { code: "5122", category: "drugs drug proprietaries and druggist sundries" },
  { code: "7216", category: "dry cleaners" },
  { code: "5099", category: "durable goods" },
  { code: "5309", category: "duty free stores" },
  { code: "5812", category: "eating places restaurants" },
  { code: "8299", category: "educational services" },
  { code: "5997", category: "electric razor stores" },
  { code: "5552", category: "electric vehicle charging" },
  { code: "5065", category: "electrical parts and equipment" },
  { code: "1731", category: "electrical services" },
  { code: "7622", category: "electronics repair shops" },
  { code: "5732", category: "electronics stores" },
  { code: "8211", category: "elementary secondary schools" },
  { code: "9702", category: "emergency services gcas visa use only" },
  { code: "7361", category: "employment temp agencies" },
  { code: "7394", category: "equipment rental" },
  { code: "7342", category: "exterminating services" },
  { code: "5651", category: "family clothing stores" },
  { code: "5814", category: "fast food restaurants" },
  { code: "6012", category: "financial institutions" },
  { code: "9222", category: "fines government administrative entities" },
  {
    code: "5718",
    category: "fireplace fireplace screens and accessories stores",
  },
  { code: "5713", category: "floor covering stores" },
  { code: "5992", category: "florists" },
  { code: "5193", category: "florists supplies nursery stock and flowers" },
  { code: "5422", category: "freezer and locker meat provisioners" },
  { code: "5983", category: "fuel dealers non automotive" },
  { code: "7261", category: "funeral services crematories" },
  { code: "7641", category: "furniture repair refinishing" },
  {
    code: "5712",
    category:
      "furniture home furnishings and equipment stores except appliances",
  },
  { code: "5681", category: "furriers and fur shops" },
  { code: "5947", category: "gift card novelty and souvenir shops" },
  { code: "5231", category: "glass paint and wallpaper stores" },
  { code: "7992", category: "golf courses public" },
  {
    code: "7801",
    category:
      "government licensed online casions online gambling us region only",
  },
  { code: "9399", category: "government services" },
  {
    code: "7802",
    category: "government licensed horse dog racing us region only",
  },
  { code: "9406", category: "government owned lotteries non us region" },
  { code: "5411", category: "grocery stores supermarkets" },
  { code: "5251", category: "hardware stores" },
  { code: "5072", category: "hardware equipment and supplies" },
  { code: "7298", category: "health and beauty spas" },
  { code: "5975", category: "hearing aids sales and supplies" },
  { code: "1711", category: "heating plumbing a c" },
  { code: "5945", category: "hobby toy and game shops" },
  { code: "8062", category: "hospitals" },
  { code: "7011", category: "hotels motels and resorts" },
  { code: "5722", category: "household appliance stores" },
  { code: "5085", category: "industrial supplies" },
  { code: "7375", category: "information retrieval services" },
  { code: "6399", category: "insurance default" },
  {
    code: "5944",
    category: "jewelry stores watches clocks and silverware stores",
  },
  { code: "7211", category: "laundries" },
  { code: "8111", category: "legal services attorneys" },
  { code: "5948", category: "luggage and leather goods stores" },
  { code: "5211", category: "lumber building materials stores" },
  { code: "4468", category: "marinas service and supplies" },
  { code: "5262", category: "marketplaces" },
  { code: "7297", category: "massage parlors" },
  { code: "8099", category: "medical services" },
  { code: "8071", category: "medical and dental labs" },
  {
    code: "5047",
    category: "medical dental ophthalmic and hospital equipment and supplies",
  },
  { code: "8699", category: "membership organizations" },
  { code: "5611", category: "mens and boys clothing and accessories stores" },
  { code: "5691", category: "mens womens clothing stores" },
  { code: "5051", category: "metal service centers" },
  { code: "5699", category: "miscellaneous apparel and accessory shops" },
  { code: "5599", category: "miscellaneous auto dealers" },
  { code: "7399", category: "miscellaneous business services" },
  { code: "5499", category: "miscellaneous food stores" },
  { code: "5399", category: "miscellaneous general merchandise" },
  { code: "7299", category: "miscellaneous general services" },
  { code: "5719", category: "miscellaneous home furnishing specialty stores" },
  { code: "2741", category: "miscellaneous publishing and printing" },
  { code: "7999", category: "miscellaneous recreation services" },
  { code: "7699", category: "miscellaneous repair shops" },
  { code: "5999", category: "miscellaneous specialty retail" },
  { code: "5271", category: "mobile home dealers" },
  { code: "7832", category: "motion picture theaters" },
  { code: "4214", category: "motor freight carriers and trucking" },
  { code: "5592", category: "motor homes dealers" },
  { code: "5013", category: "motor vehicle supplies and new parts" },
  { code: "5571", category: "motorcycle shops and dealers" },
  { code: "5561", category: "motorcycle shops dealers" },
  {
    code: "5733",
    category: "music stores musical instruments pianos and sheet music",
  },
  { code: "5994", category: "news dealers and newsstands" },
  { code: "6051", category: "non fi money orders" },
  { code: "5199", category: "nondurable goods" },
  { code: "5261", category: "nurseries lawn and garden supply stores" },
  { code: "5021", category: "office and commercial furniture" },
  { code: "8043", category: "opticians eyeglasses" },
  { code: "8042", category: "optometrists ophthalmologist" },
  { code: "5976", category: "orthopedic goods prosthetic devices" },
  { code: "8031", category: "osteopaths" },
  { code: "5921", category: "package stores beer wine and liquor" },
  { code: "5198", category: "paints varnishes and supplies" },
  { code: "7523", category: "parking lots garages" },
  { code: "4112", category: "passenger railways" },
  { code: "5933", category: "pawn shops" },
  { code: "5995", category: "pet shops pet food and supplies" },
  { code: "5172", category: "petroleum and petroleum products" },
  { code: "7395", category: "photo developing" },
  { code: "7221", category: "photographic studios" },
  {
    code: "5044",
    category: "photographic photocopy microfilm equipment and supplies",
  },
  { code: "7829", category: "picture video production" },
  { code: "5131", category: "piece goods notions and other dry goods" },
  { code: "5074", category: "plumbing heating equipment and supplies" },
  { code: "8651", category: "political organizations" },
  { code: "9402", category: "postal services government only" },
  { code: "5094", category: "precious stones and metals watches and jewelry" },
  { code: "8999", category: "professional services" },
  { code: "4225", category: "public warehousing and storage" },
  { code: "7338", category: "quick copy repro and blueprint" },
  { code: "4011", category: "railroads" },
  { code: "6513", category: "real estate agents and managers rentals" },
  { code: "5735", category: "record stores" },
  { code: "7519", category: "recreational vehicle rentals" },
  { code: "5973", category: "religious goods stores" },
  { code: "8661", category: "religious organizations" },
  { code: "1761", category: "roofing siding sheet metal" },
  { code: "7339", category: "secretarial support services" },
  { code: "6211", category: "security brokers dealers" },
  { code: "5541", category: "service stations" },
  { code: "5949", category: "sewing needlework fabric and piece goods stores" },
  { code: "7251", category: "shoe repair hat cleaning" },
  { code: "5661", category: "shoe stores" },
  { code: "7629", category: "small appliance repair" },
  { code: "5598", category: "snowmobile dealers" },
  { code: "1799", category: "special trade services" },
  { code: "2842", category: "specialty cleaning" },
  { code: "5941", category: "sporting goods stores" },
  { code: "7032", category: "sporting recreation camps" },
  { code: "7941", category: "sports clubs fields" },
  { code: "5655", category: "sports and riding apparel stores" },
  { code: "5972", category: "stamp and coin stores" },
  {
    code: "5111",
    category: "stationary office supplies printing and writing paper",
  },
  {
    code: "5943",
    category: "stationery stores office and school supply stores",
  },
  { code: "5996", category: "swimming pools sales" },
  { code: "4723", category: "t ui travel germany" },
  { code: "5697", category: "tailors alterations" },
  { code: "9311", category: "tax payments government agencies" },
  { code: "7276", category: "tax preparation services" },
  { code: "4121", category: "taxicabs limousines" },
  { code: "4812", category: "telecommunication equipment and telephone sales" },
  { code: "4814", category: "telecommunication services" },
  { code: "4821", category: "telegraph services" },
  { code: "5998", category: "tent and awning shops" },
  { code: "8734", category: "testing laboratories" },
  { code: "7922", category: "theatrical ticket agencies" },
  { code: "7012", category: "timeshares" },
  { code: "7534", category: "tire retreading and repair" },
  { code: "4784", category: "tolls bridge fees" },
  { code: "7991", category: "tourist attractions and exhibits" },
  { code: "7549", category: "towing services" },
  { code: "7033", category: "trailer parks campgrounds" },
  { code: "4789", category: "transportation services" },
  { code: "4722", category: "travel agencies tour operators" },
  { code: "7511", category: "truck stop iteration" },
  { code: "7513", category: "truck utility trailer rentals" },
  { code: "2791", category: "typesetting plate making and related services" },
  { code: "5978", category: "typewriter stores" },
  { code: "9405", category: "u s federal government agencies or departments" },
  { code: "5137", category: "uniforms commercial clothing" },
  { code: "5931", category: "used merchandise and secondhand stores" },
  { code: "5331", category: "variety stores" },
  { code: "0742", category: "veterinary services" },
  { code: "7993", category: "video amusement game supplies" },
  { code: "7994", category: "video game arcades" },
  { code: "7841", category: "video tape rental stores" },
  { code: "8249", category: "vocational trade schools" },
  { code: "7631", category: "watch jewelry repair" },
  { code: "7692", category: "welding repair" },
  { code: "5698", category: "wig and toupee stores" },
  { code: "4829", category: "wires money orders" },
  { code: "5631", category: "womens accessory and specialty shops" },
  { code: "5621", category: "womens ready to wear stores" },
  { code: "5935", category: "wrecking and salvage yards" },
];
