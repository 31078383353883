import Drawer from "@/components/drawer/Drawer";
import { PAYMENT_HOST } from "@/constants";
import { useOrganization } from "@/context/OrganizationContext";
import LoadingSpin from "@/ui/LoadingSpin";
import PromptModal from "@/ui/PromptModal";
import {
  base64ToBlob,
  copyToClipboard,
  formatId,
  openFile,
  saveFile,
} from "@/utils/helper";
import { api } from "@/utils/trpc";
import { FC, useState } from "react";
import { toast } from "react-hot-toast";
import {
  HiChevronRight,
  HiOutlineDownload,
  HiOutlineExternalLink,
  HiOutlineLink,
  HiOutlineMail,
} from "react-icons/hi";
import { InvoiceType } from "./InvoicePage";
import ScheduleInvoice from "./ScheduleInvoice";

type InvoiceActionsType = "download" | "open" | "email";

type Props = {
  data: InvoiceType;
};

const InvoiceActions: FC<Props> = ({ data }) => {
  const invoiceLink = `${PAYMENT_HOST}/invoices/${data.id}`;
  const [loading, setLoading] = useState<false | InvoiceActionsType>(false);
  const { organization } = useOrganization();

  const sendInvoice = api.emails.sendInvoice.useMutation();
  const generateInvoice = api.invoices.generateInvoice.useMutation();

  const utils = api.useUtils();

  function copy() {
    copyToClipboard(invoiceLink);
  }

  const handleInvoiceAction = (action: InvoiceActionsType) => async () => {
    if (!organization) return;
    setLoading(action);

    if (action === "email") {
      const res = await sendInvoice
        .mutateAsync({
          invoiceId: data.id,
          team_member_id: organization.team_member_id,
        })
        .catch(console.log);

      if (!res) return toast.error("Failed to send email");

      utils.invoices.invoiceById.setData(data.id, (i) => {
        if (!i) return i;
        return { ...i, ...res };
      });

      toast.success("Email sent successfully");
    } else {
      const base64 = await generateInvoice.mutateAsync(data.id);
      const blob = await base64ToBlob(base64);

      if (action === "download") {
        saveFile(blob, `invoice-${formatId(data.invoice_number, "INV")}.pdf`);
      } else if (action === "open") openFile(blob);
    }

    setLoading(false);
  };

  const [scheduling, setScheduling] = useState(false);

  const showPaymentOption =
    data.paymentLinkEnabled && !["draft", "cancelled"].includes(data.status);
  const disablePaymentReason = showPaymentOption
    ? undefined
    : !data.paymentLinkEnabled
    ? "Payment link not enabled"
    : `Invoice is in ${data.status} state`;

  return (
    <div className="flex relative gap-2 items-start w-full">
      <Drawer
        title="Schedule Invoice"
        isOpen={scheduling}
        unMountOnClose
        onClose={setScheduling}
        content={
          <ScheduleInvoice
            setScheduling={setScheduling}
            invoiceId={data.id}
            dueDate={data.schedule?.dueDate}
          />
        }
      />
      <div className="w-full flex flex-col gap-2">
        <button
          onClick={handleInvoiceAction("download")}
          className="btn btn-secondary"
        >
          <LoadingSpin loading={loading === "download"} />
          <HiOutlineDownload />
          <p className="text-left flex-1">Download</p>

          <HiChevronRight />
        </button>
        <div
          className="tooltip tooltip-warning"
          data-tip={disablePaymentReason}
        >
          <button
            onClick={copy}
            className="btn btn-secondary w-full"
            disabled={!showPaymentOption}
          >
            <HiOutlineLink />
            <p className="text-left flex-1">Payment Link</p>

            <HiChevronRight />
          </button>
        </div>

        <PromptModal
          {...{
            message: `Confirm sending an Email with the invoice to the customer <${data.customer.email}> :`,
            buttons: [
              {
                label: "Send",
                onClick: handleInvoiceAction("email"),
                type: "primary",
              },
              {
                label: data.schedule ? "Re schedule" : "Schedule for later",
                onClick: () => setScheduling(true),
                type: "secondary",
              },
            ],
            render: (toggle) => (
              <button
                className="btn btn-secondary justify-between w-full"
                onClick={() => toggle(true)}
              >
                <LoadingSpin loading={loading === "email"} />
                <HiOutlineMail />
                <p className="text-left flex-1">Send</p>
                <HiChevronRight />
              </button>
            ),
          }}
        />

        <button
          onClick={handleInvoiceAction("open")}
          className="btn btn-secondary w-full justify-between"
        >
          <LoadingSpin loading={loading === "open"} />
          <HiOutlineExternalLink />

          <p className="text-left flex-1">Open</p>
          <HiChevronRight />
        </button>
      </div>
    </div>
  );
};

export default InvoiceActions;
