import { Dispatch, FC, SetStateAction } from "react";
import { BsSortDown, BsSortUp } from "react-icons/bs";
import { FaSort } from "react-icons/fa";

const SortIcon = {
  asc: <BsSortUp size={16} />,
  desc: <BsSortDown size={16} />,
  undefined: <FaSort size={16} />,
} as const;

export type Sorting = {
  total?: "asc" | "desc";
  createdAt?: "asc" | "desc";
  dueDate?: "asc" | "desc";
};

type Props = {
  setSorting: Dispatch<SetStateAction<Sorting>>;
  sorting: Sorting;
  data: any[];
  setSelection: Dispatch<SetStateAction<Record<string, any>>>;
  selectedArray: any[];
  showAccountingOverview?: boolean;
};

const InvoiceHeaders: FC<Props> = ({
  setSorting,
  sorting,
  showAccountingOverview,
}) => {
  const handleSorting = (key: keyof Sorting) => () => {
    setSorting((p) => ({
      [key]: p[key] === "asc" ? "desc" : p[key] === "desc" ? "asc" : "desc",
    }));
  };

  return (
    <thead>
      <tr>
        <th></th>

        <th>
          <button
            className="justify-start pl-0 w-max text-xs gap-1 btn btn-ghost btn-xs rounded-sm"
            onClick={handleSorting("dueDate")}
          >
            <p>Due Date</p>
            {SortIcon[sorting.dueDate!]}
          </button>
        </th>
        <th>To</th>
        <th>Status</th>
        {showAccountingOverview && (
          <>
            <th className="text-start">Accounting Status</th>
            <th>GL Account</th>
          </>
        )}
        <th>
          <button
            className="pl-0 min-w-max w-full text-left justify-start text-xs gap-1 btn btn-ghost btn-xs rounded-sm"
            onClick={handleSorting("total")}
          >
            <p>Amount</p>
            {SortIcon[sorting.total!]}
          </button>
        </th>
        <th>Invoice #</th>
        <th>
          <button
            className="flex justify-center min-w-max w-full text-xs gap-1 btn btn-ghost btn-xs rounded-sm"
            onClick={handleSorting("createdAt")}
          >
            <p>Created At</p>
            {SortIcon[sorting.createdAt!]}
          </button>
        </th>
      </tr>
    </thead>
  );
};

export default InvoiceHeaders;
