import DisplayName from "@/components/DisplayName";
import Drawer from "@/components/drawer/Drawer";
import AssignCategories from "@/ui/AssignCategories";
import Checkbox from "@/ui/Checkbox";
import { formatCurrency, formatId, formatLabel } from "@/utils/helper";
import { RouterOutputs } from "@/utils/trpc";
import format from "date-fns/format";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { useNavigate } from "react-router-dom";
import AccountingStatus from "./ManageAccountingStatus";

type Props = {
  list: RouterOutputs["bills"]["bills"];
  isLoading: boolean;
  setSelection: Dispatch<SetStateAction<Record<string, any>>>;
  selection: Record<string, any>;
  totalSelected: number;
  showAccountingOverview?: boolean;
  params: any;
};

const BillsListing: FC<Props> = ({
  list,
  isLoading,
  selection,
  setSelection,
  totalSelected,
  showAccountingOverview,
  params,
}) => {
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);

  const toggleDrawer = () => {
    setSelectedItemId(null);
  };

  const nav = useNavigate();

  const view = (id: string) => () => nav(`/bill/${id}`);

  return (
    <tbody>
      {list.map((b, i, { length }) => (
        <tr
          key={b.id}
          style={{ zIndex: length - i }}
          className="border-white/5 cursor-pointer "
        >
          <th>
            <Checkbox
              checked={!!selection[b.id]}
              onChange={() => {
                setSelection((p) => {
                  let prev = { ...p };

                  if (totalSelected > list.length) {
                    prev = list.reduce(
                      (acc, curr) => ({ ...acc, [curr.id]: curr }),
                      {}
                    );
                  }

                  if (prev[b.id]) {
                    const { [b.id]: _, ...rest } = prev;
                    return rest;
                  } else return { ...prev, [b.id]: b };
                });
              }}
            />
          </th>

          <td onClick={view(b.id)}>
            <p className="flex md:flex-row flex-col  w-full text-center pr-2 gap-1">
              {format(new Date(b.dueDate), "dd MMM")},
              <span className=" text-neutral-500 text-sm  ">
                {format(new Date(b.dueDate), "yyyy")}
              </span>
            </p>
          </td>

          <td onClick={view(b.id)} className="flex gap-6">
            <div className="flex-1 w-max">
              <DisplayName text={b.vendor?.name || "N/A"} className="" />
            </div>
          </td>
          <td>
            <div className="btn btn-xs capitalize rounded-sm btn-neutral">
              {b.status}
            </div>
          </td>
          {showAccountingOverview && (
            <>
              <td>
                <AccountingStatus
                  {...{
                    billId: b.id,
                    status: b.accountingStatus,
                    params,
                  }}
                />
              </td>
              <td>{b.financialAccount?.name || "N/A"}</td>{" "}
            </>
          )}
          <td onClick={view(b.id)}>
            <div className="w-full text-start pr-2">
              <p className="text-start">
                {formatCurrency(b.total, b.currency)}
              </p>

              <p className="text-xs text-neutral-500">
                {formatCurrency(b.totalVat, b.currency)}/
                {formatCurrency(b.totalAmount, b.currency)}
              </p>
            </div>
          </td>
          <td>
            <p className="text-xs">{formatId(b.bill_number, "BILL")}</p>
          </td>
          <td onClick={view(b.id)}>
            <p className="flex md:flex-row text-right flex-col w-full justify-center pr-2 gap-1">
              {format(new Date(b.createdAt), "dd MMM")},
              <span className=" text-neutral-500 text-sm  ">
                {format(new Date(b.createdAt), "yyyy")}
              </span>
            </p>
          </td>

          {/* <th className="">
            <button
              className="btn btn-ghost btn-xs"
              onClick={() => setSelectedItemId(i.id)}
            >
              <RiMoreFill size="22" />
            </button>
          </th> */}
          <Drawer
            isOpen={selectedItemId == b.id}
            onClose={toggleDrawer}
            title={"Bill Details"}
            content={
              <div className="flex flex-col w-full gap-3 items-center justify-end">
                <div className="w-full">
                  <AssignCategories
                    {...{
                      assignedCategories: {
                        category: b.category,
                        subCategory: b.subCategory,
                      },
                      billId: b.id,
                      isList: false,
                    }}
                  />
                </div>
                <div className="flex flex-row gap-5 mt-3 items-center w-full">
                  <div className="w-full">
                    <span className="text-gray-500   text-sm">Status</span>
                    <div
                      className={`w-full px-4 py-2 rounded-md text-sm text-center mt-1  text-gray-200     capitalize  `}
                    >
                      {b.status || "Draft"}
                    </div>
                  </div>
                  <div className="w-full">
                    <span className="text-gray-500   text-sm ">
                      Approval status
                    </span>
                    <div
                      className={`w-full px-4 py-2 rounded-md text-sm mt-1 text-gray-200 text-center    capitalize `}
                    >
                      {formatLabel(b.approvalStatus)}
                    </div>
                  </div>
                </div>
              </div>
            }
          />
        </tr>
      ))}
    </tbody>
  );
};

export default BillsListing;
