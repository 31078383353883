import { FC, useState } from "react";
import NonPayrollSpending from "./NonPayrollSpending";
import PayrollSpending from "./PayrollSpending";

type Props = {
  year: number;
  month: number;
};

const Spending: FC<Props> = ({ year, month }) => {
  const [isPayroll, setIsPayroll] = useState(false);
  return (
    <div>
      <div role="tablist" className="tabs tabs-bordered w-fit mb-6">
        <a
          role="tab"
          className={`tab ${!isPayroll ? "tab-active" : ""}`}
          onClick={() => setIsPayroll(false)}
        >
          Non-Payroll
        </a>
        <a
          onClick={() => setIsPayroll(true)}
          role="tab"
          className={`tab ${isPayroll ? "tab-active tab-border-primary" : ""}`}
        >
          Payroll
        </a>
      </div>
      {isPayroll ? (
        <PayrollSpending {...{ year, month }} />
      ) : (
        <NonPayrollSpending {...{ year, month }} />
      )}
    </div>
  );
};

export default Spending;
