import { DEMO_SESSION } from "@/constants";
import { socket } from "@/utils/socket";
import { supabase } from "@/utils/supabase";
import { api, RouterOutputs } from "@/utils/trpc";
import { Session } from "@supabase/supabase-js";
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

export type User = NonNullable<RouterOutputs["users"]["userById"]>;

interface Props<S extends boolean> {
  session: Session | null;
  userId: S extends true ? string : undefined;
  logout: () => Promise<void>;
  user: S extends true ? User : null;
  refetch: () => Promise<any>;
}

const LocalStateContext = createContext<any>({});

const LocalStateProvider = LocalStateContext.Provider;

const UserContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [session, setSession] = useState<Session | null>(null);
  const userId = session?.user.id || "";

  const {
    data: user,
    isLoading,
    remove,
    refetch,
  } = api.users.userById.useQuery(userId, {
    enabled: !!userId,
  });

  const nav = useNavigate();
  const utils = api.useUtils();

  useEffect(() => {
    if (DEMO_SESSION) {
      setSession(DEMO_SESSION);
      return;
    }
    supabase.auth.onAuthStateChange((_event, session) => {
      if (session) {
        setSession(session);
        socket.emit("join", session.user.id);
      } else {
        setSession(null);
        utils.users.userById.setData(userId, null);
      }
    });
  }, []);

  const logout = async () => {
    if (DEMO_SESSION) return;
    await supabase.auth.signOut();
    nav("/login");
    remove();
    setSession(null);
  };

  if (isLoading) {
    return (
      <div className="h-full w-full grid place-content-center">
        <progress className="progress w-56 progress-primary"></progress>
      </div>
    );
  }

  return (
    <LocalStateProvider
      value={{
        session,
        userId: session?.user.id,
        logout,
        user,
        refetch,
      }}
    >
      {children}
    </LocalStateProvider>
  );
};

const useUser = <S extends boolean>() =>
  useContext<Props<S>>(LocalStateContext);

export { UserContextProvider, useUser };
