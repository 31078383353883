import { useOrganization } from "@/context/OrganizationContext";
import { PageTitle } from "@/ui/PageTitle";
import Rows from "@/ui/skeletons/Rows";
import { api } from "@/utils/trpc";
import formatDistance from "date-fns/formatDistance";
import { HiPlusCircle } from "react-icons/hi";
import { MdDelete } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";

const Rules = () => {
  const { organizationId = "" } = useOrganization();
  const { data: rules = [], isLoading } =
    api.rules.list.useQuery(organizationId);
  const nav = useNavigate();

  const deleteRule = api.rules.delete.useMutation();
  const utils = api.useUtils();

  const handleDelete = (id: string) => async (e: any) => {
    e.stopPropagation();
    const _confirm = window.confirm(
      "Are you sure you want to delete this rule?"
    );

    if (!_confirm) return;

    await deleteRule.mutateAsync(id);
    utils.rules.list.setData(organizationId, (p) => {
      if (!p) return [];
      return p.filter((r) => r.id !== id);
    });
  };
  return (
    <>
      <PageTitle title="Automation" />

      <div className="w-full mb-6 flex justify-end">
        <Link to={"/rules/create"} className="btn btn-secondary btn-sm">
          <HiPlusCircle /> new
        </Link>
      </div>
      <table className="table bg-base-200 ">
        <thead>
          <tr>
            <th>Name</th>
            <th className="text-center">Conditions</th>
            <th>Last run</th>
            <th>Created At</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {rules.map((rule, i) => (
            <tr
              className="cursor-pointer border-white/5"
              onClick={() => nav(`/rules/${rule.id}`)}
            >
              <td>{rule.name}</td>

              <td className="text-center">{rule.conditions.length}</td>
              <td>
                {rule.lastRun
                  ? formatDistance(rule.lastRun, new Date(), {
                      addSuffix: true,
                    })
                  : "Never"}
              </td>
              <td>
                {formatDistance(rule.createdAt, new Date(), {
                  addSuffix: true,
                })}
              </td>
              <th className="flex-1 ">
                <button
                  className="btn btn-xs btn-outline btn-error"
                  onClick={handleDelete(rule.id)}
                >
                  <MdDelete />
                </button>
              </th>
            </tr>
          ))}
        </tbody>
      </table>
      {isLoading && <Rows count={10} />}
    </>
  );
};

export default Rules;
