import DisplayName from "@/components/DisplayName";
import Dropdown from "@/components/Dropdown";
import { Organization, useOrganization } from "@/context/OrganizationContext";
import { FC } from "react";
import { GoOrganization } from "react-icons/go";
import { RiAddFill } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import ImageFromPath from "./ImageFromPath";

const OrganizationsList: FC = () => {
  const {
    organization: org,
    setOrganization,
    organizations,
  } = useOrganization();

  const navigate = useNavigate();
  function setOrg(o: Organization) {
    setOrganization(o);
    navigate("/");
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Dropdown
        dropdownClass="w-full 2xl:w-56"
        title={
          <div className="flex  justify-between  items-center gap-2">
            <div className="flex items-center gap-2">
              {org?.logo ? (
                <ImageFromPath
                  key={org.id}
                  path={org.logo}
                  className="w-7 mx-auto  rounded-full"
                />
              ) : (
                <GoOrganization size={22} />
              )}
              <DisplayName
                className="text-xs  font-semibold"
                text={org?.name}
                maxLength={18}
              />
            </div>
          </div>
        }
        content={organizations
          .map((o) => (
            <button
              onTouchEnd={() => setOrg(o)}
              onClick={() => setOrg(o)}
              key={o.id}
              className="w-full"
            >
              {o.logo ? (
                <ImageFromPath
                  key={o.id}
                  {...{ path: o.logo }}
                  className="w-5 rounded-full"
                />
              ) : (
                <GoOrganization size={20} />
              )}
              <DisplayName
                className="text-xs font-medium"
                text={o.name}
                maxLength={20}
              />
            </button>
          ))
          .concat(
            <Link className="text-xs" to={"/organizations/create"}>
              <RiAddFill />
              New organization
            </Link>
          )}
      />
    </div>
  );
};

export default OrganizationsList;
