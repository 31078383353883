import NotFound from "@/components/utils/notfound";
import { useOrganization } from "@/context/OrganizationContext";
import { Customer } from "@/types";
import ImageFromPath from "@/ui/ImageFromPath";
import { api } from "@/utils/trpc";
import { Dispatch, FC, SetStateAction } from "react";
import { HiChevronRight } from "react-icons/hi";
import Spinner from "../../components/utils/spinner";

type Props = {
  setSelectedCustomer: Dispatch<SetStateAction<Customer | null>>;
};

const SelectCustomer: FC<Props> = ({ setSelectedCustomer }) => {
  const { organizationId = "" } = useOrganization();

  const { data: customers = [], isLoading } =
    api.partners.customersByOrgId.useQuery(organizationId, {
      enabled: !!organizationId,
    });

  const handleSelect = (data: Customer) => () => {
    setSelectedCustomer(data);
  };

  if (isLoading) return <Spinner />;
  if (!customers.length) return <NotFound title="Vendor" />;

  return (
    <div className="grid gap-2">
      {customers.map((c) => (
        <button
          onClick={handleSelect(c)}
          key={c.id.toString()}
          className="capitalize text-left bg-base-300 rounded-lg px-4 py-2 hover:bg-base-300   text-base font-medium w-full"
        >
          <div className="flex justify-between items-center gap-5">
            <ImageFromPath
              {...{ path: c.photo }}
              className="w-10 aspect-square"
            />
            <div className="  flex-1">
              <p className=" text-base font-meddium">{c.name}</p>
            </div>
            <p className="text-xs italic">{c.business_tax_number}</p>
            <HiChevronRight size={22} className="text-base-content" />
          </div>
        </button>
      ))}
    </div>
  );
};

export default SelectCustomer;
