import { setTitle } from "@/utils/helper";
import { FC, ReactNode } from "react";

type Props = {
  title: string;
  subtitle?: string;
  header?: ReactNode;
};

export const PageTitle: FC<Props> = ({ title, subtitle, header }) => {
  setTitle(title);
  return (
    <div className="mt-2 mb-6">
      <h1 className="font-bold text-xl capitalize">{title}</h1>
      <h2 className="text-xs text-base-content">{subtitle}</h2>
      {header}
    </div>
  );
};
