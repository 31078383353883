import { FC, ReactNode } from "react";

type Props = {
  isOpen: boolean;
  onClose: (A?: any) => any;
  children: ReactNode;
  className?: string;
  hideClose?: boolean;
};

const Modal: FC<Props> = ({
  children,
  isOpen,
  onClose,
  className,
  hideClose,
}) => {
  return (
    <dialog
      id="my_modal_1"
      className="modal"
      open={isOpen}
      onClose={() => onClose(false)}
    >
      <div className={`modal-box ${className}`}>
        {children}
        <div className="modal-action">
          {!hideClose && (
            <form method="dialog">
              <button className="btn">Close</button>
            </form>
          )}
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  );
  return (
    <div
      className={`fixed left-0 top-0 grid min-h-full  w-screen h-screen min-w-full place-content-center  bg-black/40  transition-all z-[99999] duration-200 ${
        isOpen ? "" : "invisible opacity-0"
      } `}
      onClick={() => onClose(false)}
    >
      <div
        className={` overflow-auto  p-4  rounded-lg bg-base-100 shadow-xl transition-all duration-200 ${className}  ${
          isOpen ? "translate-x-0" : "translate-x-40"
        }  `}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
