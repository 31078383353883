import { api } from "@/utils/trpc";
import { FC } from "react";
import { BiSolidCalendarX, BiSolidHide } from "react-icons/bi";
import { CiCalendarDate } from "react-icons/ci";
import { IoMdEye } from "react-icons/io";
import { SlCalender } from "react-icons/sl";
import { TransactionHistory } from "../Transactions";
import useTransactions from "../hooks/useTransactions";

const recurringTypes = [
  { value: "monthly", label: "Monthly", Icon: <SlCalender /> },
  { value: "yearly", label: "Yearly", Icon: <CiCalendarDate size={28} /> },
  { value: null, label: "Never", Icon: <BiSolidCalendarX size={24} /> },
] as const;

type RecurringType = (typeof recurringTypes)[number]["value"];

const Visibility = [
  { label: "visible", icon: <IoMdEye />, value: false },
  { label: "hidden", icon: <BiSolidHide />, value: true },
];

type Props = {
  transaction: TransactionHistory;
};

const MangeStatuses: FC<Props> = ({
  transaction: { recurringType, id, isHidden },
}) => {
  const setRecurrence = api.transaction.recurrence.set.useMutation();
  const setVisibility = api.transaction.visibility.set.useMutation();

  const update = useTransactions();

  return (
    <>
      <div>
        <p className="divider divider-start">Recurring:</p>
        <div className="flex gap-2">
          {recurringTypes.map(({ Icon, value, label }) => (
            <button
              className={`btn capitalize btn-sm ${
                recurringType === value ? "btn-primary" : ""
              }`}
              onClick={async () => {
                const res = await setRecurrence.mutateAsync({
                  transactionId: id,
                  recurringType: value,
                });
                update(id, res);
              }}
            >
              {Icon} {label}
            </button>
          ))}
        </div>
      </div>
      <div>
        <p className="divider divider-start">Visibility:</p>
        <div className="flex gap-2">
          {Visibility.map(({ icon, value, label }) => (
            <button
              className={`btn capitalize btn-sm ${
                isHidden === value ? "btn-primary" : ""
              }`}
              onClick={async () => {
                const res = await setVisibility.mutateAsync({
                  transactionId: id,
                  isHidden: value,
                });
                update(id, res);
              }}
            >
              {icon} {label}
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

export default MangeStatuses;
