import AttachProof from "@/components/proof/AttachProof";
import NotFound from "@/components/utils/notfound";
import Spinner from "@/components/utils/spinner";
import AssignCategories from "@/ui/AssignCategories";
import AssignTeam from "@/ui/AssignTeam";
import ImageFromPath from "@/ui/ImageFromPath";
import LineItems from "@/ui/LineItems";
import LoadingSpin from "@/ui/LoadingSpin";
import { PageTitle } from "@/ui/PageTitle";
import PaymentDetails from "@/ui/PaymentDetails";
import { formatCurrency, formatId } from "@/utils/helper";
import { RouterOutputs, api } from "@/utils/trpc";
import { format } from "date-fns";
import { toast } from "react-hot-toast";
import { MdDelete, MdEdit } from "react-icons/md";
import { RiArrowGoBackLine } from "react-icons/ri";
import { Link, useNavigate, useParams } from "react-router-dom";
import InvoiceActions from "./InvoiceActions";
import InvoiceStatus from "./InvoiceStatus";
import ManageFinancialAccount from "./ManageFinancialAccount";

export type InvoiceType = RouterOutputs["invoices"]["invoiceById"];

const InvoicePage = () => {
  const { invoiceId = "" } = useParams();

  const { data, isLoading } = api.invoices.invoiceById.useQuery(invoiceId, {
    enabled: !!invoiceId,
  });

  const deleteInvoice = api.invoices.deleteInvoice.useMutation();
  const nav = useNavigate();

  if (isLoading)
    return (
      <p className="invoice-page loading-text mt-[100px] p-10 font-semibold text-base-content text-center    text-xl">
        <Spinner />
      </p>
    );

  if (!data) return <NotFound title="Invoice" />;

  const editable = ["draft", "scheduled"].includes(data.status);

  const handleDelete = async () => {
    const confirm = window.confirm(
      "Are you sure you want to delete this invoice?"
    );
    if (!confirm) return;
    await deleteInvoice.mutateAsync(data.id);
    toast.success("Invoice deleted successfully");
    nav(-1);
  };

  return (
    <div className="pb-8 w-full grid gap-6">
      <PageTitle
        {...{
          title: "Invoice",
          subtitle: formatId(data.invoice_number, "INV"),
        }}
      />
      <div className="flex gap-3  justify-between items-end">
        <div className="flex-1 flex items-center gap-5">
          <Link className="link link-secondary link-hover" to="/invoices">
            <RiArrowGoBackLine size={26} />
          </Link>
          <AssignTeam
            {...{
              invoiceId: data.id,
              assignees: data.assignees.map((a) => a.team_member_Id),
            }}
          />
        </div>
        {editable && (
          <>
            <Link
              to={`/invoices/update/${data.id}`}
              className="btn btn-primary "
            >
              <MdEdit />
            </Link>
            <button onClick={handleDelete} className="btn btn-error">
              <LoadingSpin loading={deleteInvoice.isLoading} />
              <MdDelete />
            </button>
          </>
        )}
      </div>
      <div className="flex md:flex-row flex-col gap-4">
        <div className="lg:w-[60%]  grid gap-4">
          <div className="h-fit overflow-hidden w-full bg-base-100 p-4 rounded-lg grid gap-4">
            <p className="label">Customer</p>
            <div className="btn h-fit w-full transform-none justify-start">
              <ImageFromPath
                {...{
                  path: data.customer.photo,
                }}
                className="w-8 aspect-square rounded-xl"
              />
              <p>{data.customer.name}</p>
              <p className="text-xs">
                {"<"}
                {data.customer.email}
                {">"}
              </p>
            </div>

            <table className="table">
              <thead className="text-base-content bg-base-300 border border-base-300">
                <tr>
                  <th>Subtotal</th>
                  <th>VAT</th>
                  <th className="w-full text-right">Total</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>{formatCurrency(data.total, data.currency)}</td>
                  <td>
                    <p>{formatCurrency(data.totalVat, data.currency)}</p>
                    <p className="text-xs">
                      ({((data.totalVat * 100) / data.totalAmount).toFixed(2)}
                      %){" "}
                    </p>
                  </td>
                  <td className="text-right">
                    {formatCurrency(data.totalAmount, data.currency)}
                  </td>
                </tr>
              </tbody>
            </table>

            <LineItems rows={data.rows} currency={data.currency} />

            <table className="table">
              <thead className="text-base-content bg-base-300 border border-base-300">
                <tr>
                  <th>Sent at</th>
                  <th>Due date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {data.sentAt ? format(data.sentAt, "dd MMM yyy") : "N/A"}
                  </td>
                  <td>{format(data.dueDate, "dd MMM yyy")}</td>
                </tr>
              </tbody>
            </table>
          </div>

          {data.status === "paid" && (
            <PaymentDetails {...{ data: data.payments[0] }} />
          )}

          <AttachProof {...{ proofs: data.proofs, invoiceId: data.id }} />
        </div>

        <div className="lg:w-[40%] content-start w-full grid gap-2">
          <InvoiceStatus
            key={data.status}
            {...{
              approvalStatus: data.approvalStatus,
              status: data.status,
              invoiceId: data.id,
            }}
          />
          <div className="flex items-center z-20 justify-between">
            <p className="label hidden sm:flex label-text">Categories</p>
            <div className="w-60">
              <AssignCategories
                {...{
                  assignedCategories: {
                    category: data.category,
                    subCategory: data.subCategory,
                  },
                  invoiceId: data.id,
                }}
              />
            </div>
          </div>

          <div className="w-full z-10 mt-4 justify-between flex">
            <p className="label hidden sm:flex ">GL Account</p>
            <div className="w-60">
              <ManageFinancialAccount
                {...{
                  invoiceId: data.id,
                  financialAccount: data.financialAccount,
                }}
              />
            </div>
          </div>

          <div className="w-full mt-4 justify-between flex">
            <p className="label hidden sm:flex ">Actions</p>
            <div className="w-60">
              <InvoiceActions data={data} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoicePage;
