import { Enums } from "@/types";
import { matchPath } from "react-router-dom";
import { navigation } from "./lib";

export class NavigationControl {
  private navigation = navigation;

  validRoutes(plan: Enums["BillingType"]) {
    return this.navigation[plan].map((p) => p.routes).flat();
  }

  isValidRoute(path: string, plan: Enums["BillingType"]) {
    return this.validRoutes(plan).some((p) => matchPath(p, path));
  }

  validNavigationLinks(plan: Enums["BillingType"]) {
    return this.navigation[plan];
  }
}
