import { loginWithOAuth } from "@/utils/supabase";
import { useMutation } from "@tanstack/react-query";
import { FcGoogle } from "react-icons/fc";
import { Link } from "react-router-dom";

const Login: React.FC = () => {
  const OAuth = useMutation(loginWithOAuth);

  const handleOAuth = async () => {
    await OAuth.mutateAsync("google");
  };

  return (
    <div className="grid place-items-center h-screen overflow-auto  2xl:p-16 p-6 xl:gap-12 xl:p-12">
      <div className="max-w-md min-w-fit w-2/3 justify-items-center grid gap-6 bg-base-100 p-8 rounded-xl shadow-md">
        <div className="grid justify-items-center gap-2">
          <img
            src="https://api.dynt.ai/static/logo-192.png"
            alt="Dynt"
            className="w-20 rounded-lg"
          />
          <h3 className="sm:text-2xl text-2xl text-center font-bold ">
            Sign in to Dynt.ai
          </h3>
          <p className="sm:text-xs text-sm text-center">
            Start managing your finances with Dynt.ai
          </p>
        </div>
        <button onClick={handleOAuth} className="btn w-full btn-neutral my-4">
          <FcGoogle size={30} />
        </button>

        <div className="w-full flex items-center justify-between">
          <div className="flex items-center text-sm gap-2">
            <p>Don't have an account?</p>
            <Link
              className="link link-primary text-sm"
              to={"/register/sign-up"}
            >
              Sign up
            </Link>
          </div>
          <Link to={"/forgot-password"} className="link link-primary text-xs">
            Forgot Password
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
