import { ChartDataCustomTypesPerDataset, ChartOptions } from "chart.js";
import { format } from "date-fns/esm";
import { FC } from "react";
import { Bar } from "react-chartjs-2";

export const options: ChartOptions<"line"> = {
  responsive: true,
  maintainAspectRatio: false,

  scales: {
    x: { grid: { display: false }, stacked: true },
    y: {
      type: "linear",
      display: true,
      position: "left",
      grid: { color: "rgb(45,54,70)" },
    },
    y1: {
      ticks: {
        callback(tickValue) {
          return tickValue + "%";
        },
      },
      type: "linear",
      display: true,
      position: "right",
      grid: { display: false },
    },
  },
};

type Props = {
  data: {
    categorizationScore: number;
    merchantCoverage: number;
    totalTransactions: number;
    date: Date;
  }[];
};
const MerchantCoverage: FC<Props> = ({ data }) => {
  const barData: ChartDataCustomTypesPerDataset<"bar" | "line"> = {
    labels: data.map((m) => format(m.date, "dd/MMM")),

    datasets: [
      {
        label: "Merchant Coverage",
        data: data.map((d) => d.merchantCoverage),
        backgroundColor: "orange",
        type: "line",
        yAxisID: "y1",
        borderColor: "orange",
      },
      {
        xAxisID: "x",
        yAxisID: "y",
        label: "Merchant Transactions",
        data: data.map((d) => d.totalTransactions * (d.merchantCoverage / 100)),
        type: "bar",
        backgroundColor: "yellow",
      },
      {
        backgroundColor: "violet",
        label: "Transactions",
        data: data.map((d) => d.totalTransactions),
        type: "bar",
        yAxisID: "y",
        xAxisID: "x",
      },
    ],
  };

  return (
    <div className="flex-1 w-full">
      <span className="badge mb-6 badge-primary">Merchant Completion</span>
      <div className="w-full" style={{ height: "400px" }}>
        <Bar options={options as any} data={barData as any} />
      </div>
    </div>
  );
};

export default MerchantCoverage;
