import { useOrganization } from "@/context/OrganizationContext";
import { api } from "@/utils/trpc";
import { Agent } from "../agent/Agents";

const useAgents = () => {
  const utils = api.useUtils();
  const { organizationId } = useOrganization<true>();

  const update = (id: string, data: Partial<Agent>) => {
    utils.organizations.agent.list.setData(organizationId, (p = []) => {
      return p.map((agent) =>
        agent.id === id ? { ...agent, ...data } : agent
      );
    });
  };

  return update;
};

export default useAgents;
