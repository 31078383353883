import ThemeToggle from "@/components/ThemeToggle";
import { FC } from "react";
import { RiMenu4Fill, RiUser3Fill } from "react-icons/ri";
import { TbProgressHelp } from "react-icons/tb";
import { Link, useLocation } from "react-router-dom";
import Notifications from "./Notifications";

const Header: FC = () => {
  const { pathname } = useLocation();

  return (
    <header
      className={`items-center  flex justify-between py-2 sm:px-4 px-2 w-full`}
    >
      <label
        htmlFor="navbar"
        className="md:hidden btn btn-ghost btn-xs rounded-sm btn-square"
      >
        <RiMenu4Fill />
      </label>

      <div className="flex ml-auto items-center sm:gap-5 gap-3">
        <div className="hidden sm:block">
          <ThemeToggle />
        </div>
        {/* <button
          onClick={() =>
            window.Mirars.init({
              token: "8a2cd340-a535-49e2-81dd-dd3e8d3b7892",
              containerId: "mirars-survey-container",
              onSubmit: function () {
                console.log("Survey submitted successfully!");
              },
            })
          }
          className="btn btn-sm btn-ghost"
        >
          <TbProgressHelp size={22} />
        </button> */}
        <Notifications />
        <Link
          to={"/profile"}
          className={` ${pathname === "/profile" ? "link link-primary" : ""} `}
        >
          <RiUser3Fill size={22} />
        </Link>
      </div>
      <div id="mirars-survey-container" className="absolute"></div>
    </header>
  );
};

export default Header;
