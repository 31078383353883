import { useOrganization } from "@/context/OrganizationContext";
import { formatCurrency } from "@/utils/helper";
import { api } from "@/utils/trpc";
import { Dispatch, FC, SetStateAction } from "react";
import { AiFillApi, AiFillEdit } from "react-icons/ai";
import { HiChevronRight } from "react-icons/hi";

type Props = {
  setAccountInfo: Dispatch<SetStateAction<AccountInfo | null>>;
};

const tabs = [
  {
    label: "Connected Banks",
    name: "connectedBanks",
    icon: <AiFillApi />,
  },
  {
    label: "Manual Banks",
    name: "manualBanks",
    icon: <AiFillEdit />,
  },
] as const;

type Tab = (typeof tabs)[number]["name"];

export type AccountInfo = {
  name: string;
  currency: string;
  countryCode?: string;
  type?: string | null;
  bankAddress?: string | null;

  IBAN?: string | null;
  SWIFT?: string | null;
  ACCOUNT_NUMBER?: string | null;
  ROUTING_NUMBER?: string | null;
  SORT_CODE?: string | null;
  BBAN?: string | null;
};

const SelectOrganizationAccount: FC<Props> = ({ setAccountInfo }) => {
  const { organizationId } = useOrganization<true>();
  const { data: accounts = [], isLoading: banksLoading } =
    api.account.list.useQuery(organizationId);

  return (
    <div className="grid max-h-96 overflow-auto gap-4">
      {accounts.map((account) => {
        return (
          <button
            key={account.id}
            onClick={() => setAccountInfo(account)}
            className="btn bg-base-100 hover:bg-base-300 w-full justify-between text-sm"
          >
            <div className="flex items-center gap-4">
              <img src={account.bankLogo!} className="w-10  object-contain" />
              <div className="text-left">
                <p className="font-semibold">{account.name || "No Name"}</p>
                <p className="text-xs text-primary">
                  {account.IBAN || account.BBAN}
                </p>
              </div>
            </div>
            <div className="text-right items-center gap-3 flex">
              <div>
                <p className=" text-sm font-medium">
                  {formatCurrency(account.balance, account.currency)}
                </p>
              </div>

              <HiChevronRight size={22} className="text-base-content" />
            </div>
          </button>
        );
      })}
    </div>
  );
};

export default SelectOrganizationAccount;
