import { useUser } from "@/context/UserContext";
import { UpdateUser } from "@/types/validation";
import "react-phone-input-2/lib/style.css";

import FormInput from "@/components/FormInput";
import PhoneInput from "@/components/PhoneInput";
import SelectCountry from "@/components/SelectCountry";
import LoadingSpin from "@/ui/LoadingSpin";
import { PageTitle } from "@/ui/PageTitle";
import { api } from "@/utils/trpc";
import useForm from "@/utils/useForm";
import { DatePicker } from "@tremor/react";
import { format } from "date-fns/esm";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { AiOutlineLogout } from "react-icons/ai";
const fields: {
  name: keyof UpdateUser;
  placeholder: string;
  number?: boolean;
  numberString?: boolean | "phone";
  title: string;
}[] = [
  {
    name: "firstName",
    placeholder: "Enter first name",
    title: "First Name",
  },
  {
    name: "lastName",
    placeholder: "Enter last name",
    title: "Last Name",
  },

  {
    name: "address",
    placeholder: "Enter address",
    title: "Address",
  },
  {
    name: "city",
    placeholder: "Enter city",
    title: "City",
  },
  {
    name: "state",
    placeholder: "Enter state",
    title: "State",
  },
  {
    name: "postCode",
    placeholder: "Enter post code",
    title: "Post Code",
  },
  {
    name: "SSN",
    placeholder: "Enter SSN",
    title: "Social Security Number",
  },
];

const Profile = () => {
  const { userId, user, logout } = useUser<true>();

  const { handleChange, inputs, setInputs, errors, setErrors, setValue } =
    useForm(user!);

  useEffect(() => {
    setInputs(user!);
  }, [user]);

  const utils = api.useUtils();

  const update = api.users.profile.update.useMutation();

  const handleUpdate = async () => {
    const valid = UpdateUser.safeParse({
      ...inputs,
      dateOfBirth: inputs.dateOfBirth
        ? format(inputs.dateOfBirth, "yyyy-MM-dd")
        : null,
    });

    if (!valid.success) {
      return setErrors(valid.error.formErrors.fieldErrors);
    }

    const res = await update.mutateAsync({
      ...valid.data,
      name: `${valid.data.firstName} ${valid.data.lastName}`,
      id: userId,
    });

    toast.success("Profile updated successfully");
    utils.users.userById.setData(res.id, (p) => {
      if (!p) return null;
      return { ...p, ...res };
    });
  };

  if (!user) return null;

  return (
    <div>
      <PageTitle title="Profile" />
      <div className="w-fit grid gap-4">
        <div className="card bg-base-200 w-fit">
          <div className="card-body ">
            <p className="card-title">Basic Information</p>

            <div className="flex md:flex-row flex-col-reverse   justify-between w-full items-center gap-6">
              <div className="grid md:grid-cols-2 grid-cols-1  gap-4">
                {fields.slice(0, 2).map(({ name, placeholder, title }) => (
                  <FormInput
                    key={name}
                    placeholder={placeholder}
                    title={title}
                    value={inputs[name]}
                    onChange={handleChange(name)}
                    isError={errors[name]}
                  />
                ))}

                <PhoneInput
                  onChange={(e) => setValue("phone", e)}
                  value={inputs.phone}
                  isError={errors.phone}
                />
                <div className="">
                  <label className="label">
                    <span className="label-text">Email</span>
                  </label>
                  <input
                    type="text"
                    className="input input-disabled w-full shadow-sm input-bordered"
                    readOnly
                    value={user.email!}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card w-fit bg-base-200">
          <div className="card-body">
            <p className="card-title">Additional Information</p>

            <div className="flex md:flex-row flex-col-reverse   justify-between w-full items-center gap-6">
              <div className="grid md:grid-cols-2 grid-cols-1  gap-4">
                {fields.slice(2).map(({ name, placeholder, title }) => (
                  <FormInput
                    key={name}
                    placeholder={placeholder}
                    title={title}
                    value={inputs[name]}
                    onChange={handleChange(name)}
                    isError={errors[name]}
                  />
                ))}
                <div className="">
                  <label className="capitalize     text-sm font-semibold">
                    Date of Birth
                  </label>
                  <DatePicker
                    className="input_a px-0"
                    onValueChange={(e) => e && setValue("dateOfBirth", e)}
                    value={inputs.dateOfBirth!}
                    enableYearNavigation
                  />
                </div>
                <SelectCountry
                  onChange={(e) => setValue("countryId", +e)}
                  className="col-span-2"
                  value={inputs.countryId}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="card bg-base-200 w-fit">
          <div className="card-body ">
            <p className="card-title">Bank Account Details</p>

            <div className="flex md:flex-row flex-col justify-between w-full items-center gap-6">
              <FormInput
                placeholder="Enter Account Name"
                title="Account Name"
                value={inputs.accountName}
                onChange={handleChange("accountName")}
                isError={errors.accountName}
              />
              <FormInput
                placeholder="Enter IBAN"
                title="IBAN"
                value={inputs.IBAN}
                onChange={handleChange("IBAN")}
                isError={errors.IBAN}
              />
            </div>
            <FormInput
              placeholder="Enter Bank Address"
              title="Bank Address"
              value={inputs.bankAddress}
              onChange={handleChange("bankAddress")}
              isError={errors.bankAddress}
            />
          </div>
        </div>
        <div className="flex items-center gap-2 ">
          <button
            onClick={handleUpdate}
            className="btn btn-primary btn-sm flex-1"
          >
            Save
            <LoadingSpin loading={update.isLoading} />
          </button>
          <button onClick={logout} className="btn btn-outline btn-error btn-sm">
            <AiOutlineLogout />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Profile;
