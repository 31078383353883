import { downloadFile } from "@/utils/supabase";
import { useEffect, useState } from "react";
import { CiImageOff } from "react-icons/ci";

type Props = {
  path?: string | null;
  bucket?: string;
  className?: string;
};

const ImageFromPath = ({ bucket = "files", path, className = "" }: Props) => {
  const [url, setUrl] = useState(path);

  useEffect(() => {
    if (!path) return;
    (async () => {
      const data = await downloadFile(path, bucket, false);
      if (data) setUrl(URL.createObjectURL(data));
    })();
  }, [path, bucket]);

  return url ? (
    <img className={className} src={url} onError={(e) => setUrl(null)} />
  ) : (
    <CiImageOff size={32} className={className} />
  );
};

export default ImageFromPath;
