import Drawer from "@/components/drawer/Drawer";
import Spinner from "@/components/utils/spinner";
import { useOrganization } from "@/context/OrganizationContext";
import { useUser } from "@/context/UserContext";
import LoadingSpin from "@/ui/LoadingSpin";
import { PageTitle } from "@/ui/PageTitle";
import { api } from "@/utils/trpc";
import { useState } from "react";
import { HiPlus } from "react-icons/hi";
import { Link } from "react-router-dom";
import CreateCard from "./dpereateCard";

type Props = {};

const EmployeeCards = (props: Props) => {
  const { organizationId = "", organization } = useOrganization();
  const { userId = "" } = useUser();

  const { data: cards, isLoading } = api.stripe.cardsByUserId.useQuery(
    { userId, organizationId },
    { enabled: !!userId && !!organization?.stripeAccount }
  );
  const [creatingCard, setCreatingCard] = useState(false);
  const pay = api.stripe.payWithCard.useMutation();

  if (!organization?.stripeAccount?.id) {
    return (
      <div>
        <PageTitle title="Cards" />
        <p className="pb-2">
          This organization is not registered for card issuing
        </p>
      </div>
    );
  }

  const handlePay = (cardId: string) => async () => {
    if (!organization?.stripeAccount?.id) return;
    pay.mutate({
      accountId: organization.stripeAccount.id,
      cardId: cardId,
      amount: ~~(Math.random() * 100 + 20),
    });
  };

  return (
    <>
      <PageTitle title="Cards" />
      <Drawer
        content={
          <CreateCard
            {...{ setCreatingCard, accountId: organization.stripeAccount.id }}
          />
        }
        unMountOnClose
        title="Create Card"
        isOpen={creatingCard}
        onClose={setCreatingCard}
      />
      <div className="">
        <button
          onClick={() => setCreatingCard(true)}
          className="btn mb-10 ml-auto bg-primary btn"
        >
          <HiPlus /> Card
        </button>

        <div className="flex mb-4 items-center ">
          <div className="grid grid-cols-4 flex-1">
            <p>Brand</p>
            <p>Type</p>
            <p>Last 4</p>
            <p>Cardholder</p>
          </div>
          <p className="text-center">Pay</p>
        </div>

        {isLoading && <Spinner />}

        <div className="mt-6 grid gap-3">
          {cards?.data.map((card) => (
            <div key={card.id} className="flex items-center  border-b pb-2">
              <Link
                to={`/cards/${card.id}`}
                className="grid grid-cols-4 flex-1"
              >
                <p>{card.brand}</p>
                <p>{card.type}</p>
                <p>{card.last4}</p>
                <p>{card.cardholder.name}</p>
              </Link>
              <button
                className="btn w-fit px-4 mx-auto relative"
                onClick={handlePay(card.id)}
              >
                <LoadingSpin loading={pay.isLoading} />
                Pay
              </button>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default EmployeeCards;
