import Drawer from "@/components/drawer/Drawer";
import NotFound from "@/components/utils/notfound";
import Spinner from "@/components/utils/spinner";
import { useOrganization } from "@/context/OrganizationContext";
import { useUser } from "@/context/UserContext";
import { accountingStatuses } from "@/lib";
import LoadingSpin from "@/ui/LoadingSpin";
import Pagination from "@/ui/Pagination";
import { formatCurrency, formatLabel, isEditableExpense } from "@/utils/helper";
import { RouterInputs, api } from "@/utils/trpc";
import { Dispatch, FC, SetStateAction, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { DatePeriod } from "../../components/PaymentsWrapper";
import PaymentHandler from "../bills/PaymentHandler";
import SelectExpenseFilters from "./ExpenseFilters";
import ExpenseHeaders, { Sorting } from "./ExpenseHeaders";
import ExpensesListing from "./ExpenseListing";

type Props = {
  showFilters: boolean;
  setShowFilters: Dispatch<SetStateAction<boolean>>;
  setSelection: Dispatch<SetStateAction<Record<string, any>>>;
  selection: Record<string, any>;
  period: DatePeriod | null;
  showAccountingOverview?: boolean;
};

export type ExpenseFilter =
  RouterInputs["expenses"]["expensesByOrgId"]["filters"];

const OrgExpenses: FC<Props> = ({
  setShowFilters,
  showFilters,
  selection,
  setSelection,
  period,
  showAccountingOverview,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const { organizationId = "", organization } = useOrganization();
  const { userId = "" } = useUser();

  const [filters, setFilters] = useState<ExpenseFilter>(null);
  const selectAll = api.expenses.selectAllExpenses.useMutation();
  const deleteExpenses = api.expenses.deleteExpenses.useMutation();
  const utils = api.useUtils();

  const selectedArray: string[] = useMemo(
    () => Object.values(selection).map((a) => a.id),
    [selection]
  );

  const [sorting, setSorting] = useState<Sorting>({
    createdAt: "desc",
  });

  const params = {
    organizationId,
    currentPage,
    filters,
    sorting,
    userId,
    period,
  };

  const { data = [], isLoading } = api.expenses.expensesByOrgId.useQuery(
    params,
    { enabled: !!organizationId }
  );

  const { data: filtersData } = api.expenses.filtersData.useQuery(
    {
      organizationId,
      filters,
      period,
      userId,
      groupBy: showAccountingOverview ? "accountingStatus" : "status",
    },
    { enabled: !!organizationId }
  );

  const total = filtersData?.total ?? 0;

  const handleSelectAll = async () => {
    if (allSelected) {
      setSelection({});
      return;
    }
    const res = await selectAll.mutateAsync({
      organizationId,
      filters,
      sorting,
      period,
    });

    setSelection(res.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {}));
  };

  const fullListSelected = data.length
    ? selectedArray.length === data.length
    : false;

  const hasMoreToSelect = fullListSelected && total > selectedArray.length;

  const allSelected = total > 0 && total === selectedArray.length;

  const handleBulkDelete = async () => {
    const expenses: typeof data = Object.values(selection);

    const _confirm = confirm(
      `Are you sure you want to delete ${expenses.length} expenses?`
    );

    if (!_confirm) return;

    if (expenses.some((i) => !i.status || !isEditableExpense(i.status))) {
      toast.error(
        "You can only delete expenses that are in draft or rejected status"
      );
      return;
    }

    await deleteExpenses.mutateAsync(selectedArray);
    setSelection({});
    await utils.expenses.expensesByOrgId.invalidate();
  };

  const statusFilters = useMemo(() => {
    const expenseStatus = [
      "reimbursed",
      "sent",
      "pending",
      "processing",
    ] as const;
    return expenseStatus
      .map((s) => ({
        status: s,
        totalAmount: 0,
        totalCount: 0,
        ...filtersData?.statusData[s],
      }))
      .sort((a, b) => b.totalCount - a.totalCount);
  }, [filtersData?.statusData]);

  const accountingStatusFilters = useMemo(() => {
    return accountingStatuses
      .map((s) => ({
        status: s,
        totalAmount: 0,
        totalCount: 0,
        ...filtersData?.statusData[s],
      }))
      .sort((a, b) => b.totalCount - a.totalCount);
  }, [filtersData?.statusData]);

  return (
    <div className="overflow-auto flex-1 h-full flex flex-col">
      <Drawer
        isOpen={showFilters}
        onClose={setShowFilters}
        title={"Filters"}
        content={
          <SelectExpenseFilters
            {...{
              ranges: filtersData?.ranges ?? { max: 0, min: 0 },
              setFilters,
              setShowFilters,
              showFilters,
              unCategorized: filtersData?.unCategorized ?? 0,
            }}
          />
        }
      />

      {!showAccountingOverview ? (
        <div className="lg:flex hidden gap-2 items-center my-4">
          <button
            onClick={() =>
              setFilters((p) => (p ? { ...p, statuses: [] } : { statuses: [] }))
            }
            className={`btn btn-outline ${
              filters?.statuses?.length ? "" : "btn-primary"
            } `}
          >
            <p>All</p>
            <p>{filtersData?.total}</p>
          </button>
          {statusFilters.map((s) => (
            <button
              onClick={() =>
                setFilters((p) =>
                  p ? { ...p, statuses: [s.status] } : { statuses: [s.status] }
                )
              }
              className={`btn btn-outline btn-ghost h-full ${
                !filters?.statuses?.includes(s.status) ? "" : "btn-primary "
              } `}
            >
              <p className="capitalize flex items-center gap-2">
                {s.status}
                <span className="text-xs">
                  (
                  {formatCurrency(s.totalAmount, organization?.defaultCurrency)}
                  )
                </span>
              </p>
              <p>{s.totalCount}</p>
            </button>
          ))}
        </div>
      ) : (
        <div className="lg:flex hidden gap-2 items-center my-4">
          <button
            onClick={() =>
              setFilters((p) =>
                p
                  ? { ...p, accountingStatus: null }
                  : { accountingStatus: null }
              )
            }
            className={`btn btn-outline ${
              filters?.accountingStatus ? "" : "btn-primary"
            } `}
          >
            <p>All</p>
            <p>{filtersData?.total}</p>
          </button>
          {accountingStatusFilters.map((s) => (
            <button
              onClick={() =>
                setFilters((p) =>
                  p
                    ? { ...p, accountingStatus: s.status }
                    : { accountingStatus: s.status }
                )
              }
              className={`btn btn-outline btn-ghost h-full ${
                filters?.accountingStatus !== s.status ? "" : "btn-primary "
              } `}
            >
              <p className="capitalize flex items-center gap-2">
                {formatLabel(s.status)}
                <span className="text-xs">
                  (
                  {formatCurrency(s.totalAmount, organization?.defaultCurrency)}
                  )
                </span>
              </p>
              <p>{s.totalCount}</p>
            </button>
          ))}
        </div>
      )}
      <div className="flex items-center gap-4 h-12">
        {!allSelected && (
          <button
            className={`btn-secondary btn-sm text-xs btn-outline btn ${
              hasMoreToSelect || allSelected ? "" : "hidden"
            } `}
            onClick={handleSelectAll}
          >
            <LoadingSpin loading={selectAll.isLoading} />
            <p>Select all {total} expenses</p>
          </button>
        )}

        <button
          className={`btn btn-sm text-xs btn-error btn-outline  ${
            selectedArray.length ? "" : "hidden"
          } `}
          onClick={handleBulkDelete}
        >
          <LoadingSpin
            loading={deleteExpenses.isLoading || selectAll.isLoading}
          />

          <p>Delete all {selectedArray.length} expenses</p>
        </button>

        {!!selectedArray.length && (
          <PaymentHandler
            expenses={selectedArray}
            className="btn btn-sm btn-primary text-xs"
            text={`all expenses (${selectedArray.length})`}
          />
        )}
      </div>

      <div className="overflow-x-auto overflow-auto h-full">
        <table className="table bg-base-200">
          <ExpenseHeaders
            {...{
              setSorting,
              sorting,
              data: data,
              setSelection,
              showAccountingOverview,
              selectedArray,
            }}
          />

          <ExpensesListing
            {...{
              list: data,
              isLoading,
              selection,
              setSelection,
              totalSelected: selectedArray.length,
              params,
              showAccountingOverview,
            }}
          />
        </table>
        {isLoading && <Spinner />}
        {!isLoading && !data.length && <NotFound title="expenses" />}
        <Pagination {...{ currentPage, setCurrentPage, total: total }} />
      </div>
    </div>
  );
};

export default OrgExpenses;
