import Drawer from "@/components/drawer/Drawer";
import FormInput from "@/components/FormInput";
import { useOrganization } from "@/context/OrganizationContext";
import { TaxCode } from "@/types/validation";
import LoadingSpin from "@/ui/LoadingSpin";
import { api } from "@/utils/trpc";
import useForm from "@/utils/useForm";
import { useNavigate, useSearchParams } from "react-router-dom";

const CreateTaxCode = () => {
  const { organizationId = "" } = useOrganization();
  const { handleChange, setValue, setErrors, errors, inputs } = useForm<
    Partial<TaxCode>
  >({});

  const create = api.organizations.taxCode.create.useMutation();

  const [params] = useSearchParams();
  const nav = useNavigate();
  const isOpen = params.has("new");

  const utils = api.useUtils();

  const handleCreate = async () => {
    const valid = await TaxCode.safeParseAsync(inputs);

    if (!valid.success) {
      return setErrors(valid.error.formErrors.fieldErrors);
    }
    const { name, rate, code } = valid.data;
    const res = await create.mutateAsync({
      name,
      rate: +rate,
      code,
      organizationId,
    });

    utils.organizations.taxCode.list.setData(organizationId, (p = []) => {
      return [res, ...p];
    });
    nav("/organization/tax-code");
  };
  return (
    <Drawer
      isOpen={isOpen}
      onClose={() => nav("/organization/tax-code")}
      title="Create Tax Code"
      className="grid gap-4"
    >
      <div className="flex items-center gap-2">
        <FormInput
          className="flex-[2]"
          placeholder="Tax Name"
          title="Name"
          value={inputs.name}
          onChange={handleChange("name")}
          isError={errors.name}
        />
        <div className="items-end flex-1">
          <div>
            <p className="divider divider-start">Rate</p>
            <div className="join">
              <input
                type="text"
                placeholder="Tax Rate"
                value={inputs.rate}
                onChange={(e) => {
                  const value = e.target.value;
                  if (!isNaN(Number(value))) setValue("rate", value);
                }}
                className={`input join-item w-full shadow-sm  ${
                  errors.rate ? "input-error" : "input-bordered"
                }`}
              />
              <span className="join-item bg-base-300 flex items-center  px-1">
                %
              </span>
            </div>
          </div>
        </div>
      </div>

      <FormInput
        placeholder="Tax Code"
        title="Code"
        value={inputs.code}
        onChange={handleChange("code")}
      />

      <button className="btn mt-4 btn-primary btn-sm" onClick={handleCreate}>
        <LoadingSpin loading={create.isLoading} />
        Save
      </button>
    </Drawer>
  );
};

export default CreateTaxCode;
