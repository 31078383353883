import formatDistance from "date-fns/formatDistance";
import { FC } from "react";
import { Merchant } from "./Merchants";

type Props = {
  merchant: Merchant;
};

const MerchantRow: FC<Props> = ({ merchant }) => {
  return (
    <tr key={merchant.id}>
      <td>{merchant.name}</td>
      <td>
        {formatDistance(merchant.createdAt, new Date(), { addSuffix: true })}
      </td>
      <td>{merchant.rutterVendor?.name || "-"}</td>
      <td>{merchant.code || "-"}</td>
    </tr>
  );
};

export default MerchantRow;
