import Drawer from "@/components/drawer/Drawer";
import FormInput from "@/components/FormInput";
import { useOrganization } from "@/context/OrganizationContext";
import { TaxCode as Validate } from "@/types/validation";
import LoadingSpin from "@/ui/LoadingSpin";
import { api } from "@/utils/trpc";
import useForm from "@/utils/useForm";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TaxCodeType } from "./TaxCodeList";

type Props = {
  taxCode: TaxCodeType;
};

const UpdateTaxCode: FC<Props> = ({ taxCode }) => {
  const { organizationId = "" } = useOrganization();
  const { handleChange, setValue, setErrors, errors, inputs } = useForm<
    Partial<Validate>
  >({ ...taxCode, rate: taxCode.rate.toString() });

  const update = api.organizations.taxCode.update.useMutation();

  const { id } = useParams<{ id?: string }>();

  const nav = useNavigate();

  const utils = api.useUtils();

  const handleUpdate = async () => {
    const valid = await Validate.safeParseAsync(inputs);

    if (!valid.success) {
      return setErrors(valid.error.formErrors.fieldErrors);
    }
    const { name, rate, code } = valid.data;
    const res = await update.mutateAsync({
      name,
      rate: +rate,
      code,
      id: taxCode.id,
    });

    utils.organizations.taxCode.list.setData(organizationId, (p = []) => {
      return p.map((i) => (i.id === taxCode.id ? res : i));
    });
    nav("/organization/tax-code");
  };
  return (
    <Drawer
      isOpen={taxCode.id === id}
      onClose={() => nav("/organization/tax-code")}
      title="Create Tax Code"
      className="grid gap-4"
    >
      <FormInput
        placeholder="Tax Name"
        title="Name"
        value={inputs.name}
        onChange={handleChange("name")}
        isError={errors.name}
      />
      <FormInput
        placeholder="Tax Rate"
        title="Rate"
        value={inputs.rate}
        onChange={(e) => {
          const value = e.target.value;
          if (!isNaN(Number(value))) setValue("rate", value);
        }}
        isError={errors.rate}
      />
      <FormInput
        placeholder="Tax Code"
        title="Code"
        value={inputs.code}
        onChange={handleChange("code")}
      />

      <button className="btn mt-4 btn-primary btn-sm" onClick={handleUpdate}>
        <LoadingSpin loading={update.isLoading} />
        Save
      </button>
    </Drawer>
  );
};

export default UpdateTaxCode;
