import { FC } from "react";

type Props = {
  checked: boolean;
  onChange: (a: boolean) => void;
  className?: string;
};

const Checkbox: FC<Props> = ({ checked, onChange, className = "" }) => {
  return (
    <label onClick={(e) => e.stopPropagation()}>
      <input
        type="checkbox"
        checked={checked}
        className={`checkbox rounded-md checkbox-sm checkbox-primary ${className} `}
        onChange={(e) => onChange(e.target.checked)}
      />
    </label>
  );
};

export default Checkbox;
