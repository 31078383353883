import { useOrganization } from "@/context/OrganizationContext";
import Rows from "@/ui/skeletons/Rows";
import { api, RouterOutputs } from "@/utils/trpc";
import { HiPlusCircle } from "react-icons/hi";
import { Link } from "react-router-dom";
import GoCardlessBankRow from "./BankRow";
import ConnectGocardless from "./Connect";

type Props = {};

export type GocardlessBank =
  RouterOutputs["gocardless"]["bank"]["list"][number];

const GocardlessAccount = (props: Props) => {
  const { organizationId } = useOrganization<true>();
  const { data = [], isLoading } =
    api.gocardless.bank.list.useQuery(organizationId);

  return (
    <div className="grid gap-6">
      <div className="flex justify-end">
        <Link to={"?new"} className="btn ml-auto btn-secondary btn-sm">
          <HiPlusCircle size={22} /> New
        </Link>
      </div>

      <Rows isLoading={isLoading} />

      <div className="grid gap-2">
        {data.map((bank) => (
          <GoCardlessBankRow key={bank.id} bank={bank} />
        ))}
      </div>

      <ConnectGocardless />
    </div>
  );
};

export default GocardlessAccount;
