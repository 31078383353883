import Select from "@/components/Select";
import { useOrganization } from "@/context/OrganizationContext";
import { useUser } from "@/context/UserContext";
import { CreateCard, CreateCardType } from "@/types/validation";
import LoadingSpin from "@/ui/LoadingSpin";
import { api } from "@/utils/trpc";
import useForm from "@/utils/useForm";
import { FC, useMemo } from "react";
import { toast } from "react-hot-toast";

type Props = {
  setCreatingCard: (v: boolean) => void;
  accountId: string;
};

const cardTypes: CreateCardType["type"][] = ["virtual", "physical"];

const CardDetails: FC<Props> = ({ setCreatingCard, accountId }) => {
  const { organizationId = "", organization } = useOrganization();
  const { userId = "" } = useUser();
  const { data: members = [], isLoading } =
    api.organizations.teamMembers.useQuery(organizationId);
  const { inputs, setValue, errors, setErrors } = useForm<
    Partial<CreateCardType>
  >({});

  const add = api.stripe.createCard.useMutation({
    onError: (e) => toast.error(e.message),
  });

  const utils = api.useUtils();

  const handleAdd = async () => {
    const value = CreateCard.safeParse(inputs);

    if (!value.success) {
      setErrors(value.error.flatten().fieldErrors);
      return;
    }

    const card = await add.mutateAsync(value.data);

    utils.stripe.cardsByAccountId.setData(accountId, (p) => {
      if (!p) return;
      return [card, ...p];
    });
    setCreatingCard(false);
  };

  const membersList = useMemo(() => {
    if (!organization?.role || !userId) return [];
    return members.filter(
      (s) => organization.role === "admin" || s.user.id === userId
    );
  }, [members.length, organization?.role, userId]);

  return (
    <div className="grid gap-2">
      <div className="z-30">
        <p className="label label-text">Team member</p>
        <Select
          {...{
            onChange: (e) => setValue("team_member_id", e),
            value: inputs.team_member_id,
            placeholder: isLoading ? "Loading..." : "Select a member",
            searchable: true,
            options: membersList.map((member) => ({
              value: member.id,
              label: member.user.name,
            })),
            isError: !!errors.team_member_id,
          }}
        />
      </div>
      <div className="z-20">
        <p className="label label-text">Type</p>

        <Select
          {...{
            onChange: (e) => setValue("type", e as CreateCardType["type"]),
            value: inputs.type,
            placeholder: "Select a card type",
            searchable: true,
            options: cardTypes.map((type) => ({
              value: type,
              label: type,
            })),
            isError: !!errors.type,
          }}
        />
      </div>

      <div className="z-10">
        <p className="label label-text">Currency</p>

        <Select
          {...{
            onChange: (e) => setValue("currency", e),
            value: inputs.currency,
            placeholder: "Select a currency",
            searchable: true,
            options: ["EUR"].map((currency) => ({
              value: currency,
              label: currency,
            })),
            isError: !!errors.currency,
          }}
        />
      </div>

      <button className="btn btn-primary mt-4" onClick={handleAdd}>
        Add
        <LoadingSpin loading={add.isLoading} />
      </button>
    </div>
  );
};

export default CardDetails;
