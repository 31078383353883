import { Organization } from "@/context/OrganizationContext";
import Rows from "@/ui/skeletons/Rows";
import { RouterOutputs, api } from "@/utils/trpc";
import { FC } from "react";
import toast from "react-hot-toast";
import { HiPlusCircle } from "react-icons/hi";
import { RiRefreshLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import InviteTeam from "./InviteTeam";
import PendingMembers from "./PendingMembers";
import TeamMember from "./TeamMember";

type Props = {
  organization: Organization;
};

export type TeamMember = RouterOutputs["organizations"]["teamMembers"][number];

const TeamsListing: FC<Props> = ({ organization }) => {
  const {
    data = [],
    isLoading,
    refetch,
    isRefetching,
  } = api.organizations.team_member.list.useQuery(organization.id);

  if (isLoading) return <Rows />;

  const handleRefetch = async () => {
    await refetch();
    toast.success("Refetch successful!");
  };

  return (
    <div className="grid gap-6">
      <InviteTeam />
      <div className="flex w-full items-center justify-between">
        <PendingMembers />
        <div className="flex items-center w-fit justify-self-end ml-auto gap-2">
          <button
            className="btn btn-secondary btn-outline btn-sm"
            onClick={handleRefetch}
          >
            <RiRefreshLine
              size={20}
              className={isRefetching ? "animate-spin" : ""}
            />
          </button>
          <Link to={"?new"} className="btn ml-auto btn-secondary btn-sm">
            <HiPlusCircle size={22} /> New
          </Link>
        </div>
      </div>

      <table className="table bg-base-200">
        <thead>
          <tr>
            <th className="text-left">Name</th>
            <th>Phone</th>
            <th>Role</th>
            <th>Member since</th>
            <th className="text-center">Status</th>
          </tr>
        </thead>

        <tbody>
          {data
            .sort((a, b) => (a.isActive && !b.isActive ? -1 : 1))
            .map((t) => (
              <TeamMember key={t.id} member={t} />
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default TeamsListing;
