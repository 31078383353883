import LoadingSpin from "@/ui/LoadingSpin";
import { api } from "@/utils/trpc";
import { FC } from "react";
import toast from "react-hot-toast";
import { MdDelete } from "react-icons/md";
import { VscDebugDisconnect } from "react-icons/vsc";
import AccountRow from "./AccountRow";
import { GocardlessBank } from "./Banks";

type Props = {
  bank: GocardlessBank;
};

const GoCardlessBankRow: FC<Props> = ({ bank }) => {
  const sync = api.gocardless.bank.sync.useMutation();
  const reauthorize = api.gocardless.bank.reauthorize.useMutation();
  const _delete = api.gocardless.bank.delete.useMutation();

  const handleSync = async (id: string) => {
    await sync.mutateAsync(id);
    toast.success("Bank synchronized successfully");
  };

  const handleReauthorize = async () => {
    const res = await reauthorize.mutateAsync(bank.id);
    window.location.replace(res.link);
  };

  return (
    <div key={bank.id} className="card bg-base-200">
      <div className="card-body justify-between flex-row items-center">
        <div className="flex items-start gap-4">
          <img src={bank.logo} alt={bank.name} className="w-12 h-12 mr-4" />
          <div>
            <h2 className="text-lg font-semibold">{bank.name}</h2>
            <p className="text-sm text-gray-500">{bank.SWIFT}</p>
          </div>
          <button
            onClick={handleReauthorize}
            className="btn gap-0 hover:gap-2 overflow-hidden btn-outline btn-sm group btn-accent"
          >
            <VscDebugDisconnect />{" "}
            <p className="max-w-0 opacity-0 group-hover:opacity-100 transition-all group-hover:max-w-20">
              Reauthorize <LoadingSpin loading={reauthorize.isLoading} />
            </p>
          </button>
        </div>
        <div className="join">
          <button
            className="btn btn-sm btn-primary join-item text-xs"
            onClick={() => handleSync(bank.id)}
          >
            <LoadingSpin loading={sync.isLoading} />
            <span className="">
              <span className="font-bold">Synchronize</span>
            </span>
          </button>
          <button
            onClick={() => confirm("Confirm delete") && _delete.mutate(bank.id)}
            className="join-item btn-outline btn btn-error btn-sm"
          >
            <LoadingSpin loading={_delete.isLoading} />
            <MdDelete />
          </button>
        </div>
      </div>
      <div className="card-body">
        <table className="table">
          <tbody>
            {bank.accounts.map((a) => (
              <AccountRow key={a.id} account={a} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GoCardlessBankRow;
