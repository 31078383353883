import { useOrganization } from "@/context/OrganizationContext";
import { api } from "@/utils/trpc";
import { useAtomValue } from "jotai";
import { filterAtom } from "../store/filters";
import { TransactionHistory } from "../Transactions";

const useTransactions = () => {
  const utils = api.useUtils();
  const { organizationId } = useOrganization<true>();
  const filters = useAtomValue(filterAtom);

  const update = (
    id: string,
    data: Partial<TransactionHistory>,
    _delete = false
  ) => {
    utils.transaction.list.setInfiniteData(
      { organizationId, ...filters },
      (p) => {
        if (!p) return p;
        return {
          ...p,
          pages: p.pages.map((page) => ({
            ...page,
            data: _delete
              ? page.data.filter((t) => t.id !== id)
              : page.data.map((t) => {
                  if (t.id !== id) return t;
                  return { ...t, ...data };
                }),
          })),
        };
      }
    );
  };

  return update;
};

export default useTransactions;
