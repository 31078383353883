import { countries } from "@/lib/countries";
import { FC } from "react";
import Select from "./Select";

type Props = {
  onChange: (id: string) => void;
  value?: string | number | null;
  className?: string;
  error?: boolean;
};

const SelectCountry: FC<Props> = ({ onChange, value, className, error }) => {
  return (
    <div>
      <label className="label label-text">Country</label>

      <Select
        {...{
          onChange,
          options: countries.map((c) => ({
            label: c.name,
            value: c.id.toString(),
          })),
          value: value?.toString(),
          isError: error,
          placeholder: "Select Country",
          searchable: true,
        }}
      />
    </div>
  );
};

export default SelectCountry;
