import React from "react";

import Rows from "@/ui/skeletons/Rows";
import { api, RouterOutputs } from "@/utils/trpc";
import toast from "react-hot-toast";
import { HiPlusCircle } from "react-icons/hi";
import { RiRefreshLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { useOrganization } from "../../../context/OrganizationContext";
import CreateVendor from "./CreateVendor";
import VendorRow from "./VendorRow";

export type Vendor = RouterOutputs["organizations"]["vendor"]["list"][number];

const Vendors: React.FC = () => {
  const { organizationId } = useOrganization<true>();

  const {
    data = [],
    isLoading,
    refetch,
    isRefetching,
  } = api.organizations.vendor.list.useQuery(organizationId);

  const handleRefetch = async () => {
    await refetch();
    toast.success("Refetch successful!");
  };

  return (
    <div className="grid gap-6">
      <div className="flex items-center w-fit justify-self-end gap-2">
        <button
          className="btn btn-secondary btn-outline btn-sm"
          onClick={handleRefetch}
        >
          <RiRefreshLine
            size={20}
            className={isRefetching ? "animate-spin" : ""}
          />
        </button>
        <Link to={"?new"} className="btn btn-secondary btn-sm">
          <HiPlusCircle /> New
        </Link>
      </div>

      <table className="table bg-base-200">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Created at</th>
          </tr>
        </thead>
        <tbody>
          {data.map((v) => (
            <VendorRow key={v.id} vendor={v} />
          ))}
        </tbody>
      </table>
      <Rows isLoading={isLoading} />
      <CreateVendor />
    </div>
  );
};

export default Vendors;
