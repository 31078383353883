import { formatCurrency } from "@/utils/helper";
import { FC } from "react";

type Props = {
  rows: {
    id: string;
    description: string | null;
    quantity: number;
    amount: number;
  }[];
  currency: string;
};

const LineItems: FC<Props> = ({ rows, currency }) => {
  return (
    <div className="overflow-x-auto w-full">
      <table className="table">
        <thead className="text-base-content bg-neutral border border-neutral">
          <tr>
            <th className="w-full">Description</th>
            <th className="text-center">Quantity</th>
            <th className="text-center">Currency</th>
            <th className="text-center">Amount</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((r, i) => (
            <tr key={r.id}>
              <td>{r.description}</td>
              <td className="text-center">{r.quantity}</td>
              <td className="text-center">{currency}</td>
              <td className="text-center">
                {formatCurrency(r.amount, currency)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LineItems;
