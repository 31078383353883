import { FC, ReactNode } from "react";
import { Route } from "react-router-dom";
import { SentryRouterType } from "./main";
import CreateRule from "./pages/Automation/CreateRule";
import Rules from "./pages/Automation/Rules";
import Accounts from "./pages/accounts/Accounts";
import BillPage from "./pages/bills/BillPage";
import CreateBill from "./pages/bills/CreateBill";
import UpdateBill from "./pages/bills/updateBill";
import CardPage from "./pages/cards/CardPage";
import EmployeeCards from "./pages/cards/EmployeeCards";
import AdminDashboard from "./pages/dashboard/AdminDashboard";
import EmployeeDashboard from "./pages/dashboard/EmployeeDashboard";
import CreateExpense from "./pages/expense/CreateExpense";
import ExpensePage from "./pages/expense/ExpensePage";
import UpdateExpense from "./pages/expense/UpdateExpense";
import InvoicePage from "./pages/invoices/InvoicePage";
import CreateInvoice from "./pages/invoices/createInvoice";
import UpdateInvoice from "./pages/invoices/updateInvoice";
import NotFound from "./pages/notfound/notfound";
import CompanyDetails from "./pages/onboarding/CompanyDetails";
import ForgotPassword from "./pages/onboarding/ForgotPassword";
import InviteTeam from "./pages/onboarding/InviteTeam";

import PaymentsWrapper from "./components/PaymentsWrapper";
import UpdateRule from "./pages/Automation/UpdateRule";
import Integration from "./pages/Integration/Integration";
import Payroll from "./pages/Payroll";
import Accounting from "./pages/accounting/Accounting";
import AccountPage from "./pages/accounts/AccountPage";
import ConnectedBank from "./pages/bank/ConnectedBank";
import Files from "./pages/documents/Files";
import Invitation from "./pages/onboarding/Invitation";
import Login from "./pages/onboarding/Login";
import PendingApproval from "./pages/onboarding/PendingApproval";
import PersonalDetails from "./pages/onboarding/PersonalDetails";
import ResetPassword from "./pages/onboarding/ResetPassword";
import SignUp from "./pages/onboarding/Signup";
import CreateOrg from "./pages/organization/CreateOrg";
import Organization from "./pages/organization/Organization";
import Partners from "./pages/partners/Partners";
import EmployeePayments from "./pages/payments/EmployeePayments";
import Create from "./pages/payments/create";
import People from "./pages/people/people";
import Profile from "./pages/profile/UserProfile";
import Reports from "./pages/reports/Reports";
import Cards_EU from "./pages/swan/Cards_EU";
import Transfer from "./pages/swan/Transfer";
import Tasks from "./pages/tasks/Tasks";
import Transactions from "./pages/transactions/Transactions";
import Users from "./pages/users/users";
import PageWrapper from "./ui/PageWrapper";

interface RouteConfig {
  path: string;
  component: ReactNode;
  title?: string;
}

const routesConfig: RouteConfig[] = [
  { path: "/login", component: <Login /> },
  { path: "/register", component: <PersonalDetails /> },
  { path: "/register/sign-up", component: <SignUp /> },
  { path: "/register/personal-details", component: <PersonalDetails /> },
  { path: "/register/company-details", component: <CompanyDetails /> },
  { path: "/register/invite", component: <InviteTeam /> },
  { path: "/pending-approval", component: <PendingApproval /> },

  { path: "/invitation/:inviteId", component: <Invitation /> },

  { path: "/documents", component: <Files /> },

  { path: "/reset-password", component: <ResetPassword /> },
  { path: "/forgot-password", component: <ForgotPassword /> },
  { path: "/", component: <AdminDashboard />, title: "Dashboard" },
  { path: "/employee", component: <EmployeeDashboard /> },

  { path: "/automation", component: <Rules /> },
  { path: "/integration", component: <Integration /> },
  { path: "/tasks", component: <Tasks /> },
  { path: "/rules/create", component: <CreateRule /> },
  { path: "/rules/:id", component: <UpdateRule /> },

  { path: "/users", component: <Users /> },
  { path: "/partners/:tab/:id?", component: <Partners /> },
  { path: "/reports/:tab", component: <Reports /> },
  { path: "/accounting/:tab/:id?", component: <Accounting /> },

  { path: "/payments/create", component: <Create /> },
  { path: "/people", component: <People /> },

  { path: "/accounts/:tab?", component: <Accounts /> },
  { path: "/account/:id", component: <AccountPage />, title: "Account" },
  // { path: "/accounts/connect", component: <Connect /> },
  // { path: "/accounts/connect/auth", component: <Authorize /> },
  // { path: "/accounts/connect/re-auth", component: <ReAuthorize /> },

  { path: "/profile", component: <Profile /> },

  // { path: "/organization", component: <Organization /> },
  { path: "/organizations/create", component: <CreateOrg /> },

  {
    path: "/transactions/:id?",
    title: "Transactions",
    component: <Transactions />,
  },

  {
    path: "/bills",
    component: <PaymentsWrapper tab="bills" />,
    title: "Bills",
  },
  { path: "/bills/create", component: <CreateBill /> },
  { path: "/bills/update/:billId", component: <UpdateBill /> },
  { path: "/bill/:billId", component: <BillPage /> },

  {
    path: "/expenses",
    title: "Expenses",
    component: <PaymentsWrapper tab="expenses" />,
  },
  { path: "/expenses/create", component: <CreateExpense /> },
  { path: "/expenses/update/:expenseId", component: <UpdateExpense /> },
  { path: "/expense/:expenseId", component: <ExpensePage /> },

  { path: "/employee/expenses", component: <EmployeePayments /> },

  { path: "/payroll", component: <Payroll /> },

  {
    path: "/invoices",
    component: <PaymentsWrapper tab="invoices" />,
    title: "Invoices",
  },
  { path: "/invoices/create", component: <CreateInvoice /> },
  { path: "/invoices/update/:invoiceId", component: <UpdateInvoice /> },
  { path: "/invoice/:invoiceId", component: <InvoicePage /> },

  { path: "/connected-banks/:id", component: <ConnectedBank /> },

  {
    path: "/pro-account/:tab?/:id?/:subTab?/:subId?",
    component: <Cards_EU />,
    title: "Cards",
  },
  {
    path: "/organization/:tab?/:id?",
    component: <Organization />,
    title: "Organization",
  },
  { path: "/employee/cards", component: <EmployeeCards /> },
  { path: "/cards/:id", component: <CardPage /> },

  { path: "/pay", component: <Transfer />, title: "Make Payments" },

  { path: "*", component: <NotFound /> },
];

type Props = {
  SentryRoutes: SentryRouterType;
};
const AppRouter: FC<Props> = ({ SentryRoutes }) => {
  return (
    <SentryRoutes>
      {routesConfig.map(({ path, component, title }) => (
        <Route
          key={path}
          path={path}
          element={<PageWrapper page={component} title={title} />}
        />
      ))}
    </SentryRoutes>
  );
};

export default AppRouter;
