import { FC } from "react";

type Props = {
  count?: number;
  className?: string;
  isLoading?: boolean;
};

const Rows: FC<Props> = ({ count = 10, className = "", isLoading = true }) => {
  if (!isLoading) return null;
  return (
    <div className={`flex w-full p-2  rounded-lg flex-col gap-4 ${className} `}>
      {Array.from({ length: count }, (_, i) => i).map((i) => (
        <span key={i} className="skeleton rounded-lg w-full h-8" />
      ))}
    </div>
  );
};

export default Rows;
