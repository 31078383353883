import { formatLabel } from "@/utils/helper";
import { formatDistance } from "date-fns";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { type FinancialAccount as FinancialAccountRow } from "./FinancialAccountList";
import UpdateFinancialAccounts from "./UpdateFinancialAccounts";

type Props = {
  account: FinancialAccountRow;
};

const FinancialAccountRow: FC<Props> = ({ account }) => {
  const nav = useNavigate();
  return (
    <tr
      key={account.id}
      className="border-white/5 cursor-pointer"
      onClick={() => nav(`/organization/g-l-accounts/${account.id}`)}
    >
      <td>{account.name}</td>
      <td className="capitalize">{formatLabel(account.type)}</td>
      <td>{account.status}</td>
      <td>
        {formatDistance(new Date(account.lastSyncedAt), new Date(), {
          addSuffix: true,
        })}
      </td>
      <UpdateFinancialAccounts account={account} />
    </tr>
  );
};

export default FinancialAccountRow;
