import { useOrganization } from "@/context/OrganizationContext";
import { api, RouterOutputs } from "@/utils/trpc";
import { atom, useAtom } from "jotai";
import { useEffect } from "react";

type CategoryList = RouterOutputs["organizations"]["category"]["list"];

export type CategoryData = { id: string; name: string; color: string };
export type Categories = {
  category?: CategoryData | null;
  subCategory?: CategoryData | null;
};

const categoryAtom = atom<CategoryList>([]);
let reuse = false;

const useCategories = () => {
  const { organizationId } = useOrganization<true>();
  const [categories, setCategories] = useAtom(categoryAtom);

  const { data = [] } = api.organizations.category.list.useQuery(
    organizationId,
    { enabled: !reuse }
  );

  useEffect(() => {
    reuse = true;
  }, []);

  useEffect(() => setCategories(data), [data]);

  return [categories, setCategories] as const;
};

export default useCategories;
