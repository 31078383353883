import LoadingSpin from "@/ui/LoadingSpin";
import { api } from "@/utils/trpc";
import useForm from "@/utils/useForm";
import { FC } from "react";
import { toast } from "react-hot-toast";

import Drawer from "@/components/drawer/Drawer";
import FormInput from "@/components/FormInput";
import PhoneInput from "@/components/PhoneInput";
import { useNavigate, useParams } from "react-router-dom";
import Accounts from "../components/Accounts";
import useVendors from "../hooks/useVendor";
import { fields } from "./CreateVendor";

import { MdClose } from "react-icons/md";
import { Partner } from "../validation";
import { Vendor } from "./Vendors";

type Props = {
  vendor: Vendor;
};

const UpdateVendor: FC<Props> = ({ vendor }) => {
  const { handleChange, inputs, errors, setValue, setErrors } =
    useForm<Partner>(vendor);

  const nav = useNavigate();
  const { id } = useParams<{ id?: string }>();

  const update = api.organizations.vendor.update.useMutation();
  const _delete = api.organizations.vendor.delete.useMutation();
  const _update = useVendors();

  const handleUpdate = async () => {
    const valid = Partner.safeParse(inputs);

    if (!valid.success) {
      setErrors(valid.error.formErrors.fieldErrors);

      return toast.error("Please check the details and try again");
    }

    const res = await update.mutateAsync({
      ...valid.data,
      id: vendor.id,
    });

    _update(res);
    nav("/partners/vendors");
  };

  return (
    <Drawer
      isOpen={id === vendor.id}
      onClose={() => nav("/partners/vendors")}
      title="Create Vendor"
      className="grid gap-6"
    >
      <div className="grid gap-x-2 grid-cols-2">
        {fields.map((p) => (
          <FormInput
            {...p}
            key={p.name}
            className="last:col-span-2"
            onChange={handleChange(p.name)}
            isError={errors[p.name]}
            value={inputs[p.name]}
          />
        ))}
      </div>

      <PhoneInput
        onChange={(e) => setValue("phone", e)}
        value={inputs.phone}
        isError={errors.phone}
      />

      <div className="flex gap-2 items-center justify-end">
        <button
          className="btn  ml-auto btn-sm w-fit btn-primary"
          onClick={() => handleUpdate()}
        >
          <LoadingSpin loading={update.isLoading} />
          Update
        </button>

        <button
          className="btn btn-sm btn-error"
          onClick={async () => {
            await _delete.mutateAsync(vendor.id);
            _update(vendor, true);
            nav("/partners/vendors");
          }}
        >
          <LoadingSpin loading={_delete.isLoading} />
          <MdClose />
        </button>
      </div>

      <Accounts vendorId={vendor.id} />
    </Drawer>
  );
};

export default UpdateVendor;
