import { FC } from "react";
import { useDropzone } from "react-dropzone";
import { TbUpload } from "react-icons/tb";

type Props = {
  files: File[];
  setFiles: (files: File[]) => void;
  accept?: string;
  multiple?: boolean;
};

const FileDropZone: FC<Props> = ({
  files,
  setFiles,
  accept = "*",
  multiple = true,
}) => {
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: { [accept]: [] },
      onDrop: (acceptedFiles) => setFiles(acceptedFiles),
    });

  return (
    <div
      {...getRootProps()}
      className={`btn p-4 h-fit w-full  grid w-fit justify-items-center content-center gap-2 text-xs ${
        isFocused
          ? "border-primary"
          : isDragAccept
          ? "border-success"
          : isDragReject
          ? "border-error"
          : ""
      }`}
    >
      <input {...getInputProps({ multiple })} />
      <TbUpload size={22} />
      <p>Drop a file to upload, or </p>
      <span className="text-primary">click to browse</span>
    </div>
  );
};

export default FileDropZone;
