import MultiSelect, { MultiSelectOption } from "@/components/MultiSelect";
import { useOrganization } from "@/context/OrganizationContext";
import {
  accountingStatuses,
  billStatuses,
  expenseStatuses,
  invoiceStatuses,
} from "@/lib";
import { formatLabel } from "@/utils/helper";
import { api } from "@/utils/trpc";
import { FC } from "react";
import { FaDeleteLeft } from "react-icons/fa6";
import { ProductType } from "./Conditions";

export const actionsList = [
  "addCategory",
  "addSubCategory",
  "assignTeamMember",
  "assignReviewer",
  "notifyTeamMember",
  "emailTeamMember",
  "changeStatus",
  "changeAccountingStatus",
] as const;

export type ActionType = (typeof actionsList)[number];

type Props = {
  product: ProductType;
  actions: Record<
    string,
    {
      type: ActionType;
      values: string[];
    }
  >;
  setActionValue: <
    A extends string,
    B extends {
      type: ActionType;
      values: string[];
    },
  >(
    key: A,
    value: B | ((prevState: B) => B)
  ) => void;

  setActions: React.Dispatch<
    React.SetStateAction<
      Record<
        string,
        {
          type: ActionType;
          values: string[];
        }
      >
    >
  >;
  errors: Partial<Record<string, string | string[]>>;
};

const statuses: Record<ProductType, Readonly<string[]>> = {
  BILL: billStatuses,
  INVOICE: invoiceStatuses,
  EXPENSE: expenseStatuses,
  TRANSACTION: [],
};

const Actions: FC<Props> = ({
  product,
  actions,
  setActionValue,
  setActions,
  errors,
}) => {
  const { organizationId = "" } = useOrganization();
  const { data: categories = [] } =
    api.utils.categories.useQuery(organizationId);

  const { data: team_members = [] } =
    api.organizations.teamMembers.useQuery(organizationId);

  const category = Object.values(actions).find((a) => a.type === "addCategory")
    ?.values[0];

  const members = team_members.map((s) => ({
    value: s.id,
    label: s.user.name,
  }));

  const valuesMap: Record<ActionType, MultiSelectOption[]> = {
    // changeApprovalStatus: approvalStatuses.map((s) => ({ value: s, label: s })),
    changeAccountingStatus: accountingStatuses.map((s) => ({
      value: s,
      label: formatLabel(s),
    })),
    changeStatus: statuses[product].map((s) => ({ value: s, label: s })),
    addCategory: categories.map((c) => ({ value: c.id, label: c.name })),
    addSubCategory:
      categories
        .find((c) => c.id === category)
        ?.subCategories.map((s) => ({ value: s.id, label: s.name })) ?? [],
    assignTeamMember: members,
    emailTeamMember: members,
    notifyTeamMember: members,
    assignReviewer: members,
  } as const;

  const usedActions = new Set(Object.values(actions).map((a) => a.type));
  const availableActions = actionsList.filter((a) => !usedActions.has(a));

  return (
    <div>
      <p className="abel">THEN</p>
      <p className="label-text text-info">perform this actions</p>

      {Object.entries(actions).map(([id, action], i, { length }) => {
        const isSearchable = /te/i.test(action.type);
        const canMultiSelect = action.type.includes("Team");

        return (
          <div key={id}>
            <div className="flex gap-4 items-end rounded-lg w-fit  justify-between ">
              <div>
                <p className="label text-secondary">{i ? "And" : ""}</p>
                <select
                  className="select capitalize"
                  value={action.type}
                  onChange={(e) =>
                    setActionValue(id, {
                      type: e.target.value as ActionType,
                      values: [],
                    })
                  }
                >
                  {actionsList.map((t) => {
                    if (
                      t === "addSubCategory" &&
                      (action.type === "addCategory" || !category)
                    )
                      return null;
                    if (usedActions.has(t) && t !== action.type) return null;

                    return <option value={t}>{formatLabel(t)}</option>;
                  })}
                </select>
              </div>
              {canMultiSelect ? (
                <div className="lg:w-96 w-64" style={{ zIndex: length - i }}>
                  <MultiSelect
                    searchable={isSearchable}
                    options={valuesMap[action.type]}
                    values={action.values}
                    error={!!errors[action.type] && !action.values.length}
                    onChange={(values) =>
                      setActionValue(id, (p) => ({ ...p, values }))
                    }
                  />
                </div>
              ) : (
                <select
                  className={`select capitalize ${
                    errors[action.type] && !action.values.length
                      ? "select-error"
                      : ""
                  } `}
                  value={action.values[0] || ""}
                  onChange={(e) =>
                    setActionValue(id, (p) => ({
                      ...p,
                      values: [e.target.value],
                    }))
                  }
                >
                  <option disabled value="">
                    Select a value
                  </option>
                  {valuesMap[action.type].map((t) => (
                    <option value={t.value}>{t.label}</option>
                  ))}
                </select>
              )}
              {length > 1 && (
                <button
                  className="btn mb-2 btn-error btn-outline border-none btn-sm"
                  onClick={() =>
                    setActions((p) => {
                      const { [id]: _, ...rest } = p;
                      return rest;
                    })
                  }
                >
                  <FaDeleteLeft />
                </button>
              )}
            </div>
            {length - 1 === i && !!availableActions.length && (
              <button
                className="no-underline btn-link mt-4"
                onClick={() => {
                  setActions((p) => ({
                    ...p,
                    [Math.random()]: { type: availableActions[0], values: [] },
                  }));
                }}
              >
                + Add action
              </button>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Actions;
