import { useOrganization } from "@/context/OrganizationContext";
import Checkbox from "@/ui/Checkbox";
import LoadingSpin from "@/ui/LoadingSpin";
import Rows from "@/ui/skeletons/Rows";
import { openFile, saveFile } from "@/utils/helper";
import { downloadFile } from "@/utils/supabase";
import { RouterOutputs, api } from "@/utils/trpc";
import { useMutation } from "@tanstack/react-query";
import { Dispatch, FC, SetStateAction, useMemo, useState } from "react";
import { BsDownload } from "react-icons/bs";
import { FiMoreVertical } from "react-icons/fi";
import { HiOutlineExternalLink } from "react-icons/hi";
import { MdDelete } from "react-icons/md";
import Dropdown from "../Dropdown";
import InViewTrigger from "../InViewTrigger";
import Drawer from "../drawer/Drawer";

type Props = {
  proofs?:
    | RouterOutputs["invoices"]["invoiceById"]["proofs"]
    | RouterOutputs["transactions"]["proofs"];
  adding: boolean;
  setAdding: (value: boolean) => void;
  selected: Record<string, boolean>;
  setSelected: Dispatch<SetStateAction<Record<string, boolean>>>;
  handleAttach: () => void;
  handleDelete: (id: string) => Promise<void>;
  isDeleting: boolean;
};

const AttachDocuments: FC<Props> = ({
  proofs,
  adding,
  setAdding,
  selected,
  setSelected,
  handleAttach,
  handleDelete,
  isDeleting,
}) => {
  const { organizationId = "" } = useOrganization();
  const [loading, setLoading] = useState<"download" | "view" | null>(null);

  const {
    data = { pages: [] },
    isLoading,
    isFetching,
    fetchNextPage,
  } = api.organizations.files.useInfiniteQuery(
    { organizationId },
    { getNextPageParam: (p) => p.nextCursor }
  );

  const download = useMutation(downloadFile);

  const files = useMemo(() => data.pages.flatMap((p) => p.list), [data]);

  const attached = useMemo(() => {
    if (!proofs) return [];

    return proofs
      .filter((p) => p.file)
      .map((p) => ({ ...p.file!, proofId: p.id }));
  }, [proofs]);

  const attachedSet = useMemo(
    () => new Set(attached.map((p) => p.id)),
    [attached]
  );

  const handleView = (file: (typeof files)[number]) => async (e: any) => {
    e.stopPropagation();
    setLoading("view");
    const blob = await download.mutateAsync(file.link);

    const _file = new Blob([blob], { type: file.mimeType });
    setLoading(null);
    openFile(_file);
  };

  const handleDownload = (file: (typeof files)[number]) => async (e: any) => {
    e.stopPropagation();
    setLoading("download");
    const blob = await download.mutateAsync(file.link);

    const _file = new Blob([blob], { type: file.mimeType });
    saveFile(_file, file.name);
  };

  const canSave = useMemo(() => Object.keys(selected).length > 0, [selected]);

  return (
    <div>
      <table className="table w-full">
        <thead className="sticky top-0 z-10">
          <tr className="text-base-content bg-neutral">
            <th> Name & Type</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {attached.map((doc) => (
            <tr
              key={doc.id}
              className="btn !transform-none table-row text-start"
            >
              <td>
                <p className="break-words break-all">
                  {doc.name}{" "}
                  <span className="text-xs text-neutral-400 ml-2">
                    ({doc.size})
                  </span>
                </p>
                <p className="capitalize mt-1 text-xs text-info">{doc.type}</p>
              </td>
              <th className="flex items-center gap-3">
                <Dropdown
                  title={<FiMoreVertical size={22} />}
                  hideArrow
                  buttonClass="btn-xs"
                  dropdownClass="dropdown-left dropdown-bottom "
                  listClass="w-44"
                  content={[
                    <button
                      className="flex items-center gap-2  z-10 w-full"
                      onClick={handleDownload(doc)}
                    >
                      <BsDownload className="text-primary" />
                      <LoadingSpin loading={loading === "download"} />
                      Download
                    </button>,
                    <button
                      className="flex items-center gap-2  z-10 w-full"
                      onClick={handleView(doc)}
                    >
                      <HiOutlineExternalLink className="text-secondary" />
                      <LoadingSpin loading={loading === "view"} />
                      View
                    </button>,
                    <button
                      className="flex items-center gap-2  z-10 w-full"
                      onClick={async (e) => {
                        e.stopPropagation();
                        await handleDelete(doc.proofId);
                      }}
                    >
                      <MdDelete className="text-error" />
                      <LoadingSpin loading={isDeleting} />
                      Delete
                    </button>,
                  ]}
                />
              </th>
            </tr>
          ))}
        </tbody>
      </table>
      {isLoading || !proofs ? (
        <Rows count={2} />
      ) : (
        !attached.length && (
          <p className="label label-text-alt w-full justify-center bg-base-200">
            No file attached
          </p>
        )
      )}
      <Drawer title="Attach Documents" isOpen={adding} onClose={setAdding}>
        <div className="flex-1  w-full  overflow-auto">
          <table className="table ">
            <thead className="sticky top-0 z-10">
              <tr className="text-base-content bg-base-300">
                <th />
                <th>Name & Type</th>
              </tr>
            </thead>
            <tbody>
              {files.map((doc) => {
                if (attachedSet.has(doc.id)) return null;
                return (
                  <tr
                    key={doc.id}
                    className={`btn table-row text-start btn-neutral`}
                    onClick={() =>
                      setSelected((p) => {
                        if (p[doc.id]) {
                          const { [doc.id]: _, ...rest } = p;
                          return rest;
                        }
                        return { ...p, [doc.id]: true };
                      })
                    }
                  >
                    <th className="w-fit !p-2">
                      <Checkbox
                        checked={!!selected[doc.id]}
                        onChange={() => {}}
                      />
                    </th>
                    <td>
                      <p className="break-words break-all">
                        {doc.name}{" "}
                        <span className="text-xs text-neutral-400 ml-2">
                          ({doc.size})
                        </span>
                      </p>
                      <p className="capitalize mt-1 text-xs text-info">
                        {doc.type}
                      </p>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {isLoading && <Rows />}
          {isLoading ? null : (
            <InViewTrigger
              onInView={fetchNextPage}
              children={<LoadingSpin loading={isFetching} />}
            />
          )}
        </div>

        <div className="sticky bottom-1">
          {canSave && (
            <button
              className="btn btn-sm btn-primary w-full"
              onClick={handleAttach}
            >
              Add
            </button>
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default AttachDocuments;
