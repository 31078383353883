import { format } from "date-fns/esm";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Customer } from "./Customers";
import UpdateCustomer from "./UpdateCustomer";

type Props = {
  customer: Customer;
};

const CustomerRow: FC<Props> = ({ customer }) => {
  const nav = useNavigate();
  return (
    <tr
      className="border-white/10 cursor-pointer"
      onClick={() => nav(`/partners/customers/${customer.id}`)}
    >
      <td>{customer.name}</td>
      <td>{customer.email}</td>
      <td>{format(new Date(customer.createdAt), "MMM dd, yyyy")}</td>
      <UpdateCustomer customer={customer} />
    </tr>
  );
};

export default CustomerRow;
