import { useOrganization } from "@/context/OrganizationContext";
import { api } from "@/utils/trpc";

type Props = {
  //   categoryId: string;
};

const ManageGLCode = (props: Props) => {
  const { organizationId } = useOrganization<true>();
  const { data = [], isLoading } =
    api.rutter.accounts.list.useQuery(organizationId);
  return (
    <div>
      <select name="" id="" className="select select-primary">
        <option value="">Select GL Code</option>
        {data.map((account) => (
          <option value={account.id}>{account.name}</option>
        ))}
      </select>
    </div>
  );
};

export default ManageGLCode;
