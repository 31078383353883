import AppRouter from "@/Router";
import { NavigationControl } from "@/components/navigation/NavigationControl";
import { publicRoutes } from "@/components/navigation/lib";
import { useOrganization } from "@/context/OrganizationContext";
import { useTheme } from "@/context/ThemeContext";
import { useUser } from "@/context/UserContext";
import { SentryRouterType } from "@/main";
import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DesktopNavBar from "../components/navigation/DesktopNavbar";
import MobileNavBar from "../components/navigation/MobileNav";
import Header from "./Header";
import OrganizationsList from "./OrganizationsList";

type Props = {
  SentryRoutes: SentryRouterType;
};

const control = new NavigationControl();

const Layout: FC<Props> = ({ SentryRoutes }) => {
  const { user, session } = useUser();
  const { pathname } = useLocation();
  const nav = useNavigate();
  const { organization } = useOrganization();
  const { isDark } = useTheme();

  const [showHeader, setShowHeader] = useState(false);

  useEffect(() => {
    setShowHeader(false);

    if (!session) {
      if (publicRoutes.includes(pathname)) return;
      return nav("/register/sign-up");
    }

    if (!user) return nav("/register/personal-details");

    const inviteId = localStorage.getItem("@inviteId");
    if (inviteId) return nav(`/invitation/${inviteId}`);

    if (!organization) return nav("/register/company-details");
    if (!user.isApproved) return nav("/pending-approval");

    if (!publicRoutes.includes(pathname)) setShowHeader(true);

    if (control.isValidRoute(pathname, organization.billingPlan)) return;
    nav("/");
  }, [pathname, organization, pathname, session, user]);

  return (
    <div className={`h-screen w-screen ${isDark ? "dark" : ""}`}>
      <div className="glass h-screen w-screen overflow-hidden">
        <div className="flex h-full  bg-base-100/95  w-screen">
          {showHeader && (
            <div className="relative">
              <DesktopNavBar />

              <div className="drawer 2xl:hidden z-[999] absolute">
                <input type="checkbox" className="drawer-toggle" id="navbar" />

                <div className="drawer-side z-[9999]">
                  <label
                    htmlFor="navbar"
                    aria-label="close sidebar"
                    className="drawer-overlay"
                  ></label>

                  <div className="h-screen overflow-hidden flex flex-col bg-base-200  py-2">
                    <div className="border-r border-gray-400/50">
                      <OrganizationsList />
                      <div className="divider  px-2 h-0"></div>
                    </div>
                    <MobileNavBar />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="flex w-full md:max-w-[calc(100vw-3rem)] max-w-[100vw] relative h-full flex-col flex-1">
            {showHeader && <Header />}

            <div
              className={`flex h-full flex-1 flex-col px-4 pb-2 overflow-auto`}
            >
              <AppRouter SentryRoutes={SentryRoutes} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
