import Select from "@/components/Select";
import { SwanLimitPeriod } from "@/types/validation";
import { FC } from "react";

export type SwanLimitPeriod = (typeof SwanLimitPeriod)["options"][number];

type Props = {
  amount?: number;
  period?: SwanLimitPeriod;
  onAmountChange: (value: number) => void;
  onPeriodChange: (value: SwanLimitPeriod) => void;
};

const SpendingLimitControl: FC<Props> = ({
  onAmountChange,
  onPeriodChange,
  amount,
  period,
}) => {
  return (
    <div>
      <div className="divider divider-start">Spending Limit</div>

      <div className="flex items-center join">
        <p className="btn border border-white/10 btn-neutral btn-sm join-item">
          EUR
        </p>
        <input
          type="text"
          value={amount}
          className="input input-sm input-bordered w-44 join-item"
          placeholder="Amount"
          onChange={(e) => onAmountChange(+e.target.value)}
        />
        <Select
          options={SwanLimitPeriod.options.map((value) => ({
            value,
            label: value.replace("Always", "Total"),
          }))}
          onChange={(e) => onPeriodChange(e)}
          className="!w-28"
          buttonClass="btn-sm !join-item"
          value={period}
          placeholder="Period"
        />
      </div>
    </div>
  );
};

export default SpendingLimitControl;
