import { useOrganization } from "@/context/OrganizationContext";
import LoadingSpin from "@/ui/LoadingSpin";
import { api } from "@/utils/trpc";
import useForm from "@/utils/useForm";
import { Dispatch, SetStateAction, useEffect } from "react";

import FormInput from "@/components/FormInput";
import PhoneInput from "@/components/PhoneInput";
import Select from "@/components/Select";
import { contractTypes } from "@/lib";
import { UpsertStaff, UpsertStaffType } from "@/types/validation";
import { TOAST, formatLabel } from "@/utils/helper";
import { Staff } from "./Staff";

const fields: {
  name: keyof UpsertStaffType;
  placeholder: string;
  title: string;
}[] = [
  {
    name: "name",
    placeholder: "Enter employee name",
    title: "Employee Name",
  },
  {
    name: "email",
    placeholder: "Enter employee email",
    title: "Email address",
  },
];

type Props = {
  staff: Staff | null;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const StaffDetails: React.FC<Props> = ({ staff, setIsOpen }) => {
  const { handleChange, inputs, errors, setValue, setErrors, setInputs } =
    useForm<Partial<UpsertStaffType>>({});

  const { organizationId = "" } = useOrganization();

  const { data: roles = [] } = api.organizations.roles.useQuery(organizationId);

  const { data: departments = [] } =
    api.organizations.departments.useQuery(organizationId);

  useEffect(() => {
    if (!staff) return;
    setInputs(staff);
  }, [staff]);

  const utils = api.useUtils();

  const upsert = api.organizations.upsertStaff.useMutation();

  const handleUpsert = async () => {
    const valid = UpsertStaff.safeParse({
      ...inputs,
      organizationId,
    });

    if (!valid.success) {
      TOAST("Please check the details and try again", "error");
      return setErrors(valid.error.flatten().fieldErrors);
    }

    const res = await upsert.mutateAsync({
      ...valid.data,
      organizationId: organizationId,
    });

    setIsOpen(false);

    utils.organizations.staffs.setData(organizationId, (p) => {
      if (!p) return p;
      return [res, ...p.filter((p) => p.id !== res.id)];
    });
  };

  return (
    <div className="flex gap-5 items-stretch flex-col bg-grey-lighter min-h-screen">
      <div className="grid gap-2">
        <div className="grid  gap-x-2 grid-cols-2">
          {fields.map((p) => (
            <FormInput
              {...p}
              onChange={handleChange(p.name)}
              value={inputs[p.name]}
              isError={errors[p.name]}
            />
          ))}

          <div>
            <p className="label">Department</p>

            <Select
              className="z-40"
              searchable
              listClass="w-full"
              placeholder="Select department"
              isError={!!errors.departmentId}
              options={departments.map((c) => ({
                label: c.name,
                value: c.id,
              }))}
              value={inputs.departmentId}
              onChange={(e) => setValue("departmentId", e)}
            />
          </div>
          <div>
            <p className="label">Role</p>

            <Select
              className="z-40"
              searchable
              placeholder="Select role"
              isError={!!errors.roleId}
              options={roles.map((c) => ({
                label: c.name,
                value: c.id,
              }))}
              value={inputs.roleId}
              onChange={(e) => setValue("roleId", e)}
            />
          </div>
        </div>
        <PhoneInput
          onChange={(e) => setValue("phone", e)}
          value={inputs.phone}
          className="z-30"
          isError={errors.phone}
        />
        <div>
          <p className="label">Contract Type</p>

          <Select
            className="z-20"
            placeholder="Select contract type"
            isError={!!errors.contractType}
            options={contractTypes.map((c) => ({
              label: formatLabel(c),
              value: c,
            }))}
            value={inputs.contractType}
            onChange={(e) =>
              setValue("contractType", e as (typeof contractTypes)[number])
            }
          />
        </div>

        <div>
          <label className="label label-text ">Gross Monthly Salary</label>
          <div className="flex gap-2 items-center">
            <Select
              className="capitalize z-10 w-1/4"
              options={["USD", "EUR", "GBP"].map((c) => ({
                label: c,
                value: c,
              }))}
              isError={!!errors.currency}
              placeholder="Select currency"
              value={inputs.currency}
              onChange={(e) => setValue("currency", e)}
            />

            <FormInput
              isError={errors.gross_monthly_salary}
              value={inputs.gross_monthly_salary}
              onChange={(e) =>
                setValue("gross_monthly_salary", +e.target.value)
              }
              placeholder="Enter salary"
            />
          </div>
        </div>

        <button
          className="btn btn-primary mt-40 z-10 mb-2 w-full"
          onClick={() => handleUpsert()}
        >
          <LoadingSpin loading={upsert.isLoading} />
          {staff?.id ? "Update" : "Create"}
        </button>
      </div>
    </div>
  );
};

export default StaffDetails;
