import CopyToClipboard from "@/components/CopyToClipboard";
import Drawer from "@/components/drawer/Drawer";
import { useOrganization } from "@/context/OrganizationContext";
import Rows from "@/ui/skeletons/Rows";
import { formatCurrency } from "@/utils/helper";
import { api } from "@/utils/trpc";
import { format } from "date-fns/esm";
import { FC, useState } from "react";
import toast from "react-hot-toast";
import { RiRefreshLine } from "react-icons/ri";

type Props = {};

const accountStatusColor = {
  Opened: "badge-success",
  Suspended: "badge-warning",
  Closing: "badge-warning",
  Closed: "badge-error",
};

const holderStatusColor = {
  Enabled: "badge-success",
  Suspended: "badge-warning",
  Canceled: "badge-error",
};

const holderVerificationStatusColor = {
  NotStarted: "badge-warning",
  WaitingForInformation: "badge-warning",
  Pending: "badge-warning",
  Verified: "badge-success",
  Refused: "badge-error",
};

const paymentLevelColor = {
  Limited: "badge-warning",
  Unlimited: "badge-success",
};

const Account: FC<Props> = () => {
  const { organizationId } = useOrganization<true>();
  const {
    data: account,
    isLoading,
    refetch,
    isRefetching,
  } = api.swan.account.get.useQuery(organizationId);

  const [showDetails, setShowDetails] = useState(false);

  const handleRefetch = async () => {
    await refetch();
    toast.success("Refetch successful!");
  };

  if (isLoading) return <Rows />;

  if (!account) return <p>No account</p>;

  const { holder } = account;

  return (
    <div className="grid gap-6">
      <button
        className="btn btn-secondary justify-self-end btn-outline btn-sm"
        onClick={handleRefetch}
      >
        <RiRefreshLine
          size={20}
          className={isRefetching ? "animate-spin" : ""}
        />
      </button>
      <div className="card w-full bg-base-100 shadow-xl">
        <div className="card-body gap-8">
          <div className="stats xl:grid-cols-2 stats-vertical xl:stats-horizontal  shadow">
            <div className="stat  ">
              <div className="stat-title">Account </div>
              <div className="stat-value !whitespace-break-spaces flex items-start gap-1">
                {account.name}
                <div className="join leading-3 cursor-pointer">
                  <div className="tooltip" data-tip="Account Status">
                    <span
                      className={`badge join-item  ${
                        accountStatusColor[account.status]
                      }`}
                    >
                      {account.status}
                    </span>
                  </div>
                  <div className="tooltip " data-tip="Account Payment level">
                    <span
                      className={`badge badge-outline join-item ${
                        paymentLevelColor[account.paymentLevel]
                      }`}
                    >
                      {account.paymentLevel}
                    </span>
                  </div>
                </div>
              </div>
              <div className="stat-desc  my-4">
                <CopyToClipboard
                  className="btn-secondary btn-xs"
                  label="Account ID"
                  value={account.id}
                  text={"Copy Account ID"}
                />
              </div>
              <div className="stat-desc">
                Created At{" "}
                <span className="text-accent">
                  {format(account.createdAt, "dd MMMM yyy")}
                </span>
              </div>
            </div>

            <div className="stat col-span-1 ">
              <div className="stat-title">Account Holder</div>
              <div className="stat-value !whitespace-break-spaces break-words flex items-start gap-1">
                {account.holder.name}

                <div className="join leading-3 cursor-pointer">
                  <div className="tooltip" data-tip="Account Holder Status">
                    <span
                      className={`badge join-item  ${
                        holderStatusColor[account.holder.status!]
                      }`}
                    >
                      {account.holder.status}
                    </span>
                  </div>
                  <div
                    className="tooltip "
                    data-tip="Account Holder Verification Status"
                  >
                    <span
                      className={`badge badge-outline join-item ${
                        holderVerificationStatusColor[
                          account.holder.verificationStatus
                        ]
                      }`}
                    >
                      {account.holder.verificationStatus}
                    </span>
                  </div>
                </div>
              </div>
              <div className="stat-desc  my-4">
                <CopyToClipboard
                  className="btn-secondary btn-xs"
                  label="Account Holder ID"
                  value={account.holder.id}
                  text={"Copy Account Holder ID"}
                />
              </div>
              {holder.verificationStatus !== "Verified" && (
                <div className="stat-desc whitespace-break-spaces text-warning">
                  The account is limited to 150€/30 days as long as the account
                  holder has not been verified
                </div>
              )}
            </div>
          </div>

          <div className="stats xl:grid-cols-2 stats-vertical xl:stats-horizontal  shadow">
            <div className="stat col-span-1">
              <div className="stat-title">Available balance</div>
              <div className="stat-value">
                {formatCurrency(
                  +(account.availableBalance || 0),
                  account.currency
                )}
              </div>
              <div className="stat-actions">
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => setShowDetails(true)}
                >
                  Add funds
                </button>
              </div>
            </div>

            <div className="stat col-span-1">
              <div className="stat-title">Booked balance</div>
              <div className="stat-value">
                {formatCurrency(
                  +(account.bookedBalance || 0),
                  account.currency
                )}
              </div>
              <div className="stat-actions join">
                <button className="btn join-item btn-info btn-outline">
                  Reserved:{" "}
                  {formatCurrency(
                    +(account.reservedBalance || 0),
                    account.currency
                  )}
                </button>
                <button className="btn join-item btn-info">
                  Pending:{" "}
                  {formatCurrency(
                    +(account.pendingBalance || 0),
                    account.currency
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Drawer
        isOpen={showDetails}
        onClose={() => setShowDetails(false)}
        title="Account Details"
        className="grid gap-2"
      >
        <div>
          <p className="label">IBAN</p>
          <CopyToClipboard
            label="IBAN"
            value={account.IBAN!}
            text={account.IBAN! || "N/A"}
            className="w-full justify-start"
          />
        </div>
        <div>
          <p className="label">Account Number</p>
          <CopyToClipboard
            label="Account number"
            value={account.ACCOUNT_NUMBER!}
            text={account.ACCOUNT_NUMBER! || "N/A"}
            className="w-full justify-start"
          />
        </div>
        <div>
          <p className="label">BIC/SWIFT</p>
          <CopyToClipboard
            className="w-full justify-start"
            label="Account number"
            value={account.SWIFT!}
            text={account.SWIFT! || "N/A"}
          />
        </div>
        <div>
          <p className="label"> Branch address</p>

          <CopyToClipboard
            className="w-full h-28 justify-start "
            label="Bank address"
            value={`
              SWAN SAS
              95 Avenue du Président Wilson
              93100 Montreuil
              France
            `}
            text={
              <p className="text-start leading-5 ">
                SWAN SAS
                <br />
                95 Avenue du Président Wilson
                <br />
                93100 Montreuil
                <br />
                France
              </p>
            }
          />
        </div>
      </Drawer>
    </div>
  );
};

export default Account;
