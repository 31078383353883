import Switch from "@/components/Switch";
import Drawer from "@/components/drawer/Drawer";
import NotFound from "@/components/utils/notfound";
import { useUser } from "@/context/UserContext";
import { reAuthErrorMessages, syncErrorMessages } from "@/lib";
import { countriesMap } from "@/lib/countriesWithBanks";
import {
  UpdateConnectedAccount,
  UpdateConnectedAccountType,
} from "@/types/validation";
import Collapsible from "@/ui/Collapsible";
import LoadingSpin from "@/ui/LoadingSpin";
import { PageTitle } from "@/ui/PageTitle";
import Rows from "@/ui/skeletons/Rows";
import { TOAST, formatCurrency } from "@/utils/helper";
import { api } from "@/utils/trpc";
import useForm from "@/utils/useForm";
import addDays from "date-fns/addDays";
import format from "date-fns/format";
import isBefore from "date-fns/isBefore";
import { useEffect, useMemo, useState } from "react";
import { AiTwotoneBank } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { TbAlertTriangleFilled } from "react-icons/tb";
import { JsonView, allExpanded, defaultStyles } from "react-json-view-lite";
import "react-json-view-lite/dist/index.css";
import { useNavigate, useParams } from "react-router-dom";

const fields = [
  {
    name: "name" as const,
    title: "Account Name",
    placeholder: "Enter Account name",
  },
  {
    name: "bankAddress" as const,
    title: "Bank Address",
    placeholder: "Enter Bank Address",
  },
];

const ConnectedBank = () => {
  const { id = "" } = useParams();

  const [adding, setAdding] = useState(false);

  const utils = api.useUtils();
  const nav = useNavigate();
  const { userId } = useUser();

  const { handleChange, inputs, errors, setErrors, setInputs, setValue } =
    useForm<Partial<UpdateConnectedAccountType & { rawJSON: any }>>({});

  const [bankName, setBankName] = useState("");
  const updateAccount = api.accounts.updateConnectedAccount.useMutation();
  const deleteAccount = api.accounts.deleteConnectedAccount.useMutation();
  const updateBank = api.banks.updateConnectedBank.useMutation();
  const sync = api.banks.sync.useMutation();
  const auth = api.yapily.accountAuthRequest.useMutation();

  const { data, isLoading, remove, refetch } =
    api.banks.connectedBankById.useQuery(id, {
      enabled: !!id,
    });

  useEffect(() => {
    if (!data) return;
    setBankName(data.name);
  }, [data]);

  const totalBalance = useMemo(() => {
    if (!data) return 0;
    return data.accounts.reduce((acc, curr) => acc + curr.converted, 0);
  }, [data]);

  if (isLoading) return <Rows count={10} />;

  if (!data) return <NotFound title="Bank" />;

  const handleUpdateAccount = async () => {
    const valid = UpdateConnectedAccount.safeParse(inputs);

    if (!valid.success) return setErrors(valid.error.formErrors.fieldErrors);

    const account = await updateAccount.mutateAsync({ ...valid.data });

    utils.banks.connectedBankById.setData(id, (p) => {
      if (!p) return p;
      return {
        ...p,
        accounts: p.accounts.map((a) => {
          if (a.id === account.id) return { ...a, ...account };
          return a;
        }),
      };
    });
    setAdding(false);
  };

  const handleSync = async () => {
    if (!data) return;
    const res = await sync.mutateAsync([data.id]);

    if (res.every((r) => r.isSyncError)) {
      return TOAST("Synchronization failed", "error");
    } else TOAST("Synchronized successfully", "success");

    remove();
    await refetch();
  };

  const handleUpdateBank = async () => {
    if (!bankName || !data) return;

    const res = await updateBank.mutateAsync({ id: data.id, name: bankName });

    utils.banks.connectedBankById.setData(id, (p) => {
      if (!p) return p;
      return {
        ...p,
        ...res,
      };
    });

    TOAST("Bank name updated successfully", "success");
  };

  const handleReAuth = async () => {
    if (!userId || !data?.institution_id) return;

    const res = await auth.mutateAsync({
      institutionId: data.institution_id,
      applicationUserId: userId,
      callback: `${window.location.protocol}//${window.location.host}/accounts/connect/re-auth?bankId=${data.id}`,
    });

    if (!res) return false;

    window.open(res.authorisationUrl, "_self");
  };

  const handleDelete = async () => {
    if (!inputs.id) return;
    const _confirm = confirm(
      `Confirm deleting ${inputs.name}? All its transaction and related data will be lost. This action cannot be undone.`
    );

    if (!_confirm) return;

    await deleteAccount.mutateAsync(inputs.id);

    utils.banks.connectedBankById.setData(id, (p) => {
      if (!p) return p;
      return {
        ...p,
        accounts: p.accounts.filter((a) => a.id !== inputs.id),
      };
    });

    TOAST("Account deleted successfully", "success");

    setAdding(false);

    await refetch();
  };
  return (
    <div>
      <PageTitle title="Connected Bank" />
      <button
        className="link mr-auto mb-6 link-primary"
        onClick={() => nav(-1)}
      >
        Back to overview
      </button>

      <div className="grid gap-10">
        <div className="card lg:card-side bg-base-100 shadow-xl">
          <div className="card-body gap-6 p-6 sm:p-8">
            <div className="card-title  flex-col sm:flex-row relative w-fit ">
              <p className="w-full">Bank Name:</p>
              <div className="flex items-center">
                <input
                  type="text"
                  value={bankName}
                  className={`input input-sm hover:input-bordered input-bordered sm:border-none ${
                    bankName ? "input-ghost" : "input-error"
                  }`}
                  onChange={(e) => setBankName(e.target.value)}
                />
                {data.name === bankName ? (
                  <div className="bg-base-100 pl-2 absolute right-2 -z-0 pointer-events-none ">
                    <MdEdit />
                  </div>
                ) : (
                  <button
                    onClick={handleUpdateBank}
                    disabled={!bankName}
                    className="btn btn-sm btn-primary"
                  >
                    <LoadingSpin loading={updateBank.isLoading} />
                    save
                  </button>
                )}
              </div>
            </div>
            <div className=" flex gap-4 items-center">
              <h3>@{data.institution_name}</h3>
              <figure className="w-10">
                <img src={countriesMap[data.countryId]?.flag} alt="country" />
              </figure>
            </div>

            <div className="join">
              <button
                className="btn max-w-xs w-ma mt-4 btn-primary join-item"
                onClick={handleSync}
              >
                <LoadingSpin loading={sync.isLoading} />
                Synchronize
                {data.isSyncError && (
                  <div
                    className="tooltip before:w-60 tooltip-right before:text-sm bg-black p-1 rounded-md tooltip-warning"
                    data-tip={syncErrorMessages}
                  >
                    <TbAlertTriangleFilled className="text-warning text-lg" />
                  </div>
                )}
              </button>
              <button
                className="btn max-w-xs w-ma mt-4 btn-secondary btn-outline join-item"
                onClick={handleReAuth}
              >
                <LoadingSpin loading={auth.isLoading} />
                Reauthorize
                {isBefore(data.authorizedOn, addDays(new Date(), -30)) && (
                  <div
                    className="tooltip before:w-60 tooltip-right before:text-sm tooltip-warning"
                    data-tip={reAuthErrorMessages}
                  >
                    <TbAlertTriangleFilled className="text-warning text-lg" />
                  </div>
                )}
              </button>
            </div>
          </div>
          <div className="card-body text-xs lg:text-base lg:items-end p-6 sm:p-8">
            <p className=" flex">
              <p className="font-normal">Total Balance:</p>
              <p className="flex-grow-0 font-semibold lg:w-60 w-28 text-right">
                {formatCurrency(totalBalance, data.currency)}
              </p>
            </p>
            <p className="flex">
              <p className="font-normal">Connected on:</p>
              <p className="flex-grow-0 font-semibold lg:w-60 w-28 text-right">
                {format(data.createdAt, "MMM, dd, yyy")}
              </p>
            </p>

            <p className="flex">
              <p className="font-normal">Last Authorized on:</p>
              <p className="flex-grow-0 font-semibold lg:w-60 w-28 text-right">
                {format(data.authorizedOn, "MMM, dd, yyy")}
              </p>
            </p>
            <p className="flex">
              <p className="font-normal">Last synced at:</p>
              <p className="flex-grow-0 font-semibold lg:w-60 w-28 text-right">
                {data.lastSyncedAt
                  ? format(data.lastSyncedAt, "MMM, dd, yyy")
                  : "N/A"}
              </p>
            </p>
          </div>
        </div>

        <div>
          <p className="btn mb-2 w-full btn-neutral justify-start text-base">
            Accounts
          </p>

          <div className="grid gap-1">
            {data.accounts.map((a) => (
              <button
                className={`btn h-fit  w-full justify-between ${
                  a.isActive ? "" : "btn-disabled pointer-events-auto"
                } `}
                key={a.id}
                onClick={() => {
                  setInputs(a);
                  setAdding(true);
                }}
              >
                <AiTwotoneBank />
                <div className="flex-1 w-1/3 break-words text-left">
                  <p className="lg:text-sm text-xs font-semibold">{a.name}</p>
                  <p className="text-xs">
                    {" "}
                    {a.IBAN || a.ACCOUNT_NUMBER || a.ROUTING_NUMBER}
                  </p>
                </div>
                <div>
                  <p className=" text-sm font-medium">
                    {formatCurrency(a.balance || 0, a.currency)}
                  </p>
                  <p className="text-xs font-medium">
                    {formatCurrency(a.converted, data.currency)}
                  </p>
                </div>
                <FaEdit size={18} className=" " />
              </button>
            ))}
          </div>
        </div>

        <Drawer title="Account Details" isOpen={adding} onClose={setAdding}>
          <div>
            <div className="flex items-center justify-end">
              <label
                className={`label ${
                  inputs.isActive ? "text-primary" : "text-neutral-500"
                }`}
              >
                Account is {inputs.isActive ? "active" : "inactive"}
              </label>
              <Switch
                value={!!inputs.isActive}
                onChange={(e) => setValue("isActive", e)}
              />
            </div>
            {fields.map(({ name, placeholder, title }) => {
              return (
                <div key={name}>
                  <label className="label label-text">{title}</label>
                  <input
                    type="text"
                    placeholder={placeholder}
                    className={`input w-full shadow-sm ${
                      errors[name] ? "input-error" : "input-bordered"
                    }`}
                    value={inputs[name]!}
                    onChange={handleChange(name)}
                  />
                </div>
              );
            })}
          </div>
          <div className="my-6 grid gap-2">
            <button
              onClick={handleUpdateAccount}
              className="btn btn-primary w-full"
            >
              <LoadingSpin loading={updateAccount.isLoading} />
              Update Account
            </button>
            <button
              className="btn btn-error btn-outline w-full"
              onClick={handleDelete}
            >
              <LoadingSpin loading={deleteAccount.isLoading} />
              Delete Account
            </button>
          </div>

          {inputs.rawJSON && (
            <Collapsible title="Raw payload">
              <div className="w-full">
                <p className="label underline">Raw payload:</p>
                <JsonView
                  data={inputs.rawJSON || {}}
                  shouldExpandNode={allExpanded}
                  style={{
                    ...defaultStyles,
                    container: "break-words rounded-xl text-xs w-full bg-white",
                  }}
                />
              </div>
            </Collapsible>
          )}
        </Drawer>
      </div>
    </div>
  );
};

export default ConnectedBank;
