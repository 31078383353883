import { useOrganization } from "@/context/OrganizationContext";
import LoadingSpin from "@/ui/LoadingSpin";
import Rows from "@/ui/skeletons/Rows";
import { api } from "@/utils/trpc";
import { FC, useMemo } from "react";
import InViewTrigger from "./InViewTrigger";

type Props = {
  files: Record<string, string>;
  setFiles: (files: Record<string, string>) => void;
  className?: string;
};

const SelectFiles: FC<Props> = ({ files, setFiles, className }) => {
  const { organizationId } = useOrganization<true>();

  const {
    data = { pages: [] },
    isLoading,
    isFetching,
    fetchNextPage,
  } = api.organizations.files.useInfiniteQuery(
    { organizationId },
    { getNextPageParam: (p) => p.nextCursor }
  );

  const _files = useMemo(() => data.pages.flatMap((p) => p.list), [data]);

  return (
    <div className={`grid gap-2 ${className}`}>
      {_files.map((f) => (
        <button
          onClick={() => {
            if (!files[f.id]) return setFiles({ ...files, [f.id]: f.name });
            const { [f.id]: _, ...rest } = files;
            setFiles(rest);
          }}
          className={`btn h-max py-2  text-start justify-start btn-sm ${files[f.id] ? "btn-neutral" : "underline"} `}
          key={f.id}
        >
          {f.name}
        </button>
      ))}

      {isLoading ? (
        <Rows />
      ) : (
        <InViewTrigger
          onInView={fetchNextPage}
          children={<LoadingSpin loading={isFetching} />}
        />
      )}
    </div>
  );
};

export default SelectFiles;
