import Dropdown from "@/components/Dropdown";
import { useOrganization } from "@/context/OrganizationContext";
import { TaxCodeType } from "@/pages/organization/taxCode/TaxCodeList";
import LoadingSpin from "@/ui/LoadingSpin";
import { api } from "@/utils/trpc";
import { FC } from "react";
import useTransactions from "../hooks/useTransactions";

type Props = {
  transactionId: string;
  taxCode: TaxCodeType | null;
  cardId?: string;
  amount: number;
  currency: string;
  showLabel?: boolean;
  className?: string;
};

const AssignTaxCode: FC<Props> = ({
  transactionId,
  taxCode,
  cardId,
  showLabel,
  className = "",
}) => {
  const { organizationId } = useOrganization<true>();

  const { data = [], isLoading } =
    api.organizations.taxCode.list.useQuery(organizationId);

  const add = api.transaction.taxCode.add.useMutation();
  const remove = api.transaction.taxCode.remove.useMutation();

  const update = useTransactions();

  const handleAdd = (taxCodeId: string) => async () => {
    const { taxCode } = await add.mutateAsync({ taxCodeId, transactionId });
    update(transactionId, { taxCode });
  };

  const handleRemove = async () => {
    await remove.mutateAsync(transactionId);

    update(transactionId, { taxCode: null });
  };

  const loading = add.isLoading || remove.isLoading;

  return (
    <div className={className}>
      {showLabel && (
        <label className="text-sm divider divider-start">Tax Code</label>
      )}
      <Dropdown
        dropdownClass="w-full min-w-max z-10 "
        buttonClass="bg-base-300"
        title={
          loading ? (
            <LoadingSpin loading />
          ) : taxCode ? (
            <span>
              {taxCode.name} ({taxCode.rate}%)
            </span>
          ) : (
            "N/A"
          )
        }
        footer={
          taxCode && (
            <button
              onClick={handleRemove}
              className="btn btn-error btn-sm w-full text-xs"
            >
              Remove ({taxCode.name})
            </button>
          )
        }
        content={
          isLoading
            ? ["Loading..."]
            : data.map((t) => (
                <button
                  onClick={handleAdd(t.id)}
                  onTouchEnd={handleAdd(t.id)}
                  key={t.id}
                >
                  {t.name} ({t.rate}%)
                </button>
              ))
        }
      />
    </div>
  );
};

export default AssignTaxCode;
