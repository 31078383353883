import Dropdown from "@/components/Dropdown";
import FormInput from "@/components/FormInput";
import Drawer from "@/components/drawer/Drawer";
import { useOrganization } from "@/context/OrganizationContext";
import { AvailableIntegrationType, IntegrationAppType } from "@/lib";
import { UpdateIntegration } from "@/types/validation";
import LoadingSpin from "@/ui/LoadingSpin";
import { api } from "@/utils/trpc";
import useForm from "@/utils/useForm";
import formatDistance from "date-fns/formatDistance";
import { FC, useEffect, useState } from "react";
import { FiMoreVertical } from "react-icons/fi";
import { MdDelete, MdEdit } from "react-icons/md";
import { PiInfoBold } from "react-icons/pi";
import { Integration } from "./Integration";

type Props = {
  item: AvailableIntegrationType;
  integration?: Integration;
  isLoading: boolean;
  setConnect: (appName: IntegrationAppType) => void;
};

const SingleIntegration: FC<Props> = ({
  item,
  integration,
  isLoading,
  setConnect,
}) => {
  const { organizationId = "" } = useOrganization();
  const sync = api.integrations.sync.useMutation();

  const { inputs, setValue, handleChange, setErrors, errors, setInputs } =
    useForm<Partial<Integration>>({});

  const [editing, setEditing] = useState(false);

  const utils = api.useUtils();
  const deleteIntegration = api.integrations.delete.useMutation();
  const update = api.integrations.update.useMutation();

  useEffect(() => {
    if (!integration) return;
    setInputs(integration);
  }, [integration]);

  const handleDelete = async (e: any) => {
    e.stopPropagation();

    if (!integration) return;

    await deleteIntegration.mutateAsync(integration.id);
    utils.integrations.list.setData(organizationId, (p) => {
      if (!p) return [];
      return p.filter((i) => i.id !== integration.id);
    });
  };

  const handleSync = async (id: string) => {
    const res = await sync.mutateAsync(id);

    utils.integrations.list.setData(organizationId, (p) => {
      if (!p) return [res];
      return p.map((i) => (i.id === id ? res : i));
    });
  };

  const handleUpdate = async () => {
    if (!integration) return;

    const valid = UpdateIntegration.safeParse(inputs);

    if (!valid.success) {
      setErrors(valid.error.formErrors.fieldErrors);
      return;
    }

    const res = await update.mutateAsync({
      ...valid.data,
      id: integration.id,
    });

    utils.integrations.list.setData(organizationId, (p) => {
      if (!p) return [res];
      return p.map((i) => (i.id === res.id ? res : i));
    });

    setInputs({});
    setEditing(false);
  };
  return (
    <div key={item.name} className="card w-80 bg-base-100 shadow-xl">
      <figure>
        <img
          src={item.logoUrl}
          className="w-full h-32 object-center object-cover"
          alt={item.name}
        />
      </figure>
      {!isLoading ? (
        <div className="card-body">
          <h2 className="card-title">
            {item.name}
            {!item.isActive ? (
              <span className="badge badge-secondary">coming soon!</span>
            ) : integration ? (
              <div className="">
                {integration.isError ? (
                  <div
                    className="tooltip cursor-pointer tooltip-error"
                    data-tip={integration.errorDescription}
                  >
                    <span className="badge badge-error gap-2 ">
                      Error <PiInfoBold />
                    </span>
                  </div>
                ) : (
                  <span
                    className="badge cursor-pointer badge-success tooltip tooltip-success"
                    data-tip={`Connected ${formatDistance(
                      integration.createdAt,
                      new Date(),
                      { addSuffix: true }
                    )}`}
                  >
                    Connected
                  </span>
                )}
              </div>
            ) : (
              <span className="badge badge-accent">Available now!</span>
            )}
          </h2>
          <p className="text-sm">{item.description}</p>

          {item.isActive && (
            <div className="card-actions justify-end">
              {integration ? (
                <div className="flex items-center">
                  <button
                    onClick={() => handleSync(integration.id)}
                    className="btn btn-primary btn-sm rounded-r-none"
                  >
                    <LoadingSpin loading={sync.isLoading} />
                    Sync
                  </button>
                  <Dropdown
                    title={<FiMoreVertical size={22} />}
                    hideArrow
                    buttonClass="btn-xs h-9 !w-6 !rounded-l-none"
                    dropdownClass="dropdown-bottom"
                    listClass="w-44"
                    content={[
                      <button
                        className="flex items-center gap-2  z-10 w-full"
                        onClick={handleDelete}
                      >
                        <MdDelete className="text-error" />
                        <LoadingSpin loading={deleteIntegration.isLoading} />
                        Delete
                      </button>,
                      <button className="z-10" onClick={() => setEditing(true)}>
                        <MdEdit className="text-accent" />
                        Edit
                      </button>,
                    ]}
                  />
                </div>
              ) : (
                <button
                  onClick={() => setConnect(item.name)}
                  className="btn btn-primary btn-sm"
                >
                  Connect
                </button>
              )}
            </div>
          )}
          {!!integration?.lastSyncedAt && (
            <p className="text-xs">
              last synced{" "}
              {formatDistance(integration.lastSyncedAt!, new Date(), {
                addSuffix: true,
              })}
            </p>
          )}
        </div>
      ) : (
        <div className="card-body">
          <span className="skeleton h-6 flex w-32" />
          <span className="skeleton h-6 flex w-full" />
          <span className="skeleton h-6 flex w-full" />
          <span className="skeleton h-6 flex w-full" />
        </div>
      )}

      <Drawer
        isOpen={editing}
        onClose={setEditing}
        title={`Add ${integration?.appName || ""} Integration`}
      >
        <FormInput
          onChange={handleChange("apiKey")}
          value={inputs.apiKey}
          placeholder="Enter API Key"
          title={`${integration?.appName} API Key`}
          isError={errors.apiKey}
        />

        <FormInput
          onChange={handleChange("subdomain")}
          value={inputs.subdomain}
          placeholder="Enter Subdomain"
          title={`${integration?.appName} Subdomain`}
          isError={errors.subdomain}
        />
        <p className="label text-gray-400 label-text-alt justify-start">
          Example: https:// <kbd className="kbd">dynt-ai</kbd> .kiwihr.com
        </p>

        <button onClick={handleUpdate} className="btn mt-6 w-full btn-primary">
          <LoadingSpin loading={update.isLoading} />
          Save
        </button>
      </Drawer>
    </div>
  );
};

export default SingleIntegration;

// <div key={i.name} className="card w-80 bg-base-100 shadow-xl">
// <figure>
//   <img
//     src={i.logoUrl}
//     className="w-full h-32 object-center object-cover"
//     alt={i.name}
//   />
// </figure>
// {!isLoading ? (
//   <div className="card-body">
//     <h2 className="card-title">
//       {i.name}
//       {!i.isActive ? (
//         <span className="badge badge-secondary">
//           coming soon!
//         </span>
//       ) : integrationMap[i.name] ? (
//         <div className="">
//           {integrationMap[i.name].isError ? (
//             <div
//               className="tooltip cursor-pointer tooltip-error"
//               data-tip={
//                 integrationMap[i.name].errorDescription
//               }
//             >
//               <span className="badge badge-error gap-2 ">
//                 Error <PiInfoBold />
//               </span>
//             </div>
//           ) : (
//             <span
//               className="badge cursor-pointer badge-success tooltip tooltip-success"
//               data-tip={`Connected ${formatDistance(
//                 integrationMap[i.name].createdAt,
//                 new Date(),
//                 { addSuffix: true }
//               )}`}
//             >
//               Connected
//             </span>
//           )}
//         </div>
//       ) : (
//         <span className="badge badge-accent">
//           Available now!
//         </span>
//       )}
//     </h2>
//     <p className="text-sm">{i.description}</p>

//     {i.isActive && (
//       <div className="card-actions justify-end">
//         {integrationMap[i.name] ? (
//           <div>
//             <button
//               onClick={() =>
//                 handleSync(integrationMap[i.name].id)
//               }
//               className="btn btn-primary btn-sm"
//             >
//               <LoadingSpin loading={sync.isLoading} />
//               Sync
//             </button>
//           </div>
//         ) : (
//           <button
//             onClick={() => {
//               setValue("appName", i.name);
//               setCreating(true);
//             }}
//             className="btn btn-primary btn-sm"
//           >
//             Connect
//           </button>
//         )}
//       </div>
//     )}
//     {!!integrationMap[i.name]?.lastSyncedAt && (
//       <p className="text-xs">
//         last synced{" "}
//         {formatDistance(
//           integrationMap[i.name].lastSyncedAt!,
//           new Date(),
//           { addSuffix: true }
//         )}
//       </p>
//     )}
//   </div>
// ) : (
//   <div className="card-body">
//     <span className="skeleton h-6 flex w-32" />
//     <span className="skeleton h-6 flex w-full" />
//     <span className="skeleton h-6 flex w-full" />
//     <span className="skeleton h-6 flex w-full" />
//   </div>
// )}
// </div>
