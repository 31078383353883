import Drawer from "@/components/drawer/Drawer";
import { useOrganization } from "@/context/OrganizationContext";
import { documentTypes } from "@/lib";
import { DocumentTypesType, EditFile_v } from "@/types/validation";
import LoadingSpin from "@/ui/LoadingSpin";
import { api } from "@/utils/trpc";
import useForm from "@/utils/useForm";
import { Select, SelectItem } from "@tremor/react";
import { FC } from "react";
import { File } from "./Files";

const fields = ["name", "description"] as const;

type Props = {
  file: File;
  folderId: string | null;
  editing: boolean;
  setEditing: (e: boolean) => void;
};

const EditFile: FC<Props> = ({ file, folderId, editing, setEditing }) => {
  const utils = api.useUtils();
  const { organizationId = "" } = useOrganization();

  const { inputs, setValue, handleChange, errors, setErrors, setInputs } =
    useForm(file);
  const edit = api.organizations.editFile.useMutation();

  const handleCreate = async () => {
    const valid = EditFile_v.safeParse(inputs);

    if (!valid.success) {
      return setErrors(valid.error.formErrors.fieldErrors);
    }

    const res = await edit.mutateAsync({ ...valid.data, id: file.id });

    utils.organizations.files.setInfiniteData(
      { organizationId, folderId },
      (p) => {
        if (!p) return p;
        return {
          ...p,
          pages: p.pages.map((page) => ({
            ...page,
            list: page.list.map((l) => (l.id === file.id ? res : l)),
          })),
        };
      }
    );

    setEditing(false);
  };

  const filename = inputs.name.slice(0, inputs.name.lastIndexOf("."));
  const ext = inputs.name.slice(inputs.name.lastIndexOf("."));

  return (
    <Drawer title="Edit File" isOpen={editing} onClose={setEditing}>
      <div className="grid gap-4">
        <div>
          <p className="label label-text-alt">name</p>
          <div className="flex items-center">
            <input
              type="text"
              className="input input-sm flex-1 rounded-r-none input-bordered w-full"
              placeholder="File name"
              value={filename}
              onChange={(e) => setValue("name", e.target.value + ext)}
            />
            <p className="label bg-neutral rounded-l-none rounded-lg h-8">
              {file.name.slice(file.name.lastIndexOf("."))}
            </p>
          </div>

          <p className="label capitalize label-text-alt">Description</p>
          <input
            type="text"
            className="input input-sm input-bordered w-full"
            placeholder="File description"
            value={inputs.description!}
            onChange={handleChange("description")}
          />
          <p className="label label-text-alt">File Type</p>
          <Select
            value={inputs.type!}
            onValueChange={(t) =>
              setValue("type", (t as DocumentTypesType) || null)
            }
          >
            {documentTypes.map((d) => (
              <SelectItem
                value={d}
                className="grid !w-full  !p-0 !justify-start"
              >
                {d}
              </SelectItem>
            ))}
          </Select>
        </div>

        <button
          className="btn sticky bottom-1 mt-6  self-end btn-primary"
          onClick={handleCreate}
        >
          <LoadingSpin loading={edit.isLoading} />
          Save
        </button>
      </div>
    </Drawer>
  );
};

export default EditFile;
