import { useUser } from "@/context/UserContext";
import { FC, type ReactNode } from "react";

type Props = {
  children: ReactNode;
  step: 1 | 2 | 3 | 4;
};

const steps = ["Sign Up", "Personal Details", "Company Details"] as const;

const CommonLayout: FC<Props> = ({ children, step }) => {
  const { logout } = useUser();

  const inviteId = localStorage.getItem("@inviteId");
  return (
    <div className="overflow-auto flex flex-col  items-center gap-6 2xl:gap-16 2xl:p-16 p-6 xl:gap-12 xl:p-12">
      <div className="text-xs sm:text-sm 2xl:text-base z-10  ">
        <ul className="steps ">
          {steps.map((s, i) => (
            <li
              key={s}
              data-content={i + 1 < step ? "✓" : undefined}
              className={`step  ${
                i + 1 <= step ? "step-primary" : "stepsecondary"
              }`}
            >
              <div className="flex justify-center">
                <p className="opacity-0 ">0000</p>
                {s === "Company Details" ? (inviteId ? "Invitation" : s) : s}
                <p className="opacity-0 ">0000</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="max-w-md min-w-fit w-2/3 justify-items-center grid gap-6 bg-base-100 p-8 rounded-xl shadow-md">
        {children}
      </div>

      {step > 1 && (
        <button onClick={logout} className="btn btn-outline btn-error btn-sm">
          Logout
        </button>
      )}
    </div>
  );
};

export default CommonLayout;
