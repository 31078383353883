export const countries = [
  {
    name: "Portugal",
    code: "PT",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Flag_of_Portugal.svg/640px-Flag_of_Portugal.svg.png",
    id: 182,
  },
  {
    id: 161,
    name: "Netherlands",
    code: "NL",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/20/Flag_of_the_Netherlands.svg/1200px-Flag_of_the_Netherlands.svg.png",
  },
  {
    id: 233,
    name: "United Kingdom",
    code: "GB",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Flag_of_the_United_Kingdom_%281-2%29.svg/640px-Flag_of_the_United_Kingdom_%281-2%29.svg.png",
  },
  {
    id: 234,
    name: "United States",
    code: "US",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Flag_of_the_United_States.svg/640px-Flag_of_the_United_States.svg.png",
  },
];

export type Country = (typeof countries)[0];

export const countriesMap: Record<number | string, Country> = {
  "161": {
    id: 161,
    name: "Netherlands",
    code: "NL",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/20/Flag_of_the_Netherlands.svg/1200px-Flag_of_the_Netherlands.svg.png",
  },
  "233": {
    id: 233,
    name: "United Kingdom",
    code: "GB",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Flag_of_the_United_Kingdom_%281-2%29.svg/640px-Flag_of_the_United_Kingdom_%281-2%29.svg.png",
  },
  "234": {
    id: 234,
    name: "United States",
    code: "US",
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Flag_of_the_United_States.svg/640px-Flag_of_the_United_States.svg.png",
  },
};

export type CountryMap = typeof countriesMap;
