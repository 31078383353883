import Rows from "@/ui/skeletons/Rows";
import { api } from "@/utils/trpc";
import { FC } from "react";
import toast from "react-hot-toast";
import { HiPlusCircle } from "react-icons/hi";
import { RiRefreshLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import Card from "./Card";
import CreateCard from "./CreateCard";

type Props = {
  accountId: string;
};

const CardsList: FC<Props> = ({ accountId }) => {
  const {
    data = [],
    isLoading,
    refetch,
    isRefetching,
  } = api.swan.card.list.useQuery(accountId);

  const handleRefetch = async () => {
    await refetch();
    toast.success("Refetch successful!");
  };

  if (isLoading) return <Rows count={3} />;

  return (
    <div className="grid gap-6">
      <div className="flex items-center w-fit justify-self-end gap-2">
        <button
          className="btn btn-secondary btn-outline btn-sm"
          onClick={handleRefetch}
        >
          <RiRefreshLine
            size={20}
            className={isRefetching ? "animate-spin" : ""}
          />
        </button>
        <Link to={"?new"} className="btn ml-auto btn-secondary btn-sm">
          <HiPlusCircle size={22} /> New
        </Link>
      </div>
      <table className="table bg-base-200 ">
        <thead>
          <tr>
            <th>Cardholder</th>
            <th>Card name</th>
            <th className="text-">Card</th>
            <th className="text-center">Spending</th>
            <th>Type</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.map((card) => (
            <Card card={card} key={card.id} />
          ))}
        </tbody>
      </table>

      <CreateCard accountId={accountId} />
    </div>
  );
};

export default CardsList;
