import { formatCurrency, logError } from "@/utils/helper";
import { api } from "@/utils/trpc";
import Papa from "papaparse";
import React, { useState } from "react";
import toast from "react-hot-toast";

import DropZoneButton from "@/components/DropZone/DropZoneButton";
import Select from "@/components/Select";
import { useOrganization } from "@/context/OrganizationContext";
import { months } from "@/lib";
import { CreatePayRun, CreatePayRunType } from "@/types/validation";
import Collapsible from "@/ui/Collapsible";
import LoadingSpin from "@/ui/LoadingSpin";
import { PageTitle } from "@/ui/PageTitle";
import Rows from "@/ui/skeletons/Rows";
import startOfDay from "date-fns/startOfDay";
import { MdClose } from "react-icons/md";

const Payroll: React.FC = () => {
  const [csvData, setCsvData] = useState<CreatePayRunType[]>([]);
  const { organizationId = "" } = useOrganization();
  const [date, setDate] = useState(startOfDay(new Date()));

  const create = api.people.createPayRuns.useMutation();

  const utils = api.useUtils();

  const { data = [], isLoading } = api.people.payRuns.useQuery({
    organizationId,
    date,
  });

  const handleFile = (file: File | null) => {
    if (!file) return;
    Papa.parse(file, {
      header: true,
      dynamicTyping: true,
      complete: ({ data }) => {
        data.forEach((d: any) => {
          const valid = CreatePayRun.safeParse({
            employeeId: d["Employee ID"],
            employeeName: d["Employee name"],
            amount: d.Amount,
            currency: d.Currency,
            description: d.Description,
          });

          if (valid.success) setCsvData((p) => [...p, valid.data]);
          else console.log(valid.error);
        });
      },
      error: (error) => {
        logError(error);
        toast.error("Error parsing CSV file");
      },
    });
  };

  const handleCreate = async () => {
    const res = await create.mutateAsync(
      csvData.map((d) => ({ ...d, date, organizationId }))
    );

    utils.people.payRuns.setData({ organizationId, date }, (p) => {
      if (!p) return [];
      return [...res, ...p];
    });

    setCsvData([]);

    toast.success("Payroll created successfully");
  };

  return (
    <div>
      <PageTitle title="Payroll" />
      <div className=" w-full items-center flex justify-between my-6 ">
        <Select
          options={months.map((m) => ({
            value: m.value.toString(),
            label: m.fullName,
          }))}
          className="w-fit"
          value={date.getMonth().toString()}
          onChange={(e) => setDate(startOfDay(new Date(date.setMonth(+e))))}
        />

        <div>
          <DropZoneButton
            files={[]}
            setFiles={(f) => handleFile(f[0])}
            multiple={false}
            accept="text/csv"
          />
        </div>
      </div>
      <Collapsible
        hide={!csvData.length}
        defaultOpen
        header={
          <div className="flex justify-between items-center">
            <h1 className="label gap-2">
              New Payroll <span className="badge badge-warning">preview</span>{" "}
            </h1>

            <div className="flex items-center gap-2">
              <button
                onClick={handleCreate}
                className="btn z-10 btn-sm text-xs btn-primary"
              >
                <LoadingSpin loading={create.isLoading} />
                Create
              </button>
              <button
                className="btn z-10 btn-error btn-sm btn-outline"
                onClick={() => setCsvData([])}
              >
                <MdClose />{" "}
              </button>
            </div>
          </div>
        }
      >
        <table className="table">
          <thead>
            <tr>
              <th>Id</th>
              <th>Name</th>
              <th>Salary</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {csvData.map((r, i) => (
              <tr key={i}>
                <td>{r.employeeId}</td>
                <td>{r.employeeName}</td>
                <td>{formatCurrency(r.amount, r.currency)}</td>
                <td>{r.description}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Collapsible>

      {!csvData.length && (
        <div className="overflow-x-auto">
          <table className="table">
            <thead>
              <tr>
                <th>Id</th>
                <th>Name</th>
                <th>Salary</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {data.map((r, i) => (
                <tr key={i}>
                  <td>{r.staff.appId}</td>
                  <td>{r.staff.name}</td>
                  <td>{formatCurrency(+r.amount, r.currency)}</td>
                  <td>{r.description}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {isLoading && <Rows />}
    </div>
  );
};

export default Payroll;
