import LoadingSpin from "@/ui/LoadingSpin";
import { resetPassword } from "@/utils/supabase";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import isEmail from "validator/es/lib/isEmail";

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();

  const reset = useMutation(resetPassword);
  const [email, setEmail] = useState("");

  const handleAuth = async () => {
    if (!isEmail(email)) return toast.error("Enter a valid email");
    await reset.mutateAsync(email);

    navigate("/login");
  };

  return (
    <div className="grid place-items-center h-screen overflow-auto  2xl:p-16 p-6 xl:gap-12 xl:p-12">
      <div className="max-w-md min-w-fit w-2/3 justify-items-center grid gap-6 bg-base-100 p-8 rounded-xl shadow-md">
        <div>
          <h3 className="sm:text-2xl text-2xl text-center font-bold ">
            Forgot Password
          </h3>
          <p className="sm:text-xs text-sm text-center">
            Enter your email and we'll send you a link to reset your password.
          </p>
        </div>

        <div className="w-full">
          <label className="label label-text">Your Email</label>
          <input
            placeholder="Enter your email"
            className="input input-bordered shadow-sm w-full"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <button onClick={handleAuth} className="btn  w-full btn-primary">
          <LoadingSpin loading={reset.isLoading} />
          Submit
        </button>

        <Link to={"/login"} className="link link-primary text-sm mr-auto">
          Back to login
        </Link>
      </div>
    </div>
  );
};

export default ForgotPassword;
