import { useOrganization } from "@/context/OrganizationContext";
import LoadingSpin from "@/ui/LoadingSpin";
import { api } from "@/utils/trpc";
import useForm from "@/utils/useForm";
import { Dispatch, FC, SetStateAction } from "react";
import { toast } from "react-hot-toast";

import { errMessage } from "@/lib";
import { DatePicker } from "@tremor/react";
import { addDays } from "date-fns";
import compareAsc from "date-fns/compareAsc";
import { Event } from "./Events";

const defaultValues = {
  name: "",
  startDate: new Date(),
  endDate: addDays(new Date(), 1),
};

type Tab = keyof typeof defaultValues;

type Props = {
  event?: Event | null;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const EventDetails: FC<Props> = ({ event: event, setIsOpen }) => {
  const { handleChange, inputs, errors, changed, setValue, setErrors } =
    useForm(event ?? defaultValues);

  const utils = api.useUtils();
  const { organizationId: orgId = "" } = useOrganization();

  const upsert = api.expenses.upsertEvent.useMutation();
  const deleteEvent = api.expenses.deleteEvent.useMutation();
  const handleUpdate = async (_photo?: string) => {
    if (!orgId) return;

    const { endDate, name, startDate } = inputs;

    const _errors: { [key in Tab]?: string } = {};

    if (!name) _errors.name = errMessage;
    if (!startDate) _errors.startDate = errMessage;
    if (!endDate) _errors.endDate = errMessage;

    if (compareAsc(startDate, endDate) === 1) {
      _errors.endDate = "End date should be greater than start date";
    }

    if (Object.values(_errors).length) {
      setErrors(_errors);
      return toast.error("Fill all the required fields");
    }

    const event = await upsert.mutateAsync({
      ...inputs,
      organizationId: orgId,
    });

    utils.expenses.events.setData(orgId, (p) => {
      if (!p) return [];
      if (event.id) {
        return [event, ...p.filter((t) => t.id !== event.id)];
      }
      return [event, ...p];
    });
    setIsOpen(false);
  };

  const handleDelete = (id: string) => async () => {
    if (event?._count.expenses)
      return toast.error("Cannot delete event with expenses");

    await deleteEvent.mutateAsync(id);

    utils.expenses.events.setData(orgId, (p) => {
      if (!p) return [];
      return p.filter((t) => t.id !== id);
    });
    setIsOpen(false);
  };

  return (
    <div className="flex gap-5 items-stretch flex-col bg-grey-lighter min-h-screen">
      <div className="grid gap-5">
        <div>
          <label className="capitalize text-slate-400   font-base text-xs  ">
            Event name
          </label>
          <input
            type="text"
            placeholder="Name of the event"
            value={inputs.name}
            onChange={handleChange("name")}
            className="border border-gray-200  font-medium  text-base-content text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2 "
          />

          <p className="text-red-500 italic text-xs font-semibold mt-1">
            {errors.name}
          </p>
        </div>
        <div className="grid gap-2 grid-cols-2">
          <div>
            <p className="capitalize text-slate-400   font-base text-xs  ">
              Start date
            </p>
            <DatePicker
              value={inputs.startDate}
              onValueChange={(e) => e && setValue("startDate", e)}
            />
          </div>
          <div>
            <p className="capitalize text-slate-400   font-base text-xs  ">
              End date
            </p>
            <DatePicker
              value={inputs.endDate}
              onValueChange={(e) => e && setValue("endDate", e)}
            />
          </div>
        </div>

        <button
          className={`bg-green-500 text-white rounded-md  text-sm p-2 mt-3  w-full font-medium relative ${
            changed ? "" : "opacity-50 cursor-not-allowed"
          }`}
          onClick={() => handleUpdate()}
        >
          <LoadingSpin loading={upsert.isLoading} />
          {event?.id ? "Update" : "Create"}
        </button>
        {!!event?.id && (
          <button
            className={`bg-danger text-white rounded-md  text-sm p-2 mt-3  w-full font-medium relative`}
            onClick={handleDelete(event.id)}
          >
            <LoadingSpin loading={deleteEvent.isLoading} />
            Delete
          </button>
        )}
      </div>
    </div>
  );
};

export default EventDetails;
