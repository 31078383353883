import { TransactionHistory } from "./Transactions";

export const formatCSV = (data: TransactionHistory[]) => {
  return data.map((t) => ({
    id: t.id,
    date: t.date,
    amount: t.amount,
    currency: t.currency,
    merchant: t.merchant?.name || "N/A",
    account: t.account?.name,
    bank: t.account?.bankName,
    status: t.status,
    category: t.category?.name || "N/A",
    recurring: t.isRecurring ? "yes" : "no",
    proof: t.proofs.map((p) => `https://app.dynt.ai/files/${p.fileId}`),
    accounting_status: t.accountingStatus,
  }));
};
