import LoadingSpin from "@/ui/LoadingSpin";
import { api } from "@/utils/trpc";
import format from "date-fns/format";
import { FC, useState } from "react";
import { toast } from "react-hot-toast";

type Props = {
  invoiceId: string;
  setScheduling: (a: boolean) => void;
  dueDate?: Date;
};

const ScheduleInvoice: FC<Props> = ({ invoiceId, setScheduling, dueDate }) => {
  const [dateTime, setDateTime] = useState(
    format(dueDate || new Date(), "yyyy-MM-dd'T'HH:mm")
  );
  const [error, setError] = useState("");

  const schedule = api.invoices.createInvoiceSchedule.useMutation();
  const cancel = api.invoices.cancelScheduledInvoice.useMutation();

  const utils = api.useUtils();

  const handleSchedule = async () => {
    if (error) return;
    const invoice = await schedule.mutateAsync({
      invoiceId,
      dueDate: new Date(dateTime),
    });

    if (!invoice) return toast.error("Something went wrong");

    utils.invoices.invoiceById.setData(invoiceId, (p) => {
      if (!p) return;
      return { ...p, status: "scheduled", schedule: invoice.schedule };
    });
    setScheduling(false);
  };

  const handleCancel = async () => {
    const invoice = await cancel.mutateAsync(invoiceId);
    if (!invoice) return toast.error("Something went wrong");

    utils.invoices.invoiceById.setData(invoiceId, (p) => {
      if (!p) return;
      return { ...p, status: "draft", schedule: null };
    });
    setScheduling(false);
  };

  return (
    <div className="grid gap-5">
      <input
        type="datetime-local"
        className="w-full  py-2 border-2 border-base-300 rounded-md"
        value={dateTime}
        onChange={(e) => {
          const _date = new Date(e.target.value);
          if (_date < new Date()) {
            return setError("Date must be in the future");
          }
          setDateTime(e.target.value);
          setError("");
        }}
      />
      {error && <p className="err mt-1">{error}</p>}
      <button
        className={`w-full py-2 ${
          dueDate ? "bg-secondary" : "bg-primary"
        } text-white rounded-md relative`}
        onClick={handleSchedule}
        disabled={!!error}
      >
        <LoadingSpin loading={schedule.isLoading} />
        {dueDate ? "Re schedule" : "Schedule"}
      </button>

      {dueDate && (
        <button
          onClick={handleCancel}
          className="w-full py-1 bg-danger text-white rounded-md relative"
        >
          Cancel
          <LoadingSpin loading={cancel.isLoading} />
        </button>
      )}
    </div>
  );
};

export default ScheduleInvoice;
