import Dropdown from "@/components/Dropdown";
import { useOrganization } from "@/context/OrganizationContext";
import { generateColor } from "@/utils/helper";
import { api } from "@/utils/trpc";
import { FC, useMemo, useState } from "react";
import { HiChevronRight, HiPlus } from "react-icons/hi";
import LoadingSpin from "./LoadingSpin";

type Props = {
  invoiceId?: string;
  billId?: string;
  expenseId?: string;
  assignees: string[];
};

const AssignTeam: FC<Props> = ({ invoiceId, assignees, billId, expenseId }) => {
  const { organizationId = "" } = useOrganization();

  const { data: members = [], isLoading } =
    api.organizations.teamMembers.useQuery(organizationId);
  const utils = api.useUtils();

  const assignToInvoice = api.invoices.assignTeam.useMutation();
  const assignToBill = api.bills.assignTeam.useMutation();
  const assignToExpense = api.expenses.assignTeam.useMutation();

  const [selected, setSelected] = useState<string[]>(assignees);
  const [changed, setChanged] = useState(false);

  const handleAssign = async () => {
    if (invoiceId) {
      const res = await assignToInvoice.mutateAsync({
        invoiceId,
        assignees: selected,
      });

      utils.invoices.invoiceById.setData(invoiceId, (p) => {
        if (!p) return p;
        return { ...p, assignees: res };
      });
    }

    if (billId) {
      const res = await assignToBill.mutateAsync({
        billId,
        assignees: selected,
      });

      utils.bills.billById.setData(billId, (p) => {
        if (!p) return p;
        return { ...p, assignees: res };
      });
    }

    if (expenseId) {
      const res = await assignToExpense.mutateAsync({
        expenseId,
        assignees: selected,
      });

      utils.expenses.get.setData(expenseId, (p) => {
        if (!p) return p;
        return { ...p, assignees: res };
      });
    }
    setChanged(false);
  };

  const loading =
    assignToBill.isLoading ||
    assignToInvoice.isLoading ||
    assignToExpense.isLoading;

  const selectSet = new Set(selected);

  const membersMap = useMemo(
    () => Object.fromEntries(members.map((m) => [m.id, m])),
    [members]
  );

  return (
    <div>
      <div className="label label-text-alt">Members</div>
      <div className="flex items-center gap-1">
        {assignees.map((a) => {
          if (!membersMap[a]) return null;

          return (
            <div
              className="tooltip tooltip-right hover:z-20 tooltip-accent"
              data-tip={membersMap[a].user.name}
            >
              <div
                className="btn btn-circle text-black btn-sm "
                style={{
                  backgroundColor: generateColor(membersMap[a].user.name || ""),
                }}
              >
                {membersMap[a].user.name?.slice(0, 1)}
              </div>
            </div>
          );
        })}

        <Dropdown
          title={loading ? <LoadingSpin loading /> : <HiPlus />}
          dropdownClass="w-60 "
          hideArrow
          buttonClass="btn-neutral btn-circle !w-8 btn-sm"
          content={members.map((s) => (
            <button
              className={`justify-between hover:bg-neutral capitalize ${
                selectSet.has(s.id) ? "bg-neutral" : "bg-base-300"
              }`}
              onClick={(e) => {
                e.stopPropagation();
                if (selectSet.has(s.id)) {
                  setSelected(selected.filter((id) => id !== s.id));
                } else setSelected([...selected, s.id]);
                setChanged(true);
              }}
              onTouchEnd={(e) => {
                e.stopPropagation();
                if (selectSet.has(s.id)) {
                  setSelected(selected.filter((id) => id !== s.id));
                } else setSelected([...selected, s.id]);
                setChanged(true);
              }}
            >
              {s.user.name}
              <HiChevronRight />
            </button>
          ))}
          footer={
            changed && (
              <div className="w-full sticky bottom-0">
                <button
                  onClick={handleAssign}
                  onTouchEnd={handleAssign}
                  className="btn btn-primary sticky bottom-0 w-48 btn-sm"
                >
                  Save
                </button>
              </div>
            )
          }
        />
      </div>
    </div>
  );
};

export default AssignTeam;
