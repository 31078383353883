import { api } from "@/utils/trpc";
import { FC } from "react";
import { AiTwotoneBank } from "react-icons/ai";
import Spinner from "../../components/utils/spinner";
import { AccountInfo } from "../invoices/SelectOrganizationAccount";

type Props = {
  setAccountInfo: (info: AccountInfo) => void;
  prevSelection?: string;
  vendorId: string;
};

const SelectVendorAccount: FC<Props> = ({
  setAccountInfo,

  vendorId,
}) => {
  const { data = [], isLoading } =
    api.manualAccounts.vendorAccounts.useQuery(vendorId);

  if (isLoading) return <Spinner />;

  return (
    <div className="grid gap-1 my-4">
      {data.map((a) => (
        <button
          className="btn h-fit  w-full justify-between"
          key={a.id}
          onClick={() =>
            setAccountInfo({
              ...a,
              countryCode: a.country.iso2,
            })
          }
        >
          <AiTwotoneBank />
          <div className="flex-1 w-1/3 break-words text-left">
            <p className="lg:text-sm text-xs font-semibold">{a.name}</p>
            <p className="text-xs">
              {a.IBAN || a.ACCOUNT_NUMBER || a.ROUTING_NUMBER}
            </p>
          </div>
        </button>
      ))}
    </div>
  );
};

export default SelectVendorAccount;
