import { setTitle } from "@/utils/helper";
import { signUpWithOAuth } from "@/utils/supabase";
import { useMutation } from "@tanstack/react-query";
import { FC, useEffect } from "react";
import { FcGoogle } from "react-icons/fc";
import { Link } from "react-router-dom";
import CommonLayout from "./CommonLayout";

const SignUp: FC = () => {
  useEffect(() => setTitle("Sign up"), []);

  const OAuth = useMutation(signUpWithOAuth);

  const handleOAuth = async () => {
    await OAuth.mutateAsync("google");
  };

  return (
    <CommonLayout step={1}>
      <div className="grid justify-items-center gap-2">
        <img
          src="https://api.dynt.ai/static/logo-192.png"
          alt="Dynt"
          className="w-20 rounded-lg"
        />
        <h3 className="sm:text-2xl text-2xl text-center font-bold ">
          Create your Dynt.ai account
        </h3>
        <p className="sm:text-xs text-sm text-center">
          Get started for free. No credit card required.
        </p>
      </div>
      <button onClick={handleOAuth} className="btn w-full btn-neutral my-4">
        <FcGoogle size={30} />
      </button>

      <div className="flex items-center text-sm gap-2">
        <p>Already have an account?</p>
        <Link className="link link-primary text-sm" to={"/login"}>
          Sign in
        </Link>
      </div>
    </CommonLayout>
  );
};

export default SignUp;
