import { useUser } from "@/context/UserContext";
import { supabase } from "@/utils/supabase";
import { FC, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const ResetPassword: FC = () => {
  const navigate = useNavigate();
  const { session } = useUser();
  const [credentials, setCredentials] = useState({
    password: "",
    passwordConfirm: "",
  });

  const updateUserDetails = async () => {
    if (credentials.password !== credentials.passwordConfirm)
      return toast.error("Passwords don't match");
    if (!session?.user) return;

    const { error } = await supabase.auth.updateUser({
      password: credentials.password,
    });

    if (error) return toast.error(error.message);
    toast("User details updated");
    navigate("/");
  };

  return (
    <div className="grid place-items-center h-screen overflow-auto 2xl:p-16 p-6 xl:gap-12 xl:p-12">
      <div className="max-w-md min-w-fit w-2/3 justify-items-center grid gap-6 bg-base-100 p-8 rounded-xl shadow-md">
        <div>
          <h3 className="sm:text-2xl text-2xl text-center font-bold ">
            Update Password
          </h3>
          <p className="sm:text-xs text-sm text-center">
            Enter a new strong password
          </p>
        </div>
        <div className="w-full">
          <label htmlFor="password" className="label label-text">
            Password
          </label>
          <input
            type="password"
            value={credentials.password}
            onChange={(e) =>
              setCredentials({ ...credentials, password: e.target.value })
            }
            placeholder="****"
            className="input input-bordered w-full shadow-sm"
          />
        </div>
        <div className="w-full">
          <label htmlFor="confirm" className="label label-text">
            Confirm Password
          </label>
          <input
            type="password"
            value={credentials.passwordConfirm}
            onChange={(e) =>
              setCredentials({
                ...credentials,
                passwordConfirm: e.target.value,
              })
            }
            placeholder="****"
            className="input input-bordered w-full shadow-sm"
          />
        </div>
        <button className="btn btn-primary w-full" onClick={updateUserDetails}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default ResetPassword;
