import { useOrganization } from "@/context/OrganizationContext";
import {
  ProofStatuses,
  accountingStatuses,
  approvalStatuses,
  billStatuses,
  currencies,
  expenseStatuses,
  invoiceStatuses,
} from "@/lib";
import { BaseType, Enums } from "@/types";
import { formatLabel } from "@/utils/helper";
import { api } from "@/utils/trpc";
import { ConditionProperties } from "json-rules-engine";
import React, { FC } from "react";
import { FaDeleteLeft } from "react-icons/fa6";

export type ProductType = Enums["ProductType"];
const products: ProductType[] = ["INVOICE", "BILL", "EXPENSE", "TRANSACTION"];

export const facts = [
  "status",
  "accountingStatus",
  "approvalStatus",
  "proofStatus",
  "amount",
  "description",
  "merchant",
] as const;

export type Fact = (typeof facts)[number];

const factTypeMap: Record<Fact, "string" | "number" | "array" | "entity"> = {
  status: "string",
  accountingStatus: "string",
  approvalStatus: "string",
  proofStatus: "string",
  amount: "number",
  description: "array",
  merchant: "entity",
};

const operators = {
  string: ["equal", "notEqual"],
  entity: ["equal", "notEqual"],
  number: [
    "greaterThan",
    "greaterThanInclusive",
    "lessThan",
    "lessThanInclusive",
  ],
  array: ["contains", "doesNotContain"],
} as const;

const statuses: Record<ProductType, Readonly<string[]>> = {
  BILL: billStatuses,
  INVOICE: invoiceStatuses,
  EXPENSE: expenseStatuses,
  TRANSACTION: [],
};

export type Condition = Partial<ConditionProperties> & { fact: string };

type Props = {
  product: ProductType;
  setProduct: React.Dispatch<React.SetStateAction<ProductType>>;
  currency: string | null;
  setCurrency: React.Dispatch<React.SetStateAction<string | null>>;
  errors: Partial<Record<string, string | string[]>>;
  inputs: Record<string, Condition>;
  setInputs: React.Dispatch<React.SetStateAction<Record<string, Condition>>>;
  setValue: <A extends string, B extends Condition>(
    key: A,
    value: B | ((prevState: B) => B)
  ) => void;
};

const Conditions: FC<Props> = ({
  currency,
  errors,
  inputs,
  product,
  setCurrency,
  setInputs,
  setProduct,
  setValue,
}) => {
  const { organizationId = "" } = useOrganization();

  const { data: merchants = [] } =
    api.transactions.merchants.useQuery(organizationId);

  const statusMap: Record<string, Readonly<string[]>> = {
    status: statuses[product],
    approvalStatus: approvalStatuses,
    accountingStatus: accountingStatuses,
    proofStatus: ProofStatuses,
  };

  const entityMap: Record<string, BaseType[]> = {
    merchant: merchants,
  };
  const addCondition = () => {
    const id = Math.random();
    setInputs((p) => ({ ...p, [id]: { fact: "status" } }));
  };
  return (
    <div className="grid gap-4">
      <div className="flex items-center gap-4">
        <div className="">
          <p className="label text-secondary">For every</p>

          <select
            className="select capitalize"
            value={product}
            onChange={(e) => {
              setProduct(e.target.value as ProductType);
            }}
          >
            <option disabled>Select a product</option>
            {products.map((t) => (
              <option value={t}>{formatLabel(t)}</option>
            ))}
          </select>
        </div>
        <div className="">
          <p className="label text-secondary">in</p>

          <select
            className="select "
            value={currency || ""}
            placeholder="currency"
            onChange={(e) => setCurrency(e.target.value)}
          >
            <option value={""}>All currency</option>
            {currencies.map((t) => (
              <option value={t}>{t}</option>
            ))}
          </select>
        </div>
      </div>
      {Object.entries(inputs).map(([id, inputs], i, { length }) => {
        const { fact, operator, value } = inputs;
        const triggerType = factTypeMap[fact as Fact];

        return (
          <div className="" key={id}>
            <div className="flex gap-4 items-end rounded-lg w-fit  justify-between ">
              <div>
                <p className="label text-secondary">
                  {i ? "And if the" : "If the"}
                </p>
                <select
                  className="select capitalize"
                  value={fact}
                  onChange={(e) =>
                    setValue(id, { fact: e.target.value as Fact })
                  }
                >
                  {facts.map((t) => (
                    <option value={t}>
                      {formatLabel(t.replace("proofStatus", "proof status"))}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <p className="label text-secondary">
                  {inputs.fact !== "description" ? "is" : ""}
                </p>
                <select
                  className={`select ${
                    errors[id] && !operator ? "select-error" : ""
                  } `}
                  value={operator || ""}
                  placeholder="Select an operator"
                  onChange={(e) =>
                    setValue(id, (p) => ({
                      ...p,
                      operator: e.target.value,
                    }))
                  }
                >
                  <option disabled value="">
                    Select an operator
                  </option>
                  {operators[triggerType].map((t) => (
                    <option value={t}>{formatLabel(t)}</option>
                  ))}
                </select>
              </div>
              <div>
                {triggerType === "string" && (
                  <select
                    className={`select ${
                      errors[id] && !value ? "select-error" : ""
                    } `}
                    placeholder="Select a value"
                    value={inputs.value || ""}
                    onChange={(e) =>
                      setValue(id, (p) => ({ ...p, value: e.target.value }))
                    }
                  >
                    <option disabled value="">
                      Select a value
                    </option>
                    {statusMap[inputs.fact].map((t) => (
                      <option value={t}>{formatLabel(t)}</option>
                    ))}
                  </select>
                )}
                {triggerType === "entity" && (
                  <select
                    className={`select ${
                      errors[id] && !value ? "select-error" : ""
                    } `}
                    placeholder="Select a value"
                    value={inputs.value || ""}
                    onChange={(e) =>
                      setValue(id, (p) => ({ ...p, value: e.target.value }))
                    }
                  >
                    <option disabled value="">
                      Select a value
                    </option>
                    {entityMap[inputs.fact].map((t) => (
                      <option value={t.id}>{t.name}</option>
                    ))}
                  </select>
                )}

                {triggerType === "number" && (
                  <input
                    type="number"
                    className={`input ${
                      errors[id] && !value ? "select-error" : ""
                    }  `}
                    placeholder="Enter a value"
                    value={inputs.value || ""}
                    onChange={(e) =>
                      setValue(id, (p) => ({ ...p, value: e.target.value }))
                    }
                  />
                )}
                {triggerType === "array" && (
                  <input
                    className={`input ${
                      errors[id] && !value?.length ? "select-error" : ""
                    }`}
                    placeholder="Enter a value"
                    value={
                      Array.isArray(inputs.value) ? inputs.value.join(" ") : ""
                    }
                    onChange={({ target: { value } }) =>
                      setValue(id, (p) => ({
                        ...p,
                        value: value ? value.split(" ") : [],
                      }))
                    }
                  />
                )}
              </div>

              {length > 1 && (
                <button
                  className="btn mb-2 btn-error btn-outline border-none btn-sm"
                  onClick={() =>
                    setInputs((p) => {
                      const { [id]: _, ...rest } = p;
                      return rest;
                    })
                  }
                >
                  <FaDeleteLeft />
                </button>
              )}
            </div>
            {length - 1 === i && (
              <button
                className="no-underline btn-link mt-4"
                onClick={addCondition}
              >
                + AND
              </button>
            )}
          </div>
        );
      })}{" "}
    </div>
  );
};

export default Conditions;
