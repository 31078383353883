import { useUser } from "@/context/UserContext";
import Rows from "@/ui/skeletons/Rows";
import { api } from "@/utils/trpc";
import { FC } from "react";
import { IoMdCheckmarkCircleOutline, IoMdRemoveCircle } from "react-icons/io";

type Props = {
  reviewId: string;
  expenseId: string;
};

const UpdateReview: FC<Props> = ({ reviewId, expenseId }) => {
  const {
    user: { name },
  } = useUser<true>();

  const update = api.expenses.reviewer.update.useMutation();
  const utils = api.useUtils();

  const handleApprove = (approval: boolean) => async () => {
    const res = await update.mutateAsync({
      reviewId,
      approval: approval ? "approved" : "rejected",
    });

    utils.expenses.get.setData(expenseId, (p) => {
      if (!p) return p;
      return {
        ...p,
        reviews: p.reviews.map((r) => (r.id === reviewId ? res : r)),
      };
    });
  };

  if (update.isLoading) return <Rows count={1} />;

  return (
    <div className="btn justify-between">
      <div className="">
        {name} <span className="text-xs">(you)</span>
      </div>
      <div className="flex items-center gap-2">
        <button
          className="btn btn-success btn-xs"
          onClick={handleApprove(true)}
        >
          <IoMdCheckmarkCircleOutline />
        </button>
        <button
          className="btn btn-error btn-outline btn-xs"
          onClick={handleApprove(false)}
        >
          <IoMdRemoveCircle />
        </button>
      </div>
    </div>
  );
};

export default UpdateReview;
