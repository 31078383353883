import Drawer from "@/components/drawer/Drawer";
import Select from "@/components/Select";
import Switch from "@/components/Switch";
import { useOrganization } from "@/context/OrganizationContext";
import { AddMembership } from "@/types/validation";
import LoadingSpin from "@/ui/LoadingSpin";
import { formatLabel, wait } from "@/utils/helper";
import { api } from "@/utils/trpc";
import useForm from "@/utils/useForm";
import { FC, useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";

type Props = {
  accountId: string;
};

const defaultValue = {
  canInitiatePayments: false,
  canManageAccountMembership: false,
  canManageBeneficiaries: false,
  canManageCards: false,
  canViewAccount: false,
};

const CreateMembership: FC<Props> = ({ accountId }) => {
  const { inputs, setErrors, setInputs, setValue, errors } =
    useForm<Partial<AddMembership>>(defaultValue);

  const { organizationId = "", organization } = useOrganization();
  const [params] = useSearchParams();
  const isOpen = params.has("new");

  const nav = useNavigate();

  const { data = [], isLoading } =
    api.organizations.team_member.withoutSwanMembership.useQuery(
      organizationId
    );
  const add = api.swan.membership.create.useMutation();

  useEffect(() => {
    setInputs(defaultValue);
  }, [isOpen]);

  const handleAdd = async () => {
    if (!organization?.swanAccountMembership?.userId) {
      return toast.error("Swan account membership user info not found");
    }
    const valid = await AddMembership.safeParseAsync(inputs);

    if (!valid.success) {
      return setErrors(valid.error.formErrors.fieldErrors);
    }

    const res = await add.mutateAsync({
      ...valid.data,
      accountId,
      swanUserId: organization.swanAccountMembership.userId,
      consentRedirectUrl: `${window.location.origin}/pro-account/members`,
    });

    if (res.__typename !== "AddAccountMembershipSuccessPayload")
      return toast.error(
        `${formatLabel(res.__typename)}: ${res.message || ""}`
      );

    if (
      res.accountMembership.statusInfo.__typename !==
      "AccountMembershipConsentPendingStatusInfo"
    ) {
      return toast.error(
        `${formatLabel(res.accountMembership.statusInfo.__typename)}: ${
          res.accountMembership.statusInfo.status || ""
        }`
      );
    }

    await toast.promise(wait(1000), {
      loading: "Authorizing your request ...",
      error: "Failed to redirect to OAuth",
      success: "Redirecting to OAuth to get your consent ...",
    });

    await wait(500);
    window.open(res.accountMembership.statusInfo.consent.consentUrl);
  };

  return (
    <Drawer
      isOpen={isOpen}
      onClose={() => nav("/pro-account/members")}
      title="Create Membership"
      className="gap-6 grid"
    >
      <Select
        options={data.map((m) => ({
          label: m.user.name,
          value: m.id,
        }))}
        label="Team Member"
        placeholder={
          isLoading
            ? "Loading members ..."
            : !data.length
            ? "No new members to add"
            : "Chose a team member"
        }
        value={inputs.team_member_id}
        isError={!!errors.team_member_id}
        onChange={(e) => setValue("team_member_id", e)}
      />

      <div>
        <span className="divider divider-start">Permissions</span>
        <Switch
          value={inputs.canViewAccount}
          onChange={(e) => setValue("canViewAccount", e)}
          text="Can View Account"
          className="!w-full justify-between"
        />

        <Switch
          value={inputs.canManageBeneficiaries}
          onChange={(e) => setValue("canManageBeneficiaries", e)}
          text="Can Manage Beneficiaries"
          className="!w-full justify-between"
        />

        <Switch
          value={inputs.canInitiatePayments}
          onChange={(e) => setValue("canInitiatePayments", e)}
          text="Can Initiate Payments"
          className="!w-full justify-between"
        />

        <Switch
          value={inputs.canManageAccountMembership}
          onChange={(e) => setValue("canManageAccountMembership", e)}
          text="Can Manage Account Membership"
          className="!w-full justify-between"
        />

        <Switch
          value={inputs.canManageCards}
          onChange={(e) => setValue("canManageCards", e)}
          text="Can Manage Cards"
          className="!w-full justify-between"
        />
      </div>

      <button className="btn mt-6 btn-sm btn-primary" onClick={handleAdd}>
        <LoadingSpin loading={add.isLoading} />
        Save
      </button>
    </Drawer>
  );
};

export default CreateMembership;
