import { PageTitle } from "@/ui/PageTitle";
import { FaReceipt } from "react-icons/fa";
import { HiChevronRight } from "react-icons/hi";
import { TbFileInvoice, TbReceipt } from "react-icons/tb";
import { Link } from "react-router-dom";

const types = [
  {
    title: "invoice",
    icon: <TbFileInvoice size="26" />,
  },
  { title: "bill", icon: <TbReceipt size="26" /> },
  { title: "expense", icon: <FaReceipt size="26" /> },
] as const;

const Create = () => {
  return (
    <div>
      <PageTitle title="Choose a payment type:" />
      <div className="grid gap-2">
        {types.map((t) => (
          <Link
            key={t.title}
            to={`/${t.title}/create`}
            className="btn justify-between w-full "
          >
            <span className="flex items-center gap-3   font-medium text-base-content text-base">
              {t.icon}
              {t.title}
            </span>
            <HiChevronRight size={22} className="text-base-content" />
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Create;
