type Props = {};

const DashboardSkeleton = (props: Props) => {
  return (
    <div>
      <div className="stats   min-w-fit grid grid-rows-4 lg:grid-rows-2 2xl:grid-rows-1 w-full shadow mb-12">
        <span className="skeleton rounded-none grid content-center gap-4 stat  h-40">
          <span className="skeleton h-4 w-1/2 bg-base-100" />
          <span className="skeleton h-4 w-1/3 bg-base-100" />
          <span className="skeleton h-4 w-2/3 bg-base-100" />
        </span>
        <span className="skeleton rounded-none grid content-center gap-4 stat  h-40">
          <span className="skeleton h-4 w-1/2 bg-base-100" />
          <span className="skeleton h-4 w-1/3 bg-base-100" />
          <span className="skeleton h-4 w-2/3 bg-base-100" />
        </span>
        <span className="skeleton rounded-none grid content-center gap-4 stat  h-40">
          <span className="skeleton h-4 w-1/2 bg-base-100" />
          <span className="skeleton h-4 w-1/3 bg-base-100" />
          <span className="skeleton h-4 w-2/3 bg-base-100" />
        </span>
        <span className="skeleton rounded-none grid content-center gap-4 stat  h-40">
          <span className="skeleton h-4 w-1/2 bg-base-100" />
          <span className="skeleton h-4 w-1/3 bg-base-100" />
          <span className="skeleton h-4 w-2/3 bg-base-100" />
        </span>
      </div>
    </div>
  );
};

export default DashboardSkeleton;
