import { DataRowType } from "@/components/DataRow";
import NotFound from "@/components/utils/notfound";
import Spinner from "@/components/utils/spinner";
import { useOrganization } from "@/context/OrganizationContext";
import { useUser } from "@/context/UserContext";
import { Customer } from "@/types";
import Collapsible from "@/ui/Collapsible";
import { PageTitle } from "@/ui/PageTitle";
import { api } from "@/utils/trpc";
import { addDays } from "date-fns";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import InvoiceDetails, { CreateInvoiceProps } from "./InvoiceDetails";
import SelectCustomer from "./SelectCustomer";
import SelectOrganizationAccount, {
  AccountInfo,
} from "./SelectOrganizationAccount";

const UpdateInvoice = () => {
  const { userId } = useUser();
  const { organizationId } = useOrganization();
  const { invoiceId = "" } = useParams();
  const nav = useNavigate();

  const update = api.invoices.updateInvoice.useMutation();
  const { data: invoice, isLoading } = api.invoices.invoiceToUpdate.useQuery(
    invoiceId,
    { enabled: !!invoiceId }
  );

  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(
    null
  );
  const [accountInfo, setAccountInfo] = useState<AccountInfo | null>(null);

  if (isLoading) return <Spinner />;

  const canEdit =
    invoice &&
    ["draft", "scheduled"].includes(invoice.status) &&
    invoice.organizationId === organizationId;

  if (!canEdit) return <NotFound title="invoice" />;

  const handleCreate = async ({
    rows,
    customId,
    dueDate = addDays(new Date(), 30),
    currency,
    terms,
    paymentLinkEnabled,
    files,
  }: CreateInvoiceProps) => {
    if (!selectedCustomer || !userId || !organizationId || !accountInfo) return;

    const { totalAmount, totalVat } = rows.reduce(
      (acc, { amount, vat, quantity }) => {
        acc.totalVat += vat * quantity;
        acc.totalAmount += amount * quantity;
        return acc;
      },
      { totalAmount: 0, totalVat: 0 }
    );

    await update.mutateAsync({
      id: invoice.id,
      customerId: selectedCustomer.id,
      rows,
      accountInfo,
      dueDate,
      terms,
      total: totalAmount + totalVat,
      totalAmount,
      totalVat,
      customId,
      currency,
      paymentLinkEnabled,
      files,
      organizationId,
    });

    toast.success("Invoice updated successfully");
    nav(`/invoice/${invoice.id}`);
  };

  return (
    <div className="grid content-start gap-4">
      <PageTitle title="Create Invoice" />
      <Collapsible
        header={
          <div>
            {!accountInfo ? (
              <p className="font-semibold ">Choose a bank account:</p>
            ) : (
              <p className="text-sm flex items-center gap-2">
                Selected account:
                <span className="font-bold text-base">{accountInfo.name}</span>
              </p>
            )}
          </div>
        }
        render={(toggle) => (
          <SelectOrganizationAccount
            {...{
              setAccountInfo: (a) => {
                setAccountInfo(a);
                toggle(false);
              },
            }}
          />
        )}
      />
      <Collapsible
        hide={!accountInfo}
        header={
          <div>
            {!selectedCustomer ? (
              <p className="font-semibold ">Choose a customer:</p>
            ) : (
              <p className="text-sm flex items-center gap-2">
                Selected customer:
                <span className="font-bold text-base">
                  {selectedCustomer.name}
                </span>
              </p>
            )}
          </div>
        }
        render={(toggle) => (
          <SelectCustomer
            {...{
              setSelectedCustomer: (a) => {
                setSelectedCustomer(a);
                toggle(false);
              },
              prevSelection: invoice.customerId,
            }}
          />
        )}
      />

      {selectedCustomer && (
        <InvoiceDetails
          {...{
            handleCreate,
            data: invoice,
            prevRows: invoice.rows.reduce(
              (acc, el) => ({
                ...acc,
                [el.id]: {
                  ...el,
                  vat: (el.vat / el.amount) * 100,
                },
              }),
              {} as Record<string, DataRowType>
            ),
          }}
        />
      )}
    </div>
  );
};

export default UpdateInvoice;
