import Rows from "@/ui/skeletons/Rows";
import { api, RouterOutputs } from "@/utils/trpc";
import { FC } from "react";
import toast from "react-hot-toast";
import { HiPlusCircle } from "react-icons/hi";
import { RiRefreshLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import CreateTaxCode from "./CreateTaxCode";
import TaxCode from "./TaxCode";

type Props = {
  organizationId: string;
};

export type TaxCodeType =
  RouterOutputs["organizations"]["taxCode"]["list"][number];

const TaxCodeList: FC<Props> = ({ organizationId }) => {
  const {
    data = [],
    isLoading,
    refetch,
    isRefetching,
  } = api.organizations.taxCode.list.useQuery(organizationId);

  const handleRefetch = async () => {
    await refetch();
    toast.success("Refetch successful!");
  };

  if (isLoading) return <Rows count={3} />;
  return (
    <div className="grid gap-6">
      <div className="flex items-center w-fit justify-self-end gap-2">
        <button
          className="btn btn-secondary btn-outline btn-sm"
          onClick={handleRefetch}
        >
          <RiRefreshLine
            size={20}
            className={isRefetching ? "animate-spin" : ""}
          />
        </button>
        <Link to={"?new"} className="btn btn-secondary btn-sm">
          <HiPlusCircle /> New
        </Link>
      </div>

      <table className="table bg-base-200">
        <thead>
          <tr>
            <th>Name</th>
            <th>Rate</th>
            <th>Code</th>
            <th>Last updated</th>
          </tr>
        </thead>

        <tbody>
          {data.map((t) => (
            <TaxCode taxCode={t} key={t.id} />
          ))}
        </tbody>
      </table>

      <CreateTaxCode />
    </div>
  );
};

export default TaxCodeList;
