import React, { FC, ReactElement } from "react";
import { useInView } from "react-intersection-observer";

type Props = {
  onInView: () => void;
  children?: ReactElement;
};

const InViewTrigger: FC<Props> = ({ onInView, children }) => {
  const [ref] = useInView({
    threshold: 0.5,
    onChange: (inView) => {
      if (inView) {
        onInView();
      }
    },
  });

  return (
    <div ref={ref} className="w-full flex justify-center py-6 text-primary">
      {children}
    </div>
  );
};

export default InViewTrigger;
