import Drawer from "@/components/drawer/Drawer";
import Switch from "@/components/Switch";
import LoadingSpin from "@/ui/LoadingSpin";
import { api } from "@/utils/trpc";
import { FC, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAgents from "../hooks/useAgents";
import { Agent } from "./Agents";

type Props = {
  agent: Agent;
};

const UpdateAgent: FC<Props> = ({ agent }) => {
  const { id } = useParams<{ id: string }>();
  const nav = useNavigate();

  const [instructions, setInstructions] = useState(agent.instructions);
  const [autoApprove, setAutoApprove] = useState(agent.autoApprove);

  const update = api.organizations.agent.update.useMutation();
  const _update = useAgents();

  const handleUpdate = async () => {
    const res = await update.mutateAsync({
      id: agent.id,
      instructions,
      autoApprove,
    });
    _update(agent.id, res);
  };

  return (
    <Drawer
      title={agent.name}
      isOpen={id === agent.id}
      onClose={() => nav("/organization/agents")}
      className="grid gap-2"
    >
      <div className="flex items-center gap-4 justify-between">
        <p className="title flex-1">Auto Approve</p>
        <Switch onChange={setAutoApprove} value={autoApprove} />
      </div>
      <div className="">
        <p className="divider divider-start">Custom Instruction</p>
        <textarea
          onChange={(e) => setInstructions(e.target.value)}
          value={instructions!}
          className="textarea textarea-primary w-full"
          placeholder="Instruct your agent to do something especial for you"
        />
      </div>
      <button onClick={handleUpdate} className="btn ml-auto btn-sm btn-primary">
        Save <LoadingSpin loading={update.isLoading} />
      </button>
    </Drawer>
  );
};

export default UpdateAgent;
