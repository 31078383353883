import Collapsible from "@/ui/Collapsible";
import { FC } from "react";
import { JsonView, allExpanded, defaultStyles } from "react-json-view-lite";

type Props = {
  json: any;
  defaultOpen?: boolean;
};

const ViewJSON: FC<Props> = ({ json, defaultOpen }) => {
  return (
    <Collapsible header="View Json" defaultOpen={defaultOpen}>
      <JsonView
        data={json}
        shouldExpandNode={allExpanded}
        style={{
          ...defaultStyles,
          container:
            "break-words text-start w-full break-all rounded-xl text-xs bg-white",
        }}
      />
    </Collapsible>
  );
};

export default ViewJSON;
