import { PageTitle } from "@/ui/PageTitle";
import { HiPlus } from "react-icons/hi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Employees from "./Staff";

const People: React.FC = () => {
  const l = useLocation();
  const nav = useNavigate();

  return (
    <div className="grid gap-2 content-start">
      <PageTitle title="People" />
      <Link
        to={`${l.pathname}?modal=createEmployee`}
        className={`btn btn-primary justify-self-end`}
      >
        <HiPlus />
      </Link>
      <Employees />
    </div>
  );
};

export default People;
