import { ReactNode, useEffect, useMemo, useState } from "react";

import { TbCalendarEvent } from "react-icons/tb";
import { Link, useLocation, useNavigate } from "react-router-dom";

import Dropdown from "@/components/Dropdown";
import { PageTitle } from "@/ui/PageTitle";
import SelectDate from "@/ui/SelectDate";
import { saveFile } from "@/utils/helper";
import { endOfMonth, startOfMonth } from "date-fns";
import format from "date-fns/format";
import { json2csv } from "json-2-csv";
import { FaReceipt } from "react-icons/fa";
import { HiDownload, HiPlus } from "react-icons/hi";
import { MdFilterListAlt } from "react-icons/md";
import { DatePeriod } from "../../components/PaymentsWrapper";
import Events from "../event/Events";
import EmployeeExpenses from "../expense/EmployeeExpenses";

const tabs = [
  {
    name: "expenses" as const,
    icon: <FaReceipt size="18" />,
  },
  {
    name: "events" as const,
    icon: <TbCalendarEvent size="18" />,
  },
];

type Tab = (typeof tabs)[number]["name"];

const routeAction: Record<Tab, string> = {
  expenses: "/expense/create",
  events: "/employee/expenses?tab=events&modal=createEvent",
};

const routeLinks: Record<Tab, string> = {
  expenses: "/employee/expenses",
  events: "/employee/expenses?tab=events",
};

const EmployeePayments = () => {
  const location = useLocation();
  const nav = useNavigate();

  const [selection, setSelection] = useState<Record<string, any>>({});
  const [tab, setTab] = useState<Tab>("expenses");

  const [showFilters, setShowFilters] = useState(false);
  const [period, setPeriod] = useState<DatePeriod | null>({
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
  });

  const props = {
    setShowFilters,
    showFilters,
    selection,
    setSelection,
    period,
  };

  const content: Record<Tab, ReactNode> = {
    expenses: <EmployeeExpenses {...props} />,
    events: <Events />,
  };

  useEffect(() => {
    setSelection([]);
    setShowFilters(false);
  }, [tab]);

  const selectionArray = useMemo(() => Object.values(selection), [selection]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const _tab = queryParams.get("tab") as Tab;

    if (_tab === "events") setTab(_tab);
    else setTab("expenses");
  }, [location.search]);

  const handleDownload = async () => {
    if (!selectionArray.length) return;

    const csv = json2csv(selectionArray);
    const blob = new Blob([csv], { type: "text/csv" });

    saveFile(blob, `Dynt-${tab}-${format(new Date(), "dd MMM yyyy")}.csv`);
  };

  return (
    <div className="h-full flex-1 overflow-auto flex flex-col">
      <PageTitle title="Expenses" />

      <div className="flex  gap-2 w-full z-[60] justify-between">
        <div className="lg:flex w-full hidden flex-1 gap-3 items-center">
          {tabs.map((t) => (
            <Link
              to={routeLinks[t.name]}
              key={t.name}
              className={`btn ${t.name == tab ? "btn-primary" : " "}`}
            >
              {t.icon}
              {t.name}
            </Link>
          ))}
        </div>

        <div className="flex  lg:flex-none flex-1 gap-2 justify-between items-center">
          <Dropdown
            dropdownClass="w-full max-w-sm z-50 lg:hidden"
            title={tab}
            buttonClass="capitalize"
            content={tabs.map((t) => (
              <button
                className="capitalize"
                onClick={() => nav(routeLinks[t.name])}
                onTouchEnd={() => nav(routeLinks[t.name])}
                key={t.name}
              >
                {t.name}
              </button>
            ))}
          />
          <SelectDate
            value={period}
            showFutureDates
            className="w-fit lg:block hidden my-4"
            popoverDirection="down"
            onChange={(v) => {
              if (!v?.endDate || !v.startDate) return setPeriod(null);

              setPeriod({
                endDate: new Date(v.endDate),
                startDate: new Date(v.startDate),
              });
            }}
          />

          <div className="flex items-center gap-2">
            <button
              onClick={() => setShowFilters(true)}
              className="btn btn-primary"
            >
              <MdFilterListAlt />
            </button>
            <button
              className={`btn btn-sm h-12 md:btn-md ${
                selectionArray.length ? " btn-primary" : "pointer-events-none"
              } `}
              onClick={handleDownload}
            >
              <HiDownload />
            </button>

            <Link to={routeAction[tab]} className="btn btn-primary">
              <HiPlus size="20" />
            </Link>
          </div>
        </div>
      </div>
      <SelectDate
        value={period}
        className="w-full lg:hidden my-4"
        popoverDirection="down"
        showFutureDates
        onChange={(v) => {
          if (!v?.endDate || !v.startDate) return setPeriod(null);

          setPeriod({
            endDate: new Date(v.endDate),
            startDate: new Date(v.startDate),
          });
        }}
      />
      {content[tab]}
    </div>
  );
};

export default EmployeePayments;
