import { useOrganization } from "@/context/OrganizationContext";
import { PageTitle } from "@/ui/PageTitle";
import { api } from "@/utils/trpc";
import { useEffect, useMemo, useState } from "react";

import NotFound from "@/components/utils/notfound";
import Spinner from "@/components/utils/spinner";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import ExpenseDetails, { CreateExpenseProps } from "./ExpenseDetails";

import { DataRowType } from "@/components/DataRow";
import { useUser } from "@/context/UserContext";
import { BaseType } from "@/types";
import Checkbox from "@/ui/Checkbox";
import {
  MultiSelect,
  MultiSelectItem,
  SearchSelect,
  SearchSelectItem,
} from "@tremor/react";

const UpdateExpense = () => {
  const { organizationId = "", organization } = useOrganization();
  const { expenseId = "" } = useParams();
  const { userId } = useUser();
  const nav = useNavigate();
  const utils = api.useUtils();

  const { data: staffs = [] } = api.organizations.staffs.useQuery(
    organizationId,
    { enabled: !!organizationId }
  );

  const { data: teamMembers = [] } = api.organizations.teamMembers.useQuery(
    organizationId,
    { enabled: !!organizationId }
  );

  const { data: events = [] } = api.expenses.events.useQuery(organizationId, {
    enabled: !!organizationId,
  });

  const update = api.expenses.updateExpense.useMutation();
  const { data: merchants = [] } = api.expenses.merchants.useQuery(
    organizationId,
    { enabled: !!organizationId }
  );

  const { data: expense, isLoading } = api.expenses.expenseToUpdate.useQuery(
    expenseId,
    { enabled: !!expenseId }
  );

  const [teamMemberId, setTeamMemberId] = useState<string>();
  const [guestStaff, setGuestStaff] = useState<string[]>([]);
  const [eventId, setEventId] = useState<string | null>(null);
  const [merchantName, setMerchantName] = useState("");
  const [merchant, setMerchant] = useState<Partial<BaseType> | null>(null);
  const [externalGuest, setExternalGuest] = useState(false);

  useEffect(() => {
    if (!expense) return;
    setTeamMemberId(expense.team_member_id);
    setGuestStaff(expense.guestStaff.map((s) => s.staff.id));
    setEventId(expense.eventId);
    setMerchant(expense.merchant);
    setExternalGuest(expense.externalGuest);
  }, [expense]);

  const teamMembersList = useMemo(() => {
    if (!organization?.role || !userId) return [];
    return teamMembers.filter(
      (s) => organization.role === "admin" || s.user.id === userId
    );
  }, [teamMembers.length, organization?.role, userId]);

  if (isLoading) return <Spinner />;

  if (
    !expense ||
    !["draft", "rejected"].includes(expense.status) ||
    expense.userId !== userId
  ) {
    return <NotFound title="expense" />;
  }

  const handleCreate = async ({
    rows,
    customId,
    date,
    currency,
    files,
  }: CreateExpenseProps) => {
    if (!organizationId || !merchant || !teamMemberId) return;

    const { totalAmount, totalVat } = rows.reduce(
      (acc, { amount, vat, quantity }) => {
        acc.totalVat += vat * quantity;
        acc.totalAmount += amount * quantity;
        return acc;
      },
      { totalAmount: 0, totalVat: 0 }
    );

    const expense = await update.mutateAsync({
      id: expenseId,
      rows,
      date,
      total: totalAmount + totalVat,
      totalAmount,
      totalVat,
      customId,
      currency,
      organizationId,
      team_member_id: teamMemberId,
      guestStaff,
      eventId,
      merchant,
      externalGuest,
      files,
    });

    toast.success("Expense created successfully");
    await utils.expenses.expensesByOrgId.invalidate({ organizationId });

    nav(`/expense/${expense.id}`);
  };

  return (
    <div className="grid content-start gap-4">
      <PageTitle title="Create Expense" />
      <div className="">
        <p className="font-semibold mb-2">Choose a Merchant:</p>

        <Select
          options={[{ name: merchantName, id: undefined }, ...merchants]}
          onInputChange={(e) => setMerchantName(e)}
          getOptionLabel={(m) => m.name as string}
          onChange={(e) => setMerchant(e)}
          value={merchant}
          getOptionValue={(m) => m.id || (m.name as string)}
          formatOptionLabel={(m) => (
            <div
              className={`items-center justify-between w-full ${
                m.name ? "flex" : "hidden"
              }`}
            >
              <p
                title={m.name}
                className="flex-wrap text-xs overflow-hidden w-40 font-semibold"
              >
                {m.name}
              </p>

              <div
                className={`rounded-full px-3 py-0.5  text-xs w-fit text-white ${
                  !m.id ? "bg-primary" : "bg-secondary"
                } `}
              >
                {m.id ? "Add" : "Create new"}
              </div>
            </div>
          )}
        />
      </div>
      {!!merchant && (
        <div>
          <p className="font-semibold mb-2">Choose a team member:</p>
          <SearchSelect
            enableClear={false}
            value={teamMemberId}
            onValueChange={setTeamMemberId}
          >
            {teamMembersList.map((s) => (
              <SearchSelectItem key={s.id} value={s.id}>
                {s.user.name}
              </SearchSelectItem>
            ))}
          </SearchSelect>
        </div>
      )}
      {!!teamMemberId && (
        <>
          <div>
            <div className="flex items-center gap-2 mb-2">
              <p
                className={`font-semibold ${
                  externalGuest ? "opacity-50 pointer-events-none" : ""
                }`}
              >
                Choose guest staffs:
              </p>
              <div className="flex items-center">
                <Checkbox
                  checked={externalGuest}
                  onChange={(c) => {
                    setExternalGuest(c);
                    setGuestStaff([]);
                  }}
                />
                <p className="text-sm">External guest</p>
              </div>
            </div>
            <MultiSelect
              className={externalGuest ? "opacity-50 pointer-events-none" : ""}
              onValueChange={setGuestStaff}
              value={guestStaff}
            >
              {staffs.map((s) => {
                if (s.id === teamMemberId) return null;
                return (
                  <MultiSelectItem key={s.id} value={s.id}>
                    {s.name}
                  </MultiSelectItem>
                );
              })}
            </MultiSelect>
          </div>
          <div>
            <p className="font-semibold mb-2">Select an event:</p>
            <SearchSelect
              enableClear
              onValueChange={setEventId}
              value={eventId || ""}
            >
              {events.map((s) => (
                <SearchSelectItem key={s.id} value={s.id}>
                  {s.name}
                </SearchSelectItem>
              ))}
            </SearchSelect>
          </div>
          <ExpenseDetails
            {...{
              handleCreate,
              data: {
                date: expense.date,
                customId: expense.customId,
                currency: expense.currency,
              },
              prevRows: expense.rows.reduce(
                (acc, el) => ({
                  ...acc,
                  [el.id]: {
                    ...el,
                    vat: (el.vat / el.amount) * 100,
                  },
                }),
                {} as Record<string, DataRowType>
              ),
            }}
          />
        </>
      )}
    </div>
  );
};

export default UpdateExpense;
