import SelectAccountingStatus from "@/components/SelectAccountingStatus";
import { AccountingStatusType } from "@/lib";
import { RouterInputs, api } from "@/utils/trpc";
import { FC } from "react";

type Props = {
  status: AccountingStatusType;
  invoiceId: string;
  params: RouterInputs["invoices"]["invoices"];
};

const AccountingStatus: FC<Props> = ({ status, invoiceId, params }) => {
  const update = api.invoices.updateAccountingStatus.useMutation();
  const utils = api.useUtils();

  const handleUpdate = (status: AccountingStatusType) => async () => {
    const res = await update.mutateAsync({ invoiceId, status });

    utils.invoices.invoices.setData(params, (p) => {
      if (!p) return p;
      return p.map((b) => {
        if (b.id === invoiceId) return { ...b, ...res };
        return b;
      });
    });
  };
  return (
    <SelectAccountingStatus
      {...{ status, setStatus: handleUpdate, isLoading: update.isLoading }}
    />
  );
};

export default AccountingStatus;
