import Spinner from "@/components/utils/spinner";
import { useOrganization } from "@/context/OrganizationContext";
import { useUser } from "@/context/UserContext";
import {
  BillFiltersType,
  ExpenseFiltersType,
  InvoiceFiltersType,
  TransactionFiltersType,
} from "@/types/validation";
import Collapsible from "@/ui/Collapsible";
import { PageTitle } from "@/ui/PageTitle";
import {
  encodeObjectToBase64,
  formatCurrency,
  generateColor,
} from "@/utils/helper";
import { api } from "@/utils/trpc";
import endOfMonth from "date-fns/endOfMonth";
import format from "date-fns/format";
import formatDistance from "date-fns/formatDistance";
import startOfMonth from "date-fns/startOfMonth";
import { BiRightArrowAlt } from "react-icons/bi";
import { Link } from "react-router-dom";
import { DatePeriod } from "../../components/PaymentsWrapper";

type Props = {};

const defaultValue = {
  transactionsWithoutProof: 0,
  unApprovedBills: [],
  unApprovedExpenses: [],
  unApprovedInvoices: [],
  unCategorizedTransactions: 0,
  missingProofInMonthClosure: 0,
} as const;

const Tasks = (props: Props) => {
  const { organizationId = "" } = useOrganization();
  const { userId = "" } = useUser();
  const { data: tasks = defaultValue, isLoading } = api.utils.tasks.useQuery({
    organizationId,
    userId,
  });

  const noAssignedTasks =
    !isLoading &&
    !(
      tasks.unApprovedBills.length ||
      tasks.unApprovedExpenses.length ||
      tasks.unApprovedInvoices.length
    );

  const noRecommendedTasks =
    !isLoading &&
    !(tasks.transactionsWithoutProof || tasks.unCategorizedTransactions);

  return (
    <>
      <PageTitle title="Tasks" />
      <div className="grid gap-6">
        {noAssignedTasks && noRecommendedTasks && (
          <div className="flex w-full items-center justify-between rounded-md">
            <p>You are all set, no tasks left</p>
          </div>
        )}
        <div className="">
          <h1 className="text-xl mb-5 font-semibold">Assigned to me</h1>
          {isLoading && <Spinner />}
          {noAssignedTasks && (
            <div className="flex w-full items-center justify-between rounded-md">
              <p>You are all set, no tasks assigned to you</p>
            </div>
          )}
          <div className="grid gap-4">
            {!!tasks.unApprovedExpenses.length && (
              <Collapsible
                header={
                  <div className="flex w-full items-center justify-between">
                    <p>
                      Expenses to Approve ({tasks.unApprovedExpenses.length})
                    </p>
                    <Link
                      className="link z-10 link-primary link-hover"
                      to={`/expenses?filters=${encodeObjectToBase64<ExpenseFiltersType>(
                        { assignedTo: userId }
                      )}`}
                    >
                      view
                    </Link>
                  </div>
                }
              >
                <div className="grid grid-cols-5 mb-4 pb-2 text-gray-600 border-b text-base">
                  <p>Details</p>
                  <p>Status</p>
                  <p>Create at</p>
                  <p className="col-span-2 text-center">Assignees</p>
                </div>
                <div className="grid gap-2 text-sm">
                  {tasks.unApprovedExpenses.map((e) => (
                    <Link
                      to={`/expenses/${e.id}`}
                      key={e.id}
                      className="grid grid-cols-5 border-b pb-2"
                    >
                      <p>{formatCurrency(e.total, e.currency)}</p>
                      <p
                        className={`capitalize w-fit px-2 py-0.5 text-white text-xs rounded-sm  `}
                      >
                        {e.status}
                      </p>
                      <p>
                        {formatDistance(e.createdAt, new Date(), {
                          addSuffix: true,
                        })}
                      </p>
                      <div className="col-span-2 flex items-center justify-center ">
                        <div className="flex ml-auto justify-center gap-1 text-white text-xs flex-wrap">
                          {e.assignees.map((a) => {
                            const name = a.team_member.user.name;
                            return (
                              <div
                                className="rounded-full w-6 h-6 grid place-content-center"
                                style={{
                                  backgroundColor: generateColor(name || ""),
                                }}
                              >
                                {name?.slice(0, 1)}
                              </div>
                            );
                          })}
                        </div>
                        <BiRightArrowAlt className="ml-auto" />
                      </div>
                    </Link>
                  ))}
                </div>
              </Collapsible>
            )}
            {!!tasks.unApprovedInvoices.length && (
              <Collapsible
                header={
                  <div className="flex w-full items-center justify-between">
                    <p>
                      Invoices to Approve ({tasks.unApprovedInvoices.length})
                    </p>

                    <Link
                      to={`/invoices?filters=${encodeObjectToBase64<InvoiceFiltersType>(
                        { assignedTo: userId }
                      )}`}
                      className="link z-10 link-primary link-hover"
                    >
                      view
                    </Link>
                  </div>
                }
              >
                <div className="grid grid-cols-5 mb-4 pb-2 text-gray-600 border-b text-base">
                  <p>Details</p>
                  <p>Status</p>
                  <p>Create at</p>
                  <p className="col-span-2 text-center">Assignees</p>
                </div>
                <div className="grid gap-2 text-sm">
                  {tasks.unApprovedInvoices.map((e) => (
                    <Link
                      to={`/invoices/${e.id}`}
                      key={e.id}
                      className="grid grid-cols-5 border-b pb-2"
                    >
                      <p>{formatCurrency(e.total, e.currency)}</p>
                      <p
                        className={`capitalize w-fit px-2 py-0.5 text-white text-xs rounded-sm  `}
                      >
                        {e.status}
                      </p>
                      <p>
                        {formatDistance(e.createdAt, new Date(), {
                          addSuffix: true,
                        })}
                      </p>
                      <div className="col-span-2 flex justify-center gap-1 text-white text-xs flex-wrap">
                        {e.assignees.map((a) => {
                          const name = a.team_member.user.name;
                          return (
                            <div
                              className="rounded-full w-6 h-6 grid place-content-center"
                              style={{
                                backgroundColor: generateColor(name || ""),
                              }}
                            >
                              {name?.slice(0, 1)}
                            </div>
                          );
                        })}
                      </div>
                    </Link>
                  ))}
                </div>
              </Collapsible>
            )}
            {!!tasks.unApprovedBills.length && (
              <Collapsible
                header={
                  <div className="flex w-full items-center justify-between">
                    <p>Bills to Approve ({tasks.unApprovedBills.length})</p>

                    <Link
                      to={`/bills?filters=${encodeObjectToBase64<BillFiltersType>(
                        { assignedTo: userId }
                      )}`}
                      className="link z-10 link-primary link-hover"
                    >
                      view
                    </Link>
                  </div>
                }
              >
                <div className="grid grid-cols-5 mb-4 pb-2 text-gray-600 border-b text-base">
                  <p>Details</p>
                  <p>Status</p>
                  <p>Create at</p>
                  <p className="col-span-2 text-center">Assignees</p>
                </div>
                <div className="grid gap-2 text-sm">
                  {tasks.unApprovedBills.map((e) => (
                    <Link
                      to={`/bills/${e.id}`}
                      key={e.id}
                      className="grid grid-cols-5 border-b pb-2"
                    >
                      <p>{formatCurrency(e.total, e.currency)}</p>
                      <p
                        className={`capitalize w-fit px-2 py-0.5 text-white text-xs rounded-sm  `}
                      >
                        {e.status}
                      </p>
                      <p>
                        {formatDistance(e.createdAt, new Date(), {
                          addSuffix: true,
                        })}
                      </p>
                      <div className="col-span-2 flex justify-center gap-1 text-white text-xs flex-wrap">
                        {e.assignees.map((a) => {
                          const name = a.team_member.user.name || "";
                          return (
                            <div
                              className="rounded-full w-6 h-6 grid place-content-center"
                              style={{ backgroundColor: generateColor(name) }}
                            >
                              {name.slice(0, 1)}
                            </div>
                          );
                        })}
                      </div>
                    </Link>
                  ))}
                </div>
              </Collapsible>
            )}
          </div>
        </div>
        <div className="">
          <h1 className="text-xl mb-5 font-semibold">
            {format(new Date(), "MMMM")} - Month End
          </h1>
          {isLoading && <Spinner />}

          <div className="grid gap-4">
            {!!tasks.missingProofInMonthClosure && (
              <Link
                to={`/transactions?filters=${encodeObjectToBase64<TransactionFiltersType>(
                  { missingAttachments: true }
                )}&period=${encodeObjectToBase64<DatePeriod>({
                  endDate: endOfMonth(new Date()),
                  startDate: startOfMonth(new Date()),
                })}`}
                className="btn justify-between"
              >
                <p>
                  Proof of Transaction ({tasks.missingProofInMonthClosure}){" "}
                </p>

                <span className="link link-primary flex items-center gap-2 link-hover">
                  view <BiRightArrowAlt className="ml-auto" />
                </span>
              </Link>
            )}
          </div>
        </div>
        <div className="">
          <h1 className="text-xl mb-5 font-semibold">Recommended</h1>
          {isLoading && <Spinner />}

          <div className="grid gap-4">
            {!!tasks.transactionsWithoutProof && (
              <Link
                to={`/transactions/banks`}
                // ?filters=${encodeObjectToBase64<TransactionFiltersType>(
                //   { missingAttachments: true }
                // )}
                className="btn justify-between"
              >
                <p>Proof of Transaction ({tasks.transactionsWithoutProof}) </p>

                <span className="link link-primary flex items-center gap-2 link-hover">
                  view <BiRightArrowAlt className="ml-auto" />
                </span>
              </Link>
            )}
            {!!tasks.unCategorizedTransactions && (
              <Link
                to={`/transactions/banks`}
                // ?filters=${encodeObjectToBase64<TransactionFiltersType>(
                //   { unCategorized: true }
                // )}
                className="btn justify-between"
              >
                <p>Categorization ({tasks.unCategorizedTransactions}) </p>

                <span className="link link-primary flex items-center gap-2 link-hover">
                  view <BiRightArrowAlt className="ml-auto" />
                </span>
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Tasks;
