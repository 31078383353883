import { useOrganization } from "@/context/OrganizationContext";
import LoadingSpin from "@/ui/LoadingSpin";
import { api } from "@/utils/trpc";
import useForm from "@/utils/useForm";
import { FC } from "react";
import { toast } from "react-hot-toast";

import Drawer from "@/components/drawer/Drawer";
import FormInput from "@/components/FormInput";
import PhoneInput from "@/components/PhoneInput";
import { useNavigate, useSearchParams } from "react-router-dom";
import useCustomer from "../hooks/useCustomer";
import { Partner } from "../validation";

export const fields = [
  {
    name: "name",
    placeholder: "Enter name",
    title: "Name",
  },
  {
    name: "email",
    placeholder: "Enter email",
    title: "Email address",
  },
  {
    name: "business_tax_number",
    placeholder: "Enter business tax number",
    title: "Tax Number",
  },
  {
    name: "business_number",
    placeholder: "Enter business number",
    title: "Business Number",
  },
  {
    name: "city",
    placeholder: "Enter city name",
    title: "City name",
  },
  {
    name: "postCode",
    placeholder: "Enter postal code",
    title: "Postal Code",
  },
  {
    name: "address",
    placeholder: "Enter address",
    title: "Address",
  },
] as const;

type Props = {};

const CreateCustomer: FC<Props> = () => {
  const { handleChange, inputs, errors, setValue, setErrors } = useForm<
    Partial<Partner>
  >({});

  const [params] = useSearchParams();
  const nav = useNavigate();
  const isOpen = params.has("new");

  const { organizationId } = useOrganization<true>();

  const upsert = api.organizations.customer.create.useMutation();
  const update = useCustomer();

  const handleUpdate = async () => {
    if (!organizationId) return;

    const valid = Partner.safeParse(inputs);

    if (!valid.success) {
      setErrors(valid.error.formErrors.fieldErrors);

      return toast.error("Please check the details and try again");
    }

    const res = await upsert.mutateAsync({
      ...valid.data,
      organizationId: organizationId,
    });

    update(res);
    nav("/partners/customers");
  };

  return (
    <Drawer
      isOpen={isOpen}
      onClose={() => nav("/partners/customers")}
      title="Create Customer"
      className="grid gap-6"
    >
      <div className="grid gap-x-2 grid-cols-2">
        {fields.map((p) => (
          <FormInput
            {...p}
            key={p.name}
            className="last:col-span-2"
            onChange={handleChange(p.name)}
            isError={errors[p.name]}
            value={inputs[p.name]}
          />
        ))}
      </div>

      <PhoneInput
        onChange={(e) => setValue("phone", e)}
        value={inputs.phone}
        isError={errors.phone}
      />

      <button
        className="btn  ml-auto btn-sm w-fit btn-primary"
        onClick={() => handleUpdate()}
      >
        <LoadingSpin loading={upsert.isLoading} />
        Create
      </button>
    </Drawer>
  );
};

export default CreateCustomer;
