import { z } from "zod";

export const ExternalAccount = z.object({
  name: z.string(),
  currency: z.string(),
  IBAN: z.string().nullish(),
  SWIFT: z.string().nullish(),
  ACCOUNT_NUMBER: z.string().nullish(),
  ROUTING_NUMBER: z.string().nullish(),
  SORT_CODE: z.string().nullish(),
  BBAN: z.string().nullish(),
  bankAddress: z.string().nullish(),
});

export type ExternalAccount = z.infer<typeof ExternalAccount>;

export const Partner = z.object({
  name: z.string(),
  email: z.string().nullish(),
  phone: z.string().nullish(),

  photo: z.string().nullish(),

  business_number: z.string().nullish(),
  business_tax_number: z.string().nullish(),

  address: z.string().nullish(),
  postCode: z.string().nullish(),
  city: z.string().nullish(),
});

export type Partner = z.infer<typeof Partner>;
