import { RouterOutputs } from "@/utils/trpc";
import { FC } from "react";
import { BsThreeDots } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import MembershipDetails from "./MembershipDetails";

type Props = {
  member: RouterOutputs["swan"]["membership"]["list"][number];
};

const badgeColor = {
  Enabled: "btn-success",
  BindingUserError: "btn-error",
  ConsentPending: "btn-warning",
  InvitationSent: "btn-neutral",
  Suspended: "btn-error",
  Disabled: "btn-warning",
};

const Member: FC<Props> = ({ member }) => {
  const nav = useNavigate();
  return (
    <tr
      key={member.id}
      className="cursor-pointer border-white/5"
      onClick={() => nav(`/pro-account/members/${member.id}`)}
    >
      <td className="flex items-center gap-2">
        <p>{member.team_member.user.name}</p>
        <span
          className={`btn btn-xs btn-outline text-xs ${
            badgeColor[member.status]
          } `}
        >
          {member.status}
        </span>
      </td>
      <td>{member._count.cards}</td>
      <td>{member.email}</td>
      <td>{member.team_member.user.phone}</td>
      <th>
        <BsThreeDots />
      </th>
      <MembershipDetails membership={member} />
    </tr>
  );
};

export default Member;
