import ThemeToggle from "@/components/ThemeToggle";
import { useOrganization } from "@/context/OrganizationContext";
import { useUser } from "@/context/UserContext";
import { api } from "@/utils/trpc";
import { FC, useMemo } from "react";

import { Link, useLocation } from "react-router-dom";
import { NavigationControl } from "./NavigationControl";

const defaultValue = {
  transactionsWithoutProof: { id: 0 },
  unApprovedBills: [],
  unApprovedExpenses: [],
  unApprovedInvoices: [],
  unCategorizedTransactions: { id: 0 },
} as const;

const control = new NavigationControl();

const MobileNavBar: FC = () => {
  const { pathname } = useLocation();
  const { organization, organizationId = "" } = useOrganization();
  const { userId = "" } = useUser();

  const { data: tasks = defaultValue } = api.utils.tasks.useQuery({
    organizationId,
    userId,
  });

  const totalTasks = useMemo(() => {
    return (
      tasks.unApprovedBills.length +
      tasks.unApprovedExpenses.length +
      tasks.unApprovedInvoices.length
    );
  }, [tasks]);

  const links = useMemo(() => {
    if (!organization) return [];
    return control.validNavigationLinks(organization.billingPlan);
  }, [organizationId]);

  return (
    <div
      className={`bg-base-200 overflow-auto flex-1 border-r border-gray-400/50 duration-300 transition-all h-fit  ${
        !open ? "w-20" : "2xl:w-56 "
      }`}
    >
      <ul className="menu">
        <li className="w-fit mx-auto sm:hidden">
          <ThemeToggle />
        </li>

        {links.map(({ Icon, name, path, end }, i) => {
          return (
            <>
              <li className="clicky mb-0.5">
                <Link
                  to={path}
                  key={i}
                  className={path === pathname ? "active" : ""}
                >
                  <div className="flex gap-4  items-center">
                    <Icon size={24} />
                    <p className="w-max capitalize">{name}</p>

                    {path === "/tasks" && totalTasks > 0 && (
                      <span
                        className={`badge badge-md ${
                          path === pathname ? "badge-warning" : "badge-neutral"
                        } `}
                      >
                        {totalTasks}
                      </span>
                    )}
                  </div>
                </Link>
              </li>
              {end && <div className="divider h-1"></div>}
            </>
          );
        })}
      </ul>
    </div>
  );
};
export default MobileNavBar;
