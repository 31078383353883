import { PageTitle } from "@/ui/PageTitle";
import useForm from "@/utils/useForm";

import Spinner from "@/components/utils/spinner";
import { useOrganization } from "@/context/OrganizationContext";
import { RuleAction, RuleCondition } from "@/types/validation";
import LoadingSpin from "@/ui/LoadingSpin";
import { TOAST } from "@/utils/helper";
import { api } from "@/utils/trpc";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Actions, { ActionType } from "./Actions";
import Conditions, { Condition, ProductType } from "./Conditions";

const UpdateRule = () => {
  const { id = "" } = useParams();
  const { data: rule, isLoading } = api.rules.get.useQuery(id);

  const { inputs, setValue, setInputs, setErrors, errors } = useForm<
    Record<string, Condition>
  >({
    [Math.random()]: { fact: "status", operator: "", value: "" },
  });

  const {
    inputs: actions,
    setValue: setActionValue,
    setInputs: setActions,
    setErrors: setActionErrors,
    errors: actionErrors,
  } = useForm<Record<string, { type: ActionType; values: string[] }>>({
    [Math.random()]: { type: "addCategory", values: [] },
  });

  const { organizationId = "" } = useOrganization();

  const [currency, setCurrency] = useState<string | null>(null);
  const [name, setName] = useState("");

  const [product, setProduct] = useState<ProductType>("INVOICE");

  const nav = useNavigate();
  const update = api.rules.update.useMutation();

  useEffect(() => {
    if (!rule) return;

    setName(rule.name);

    setProduct(rule.product);
    const conditions = rule.conditions as any as Condition[];

    setCurrency(conditions.find((c) => c.fact === "currency")?.value ?? null);

    const _conditions = conditions.filter((c) => c.fact !== "currency");

    const _inputs = Object.fromEntries(
      _conditions.map((c) => [Math.random(), c])
    );

    setInputs(_inputs);

    const _actions: Record<ActionType, string[]> = {
      addCategory: rule.categorize ? [rule.categorize] : [],
      addSubCategory: rule.subCategorize ? [rule.subCategorize] : [],
      assignTeamMember: rule.assign,
      changeAccountingStatus: rule.accountingStatus
        ? [rule.accountingStatus]
        : [],
      // changeApprovalStatus: rule.approvalStatus ? [rule.approvalStatus] : [],
      changeStatus: rule.status ? [rule.status] : [],
      emailTeamMember: rule.email,
      notifyTeamMember: rule.notify,
      assignReviewer: rule.reviewers,
    };

    setActions(
      Object.fromEntries(
        Object.entries(_actions)
          .filter(([, v]) => v.length)
          .map(([k, v]) => {
            return [Math.random(), { type: k as ActionType, values: v }];
          })
      )
    );
  }, [rule]);

  if (isLoading) return <Spinner />;

  const handleCreate = async () => {
    if (!name) return TOAST("Please enter a name for the rule", "error");
    const conditions = { ...inputs };

    if (currency) {
      conditions[Math.random()] = {
        fact: "currency",
        operator: "equal",
        value: currency,
      };
    }

    const validateCondition = RuleCondition.safeParse(conditions);

    if (!validateCondition.success) {
      setErrors(validateCondition.error.formErrors.fieldErrors);
      return;
    }

    const _actions = Object.values(actions).reduce<
      Partial<Record<ActionType, string[]>>
    >((acc, el) => ({ ...acc, [el.type]: el.values }), {});

    const validateActions = RuleAction.safeParse(_actions);

    if (!validateActions.success) {
      setActionErrors(validateActions.error.formErrors.fieldErrors);
      return;
    }

    await update.mutateAsync({
      id,

      product,
      organizationId,
      name,

      conditions: Object.values(inputs),

      status: _actions.changeStatus?.[0],
      // approvalStatus: _actions.changeApprovalStatus?.[0],
      accountingStatus: _actions.changeAccountingStatus?.[0],

      categorize: _actions.addCategory?.[0],
      subCategorize: _actions.addSubCategory?.[0],

      email: _actions.emailTeamMember,
      assign: _actions.assignTeamMember,
      notify: _actions.notifyTeamMember,
      reviewers: _actions.assignReviewer,
    });

    nav("/automation");
  };

  console.log(rule);
  return (
    <>
      <PageTitle title="Update Rule" />
      <div className="grid gap-8  p-4">
        <div>
          <p className="label text-secondary">Name</p>
          <input
            type="text"
            className="input input-bordered"
            placeholder="Rule name"
            value={name}
            onChange={({ target: { value } }) => setName(value)}
          />
        </div>
        <Conditions
          {...{
            currency,
            errors,
            inputs,
            product,
            setCurrency,
            setInputs,
            setProduct,
            setValue,
          }}
        />
        <Actions
          {...{
            product,
            actions,
            setActions,
            setActionValue,
            errors: actionErrors,
          }}
        />

        <button onClick={handleCreate} className="btn btn-primary w-40 ml-auto">
          <LoadingSpin loading={update.isLoading} />
          Save
        </button>
      </div>
    </>
  );
};

export default UpdateRule;
