// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
BigInt.prototype.toJSON = function () {
  return this.toString();
};

import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";
import { Toaster } from "react-hot-toast";
import {
  BrowserRouter,
  Routes,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import ContextProviders from "./context/Index";
import "./index.css";
import Layout from "./ui/Layout";

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay(),
  ],

  tracesSampleRate: 1.0,

  tracePropagationTargets: ["localhost", "app.dynt.ai"],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export type SentryRouterType = typeof SentryRoutes;

const root = ReactDOM.createRoot(document.getElementById("root")!);

root.render(
  <BrowserRouter>
    <ContextProviders>
      <Layout SentryRoutes={SentryRoutes} />
      <Toaster position="top-right" />
    </ContextProviders>
  </BrowserRouter>
);
